<template>
  <BaseModal
    @close="modalClose"
    @confirm="assignToLeaderConfirm"
    @click="hideAutocompleteOptions"
  >
    <template #title>
      Przypisz jadłospis do prowadzącego
    </template>
    <BaseAutocompleteVirtualized
      v-model="leader"
      :options="leaderOptions"
      :placeholder="'Wybierz prowadzącego'"
      :initial-value="true"
    />
    <template #actions>
      <StyledButton
        variant="outline"
        @click.once="modalClose"
      >
        Anuluj
      </StyledButton>
      <StyledButton
        :disabled="assignToLeaderDisabled"
        @click.once="assignToLeaderConfirm"
      >
        Wyślij
      </StyledButton>
      <template v-if="sending">
        <BaseLoader class="base-loader--size-12" />
      </template>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import type { DietType } from '@/store/types'
import type { PropType } from 'vue'

import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'

import BaseAutocompleteVirtualized from '@/components/BaseAutocompleteVirtualized.vue'
import BaseLoader from '@/components/BaseLoader.vue'
import BaseModal from '@/components/BaseModal.vue'
import { StyledButton } from '@/components/StyledButton'
import eventBus from '@/eventBus'
import { dietsService } from '@/services/dietsService'
import { useGlobalStore } from '@/store/globalStore'
import { reportError } from '@/utils/reportError'

const emit = defineEmits(['close'])

const props = defineProps({
  dietType: {
    required: true,
    type: String as PropType<DietType>
  },
  dietsIds: {
    required: true,
    type: Array as PropType<number[]>
  }
})
const sending = ref(false)
const leader = ref<{ text: string; value: string }>()
const email = computed(() => {
  return leader.value?.value ?? ''
})
const shareDietsButtonEnabled = ref(true)
const store = useGlobalStore()
const { leaderOptions } = storeToRefs(store)

const assignToLeaderDisabled = computed(() => {
  return !email.value || sending.value
})

const modalClose = () => {
  emit('close')
}

const assignToLeaderConfirm = async () => {
  if (!assignToLeaderDisabled.value) {
    try {
      shareDietsButtonEnabled.value = false
      sending.value = true
      const response = await dietsService.assignDietToLeader(
        props.dietType,
        props.dietsIds,
        email.value
      )
      shareDietsButtonEnabled.value = true
      if (response.status === 201) {
        store.createMessage({
          title: 'Wysłano jadłospis do prowadzącego'
        })
      }
    } catch (error) {
      reportError(error, 'Error during assigning diet to leader', {
        dietType: props.dietType,
        dietsIds: props.dietsIds,
        email: email.value
      })
    } finally {
      modalClose()
    }
  }
}
const hideAutocompleteOptions = () => {
  eventBus.emit('hideAutocompleteOptions')
}

onMounted(() => {
  void store.updateLeaderStudents()
})
</script>
