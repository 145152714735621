import { F } from '@mobily/ts-belt'
import { inject, type ComputedRef, type InjectionKey, type Ref } from 'vue'

export type IngredientType = 'is_public' | 'is_private' | 'is_favorite' | 'all'

export type IngredientsFiltersProps = {
  addProduct: () => void
  areFiltersActive: (ingredientType: IngredientType) => boolean
  extendedFilters: Ref<boolean>
  filterDiets: (phrase: string) => void
  getAllCounter: ComputedRef<number>
  getFavoriteCounter: ComputedRef<number>
  getPrivateCounter: ComputedRef<number>
  getPublicCounter: ComputedRef<number>
  ingredientsFilterByCounted: ComputedRef<number>
  ingredientsIzzModalVisible: Ref<boolean>
  ingredientsType: Ref<IngredientType>
  izzConditionsConfirmed: Ref<boolean>
  kcalMax: Ref<number>
  kcalMin: Ref<number>
  resetExtendedFilters: () => void
  resetFiltersValue: Ref<number>
  resetKcalMinMax: () => void
  searchedPhrase: Ref<string>
  setIngrediedientsFilterByType: (type: IngredientType) => void
  setIngredientBaseForFilters: (ingredientBaseList: number[]) => void
  setIngredientCategoryForFilters: (ingredientCategoryList: number[]) => void
  setIngredientTagForFilters: (ingredientTypesList: string[]) => void
  setIngredientTypeForFilters: (ingredientTypesList: IngredientType[]) => void
  setKcalMax: (value: number) => void
  setKcalMin: (value: number) => void
  showChosenNutrientsModal: () => void
  showIngredientsIzzModal: () => void
  toggleExtendedFilters: () => void
}

export const IngredientsFiltersInject = Symbol() as InjectionKey<IngredientsFiltersProps>

export const useIngredientsProvider = () =>
  F.coerce<IngredientsFiltersProps>(inject(IngredientsFiltersInject))
