// 102 elements

// 5 elements
export const basicNutrients = ['calories', 'protein', 'fat', 'carbohydrates', 'fiber'] as const

// 97 elements
export const specificNutrients = [
  // Podstawowe składniki odżywcze
  'energy_value_kj',
  'energy_value_according_regulation_kcal',
  'energy_value_according_regulation_kj',
  'carbohydrates_total',
  'animal_protein',
  'plant_protein',
  'protein_according_regulation',
  // Składniki mineralne
  'sodium',
  'potassium',
  'calcium',
  'phosphorus',
  'magnesium',
  'iron',
  'zinc',
  'copper',
  'iodine',
  'manganese',
  // Witaminy
  'thiamin',
  'riboflavin',
  'niacin',
  'folate_total',
  'vitamin_B_12',
  'vitamin_B6',
  'vitamin_A_RAE',
  'vitamin_C',
  'vitamin_D',
  'vitamin_E',
  'carotene_beta',
  'retinol',
  // Aminokwasy;
  'isoleucine',
  'leucine',
  'lysine',
  'methionine',
  'cystine',
  'phenylalanine',
  'tyrosine',
  'threonine',
  'tryptophan',
  'valine',
  'arginine',
  'histidine',
  'alanine',
  'aspartic_acid',
  'glutamic_acid',
  'glycine',
  'proline',
  'serine',
  // Kwasy tłuszczowe
  'c_4_0',
  'c_6_0',
  'c_8_0',
  'c_10_0',
  'c_12_0',
  'c_14_0',
  'c_15_0',
  'c_16_0',
  'c_17_0',
  'c_18_0',
  'c_20_0',
  'nkt',
  'c_14_1',
  'c_15_1',
  'c_16_1',
  'c_17_1',
  'c_18_1',
  'c_20_1',
  'c_22_1',
  'jnkt',
  'c_18_2',
  'c_18_3',
  'c_18_4',
  'c_20_3',
  'c_20_4',
  'c_20_5',
  'c_22_5',
  'c_22_6',
  'wnkt',
  'n_6',
  'n_3',
  'ratio_n_3_n_6',
  'cholesterol',
  // Inne
  'waste',
  'water',
  'sucrose',
  'lactose',
  'starch',
  'ash',
  'salt',
  'glucose',
  'fructose',
  'protein_energy',
  'fat_energy',
  'carbohydrates_energy',
  'glycemic_index',
  'glycemic_load',
  'simple_sugars_sum',
  'carbohydrate_exchanger',
  'protein_fat_exchangers'
] as const

// 8 elements
export const calculatedNutrients = [
  'waste',
  'ratio_n_3_n_6',
  'protein_energy',
  'fat_energy',
  'carbohydrates_energy',
  'glycemic_load',
  'simple_sugars_sum',
  'protein_fat_exchangers'
] as const

export const basedOnCalculatedNutrients = [
  'n_3',
  'n_6',
  'sucrose',
  'lactose',
  'glucose',
  'fructose'
] as const

export const nutrientsCategories = [
  'Składniki odżywcze',
  'Składniki mineralne',
  'Witaminy',
  'Kwasy tłuszczowe',
  'Aminokwasy',
  'Inne'
] as const

export const allNutriens = [
  ...basicNutrients,
  ...specificNutrients,
  ...calculatedNutrients,
  ...basedOnCalculatedNutrients
] as const

export type BasicNutrients = (typeof basicNutrients)[number]
export type SpecificNutrients = (typeof specificNutrients)[number]
export type CalculatedNutrients = (typeof calculatedNutrients)[number]
export type BasedOnCalculatedNutrients = (typeof basedOnCalculatedNutrients)[number]
export type Nutrients = (typeof allNutriens)[number]
export type NutrientsCategories = (typeof nutrientsCategories)[number]

export type NutrientMeasurement = 'kcal' | 'kJ' | 'g' | 'mg' | 'μg' | '%' | ''
export type GenericNutrients<T extends string> = Record<
  T,
  {
    category: NutrientsCategories
    measurement: NutrientMeasurement
    name: string
    shortcut: string
  }
>

export type NutrientsMap = GenericNutrients<Nutrients>
export type NutrientsValues = Record<Exclude<Nutrients, 'ratio_n_3_n_6'>, number> & {
  ratio_n_3_n_6: string
}

export type SpecificNutrientsValues = Record<
  Exclude<SpecificNutrients, 'ratio_n_3_n_6'>,
  number
> & {
  ratio_n_3_n_6: string
}

export const nutrientsMap: NutrientsMap = {
  alanine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Alanina',
    shortcut: 'Ala'
  },
  animal_protein: {
    category: 'Składniki odżywcze',
    measurement: 'g',
    name: 'Białko zwierzęce',
    shortcut: 'Bz'
  },
  arginine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Arginina',
    shortcut: 'Arg'
  },
  ash: {
    category: 'Inne',
    measurement: 'g',
    name: 'Popiół',
    shortcut: 'Popiół'
  },
  aspartic_acid: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Kwas asparaginowy',
    shortcut: 'Asp'
  },
  c_10_0: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C10:0',
    shortcut: 'C10:0'
  },
  c_12_0: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C12:0',
    shortcut: 'C12:0'
  },
  c_14_0: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C14:0',
    shortcut: 'C14:0'
  },
  c_14_1: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C14:1',
    shortcut: 'C14:1'
  },
  c_15_0: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C15:0',
    shortcut: 'C15:0'
  },
  c_15_1: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C15:1',
    shortcut: 'C15:1'
  },
  c_16_0: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C16:0',
    shortcut: 'C16:0'
  },
  c_16_1: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C16:1',
    shortcut: 'C16:1'
  },
  c_17_0: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C17:0',
    shortcut: 'C17:0'
  },
  c_17_1: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C17:1',
    shortcut: 'C17:1'
  },
  c_18_0: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C18:0',
    shortcut: 'C18:0'
  },
  c_18_1: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C18:1',
    shortcut: 'C18:1'
  },
  c_18_2: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C18:2',
    shortcut: 'C18:2'
  },
  c_18_3: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C18:3',
    shortcut: 'C18:3'
  },
  c_18_4: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C18:4',
    shortcut: 'C18:4'
  },
  c_20_0: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C20:0',
    shortcut: 'C20:0'
  },
  c_20_1: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C20:1',
    shortcut: 'C20:1'
  },
  c_20_3: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C20:3',
    shortcut: 'C20:3'
  },
  c_20_4: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C20:4',
    shortcut: 'C20:4'
  },
  c_20_5: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'EPA',
    shortcut: 'EPA'
  },
  c_22_1: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C22:1',
    shortcut: 'C22:1'
  },
  c_22_5: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'DPA',
    shortcut: 'DPA'
  },
  c_22_6: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'DHA',
    shortcut: 'DHA'
  },
  c_4_0: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C4:0',
    shortcut: 'C4:0'
  },
  c_6_0: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C6:0',
    shortcut: 'C6:0'
  },
  c_8_0: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'C8:0',
    shortcut: 'C8:0'
  },
  calcium: {
    category: 'Składniki mineralne',
    measurement: 'mg',
    name: 'Wapń',
    shortcut: 'Ca'
  },
  calories: {
    // energy_value
    category: 'Składniki odżywcze',
    measurement: 'kcal',
    name: 'Wartość energetyczna',
    shortcut: 'K'
  },
  carbohydrate_exchanger: {
    category: 'Inne',
    measurement: '',
    name: 'Wymienniki węglowodanowe',
    shortcut: 'WW'
  },
  carbohydrates: {
    category: 'Składniki odżywcze',
    measurement: 'g',
    name: 'Węglowodany przyswajalne',
    shortcut: 'Wp'
  },
  carbohydrates_energy: {
    category: 'Inne',
    measurement: '%',
    name: '% energii z węglowodanów ogółem',
    shortcut: '% E z W'
  },
  carbohydrates_total: {
    category: 'Składniki odżywcze',
    measurement: 'g',
    name: 'Węglowodany ogółem',
    shortcut: 'W'
  },
  carotene_beta: {
    category: 'Witaminy',
    measurement: 'μg',
    name: 'β-karoten',
    shortcut: 'β-karoten'
  },
  cholesterol: {
    category: 'Kwasy tłuszczowe',
    measurement: 'mg',
    name: 'Cholesterol',
    shortcut: 'Chol.'
  },
  copper: {
    category: 'Składniki mineralne',
    measurement: 'mg',
    name: 'Miedź',
    shortcut: 'Cu'
  },
  cystine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Cysteina',
    shortcut: 'Cys'
  },
  energy_value_according_regulation_kcal: {
    category: 'Składniki odżywcze',
    measurement: 'kcal',
    name: 'Wartość energetyczna wg rozp. 1169/2011 (kcal)',
    shortcut: 'K^'
  },
  energy_value_according_regulation_kj: {
    category: 'Składniki odżywcze',
    measurement: 'kJ',
    name: 'Wartość energetyczna wg rozp. 1169/2011 (kJ)',
    shortcut: 'K^'
  },
  energy_value_kj: {
    category: 'Składniki odżywcze',
    measurement: 'kJ',
    name: 'Wartość energetyczna (kJ)',
    shortcut: 'K'
  },
  fat: {
    category: 'Składniki odżywcze',
    measurement: 'g',
    name: 'Tłuszcz',
    shortcut: 'T'
  },
  fat_energy: {
    category: 'Inne',
    measurement: '%',
    name: '% energii z tłuszczów',
    shortcut: '% E z T'
  },
  fiber: {
    category: 'Składniki odżywcze',
    measurement: 'g',
    name: 'Błonnik pokarmowy',
    shortcut: 'F'
  },
  folate_total: {
    category: 'Witaminy',
    measurement: 'μg',
    name: 'Foliany',
    shortcut: 'Foliany'
  },
  fructose: {
    category: 'Inne',
    measurement: 'g',
    name: 'Fruktoza',
    shortcut: 'Fruktoza'
  },
  glucose: {
    category: 'Inne',
    measurement: 'g',
    name: 'Glukoza',
    shortcut: 'Glukoza'
  },
  glutamic_acid: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Kwas glutaminowy',
    shortcut: 'Glu'
  },
  glycine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Glicyna',
    shortcut: 'Gly'
  },
  histidine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Histydyna',
    shortcut: 'His'
  },
  iodine: {
    category: 'Składniki mineralne',
    measurement: 'μg',
    name: 'Jod',
    shortcut: 'Jod'
  },
  iron: {
    category: 'Składniki mineralne',
    measurement: 'mg',
    name: 'Żelazo',
    shortcut: 'Fe'
  },
  isoleucine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Izoleucyna',
    shortcut: 'Ile'
  },
  jnkt: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'Kwasy tłuszczowe jednonienasycone ogółem',
    shortcut: 'JNKT'
  },
  lactose: {
    category: 'Inne',
    measurement: 'g',
    name: 'Laktoza',
    shortcut: 'Laktoza'
  },
  leucine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Leucyna',
    shortcut: 'Leu'
  },
  lysine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Lizyna',
    shortcut: 'Lys'
  },
  magnesium: {
    category: 'Składniki mineralne',
    measurement: 'mg',
    name: 'Magnez',
    shortcut: 'Mg'
  },
  manganese: {
    category: 'Składniki mineralne',
    measurement: 'mg',
    name: 'Mangan',
    shortcut: 'Mn'
  },
  methionine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Metionina',
    shortcut: 'Met'
  },
  n_3: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'n-3',
    shortcut: 'n-3'
  },
  n_6: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'n-6',
    shortcut: 'n-6'
  },
  ratio_n_3_n_6: {
    category: 'Kwasy tłuszczowe',
    measurement: '',
    name: 'Stosunek kw. tł. n-3:n-6',
    shortcut: 'n-3:n-6'
  },
  niacin: {
    category: 'Witaminy',
    measurement: 'mg',
    name: 'Niacyna',
    shortcut: 'Wit.B3'
  },
  nkt: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'Kwasy tłuszczowe nasycone ogółem',
    shortcut: 'NKT'
  },
  phenylalanine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Fenyloalanina',
    shortcut: 'Phe'
  },
  phosphorus: {
    category: 'Składniki mineralne',
    measurement: 'mg',
    name: 'Fosfor',
    shortcut: 'P'
  },
  plant_protein: {
    category: 'Składniki odżywcze',
    measurement: 'g',
    name: 'Białko roślinne',
    shortcut: 'Br'
  },
  potassium: {
    category: 'Składniki mineralne',
    measurement: 'mg',
    name: 'Potas',
    shortcut: 'K'
  },
  proline: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Prolina',
    shortcut: 'Pro'
  },
  protein: {
    category: 'Składniki odżywcze',
    measurement: 'g',
    name: 'Białko ogółem',
    shortcut: 'B'
  },
  protein_according_regulation: {
    category: 'Składniki odżywcze',
    measurement: 'g',
    name: 'Białko ogółem wg rozp. 1169/2011',
    shortcut: 'B*'
  },
  protein_energy: {
    category: 'Inne',
    measurement: '%',
    name: '% energii z białek',
    shortcut: '% E z B'
  },
  retinol: {
    category: 'Witaminy',
    measurement: 'μg',
    name: 'Retinol',
    shortcut: 'Retinol'
  },
  riboflavin: {
    category: 'Witaminy',
    measurement: 'mg',
    name: 'Ryboflawina',
    shortcut: 'Wit.B2'
  },
  salt: {
    category: 'Inne',
    measurement: 'g',
    name: 'Sól',
    shortcut: 'Sól'
  },
  serine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Seryna',
    shortcut: 'Ser'
  },
  sodium: {
    category: 'Składniki mineralne',
    measurement: 'mg',
    name: 'Sód',
    shortcut: 'Na'
  },
  starch: {
    category: 'Inne',
    measurement: 'g',
    name: 'Skrobia',
    shortcut: 'Skrobia'
  },
  sucrose: {
    category: 'Inne',
    measurement: 'g',
    name: 'Sacharoza',
    shortcut: 'Sacharoza'
  },
  thiamin: {
    category: 'Witaminy',
    measurement: 'mg',
    name: 'Tiamina',
    shortcut: 'Wit.B1'
  },
  threonine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Treonina',
    shortcut: 'Thr'
  },
  tryptophan: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Tryptofan',
    shortcut: 'Trp'
  },
  tyrosine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Tyrozyna',
    shortcut: 'Tyr'
  },
  valine: {
    category: 'Aminokwasy',
    measurement: 'mg',
    name: 'Walina',
    shortcut: 'Val'
  },
  vitamin_A_RAE: {
    category: 'Witaminy',
    measurement: 'μg',
    name: 'Witamina A - ekwiwalent retinolu',
    shortcut: 'RE'
  },
  vitamin_B6: {
    category: 'Witaminy',
    measurement: 'mg',
    name: 'Witamina B6',
    shortcut: 'Wit.B6'
  },
  vitamin_B_12: {
    category: 'Witaminy',
    measurement: 'μg',
    name: 'Witamina B12',
    shortcut: 'Wit.B12'
  },
  vitamin_C: {
    category: 'Witaminy',
    measurement: 'mg',
    name: 'Witamina C',
    shortcut: 'Wit.C'
  },
  vitamin_D: {
    category: 'Witaminy',
    measurement: 'μg',
    name: 'Witamina D',
    shortcut: 'Wit.D'
  },
  vitamin_E: {
    category: 'Witaminy',
    measurement: 'mg',
    name: 'Witamina E – ekwiwalent α-tokoferolu',
    shortcut: 'Wit.E'
  },
  waste: {
    category: 'Inne',
    measurement: '%',
    name: 'Odpadki',
    shortcut: 'Odpadki'
  },
  water: {
    category: 'Inne',
    measurement: 'g',
    name: 'Woda',
    shortcut: 'H20'
  },
  wnkt: {
    category: 'Kwasy tłuszczowe',
    measurement: 'g',
    name: 'Kwasy tłuszczowe wielonienasycone ogółem',
    shortcut: 'WNKT'
  },
  zinc: {
    category: 'Składniki mineralne',
    measurement: 'mg',
    name: 'Cynk',
    shortcut: 'Zn'
  },
  simple_sugars_sum: {
    category: 'Inne',
    measurement: 'g',
    name: 'Suma cukrów prostych',
    shortcut: 'SCP'
  },
  protein_fat_exchangers: {
    category: 'Inne',
    measurement: '',
    name: 'Wymienniki białkowo-tłuszczowe',
    shortcut: 'WBT'
  },
  glycemic_index: {
    category: 'Inne',
    measurement: '',
    name: 'Indeks glikemiczny',
    shortcut: 'IG'
  },
  glycemic_load: {
    category: 'Inne',
    measurement: '',
    name: 'Ładunek glikemiczny',
    shortcut: 'ŁG'
  }
}

export type NormClass = '' | 'red-down' | 'red-up' | 'yellow-down' | 'yellow-up' | 'ok' | undefined
export type NutrientIncompleteData = Partial<Record<Nutrients, Set<string>>>
export type NutrientIncompleteDataDay = [
  NutrientIncompleteData,
  NutrientIncompleteData,
  NutrientIncompleteData,
  NutrientIncompleteData,
  NutrientIncompleteData,
  NutrientIncompleteData,
  NutrientIncompleteData
]
export type NutrientExtended =
  | {
      average: number
      averageClass: NormClass
      averagePercent: number
      differenceAverage: number
      differenceAveragePercent: number
      differenceValue: [number, number, number, number, number, number, number]
      differenceValuePercent: [number, number, number, number, number, number, number]
      incompleteData: [string, string, string, string, string, string, string]
      key: Exclude<Nutrients, 'ratio_n_3_n_6'>
      measurement: NutrientMeasurement
      name: string
      norm: number
      normClass: [NormClass, NormClass, NormClass, NormClass, NormClass, NormClass, NormClass]
      value: [number, number, number, number, number, number, number]
      valuePercent: [number, number, number, number, number, number, number]
    }
  | {
      average: string
      averageClass: NormClass
      averagePercent: number
      differenceAverage: 0
      differenceAveragePercent: number
      differenceValue: [string, string, string, string, string, string, string]
      differenceValuePercent: [number, number, number, number, number, number, number]
      incompleteData: [string, string, string, string, string, string, string]
      key: 'ratio_n_3_n_6'
      measurement: NutrientMeasurement
      name: string
      norm: number
      normClass: [NormClass, NormClass, NormClass, NormClass, NormClass, NormClass, NormClass]
      value: [string, string, string, string, string, string, string]
      valuePercent: [number, number, number, number, number, number, number]
    }

export const getNutrientHeader = (nutrient: Nutrients) => {
  let measurement = ''
  if (nutrientsMap[nutrient].measurement) {
    measurement = '(' + nutrientsMap[nutrient].measurement + ')'
  }
  return nutrientsMap[nutrient].shortcut + measurement
}
