<template>
  <div :class="$style.pagination">
    <div />
    <Space
      :space="5"
      align-y="center"
      direction="columns"
    >
      <KcalmarIconButton
        :disabled="!canPreviousPage"
        size="small"
        variant="secondary"
        name="arrow-left-s-line"
        @click="previousPage"
      />
      <PaginationItem
        v-for="(pageNumber, pageNumberIndex) in getNumbersForPagination"
        :key="`pagination-${pageNumberIndex}`"
        class="number-box"
        :active="currentPage + 1 === pageNumber"
        :disabled="pageNumber === '...'"
        @click="setCurrentPage(pageNumber)"
      >
        {{ pageNumber }}
      </PaginationItem>
      <KcalmarIconButton
        :disabled="!canNextPage"
        size="small"
        variant="secondary"
        name="arrow-right-s-line"
        @click="nextPage"
      />
    </Space>
    <Space
      direction="columns"
      align-x="end"
      align-y="center"
      :space="8"
    >
      <T as="span">
        Idź do strony
      </T>
      <KcalmarInput
        :value="goToPageNumber"
        variant="underline"
        size="small"
        class="w-40!"
        @on-change="handleUpdateGoToPage"
      />
      <KcalmarIconButton
        size="small"
        variant="secondary"
        name="arrow-right-s-line"
        @click="handleGoToPage"
      />
    </Space>
  </div>
</template>

<script lang="ts" setup>
import { A, F, G, N } from '@mobily/ts-belt'
import { clamp, toRefs } from '@vueuse/core'
import { computed, ref, type PropType } from 'vue'

import { KcalmarIconButton } from '@ui/KcalmarIconButton'
import { KcalmarInput } from '@ui/KcalmarInput'
import { PaginationItem } from '@ui/KcalmarTable/components/PaginationItem'
import { Space } from '@ui/Space'
import { T } from '@ui/T'

const props = defineProps({
  pages: {
    type: Number,
    default: 1
  },
  currentPage: {
    type: Number,
    default: 1
  },
  canNextPage: {
    type: Boolean,
    default: false
  },
  canPreviousPage: {
    type: Boolean,
    default: false
  },
  nextPage: {
    type: Function as PropType<() => void>,
    default: F.ignore
  },
  previousPage: {
    type: Function as PropType<() => void>,
    default: F.ignore
  },
  goToPage: {
    type: Function as PropType<(page: number) => void>,
    default: F.ignore
  }
})

const { pages, nextPage, previousPage, canNextPage, canPreviousPage, currentPage } = toRefs(props)

const goToPageNumber = ref(1)
const handleUpdateGoToPage = (e: Event) => {
  const target = e.target as HTMLInputElement
  const insertedPage = target.value ? Number(target.value) - 1 : 0
  const page = clamp(insertedPage, 0, pages.value - 1)
  goToPageNumber.value = page + 1
}

const setCurrentPage = (pageNumber: number | string) => {
  if (G.isNumber(pageNumber)) {
    props.goToPage(pageNumber - 1)
  }
}

const handleGoToPage = () => {
  props.goToPage(goToPageNumber.value - 1)
}

const getNumbersForPagination = computed(() => {
  const pagesNumber = pages.value + 1
  const currPage = currentPage.value

  if (pagesNumber <= 7) {
    return A.makeWithIndex(pagesNumber - 1, N.succ)
  } else if (currPage < 4) {
    return [1, 2, 3, 4, 5, '...', pagesNumber]
  } else if (currPage + 5 > pagesNumber) {
    return [
      1,
      '...',
      pagesNumber - 4,
      pagesNumber - 3,
      pagesNumber - 2,
      pagesNumber - 1,
      pagesNumber
    ]
  } else {
    return [1, '...', currPage - 1, currPage, currPage + 1, '...', pagesNumber]
  }
})
</script>
<style lang="scss" module scoped>
.pagination {
  background-color: #fff;
  padding: $space-4 $space-9;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}
</style>
