<template>
  <BaseModal
    :modal-class="'diet-assign-to-patient-modal'"
    @close="close"
    @confirm="assignToPatientConfirm"
    @click="hideAutocompleteOptions"
  >
    <template #title>
      Przypisz jadłospis do pacjenta
    </template>
    <div
      v-if="patients.length === 0"
      class="patients-list-empty"
    >
      Aby przypisać jadłospis, musisz mieć co najmniej jednego aktywnego pacjenta.
    </div>
    <div
      v-else
      class="patient-list"
    >
      <div class="patient-week">
        <div class="patient">
          <div class="label">
            Nazwa pacjenta
          </div>
          <BaseAutocompleteVirtualized
            ref="autocomplete"
            v-model="patient"
            class="patient__select"
            :options="patients"
            :placeholder="'Wybierz pacjenta'"
            :debounce-after="300"
          />
        </div>
        <div class="week">
          <div class="label">
            {{ weekInfo }}
          </div>
          <div class="week__select">
            <div class="week__select-prev">
              <IconButton
                v-if="weekIndex > 0"
                name="chevron_left"
                @click="prevWeek"
              />
            </div>
            <div class="week__select-dates">
              {{ weekInfoDates }}
            </div>
            <div class="week__select-next">
              <IconButton
                v-if="weekIndex < 15"
                name="chevron_right"
                @click="nextWeek"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="diet-info">
        Uwaga! Sprawdź, czy w miejscu docelowym nie znajduje się już przygotowany jadłospis. Jeśli
        go nadpiszesz - nie będzie możliwości jego odzyskania!
      </div>
    </div>
    <template #actions>
      <StyledButton
        variant="outline"
        @click.once="close"
      >
        Anuluj
      </StyledButton>
      <StyledButton
        v-if="patients.length > 0"
        :disabled="assignToPatientDisabled"
        @click.once="assignToPatientConfirm"
      >
        Przypisz
      </StyledButton>
      <StyledButton
        v-else
        @click.once="assignToPatientRedirect"
      >
        Przejdź do zakładki "Pacjenci"
      </StyledButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import type { DietType } from '@/store/types'
import type { PropType } from 'vue'

import { reportError } from '@/utils/reportError'
import { addWeeks, differenceInYears, endOfWeek, format, parse, startOfWeek } from 'date-fns'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import BaseAutocompleteVirtualized from '@/components/BaseAutocompleteVirtualized.vue'
import BaseModal from '@/components/BaseModal.vue'
import { IconButton } from '@/components/IconButton'
import { StyledButton } from '@/components/StyledButton'
import eventBus from '@/eventBus'
import { dietsService } from '@/services/dietsService'
import { useGlobalStore } from '@/store/globalStore'

const props = defineProps({
  dietType: {
    required: true,
    type: String as PropType<DietType>
  },
  dietId: {
    required: true,
    type: Number
  }
})
const globalStore = useGlobalStore()
const router = useRouter()
const sending = ref(false)
const patient = ref<{ value: string; text: string }>()

const email = computed(() => {
  return patient.value?.value ?? ''
})

const weekIndex = ref(0)
const weekMap = [
  'Obecny tydzień',
  'Za tydzień',
  'Za dwa tygodnie',
  'Za trzy tygodnie',
  'Za cztery tygodnie',
  'Za pięć tygodni',
  'Za sześć tygodni',
  'Za siedem tygodni',
  'Za osiem tygodni',
  'Za dziewięć tygodni',
  'Za dziesięć tygodni',
  'Za jedenaście tygodni',
  'Za dwanaście tygodni',
  'Za trzynaście tygodni',
  'Za czternaście tygodni',
  'Za piętnaście tygodni'
]

const user = computed(() => globalStore.user)
const emits = defineEmits(['close', 'sendSystemDietsListToFile'])

const patients = computed(() => {
  return (user.value?.patients ?? []).map((v) => {
    const birthDate = v.birth_date
      ? differenceInYears(new Date(), parse(v.birth_date, 'yyyy-MM-dd', new Date()))
      : 'b.d.'

    return {
      value: v.email,
      text: `${v.name || 'Pacjent bez nazwy'} (wiek: ${birthDate})`
    }
  })
})
const assignToPatientDisabled = computed(() => {
  return !email.value || sending.value
})
const weekDates = computed(() => {
  const date = addWeeks(new Date(), weekIndex.value)
  return {
    startDate: startOfWeek(date, { weekStartsOn: 1 }),
    endDate: endOfWeek(date, { weekStartsOn: 1 })
  }
})
const weekInfo = computed(() => {
  return weekMap[weekIndex.value]
})
const weekInfoDates = computed(() => {
  const startDate = format(weekDates.value.startDate, 'dd-MM')
  const endDate = format(weekDates.value.endDate, 'dd-MM-yyyy')
  return `${startDate} / ${endDate}`
})

const hideAutocompleteOptions = () => {
  eventBus.emit('hideAutocompleteOptions')
}
const close = () => {
  emits('close')
}
const prevWeek = () => {
  if (weekIndex.value > 0) weekIndex.value--
}
const nextWeek = () => {
  if (weekIndex.value < 15) weekIndex.value++
}
const assignToPatientConfirm = async () => {
  if (!assignToPatientDisabled.value) {
    const currentDate = format(weekDates.value.startDate, 'dd-MM-yyyy')
    try {
      if (props.dietType === 'system') {
        emits('sendSystemDietsListToFile', props.dietId)
      }
      sending.value = true
      const response = await dietsService.assignDietToPatient(
        props.dietType,
        props.dietId,
        email.value,
        currentDate
      )
      if (response.status === 201) {
        await router.push({
          name: 'DietDetailWeek',
          params: {
            dietId: response.data.id,
            dietType: 'patient'
          }
        })
        router.go(0)
      }
    } catch (error) {
      reportError(error, 'Error during assigning diet to patient', {
        dietId: props.dietId,
        dietType: props.dietType,
        email: email.value,
        date: currentDate
      })
      throw error
    } finally {
      sending.value = false
      close()
    }
  }
}
const assignToPatientRedirect = () => {
  location.href = `${import.meta.env.VUE_APP_HOST}/dietetyk/patient/table/`
}
</script>

<style>
.diet-assign-to-patient-modal {
  overflow: visible !important;
}
</style>

<style scoped>
.label {
  font-size: 10px;
  color: var(--color-tertiary-text);
}

.patient-week {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.patient {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 20px;
}

.patient .label {
  margin-left: 8px;
}

.patient__select {
  display: flex;
  flex-direction: row;
}

.week {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

.week .label {
  margin-left: 32px;
}

.week__select {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.week__select-prev,
.week__select-next {
  width: 28px;
  height: 28px;
}

.week__select-dates {
  width: 116px;
  text-align: center;
}

.diet-info {
  margin-top: 10px;
  color: #c40038;
  line-height: 17px;
  font-size: 11px;
}
</style>
