<template>
  <Space>
    <KcalmarAutocomplete
      v-model="hour2"
      :options="hourOptions"
    />
    <div>
      <Space
        :padding-x="4"
        :padding-y="4"
        :space="24"
        direction="columns"
        align-y="center"
        :style="{ backgroundColor: '#fff', border: '1px solid black' }"
      >
        <Space
          :space="8"
          direction="columns"
          align-y="center"
        >
          <KcalmarIconButton name="settings-4-fill" />
          <Space :space="2">
            <T as="span">
              Test
            </T>
            <T
              as="span"
              size="xl"
            >
              jakis tekst dodatkowy
            </T>
          </Space>
        </Space>
        <KcalmarIconButton name="close" />
      </Space>
    </div>
    <Space
      direction="columns"
      gap="4"
    >
      <KcalmarAvatar
        size="xxlarge"
        :is-premium="true"
        name="test 1"
      />
      <KcalmarAvatar
        size="xlarge"
        :is-premium="true"
        name="Testowy uzy"
      />
      <KcalmarAvatar
        size="large"
        :is-premium="true"
        name="Testowy żzy"
      />
      <KcalmarAvatar
        name="Adam"
        :is-premium="true"
      />
      <KcalmarAvatar
        size="small"
        :is-premium="true"
        name="Domino "
      />
      <KcalmarAvatar
        size="xs"
        :is-premium="true"
        name="ULo"
      />
    </Space>
    <Space direction="columns">
      <KcalmarBadge
        left-icon="child_friendly"
        right-icon="delete_sweep"
      >
        Badge
      </KcalmarBadge>
      <KcalmarBadge type="error">
        Badge
      </KcalmarBadge>
      <KcalmarBadge
        type="success"
      >
        Badge
      </KcalmarBadge>
      <KcalmarBadge
        type="warning"
      >
        Badge
      </KcalmarBadge>
      <KcalmarBadge @click="noop">
        Badge
      </KcalmarBadge>
      <KcalmarBadge
        left-icon="chat_bubble_outline"
        type="error"
        @click="noop"
      >
        Badge
      </KcalmarBadge>
      <KcalmarBadge
        right-icon="16mp"
        type="success"
        @click="noop"
      >
        Badge
      </KcalmarBadge>
      <KcalmarBadge
        right-icon="checkroom"
        left-icon="directions"
        type="warning"
        @click="noop"
      >
        Badge
      </KcalmarBadge>
    </Space>
    <Space
      direction="columns"
    >
      <KcalmarButton>Button </KcalmarButton>
      <KcalmarButton :disabled="true">
        Disabled
      </KcalmarButton>
      <KcalmarButton variant="destructive">
        Button
      </KcalmarButton>
      <KcalmarButton variant="ghost">
        Button
      </KcalmarButton>
      <KcalmarButton variant="secondary">
        Button
      </KcalmarButton>
    </Space>
    <Space
      direction="columns"
      :space="12"
      align-x="start"
    >
      <KcalmarCheckbox
        v-model="selected"
        name="check1"
        label="checkbox"
      />
      <KcalmarRadio
        v-model="radioSelected"
        name="radio1"
        label="Radio"
      />
    </Space>
    <Space direction="columns">
      <KcalmarChip left-icon="check_box">
        Chip
      </KcalmarChip>
      <KcalmarChip right-icon="local_activity">
        Chip
      </KcalmarChip>
      <KcalmarChip
        size="large"
        left-icon="tab"
        right-icon="keyboard_option"
      >
        Chip
      </KcalmarChip>
      <KcalmarChip size="small">
        Chip
      </KcalmarChip>
    </Space>
    <div ref="item">
      <Space direction="columns">
        <KcalmarIconButton name="alert-fill" />
        <KcalmarIconButton
          name="arrow-up-circle-fill"
          variant="secondary"
        />
        <KcalmarIconButton
          name="close"
          variant="destructive"
        />
        <KcalmarIconButton
          name="information-fill"
          variant="ghost"
        />
        <KcalmarIconButton
          name="question-fill"
          variant="ghost"
          :disabled="true"
        />
      </Space>
    </div>
    <Space direction="columns">
      <KcalmarInput
        placeholder="Default"
      />
      <KcalmarInput
        variant="underline"
        placeholder="Underline"
      />
      <KcalmarInput
        placeholder="Default"
        size="large"
      />
      <KcalmarInput
        variant="underline"
        placeholder="Underline"
        size="large"
      />
      <KcalmarInput
        placeholder="Default"
        size="small"
      />
      <KcalmarInput
        variant="underline"
        placeholder="Underline"
        size="small"
      />
    </Space>
    <KcalmarSelect
      v-model="hour"
      :options="hourOptions"
      placeholder="Wybierz godzinę"
    />
    <Space
      :wrap="true"
      direction="columns"
      align-y="center"
    >
      <T
        as="span"
        type="black"
        size="base"
      >
        black/base
      </T>
      <T
        as="span"
        type="extrabold"
        size="sm"
      >
        extrabold/small
      </T>
      <T
        as="span"
        type="semibold"
        size="lg"
      >
        semibold/large
      </T>
      <T
        as="span"
        type="bold"
        size="xl"
      >
        bold/xlarge
      </T>
      <T
        as="span"
        type="regular"
        size="2xl"
      >
        regular/xxlarge
      </T>
      <T
        as="span"
        type="medium"
        size="3xl"
      >
        medium/xxxlarge
      </T>
      <T
        as="span"
        type="light"
        size="4xl"
      >
        light/xxxxlarge
      </T>
      <T
        as="span"
        type="extralight"
        size="5xl"
      >
        extralight/xxxxxlarge
      </T>
      <T
        as="span"
        type="thin"
        size="6xl"
      >
        thin/xxxxxxlarge
      </T>
      <T
        as="span"
        type="black"
        size="7xl"
      >
        Black/xxxxxxxlarge
      </T>
      <T
        as="span"
        type="black"
        size="8xl"
      >
        Black/xxxxxxxxlarge
      </T>
    </Space>
  </Space>
</template>
<script lang="ts" setup>
import { F } from '@mobily/ts-belt'
import { ref } from 'vue'

import { Space } from '@/components/Space'
import KcalmarAutocomplete from '@/components/ui/KcalmarAutocomplete/KcalmarAutocomplete.vue'
import { KcalmarAvatar } from '@/components/ui/KcalmarAvatar'
import { KcalmarBadge } from '@/components/ui/KcalmarBadge'
import { KcalmarButton } from '@/components/ui/KcalmarButton'
import { KcalmarCheckbox } from '@/components/ui/KcalmarCheckbox'
import { KcalmarChip } from '@/components/ui/KcalmarChip'
import { KcalmarIconButton } from '@/components/ui/KcalmarIconButton'
import { KcalmarInput } from '@/components/ui/KcalmarInput'
import { KcalmarRadio } from '@/components/ui/KcalmarRadio'
import KcalmarSelect from '@/components/ui/KcalmarSelect/KcalmarSelect.vue'
import { T } from '@/components/ui/T'
import { hours } from '@/const'
const item = ref(null)
// const onPopoverRender = (popover) => {
//   console.log(item.value, Object.keys(item.value))
//   popover.footerButtons.appendChild(item.value)
// }
const selected = ref(true)
const radioSelected = ref(false)
const noop = F.identity
const hourOptions = Array.from(hours, (v) => {
  return { value: v, text: v }
})
const hour = ref(hourOptions[10])
const hour2 = ref(hourOptions[10])
</script>
