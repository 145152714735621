<template>
  <div
    v-if="currentDish"
    id="add-dish-or-ingredient-form"
    class="add-dish-or-ingredient-form"
    :class="{ extended: extended }"
  >
    <div class="name">
      <BaseTextarea
        v-model="name"
        :disabled="!canDoiBeEditedStatus && mode !== 'add'"
        class="name__textarea"
        :autosize="true"
        :resize-on="extended"
        placeholder="Podaj nazwę potrawy"
        max-length="150"
      />
    </div>
    <div
      v-if="mode === 'edit'"
      class="info"
    >
      <BaseIcon
        class="info-icon"
        size="22"
        type="info"
        name="info"
      /> Zmiany w potrawie będą
      widoczne tylko w ramach tego przepisu
    </div>
    <div class="kcal-quantity-measurement">
      <div class="kcal">
        {{ kcal }} kcal
      </div>
      <div class="quantity">
        {{ quantity }} g
      </div>
    </div>
    <div class="nutrients">
      <div
        v-for="nutrient in selectedNutrients"
        :key="nutrient"
        class="nutrient"
      >
        {{ dishOrIngredientNutrient(currentDish, nutrient as Nutrients) }}
      </div>
    </div>
    <div
      v-if="extended"
      class="ingredients-switch"
    >
      <label class="ingredients-switch__label">Pokaż w tabeli:</label>
      <BaseRadio
        class="ingredients-switch__radio"
        :label="'Wybrane wartości'"
        :model-value="nutrientsType === 'choosen'"
        @update:model-value="nutrientsType = 'choosen'"
      />
      <BaseRadio
        class="ingredients-switch__radio"
        :label="'Domyślne wartości'"
        :model-value="nutrientsType === 'default'"
        @update:model-value="nutrientsType = 'default'"
      />
    </div>
    <div class="ingredients">
      <table class="ingredients-table">
        <tr v-if="extended">
          <th class="ingredient-name">
            <div
              class="ingredients-table__header"
              @click="updateSorting('name')"
            >
              Nazwa produktu
              <BaseIcon
                class="icon sort"
                size="18"
                :name="getIconBySort('name')"
              />
            </div>
          </th>
          <th class="ingredient-quantity">
            <div
              class="ingredients-table__header"
              @click="updateSorting('quantity')"
            >
              Ilość(g)
              <BaseIcon
                class="icon sort"
                size="18"
                :name="getIconBySort('quantity')"
              />
            </div>
          </th>
          <th class="ingredient-measurement">
            <div class="ingredients-table__header">
              Miara opisowa
            </div>
          </th>
          <template v-if="extended">
            <th class="ingredient-nutrient">
              <div
                class="ingredients-table__header"
                @click="updateSorting('calories')"
              >
                K(kcal)
                <BaseIcon
                  class="icon sort"
                  size="18"
                  :name="getIconBySort('calories')"
                />
              </div>
            </th>
            <th
              v-for="nutrient in selectedNutrients"
              :key="`ingredient-header-${nutrient}`"
              class="ingredient-nutrient"
              :class="{ disabled: nutrient === 'ratio_n_3_n_6' }"
            >
              <div
                class="ingredients-table__header"
                @click="updateSorting(nutrient)"
              >
                {{ ingredientNutrientHeader(nutrient) }}

                <BaseIcon
                  v-if="nutrient !== 'ratio_n_3_n_6'"
                  class="icon sort"
                  size="18"
                  :name="getIconBySort(nutrient)"
                />
              </div>
            </th>
          </template>
          <th class="ingredient-actions" />
        </tr>
        <DietDishFormIngredient
          v-for="(ingredient, ingredientIndex) in sortedIngredients"
          :key="ingredient.originalIndex"
          :can-doi-be-edited-status="canDoiBeEditedStatus"
          :extended="extended"
          :get-ingredient-input-ref="(el) => (ingredientQuantityInput = el)"
          :ingredient="ingredient"
          :ingredient-index="ingredientIndex"
          :mode="mode"
          :original-index="ingredient.originalIndex"
          :replace-ingredient-input-active-index="replaceIngredientInputActiveIndex"
          :selected-nutrients="selectedNutrients"
          @replace-ingredient="replaceIngredient"
          @hide-replace-ingredient-input="hideReplaceIngredientInputActive"
          @delete-ingredient="deleteIngredient"
          @set-replace-ingredient-input-active-index="setReplaceIngredientInputActiveIndex"
          @update-ingredient-in-dish-quantity="updateIngredientInDishQuantity"
        />
      </table>
      <div
        v-if="currentDish.ingredients.length === 0"
        class="ingredients-empty"
      >
        Brak produktów w potrawie.
      </div>
    </div>
    <div
      v-if="canDoiBeEditedStatus || mode === 'add'"
      class="add-ingredient"
      @click.stop
    >
      <StyledButton
        v-if="!addIngredientInputActive"
        left-icon="add"
        variant="outline"
        @click.stop="addIngredientInputActive = true"
        @keyup.enter="addIngredientInputActive = true"
        @focus="addIngredientInputActive = true"
      >
        Dodaj produkt
      </StyledButton>
      <DishOrIngredientAddInput
        v-else
        test-id="DietDishForm"
        mode="ingredient"
        @select="addIngredient"
        @keyup.esc="addIngredientInputActive = false"
      />
    </div>
    <div class="portions">
      <span
        v-if="mode === 'add'"
        class="section-label"
      >Potrawa składa się z: </span>
      <span
        v-else
        class="section-label"
      > Porcja: </span>
      <div
        v-if="mode !== 'add' && mode !== 'add-extended'"
        class="portions-inner"
      >
        <BaseInputNumber
          v-model="usedPortions"
          test-id="DietDishForm__UsedPortions"
          class="used-portions-input"
          :class="{ 'is-disabled': !canDoiBeEditedStatus }"
          :min="1"
          :max="60"
        />
        z
      </div>
      <BaseInputNumber
        v-model="maxPortions"
        test-id="DietDishForm__MaxPortions"
        class="max-portions-input"
        :class="{ 'is-disabled': !canDoiBeEditedStatus && mode !== 'add' }"
        :min="1"
        :max="60"
      />
      <span v-if="mode === 'add'"> porcji </span>
    </div>
    <div class="preparation-time">
      <span class="section-label">Czas przygotowania:</span>
      <BaseInputNumber
        v-model="preparationTime"
        test-id="DietDishForm__PreparationTime"
        class="preparation-time-input"
        :class="{
          'is-disabled': !canDoiBeEditedStatus && mode !== 'add'
        }"
        :min="1"
        :max="2000"
      />
      min
    </div>
    <div class="preparation-steps">
      <div class="section-name">
        Sposób przygotowania:
      </div>
      <BaseTextarea
        v-model="preparationSteps"
        class="preparation-steps-textarea"
        :class="{ 'is-disabled': !canDoiBeEditedStatus && mode !== 'add' }"
        :autosize="true"
        placeholder="Podaj sposób przygotowania potrawy"
      />
    </div>
    <div class="size">
      <div class="section-name">
        Rozmiar:
      </div>
      <div>
        <BaseRadio
          v-for="dishSize in dishSizes"
          :key="`size-${dishSize.value}`"
          class="size-radio"
          :disabled="!canDoiBeEditedStatus && mode !== 'add'"
          :label="dishSize.text"
          :model-value="currentDish.size === dishSize.value"
          @update:model-value="size = dishSize.value"
        />
      </div>
    </div>
    <div class="meal-types">
      <div class="section-name">
        Typ posiłku:
      </div>
      <div>
        <BaseCheckbox
          v-for="mealType in mealTypes"
          :key="`mealType-${mealType.value}`"
          class="meal-types-checkbox"
          :disabled="!canDoiBeEditedStatus && mode !== 'add'"
          :label="mealType.text"
          :model-value="currentDish.meal_types.includes(mealType.value)"
          @update:model-value="updateMealType(mealType.value)"
        />
      </div>
    </div>
    <div class="dish-types">
      <div class="section-name">
        Wybrane tagi:
      </div>
      <span
        v-if="canDoiBeEditedStatus"
        class="section-description"
      >Odpowiedni dobór tagów ułatwi Ci wyszukiwanie potraw i dalszą pracę
      </span>
      <div>
        <div>
          <div class="dish-types-row">
            <div
              v-for="dishType in currentDish.dish_types"
              :key="dishType"
            >
              <div
                class="chosen-type-box"
                :class="{ editable: canDoiBeEditedStatus }"
              >
                <span>{{ getDishTypeName(dishType) }}</span>
                <BaseIcon
                  v-if="canDoiBeEditedStatus"
                  name="close"
                  size="18"
                  class="remove-tag"
                  @click="removeSingleTag(dishType)"
                />
              </div>
            </div>
            <div
              v-for="dishType in getAutoTags"
              :key="dishType"
            >
              <div class="chosen-type-box">
                {{ getDishTypeName(dishType) }}
              </div>
            </div>
          </div>

          <StyledButton
            v-if="canDoiBeEditedStatus"
            class="show-tags"
            variant="secondary"
            left-icon="add"
            left-icon-size="24"
            @click="showDishTagList"
          >
            Pokaż dostępne tagi
          </StyledButton>
        </div>
      </div>
    </div>
    <DishesTagList
      v-if="tagListShown"
      :mode="'add-dish'"
      :auto-tags="getAutoTags"
      :chosen-tags="currentDish.dish_types"
      @set-dish-type="updateDishTypes($event)"
      @hide-tag-list="hideDishTagList"
    />
  </div>
</template>

<script lang="ts" setup>
import type { DietDishTypeIdMap, DishInFormType } from '@/types/Diet'
import type { Nutrients } from '@/utils/nutrients'

import { A, D, pipe, S } from '@mobily/ts-belt'
import { useDebounceFn, useEventListener } from '@vueuse/core'
import { klona } from 'klona'
import round from 'lodash/round'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRaw, toRef, watch } from 'vue'
import DishOrIngredientAddInput from '@/components/DishOrIngredientAddInput.vue'
import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseInputNumber from '@/components/BaseInputNumber.vue'
import BaseRadio from '@/components/BaseRadio.vue'
import BaseTextarea from '@/components/BaseTextarea.vue'
import DietDishFormIngredient from '@/components/diets/DietDishFormIngredient.vue'
import DishesTagList from '@/components/dishes/DishesTagList.vue'
import eventBus from '@/eventBus'
import { useGetAutoTags } from '@/hooks/useGetAutoTags'
import { dietsService } from '@/services/dietsService'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import { type DietIngredient, type StoreDietDish } from '@/types/Diet'
import { dishSizes, dishTypes, getIconBySortFn, mealTypes } from '@/utils/common'
import { nutrientsMap } from '@/utils/nutrients'
import { reportError } from '@/utils/reportError'

import { StyledButton } from '../StyledButton'

type IngredientWithOriginalIndex = DietIngredient & {
  originalIndex: number
}
type SortKey = 'name' | 'quantity' | Nutrients

type Props = {
  chosenNutrients?: Nutrients[]
  dish: StoreDietDish | DishInFormType
  dishIndex?: string
  extended: boolean
  lossesMultiplier?: number
  mode: 'add' | 'edit' | 'add-extended'
}
const props = defineProps<Props>()
const chosenNutrients = toRef(props, 'chosenNutrients', [
  'protein',
  'fat',
  'carbohydrates',
  'fiber',
  'carbohydrate_exchanger'
])
const lossesMultiplier = toRef(props, 'lossesMultiplier', 1)

const dish = toRef(props, 'dish')
const extended = toRef(props, 'extended')
const emptyDish: StoreDietDish | DishInFormType = {
  dish_types: [],
  ingredients: [],
  is_public: false,
  max_portions: 1,
  meal_types: [],
  name: '',
  preparation_time: 5,
  preparation_steps: '',
  size: 2,
  type: 'dish',
  used_portions: 1,
  id: 0,
  calories: 0
}

const addIngredientInputActive = ref(false)
const replaceIngredientInputActiveIndex = ref<number>()
const sortBy = ref<SortKey>('quantity')
const sortByReverse = ref(true)
const tagListShown = ref(false)
const nutrientsType = ref('choosen') // choosen or default
const nutrientsDefault = ref<Nutrients[]>([
  'protein',
  'fat',
  'carbohydrates',
  'fiber',
  'carbohydrate_exchanger'
])
const currentDish = ref<StoreDietDish | DishInFormType>(
  props.mode !== 'add' ? toRaw(dish.value) : emptyDish
)
const sortedIngredients = ref<IngredientWithOriginalIndex[]>([])
const ingredientQuantityInput = ref<HTMLInputElement>()
const dietsStore = useDietsStore()
const { createMessage } = useGlobalStore()
const { canDietBeEdited } = storeToRefs(dietsStore)
const emit = defineEmits([
  'update',
  'updateName',
  'updateQuantity',
  'updateSize',
  'updateUsedPortions',
  'updateMaxPortions',
  'updatePreparationTime',
  'updatePreparationSteps',
  'updateMealType',
  'updateMealTypes',
  'updateDishType',
  'addIngredient',
  'deleteIngredient',
  'replaceIngredient',
  'updateIngredientInDishQuantity',
  'updateIngredientInDishMeasurement',
  'updateDishTypes',
  'updateCalories',
  'updateCalculations',
  'calculateNutrientValue'
])

const calculateQuantity = computed(() => !(props.mode === 'add' || props.mode === 'add-extended'))
const canDoiBeEditedStatus = computed(() => {
  if (props.mode === 'add-extended') {
    return !currentDish.value.is_public
  }
  return canDietBeEdited.value
})

const selectedNutrients = computed(() => {
  if (nutrientsType.value === 'choosen') {
    return chosenNutrients.value
  } else {
    return nutrientsDefault.value
  }
})

const name = computed({
  get: function () {
    return currentDish.value.name
  },
  set: function (value) {
    const checkEmptyString = S.trim(value)
    if (!checkEmptyString) value = S.trim(value)
    let newValue: string
    if (props.mode !== 'add') {
      if (checkEmptyString.length > 0) {
        newValue = value
        currentDish.value.name = newValue
        void debouncedEmit('updateName', newValue)
        void debouncedUpdate()
      } else if (currentDish.value.name.length > 0) {
        const doiNameTrimmed = S.trim(currentDish.value.name)
        const firstLetter = doiNameTrimmed[0]
        if (firstLetter) {
          currentDish.value.name = firstLetter
          void debouncedEmit('updateName', firstLetter)
          void debouncedUpdate()
        }
      }
    } else {
      currentDish.value.name = value
      void debouncedEmit('updateName', value)
      void debouncedUpdate()
    }
  }
})

const kcal = computed(() => {
  const calories = dietsService.getDishNutrient(
    currentDish.value,
    'calories',
    calculateQuantity.value,
    lossesMultiplier.value
  )
  emit('updateCalories', calories)
  return calories
})

const quantity = computed({
  get: function () {
    let value = currentDish.value.ingredients.reduce((sum, ingredient) => {
      return sum + ingredient.quantity
    }, 0)
    if (calculateQuantity.value) {
      value = (value / currentDish.value.max_portions) * currentDish.value.used_portions
    }
    if (props.mode === 'add-extended') {
      emit('updateQuantity', round(value, 1))
    }
    return round(value, 1)
  },
  set: function (value) {
    emit('updateQuantity', value)
    emit('update', currentDish.value)
  }
})

const size = computed({
  get: function () {
    const value = dishSizes.find((obj) => {
      return obj.value === currentDish.value.size
    })
    return value?.value ?? 0
  },
  set: function (value) {
    currentDish.value.size = value
    emit('updateSize', value)
    emit('update', { ...currentDish.value, size: value })
  }
})

const usedPortions = computed({
  get: function () {
    return currentDish.value.used_portions
  },
  set: function (value) {
    currentDish.value.used_portions = value
    emit('updateUsedPortions', value)
    emit('update', currentDish.value)
  }
})

const maxPortions = computed({
  get: function () {
    return currentDish.value.max_portions
  },
  set: function (value) {
    currentDish.value.max_portions = value
    emit('updateMaxPortions', value)
    emit('update', currentDish.value)
  }
})

const preparationTime = computed({
  get: function () {
    return currentDish.value.preparation_time
  },
  set: function (value) {
    currentDish.value.preparation_time = value
    emit('updatePreparationTime', value)
    emit('update', currentDish.value)
  }
})

const preparationSteps = computed({
  get: function () {
    return currentDish.value.preparation_steps
  },
  set: function (value) {
    currentDish.value.preparation_steps = value
    void debouncedEmit('updatePreparationSteps', value)
    void debouncedUpdate()
  }
})

const getAutoTags = useGetAutoTags(currentDish)

const idAttr = computed(() => (props.mode === 'add' ? 'add-dish-modal' : 'app-layout'))

const setIngredientOriginalIndex = () => {
  A.forEachWithIndex(sortedIngredients.value, (index, ingredient) => {
    ingredient.originalIndex = index
  })
}
const addOrRemoveFromList = (
  valuesList: (keyof DietDishTypeIdMap)[],
  value: keyof DietDishTypeIdMap
) => {
  const index = valuesList.indexOf(value)
  if (index === -1) {
    valuesList.push(value)
  } else {
    valuesList.splice(index, 1)
  }
}
const removeSingleTag = (id: StoreDietDish['dish_types'][number]) => {
  addOrRemoveFromList(currentDish.value.dish_types, id)
  updateDishType(id)
}
const getDishTypeName = (value: StoreDietDish['dish_types'][number]) => {
  return pipe(
    A.filter(dishTypes, (type) => type.value === value),
    A.map((type) => type.text),
    A.getUnsafe(0)
  )
}
const updateDishType = (typeId: StoreDietDish['dish_types'][number]) => {
  const currentDishTypes = currentDish.value.dish_types
  emit('updateDishType', typeId)
  emit('updateDishTypes', currentDishTypes)
  currentDishTypes.sort()
  emit('update', currentDish.value)
}

const updateDishTypes = (types: StoreDietDish['dish_types'][number][]) => {
  currentDish.value.dish_types = types
  emit('updateDishTypes', types)
  hideDishTagList()
}

const showDishTagList = () => {
  tagListShown.value = true
}

const hideDishTagList = () => {
  tagListShown.value = false
}

const getSortedIngredients = () => {
  void nextTick(() => {
    sortedIngredients.value = klona(currentDish.value.ingredients).map((value, index) => ({
      ...value,
      originalIndex: index
    }))
    setIngredientOriginalIndex()
    sortedIngredients.value.sort((a, b) => sortIngredients(a, b) * (sortByReverse.value ? -1 : 1))
  })
}

const sortIngredients = (a: DietIngredient, b: DietIngredient) => {
  if (sortBy.value === 'name') {
    if (a[sortBy.value].toString().localeCompare(b[sortBy.value].toString()) < 0) return 1
    if (a[sortBy.value].toString().localeCompare(b[sortBy.value].toString()) > 0) return -1
  }
  if (sortBy.value === 'quantity') {
    if (a[sortBy.value] < b[sortBy.value]) {
      return -1
    }
    if (a[sortBy.value] > b[sortBy.value]) {
      return 1
    }
    return 0
  }
  if (
    ingredientNutrient(a, sortBy.value as Nutrients) <
    ingredientNutrient(b, sortBy.value as Nutrients)
  ) {
    return -1
  }
  if (
    ingredientNutrient(a, sortBy.value as Nutrients) >
    ingredientNutrient(b, sortBy.value as Nutrients)
  ) {
    return 1
  }
  return 0
}

const updateSorting = (byKey: SortKey) => {
  if (sortBy.value === byKey) {
    sortByReverse.value = !sortByReverse.value
  } else {
    sortBy.value = byKey
    sortByReverse.value = true
  }
  getSortedIngredients()
}

const updateMealType = (value: StoreDietDish['meal_types'][number]) => {
  const _mealTypes = currentDish.value.meal_types
  const mealTypeIndex = _mealTypes.indexOf(value)
  if (mealTypeIndex === -1) {
    _mealTypes.push(value)
  } else {
    _mealTypes.splice(mealTypeIndex, 1)
  }
  _mealTypes.sort()
  emit('updateMealTypes', _mealTypes)
  emit('updateMealType', value)
  emit('update', currentDish.value)
}

const ingredientNutrientHeader = (nutrient: Nutrients) => {
  const nutrientFromMap = nutrientsMap[nutrient]
  if (nutrientFromMap.measurement !== '') {
    return `${nutrientFromMap.shortcut}(${nutrientFromMap.measurement})`
  } else {
    return nutrientFromMap.shortcut
  }
}

const dishOrIngredientNutrient = (_dish: StoreDietDish | DishInFormType, nutrient: Nutrients) => {
  const nutrientDetail = nutrientsMap[nutrient]
  const value = dietsService.getDishNutrient(
    _dish,
    nutrient,
    calculateQuantity.value,
    lossesMultiplier.value
  )

  emit('calculateNutrientValue', { nutrient, value })
  return `${nutrientDetail.shortcut}: ${value} ${nutrientDetail.measurement}`
}

const updateCalculations = () => {
  emit('updateCalculations', {
    ...D.fromPairs(
      A.map(selectedNutrients.value, (nutrient) => [
        nutrient,
        dietsService.getDishNutrient(
          currentDish.value,
          nutrient,
          calculateQuantity.value,
          lossesMultiplier.value
        )
      ])
    ),
    calories: kcal.value,
    quantity: quantity.value
  })
}

const addIngredient = async ({ dishOrIngredientId: id }: { dishOrIngredientId: number }) => {
  const isExtendedMode = props.mode === 'add-extended'
  addIngredientInputActive.value = false

  if (id && !currentDish.value.ingredients.some((ingredient) => ingredient.ingredient_id === id)) {
    try {
      const response = await dietsService.fetchIngredient(id)

      const ingredient = response.data
      const ingredientCopy = klona(ingredient) as IngredientWithOriginalIndex
      ingredientCopy.originalIndex = currentDish.value.ingredients.length
      emit('addIngredient', ingredient)

      if (!isExtendedMode) {
        currentDish.value.ingredients.push(ingredient)
        ingredientCopy.originalIndex = currentDish.value.ingredients.length - 1
      }

      sortedIngredients.value.push(ingredientCopy)
      emit('update', currentDish.value)
      void nextTick(() => {
        ingredientQuantityInput.value?.select()
      })
    } catch (err) {
      reportError(err, 'Error while fetching ingredient', { ingredientId: id })
    }
  } else {
    createMessage({
      title: 'Ten produkt znajduje się już w potrawie'
    })
  }
}

const setReplaceIngredientInputActiveIndex = (ingredientIndex: number) => {
  replaceIngredientInputActiveIndex.value = ingredientIndex
}

const hideReplaceIngredientInputActive = () => {
  replaceIngredientInputActiveIndex.value = undefined
}

const replaceIngredient = ({
  dishOrIngredientId,
  quantity: _quantity,
  dishIngredientId,
  ingredientIndex,
  originalIndex,
  ingredient
}: {
  dishIngredientId: number | undefined
  dishOrIngredientId: number
  ingredientIndex: number
  originalIndex: number
  quantity: number
  ingredient: DietIngredient
}) => {
  if (!currentDish.value.ingredients.some((value) => value.ingredient_id === dishOrIngredientId)) {
    ingredient.quantity = _quantity
    const ingredientCopy = klona(ingredient) as IngredientWithOriginalIndex
    if (dishIngredientId) {
      ingredientCopy.dish_ingredient_id = dishIngredientId
    }
    ingredientCopy.originalIndex = originalIndex
    currentDish.value.ingredients.splice(originalIndex, 1, ingredient)
    sortedIngredients.value.splice(ingredientIndex, 1, ingredientCopy)
    emit('replaceIngredient', { ingredientIndex: originalIndex, ingredient, dishIngredientId })
    emit('update', currentDish.value)
    updateCalculations()
    hideReplaceIngredientInputActive()
  } else {
    hideReplaceIngredientInputActive()
    createMessage({
      title: 'Ten produkt znajduje się już w potrawie'
    })
  }
}

const deleteIngredient = ({
  originalIndex,
  dishIngredientId,
  ingredientId
}: {
  dishIngredientId?: number
  ingredientId: number
  originalIndex: number
}) => {
  currentDish.value.ingredients = currentDish.value.ingredients.filter((v) => {
    return v.ingredient_id !== ingredientId
  })
  getSortedIngredients()
  if (props.mode === 'add-extended') {
    emit('deleteIngredient', { originalIndex, dishIngredientId, ingredientId })
  } else {
    emit('deleteIngredient', { originalIndex, ingredientId })
  }
  emit('update', currentDish.value)
}

const updateIngredientInDishQuantity = ({
  ingredientIndex,
  originalIndex,
  quantity: _quantity,
  dishIngredientId
}: {
  dishIngredientId?: number
  ingredientIndex: number
  originalIndex: number
  quantity: number
}) => {
  if ('quantity' in (currentDish.value.ingredients[originalIndex] ?? {})) {
    // @ts-expect-error
    currentDish.value.ingredients[originalIndex].quantity = _quantity
  }
  if (sortedIngredients.value[ingredientIndex]) {
    // @ts-ignore
    sortedIngredients.value[ingredientIndex].quantity = _quantity
  }

  emit('updateIngredientInDishQuantity', {
    ingredientIndex: originalIndex,
    quantity: _quantity,
    dishIngredientId
  })
  emit('update', currentDish.value)
  updateCalculations()
}

const ingredientNutrient = (ingredient: DietIngredient, nutrient: Nutrients) => {
  return dietsService.getIngredientNutrient(ingredient, nutrient, true)
}

const hideAddIngredientInput = () => {
  addIngredientInputActive.value = false
}

const updateEvent = () => {
  emit('update', currentDish.value)
}

const getIconBySort = computed(() => getIconBySortFn(sortBy.value, sortByReverse.value))

useEventListener(document.getElementById(idAttr.value), 'click', (e) => {
  if (
    !(e.target as HTMLDivElement).classList.contains('ingredient-name-text') &&
    !(e.target as HTMLDivElement).classList.contains('option')
  ) {
    hideReplaceIngredientInputActive()
  }
  hideAddIngredientInput()
})

const debouncedEmit = useDebounceFn((action: Parameters<typeof emit>[0], payload: unknown) => {
  emit(action, payload)
}, 500)
const debouncedUpdate = useDebounceFn(updateEvent, 500)

onMounted(() => {
  eventBus.on('undoOrRedo', getSortedIngredients)

  getSortedIngredients()
})

onBeforeUnmount(() => {
  eventBus.off('undoOrRedo')
})

watch(
  [currentDish, sortedIngredients],
  () => {
    updateCalculations()
  },
  { deep: true }
)

watch(
  () => props.dishIndex,
  () => {
    getSortedIngredients()
  }
)

watch(
  () => props.extended,
  (value) => {
    nutrientsType.value = 'choosen'
    sortBy.value = 'quantity'
    sortByReverse.value = true
    if (value) {
      getSortedIngredients()
    }
  }
)

watch(
  () => props.dish,
  (next, prev) => {
    // gdy nastapi podmiana potrawy/produktu ale nie w zakladce potrawy
    if (props.mode !== 'add-extended') {
      currentDish.value = props.mode === 'add' ? emptyDish : toRaw(next)
    } else {
      // gdy naastapi podmiana potrawy w zakladce potrawy
      if (next.id !== prev.id) {
        currentDish.value = toRaw(next)
      } else {
        sortedIngredients.value = sortedIngredients.value.map((ingredient) => {
          const elem = next.ingredients.find((v) => v.ingredient_id === ingredient.ingredient_id)

          ingredient.dish_ingredient_id = elem?.dish_ingredient_id
          return ingredient
        })
        currentDish.value.ingredients = toRaw(next.ingredients)
      }
    }
  },
  { deep: true }
)
</script>

<style scoped>
.add-dish-or-ingredient-form.extended {
  min-width: 675px;
}

.allergens-row {
  display: flex;
  margin-left: 10px;
}

.allergens {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  vertical-align: middle;
}

.allergens.not-like {
  background: var(--color-warning-60);
}

.allergens.like {
  background: var(--color-success-60);
}

.allergens.allergies {
  background: #faa0a8;
}

.allergens.intolerances {
  background: #a0a8fa;
}

.name {
  width: calc(100% - 144px);
  overflow-y: auto;
}

.name__textarea {
  font-size: 24px;
  line-height: 28px;
  border: 0 !important;
  padding: 5px 0;
  font-family: Montserrat-Medium;
  width: 100%;
  display: block;
}

.info {
  line-height: 22px;
  height: 22px;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 16px;
}

.info-icon {
  margin-right: 6px;
}

.kcal-quantity-measurement {
  margin-bottom: 6px;
  font-size: 18px;
  font-family: Montserrat-Medium;
  display: flex;
  flex-direction: row;
  height: 32px;
  line-height: 32px;
}

.quantity {
  height: 32px;
  margin-left: 24px;
}

:deep(.base-input.is-disabled) {
  border-bottom: none;
  pointer-events: none;
}

.measurement {
  height: 32px;
  margin-left: 24px;
}

.nutrients {
  display: flex;
  flex-flow: row wrap;
  font-size: 12px;
  margin-top: 12px;
  line-height: 14px;
}

.nutrient {
  margin-right: 15px;
  white-space: nowrap;
}

.ingredients-switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 50px;
}

.ingredients-switch__label {
  font-size: 14px;
  font-family: Montserrat-Medium;
  line-height: 32px;
  height: 32px;
}

.ingredients-switch__radio {
  margin-left: 20px;
}

.ingredients {
  margin-top: 30px;
}

.extended .ingredients {
  padding: 0;
}

.ingredients-table {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
}

.ingredients-table :deep(tr) {
  vertical-align: top;
}

.ingredients-table :deep(th),
.ingredients-table :deep(td) {
  padding: 0;

  /* border: 1px solid orange; */
}

.ingredients-table__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-weight: normal;
  font-size: 12px;
  height: 54px;
  padding: 0;
  font-family: Montserrat-Medium;
  padding-right: 12px;
  white-space: nowrap;
  width: fit-content;
}

.ingredient-name .ingredient-tooltip {
  display: inline;
}

.ingredient-name {
  font-size: 14px;
  width: auto;
}

.ingredient-name .ingredients-table__header {
  cursor: pointer;
}

.ingredient-name :deep(.ingredients-table__cell) {
  justify-content: left;
  white-space: normal;
  max-width: 90%;
  word-break: break-word;
}

.ingredient-quantity {
  width: 100px;
}

.ingredient-quantity .ingredients-table__header {
  justify-content: center;
  cursor: pointer;
}

.ingredient-measurement {
  width: 136px;
}

.ingredient-measurement :deep(.ingredients-table__cell) {
  justify-content: left;
}

.ingredient-nutrient {
  width: 40px;
}

.ingredient-nutrient .ingredients-table__header {
  cursor: pointer;
}

.ingredient-nutrient.disabled {
  pointer-events: none;
}

.ingredient-actions {
  width: 28px;
}

.ingredient-actions .ingredients-table__header,
.ingredient-actions :deep(.ingredients-table__cell) {
  padding: 0;
}

.ingredient-actions .base-button .base-icon {
  color: var(--color-tertiary-text);
}

.ingredient-actions .base-button:hover .base-icon {
  color: #f55f6d;
}

.ingredient-quantity__input {
  width: 52px;
  padding: 5px 4px;
  text-align: center;
}

.ingredient-measurement__input {
  width: 52px;
  padding: 5px 4px;
  text-align: center;
}

.ingredients-empty {
  padding: 19px 0;
  font-size: 'Montserrat-Medium';
  font-size: 12px;
  text-align: center;
  border: 2px dashed var(--color-main-100);
  margin: 10px 0;
}

/* Add ingredient */
.add-ingredient {
  margin-top: 6px;
  height: 32px;
  width: 334px;
}

.extended .add-ingredient {
  padding: 0;
}

.section-name {
  font-family: Montserrat-Medium;
  font-size: 14px;
  line-height: 32px;
  margin-right: 16px;
  min-width: 85px;
}

.section-label {
  font-family: Montserrat-Medium;
}

/* Portions */
.portions {
  margin-top: 32px;
  font-size: 12px;
}

.portions .portions-inner {
  display: inline-block;
}

.used-portions-input,
.max-portions-input {
  text-align: center;
  width: 48px;
}

.max-portions-input,
.preparation-time-input {
  margin: 0 4px;
}

/* Preparation time */
.preparation-time {
  margin-top: 20px;
  font-size: 12px;
}

.preparation-time-input {
  width: 48px;
  text-align: center;
}

/* Preparation steps */
.preparation-steps {
  margin-top: 20px;
}

.preparation-steps-textarea {
  border: 0 !important;
  padding: 5px 10px 5px 0;
  line-height: 2.16;
}

.preparation-steps-textarea.is-disabled {
  pointer-events: none;
  background-color: rgb(239 239 239 / 30%);
}

.extended .preparation-steps-textarea {
  max-height: none;
}

/* Size */
.size {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  line-height: 32px;
  font-size: 14px;
}

.extended .size {
  flex-direction: column;
  align-items: flex-start;
}

.size-radio {
  margin-right: 20px;
  min-width: 150px;
}

/* Meal types */
.meal-types {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  line-height: 32px;
  font-size: 14px;
}

.extended .meal-types {
  flex-direction: column;
  align-items: flex-start;
}

.meal-types-checkbox {
  margin-right: 20px;
  min-width: 150px;
}

/* Dish types */
.dish-types {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  line-height: 32px;
  font-size: 14px;
}

.dish-types-row {
  display: flex;
  flex-wrap: wrap;
  font-family: Montserrat-Medium;
  gap: 8px;
  margin-bottom: 8px;
}

.chosen-type-box {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  padding: 1px 12px;
  width: max-content;
  height: 28px;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.chosen-type-box:hover {
  background: #f7f7f7;
}

.show-tags {
  display: flex;
  color: #000;
  background: #f0f0f0;
  width: 204px;
  height: 36px;
  border: none;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  padding: 0 12px 0 10px !important;
}

.extended .dish-types {
  flex-direction: column;
  align-items: flex-start;
}

.dish-types-checkbox {
  margin-right: 20px;
  min-width: 150px;
}

.ingredient-name-button {
  cursor: pointer;
}

.remove-tag {
  cursor: pointer;
  margin-left: 10px;
  color: rgb(43 43 43 / 36%);
}
</style>
