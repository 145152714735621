import type { CSSProperties, ExtractPropTypes, PropType } from 'vue'

import { computed, defineComponent, toRefs } from 'vue'

import { useFlexGapSupport } from '@/hooks/useFlexGapSupport'
import { filterEmpty } from '@/utils/vue/filterEmpty'

const spaceProps = {
  alignX: String as PropType<SpaceAlign>,
  alignY: String as PropType<SpaceAlign>,
  direction: {
    type: String as PropType<'rows' | 'columns'>,
    default: 'rows'
  },
  size: {
    type: Number
  },
  space: {
    type: Number,
    default: 4
  },
  paddingX: {
    type: [Number, Array] as PropType<number | [number, number]>,
    default: 0
  },
  paddingY: {
    type: [Number, Array] as PropType<number | [number, number]>,
    default: 0
  },
  fill: Boolean,
  fluid: Boolean,
  wrap: Boolean,
  inline: Boolean
}

export type SpaceSize = number
export type SpaceAlign = 'start' | 'end' | 'center' | 'baseline' | 'stretch' | 'between' | 'around'
export type SpaceProps = ExtractPropTypes<typeof spaceProps>

const defaultMultiplier = 2

export const Space = defineComponent({
  name: 'KSpace',
  props: spaceProps,
  setup(props, ctx) {
    const isFlexGapSupported = useFlexGapSupport()
    const { alignX, alignY, paddingX, paddingY, space, size } = toRefs(props)

    return () => {
      const children = filterEmpty(ctx.slots.default?.())
      const margin = computed(() => {
        if (size.value) {
          return `${size.value}px`
        }
        return `${space.value * defaultMultiplier}px`
      })

      const style = computed<CSSProperties>(() => {
        const paddings: CSSProperties = {}

        if (Array.isArray(paddingX.value)) {
          paddings.paddingLeft = `${paddingX.value[0] * defaultMultiplier}px`
          paddings.paddingRight = `${paddingX.value[1] * defaultMultiplier}px`
        } else {
          paddings.paddingLeft = `${paddingX.value * defaultMultiplier}px`
          paddings.paddingRight = `${paddingX.value * defaultMultiplier}px`
        }
        if (Array.isArray(paddingY.value)) {
          paddings.paddingTop = `${paddingY.value[0] * defaultMultiplier}px`
          paddings.paddingBottom = `${paddingY.value[1] * defaultMultiplier}px`
        } else {
          paddings.paddingTop = `${paddingY.value * defaultMultiplier}px`
          paddings.paddingBottom = `${paddingY.value * defaultMultiplier}px`
        }
        return paddings
      })

      const classes = computed(() => ({
        'k-space': !props.inline,
        'k-space--inline': props.inline,
        'k-space--fill': props.fill,
        [`k-space--${props.direction}`]: `k-space--${props.direction}`,
        [`k-space--align-${alignY.value}`]: alignY.value,
        [`k-space--justify-${alignX.value}`]: alignX.value,
        [`k-space--wrap`]: props.wrap,
        [`k-space--fluid`]: props.fluid
      }))

      const gapStyle = computed(() => {
        const currentStyles = style.value
        if (isFlexGapSupported) {
          if (props.direction === 'columns') {
            currentStyles.columnGap = margin.value
          }
          if (props.direction === 'rows') {
            currentStyles.rowGap = margin.value
          }
        }

        return currentStyles
      })
      const getMarginStyle = (isLast: boolean): CSSProperties => {
        const currentStyles = style.value
        if (isFlexGapSupported) {
          return {}
        }

        if (isLast) {
          return props.wrap ? { marginBottom: margin.value } : {}
        }

        if (props.direction === 'columns') {
          currentStyles.marginRight = margin.value
        }
        if (props.direction === 'rows' || props.wrap) {
          currentStyles.marginBottom = margin.value
        }

        return currentStyles
      }

      return (
        <div class={classes.value} style={gapStyle.value}>
          {isFlexGapSupported
            ? children
            : children.map((c, i) => (
                <div
                  key={`item-${i}`}
                  class="k-space-item"
                  style={getMarginStyle(i === children.length - 1)}
                >
                  {c}
                </div>
              ))}
        </div>
      )
    }
  }
})
