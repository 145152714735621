<template>
  <div class="diet-list">
    <div class="nav-actions">
      <DietListNav
        :diet-type="dietType"
        :drag-to-nav="dragToNav"
        @drop-from-nav="handleDietDrop"
      />
      <div class="actions">
        <div class="copy-diet-wrapper">
          <BaseTooltip class="base-tooltip--top base-tooltip--right">
            <template #tooltip>
              Skopiuj zaznaczone jadłospisy do Twojej grupy
            </template>
            <IconButton
              name="filter_none"
              icon-size="18"
              :disabled="!someDietsSelected"
              @click.stop="showCopySelectedDietMenu"
            />
          </BaseTooltip>
          <DietListCopyMoveMenu
            v-if="copySelectedDietMenu.visible"
            class="copy-diet-menu"
            :title="'Skopiuj do:'"
            :groups="groups"
            :hide-add-group="true"
            @select-group="copySelectedDiet"
            @close="hideCopySelectedDietMenu()"
          />
        </div>
      </div>
    </div>
    <div class="list">
      <div class="header row">
        <div class="column">
          <BaseCheckbox
            :model-value="allDietsSelected"
            @update:model-value="handleAll()"
          />
        </div>
        <div class="header__name column">
          <div
            class="column__sortable"
            @click="setSortBy('name')"
          >
            Nazwa jadłospisu
            <BaseIcon :name="getIconBySort('name')" />
          </div>
        </div>
        <div class="column">
          <div
            class="column__sortable"
            @click="setSortBy('calories')"
          >
            Kaloryczność (kcal/dzień)
            <BaseIcon :name="getIconBySort('calories')" />
          </div>
        </div>
      </div>
      <div
        v-if="diets.length > 0"
        class="diets"
      >
        <div
          v-for="diet in sortedDiets"
          :key="`diet-${diet.id}`"
          class="diet row"
          :class="{ 'diet--selected': diet.selected }"
          @mouseenter="setHoverDiet(`diet-${diet.id}`)"
          @mouseleave="setHoverDiet('')"
        >
          <div class="column">
            <BaseCheckbox
              :model-value="diet.selected"
              @update:model-value="selectDiet(diet)"
            />
          </div>
          <div class="column diet__name">
            <router-link
              :to="{ name: 'DietDetail', params: { dietType, dietId: diet.id } }"
              :draggable="'true'"
              @dragstart="handleDietDragStart(diet, $event)"
              @dragend="handleDietDragEnd"
            >
              {{ diet.name }}
            </router-link>
          </div>
          <div class="column diet__calories">
            {{ diet.calories }}
          </div>
          <div
            v-if="isHoveredDiet(`diet-${diet.id}`)"
            class="row__actions"
          >
            <div class="diet__actions">
              <BaseTooltip class="base-tooltip--right base-tooltip--top">
                <template #tooltip>
                  Skopiuj jadłospis do Twojej grupy
                </template>

                <IconButton
                  name="filter_none"
                  icon-size="18"
                  @click.stop="showCopySingleDietMenu(diet.id)"
                />
                <DietListCopyMoveMenu
                  v-if="copySingleDietMenu.visible"
                  class="copy-diet-menu"
                  :title="'Skopiuj do:'"
                  :groups="groups"
                  :hide-add-group="true"
                  @select-group="copySingleDiet"
                  @close="hideCopySingleDietMenu()"
                />
              </BaseTooltip>
              <BaseTooltip class="base-tooltip--right base-tooltip--top">
                <template #tooltip>
                  Przypisz jadłospis do pacjenta
                </template>

                <IconButton
                  name="person_add"
                  @click="showAssignToPatientModal(diet.id)"
                />
              </BaseTooltip>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="diets.length === 0 && loading === false"
        class="diets-empty"
      >
        <BaseIcon
          type="info"
          name="info"
        />
        <span>Nie masz dostępu do diet systemowych. Wykup wyższy pakiet programu, żeby aktywować
          dostęp.</span>
      </div>
      <div
        v-if="loading"
        class="list-loader"
      >
        <div class="list-loader-bar">
          <div class="list-loader-bar__progress" />
        </div>
      </div>
    </div>
    <DietAssignToPatientModal
      v-if="assignToPatientModal.visible"
      :diet-type="dietType"
      :diet-id="assignToPatientModal.dietId"
      @close="hideAssignToPatientModal"
      @send-system-diets-list-to-file="sendSystemDiets"
    />
    <div
      id="ghost-image"
      class="ghost-image"
    >
      <BaseIcon
        class="ghost-image__icon"
        name="assignment"
      /> {{ ghostText() }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  FetchSystemDietsResponse,
  SaveCopiedSystemDietInFileParam
} from '@/services/dietsService'
import type { DietType, UserGroup } from '@/store/types'

import { D } from '@mobily/ts-belt'
import { useEventListener } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import DietAssignToPatientModal from '@/components/diets/DietAssignToPatientModal.vue'
import DietListCopyMoveMenu from '@/components/diets/DietListCopyMoveMenu.vue'
import DietListNav from '@/components/diets/DietListNav.vue'
import { IconButton } from '@/components/IconButton'
import { useSortBy } from '@/hooks/useSortBy'
import { dietsService } from '@/services/dietsService'
import { useGlobalStore } from '@/store/globalStore'
import { reportError } from '@/utils/reportError'
import { stringify } from '@/utils/stringify'

type FetchSystemDietsWithSelected = FetchSystemDietsResponse & {
  selected: boolean
}
const { setSortBy, getIconBySort, sort } = useSortBy<
  keyof FetchSystemDietsResponse,
  FetchSystemDietsWithSelected
>('name')

const dietType: DietType = 'system'
const globalStore = useGlobalStore()
const { createMessage, changeDietCounter } = globalStore
const { user } = storeToRefs(globalStore)
const groups = ref<UserGroup[]>([])
const diets = ref<FetchSystemDietsWithSelected[]>([])
const hoveredDiet = ref('')
const dragToNav = ref(false)
const loading = ref(true)
const assignToPatientModal = ref({
  visible: false,
  dietId: 0
})
const copySingleDietMenu = ref({
  visible: false,
  dietId: 0
})
const copySelectedDietMenu = ref({
  visible: false
})
const sortedDiets = computed(() => {
  return [...diets.value].sort(sort)
})
const allDietsSelected = computed(() => {
  return diets.value.filter((diet) => !diet.selected).length === 0
})
const someDietsSelected = computed(() => {
  return diets.value.filter((diet) => diet.selected).length > 0
})
const isRodoAccepted = computed(() => {
  return !!user?.value?.is_rodo_accepted
})
const selectedDietsLength = computed(() => {
  return diets.value.filter((diet) => diet.selected).length
})

const loadDiets = async () => {
  loading.value = true
  const dietResponse = await dietsService.fetchSystemDiets({})

  diets.value = dietResponse.data.map((diet) => {
    return D.set(diet, 'selected', false)
  })
  loading.value = false
}
const loadGroups = async () => {
  const groupResponse = await dietsService.fetchGroups({
    dietType: 'user'
  })
  groups.value = groupResponse.data
}

const ghostText = () => {
  if (selectedDietsLength.value === 1) {
    return '1 jadłospis do przeniesienia'
  } else {
    return `${selectedDietsLength.value} jadłospisy do przeniesienia`
  }
}
const handleDietDragStart = (diet: FetchSystemDietsWithSelected, event: DragEvent) => {
  if (event.dataTransfer) {
    dragToNav.value = true
    const selectedDiets = diets.value.filter((v) => v.selected).map((v) => v.id)
    event.dataTransfer.dropEffect = 'move'
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('selectedDiets', stringify(selectedDiets))
    if (!diet.selected) {
      event.dataTransfer.setData('draggedDiets', stringify([diet.id]))
      unselectAll()
      selectDiet(diet)
    } else {
      event.dataTransfer.setData('draggedDiets', stringify(selectedDiets))
    }
    const ghostImage = document.getElementById('ghost-image')
    if (ghostImage) {
      event.dataTransfer.setDragImage(ghostImage, 0, 0)
    }
  }
}
const handleDietDrop = async (event: DragEvent) => {
  if (event.dataTransfer) {
    try {
      const selectedDiets = JSON.parse(event.dataTransfer.getData('selectedDiets')) as number[]
      const draggedDiets = JSON.parse(event.dataTransfer.getData('draggedDiets')) as number[]
      loading.value = true
      await dietsService.copyDietsToGroup({
        dietType: 'system',
        groupId: null,
        dietsIds: draggedDiets
      })
      changeDietCounter({
        dietType: 'user',
        value: draggedDiets.length
      })
      unselectAll()
      diets.value.map((diet) => {
        if (selectedDiets.includes(diet.id)) diet.selected = true
        return diet
      })
      loading.value = false
      await sendSystemDietsListToFile(draggedDiets)
    } catch (e) {
      reportError(e, 'Error while diet drop')
    }
  }
}
const handleDietDragEnd = () => {
  dragToNav.value = false
}
// Select diet
const selectDiet = (diet: FetchSystemDietsWithSelected) => {
  diets.value = diets.value.map((v) => {
    if (diet.id === v.id) {
      v.selected = !v.selected
    }
    return v
  })
}
const selectAll = () => {
  diets.value = diets.value.map((diet) => {
    diet.selected = true
    return diet
  })
}
const unselectAll = () => {
  diets.value.map((diet) => {
    diet.selected = false
    return diet
  })
}
const handleAll = () => {
  if (allDietsSelected.value) {
    unselectAll()
  } else {
    selectAll()
  }
}
// Assign to patient
const showAssignToPatientModal = (dietId: number) => {
  if (isRodoAccepted.value) {
    assignToPatientModal.value.dietId = dietId
    assignToPatientModal.value.visible = true
  } else {
    location.href = import.meta.env.VUE_APP_HOST + '/dietetyk/account/agreements'
  }
}
const hideAssignToPatientModal = () => {
  assignToPatientModal.value.visible = false
  assignToPatientModal.value.dietId = 0
}
// Hovering
const setHoverDiet = (key: string) => {
  hoveredDiet.value = key
}
const isHoveredDiet = (key: string) => {
  return hoveredDiet.value === key
}

// Copy diet
const showCopySingleDietMenu = (dietId: number) => {
  copySingleDietMenu.value.dietId = dietId
  copySingleDietMenu.value.visible = true
}
const hideCopySingleDietMenu = () => {
  copySingleDietMenu.value.visible = false
  copySingleDietMenu.value.dietId = 0
}
const copySingleDiet = async (groupId: number) => {
  const dietsIds = [copySingleDietMenu.value.dietId]
  await copyDiet(groupId, dietsIds)
  hideCopySingleDietMenu()
}
const showCopySelectedDietMenu = () => {
  copySelectedDietMenu.value.visible = true
}
const hideCopySelectedDietMenu = () => {
  copySelectedDietMenu.value.visible = false
}
const copySelectedDiet = async (groupId: number) => {
  const dietsIds = diets.value.filter((diet) => diet.selected).map((diet) => diet.id)
  await copyDiet(groupId, dietsIds)
  hideCopySingleDietMenu()
}
const sendSystemDiets = async (e: number) => {
  await sendSystemDietsListToFile([Number(e)])
}
const sendSystemDietsListToFile = async (dietsIds: number[]) => {
  const email = user?.value?.email
  if (email) {
    const dietsNamesToSend = diets.value
      .filter((diet) => dietsIds.includes(diet.id))
      .map((diet) => diet.name)
    const dietsToSendList = [] as SaveCopiedSystemDietInFileParam[]
    dietsNamesToSend.forEach((dietName) => {
      dietsToSendList.push({
        type: 'system-diets',
        user: email,
        name: dietName
      })
    })
    try {
      await dietsService.saveCopiedSystemDietInFile(dietsToSendList)
    } catch (err) {
      reportError(err, 'Error while sending system diets list to file', { dietsToSendList })
    }
  }
}
const copyDiet = async (groupId: number, dietsIds: number[]) => {
  loading.value = true
  await dietsService.copyDietsToGroup({
    dietType: 'system',
    groupId,
    dietsIds
  })
  changeDietCounter({
    dietType: 'user',
    value: dietsIds.length
  })
  createMessage({ title: 'Skopiowano jadłospis' })
  loading.value = false
  await sendSystemDietsListToFile(dietsIds)
}

onMounted(async () => {
  try {
    await Promise.all([loadDiets(), loadGroups()])
  } catch (err) {
    reportError(err, 'Error while mounting diet list system')
  }
})

useEventListener(document, 'click', () => {
  hideCopySingleDietMenu()
  hideCopySelectedDietMenu()
})
</script>

<style scoped>
.diet-list {
  margin-bottom: 24px;
  min-width: 1050px;
}

.nav-actions {
  display: flex;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.list {
  background: #fff;
  padding: 0 12px 12px;
  font-size: 12px;
  position: relative;
}

.list-loader {
  background: rgb(255 255 255 / 40%);
  display: flex;
  position: absolute;
  inset: 0;
  align-items: top;
  justify-content: center;
  z-index: 1000;
  cursor: wait;
}

.list-loader-bar {
  background-color: transparent;
  border-radius: 9999px;
  width: calc(100% - 24px);
  margin: 52px 12px;
  height: 2px;
  position: relative;
  overflow: hidden;
}

.list-loader-bar__progress {
  background-color: var(--color-main-100);
  border-radius: 9999px;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 50%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: progress-bar;
}

@keyframes progress-bar {
  from {
    left: -50%;
  }

  to {
    left: 100%;
  }
}

.row {
  display: grid;
  grid-template-columns: 24px minmax(165px, auto) 180px;
  grid-column-gap: 10px;
}

.row__actions {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column__sortable {
  height: 52px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.column__sortable .base-icon {
  cursor: pointer;
}

.header {
  height: 52px;
  border-bottom: 2px solid #eaeaea;
  margin-bottom: 12px;
  font-family: Montserrat-Medium;
  color: var(--color-main-100);
}

.header__name {
  justify-content: left;
}

.diets-empty {
  display: flex;
  gap: 10px;
  padding: 20px 0;
  align-items: center;
}

.diets {
  position: relative;
}

.diets-loader {
  position: absolute;
  inset: 0;
  z-index: 10000;
  background: rgb(255 255 255 / 60%);
  cursor: wait;
}

.diet {
  height: 32px;
  margin-bottom: 6px;
  position: relative;
}

.diet:hover,
.diet--selected {
  background: var(--color-main-10);
}

.diet__name {
  justify-content: left;
}

.diet__name > a {
  display: block;
  width: 100%;
  height: 32px;
  line-height: 32px;
  color: var(--color-primary-text);
  text-decoration: none;
}

.diet__calories {
  justify-content: flex-end;
  margin-right: 75px;
}

.diet__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 50px;
  background: linear-gradient(to right, transparent, var(--color-main-10) 50px);
  height: 32px;
}

.diet:hover .row__actions {
  display: block;
}

.copy-diet-wrapper {
  position: relative;
}

.copy-diet-menu {
  top: 12px;
  right: 12px;
}

.ghost-image {
  position: absolute;
  background-color: #5dbab3;
  border-radius: 5px;
  color: #fff;
  font-family: Montserrat-SemiBold;
  font-size: 12px;
  padding: 6px;
  display: flex;
  align-items: center;
  top: -500px;
}

.ghost-image__icon {
  padding-right: 6px;
  color: #fff;
}
</style>
