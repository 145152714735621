import type { useDietList } from '@/hooks/useDietList'
import { F } from '@mobily/ts-belt'
import type { InjectionKey } from 'vue'

import { inject } from 'vue'

export type DietListGroupsProvider = ReturnType<typeof useDietList>

export const DietListGroupsInject = Symbol() as InjectionKey<DietListGroupsProvider>

export const useDietListGroupsProvider = () =>
  F.coerce<DietListGroupsProvider>(inject(DietListGroupsInject))
