import type { VariantPropsOf } from '@/utils/variants'
import type { PropType } from 'vue'

import { computed, defineComponent, useSlots } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import { variants } from '@/utils/variants'

import s from './style.module.scss'

const styles = variants({
  base: s.root,
  variants: {
    variant: {
      primary: s['type--primary'],
      secondary: s['type--secondary'],
      destructive: s['type--destructive'],
      ghost: s['type--ghost']
    },
    size: {
      small: s['size--small'],
      default: s['size--default'],
      large: s['size--large'],
      xl: s['size--xl']
    },
    disabled: {
      true: 'disabled'
    }
  },
  defaultVariants: {
    variant: 'primary',
    size: 'default'
  }
})

type Props = VariantPropsOf<typeof styles> & {
  iconSize?: IconSize
  leftIcon?: IconName
  rightIcon?: IconName
}

const IconSize: Record<NonNullable<VariantPropsOf<typeof styles>['size']>, IconSize> = {
  small: '24',
  default: '24',
  large: '28',
  xl: '32'
}

const Component = (props: Props) => {
  const slots = useSlots()
  const classes = computed(() => {
    return styles({
      variant: props.variant,
      disabled: props.disabled,
      size: props.size
    })
  })
  const iconSize = props.iconSize ?? IconSize[props.size ?? 'default']

  return () => (
    <button class={classes.value} disabled={props.disabled}>
      {props.leftIcon && <BaseIcon size={iconSize} name={props.leftIcon} />}
      {slots.default?.()}
      {props.rightIcon && <BaseIcon size={iconSize} name={props.rightIcon} />}
    </button>
  )
}

export const KcalmarButton = defineComponent(Component, {
  props: {
    variant: String as PropType<Props['variant']>,
    size: String as PropType<Props['size']>,
    iconSize: String as PropType<Props['iconSize']>,
    disabled: Boolean,
    leftIcon: String as PropType<Props['leftIcon']>,
    rightIcon: String as PropType<Props['rightIcon']>
  },
  emits: ['update:modelValue']
})
