import { storeToRefs } from 'pinia'
import { computed } from 'vue'

import { useGlobalStore } from '@/store/globalStore'

export const useHasAddIngredientPermission = () => {
  const globalStore = useGlobalStore()
  const { hasPerm } = globalStore
  const { user } = storeToRefs(globalStore)

  return computed(() => {
    const ingredientsPrivateCount = user?.value?.counters.ingredients ?? 0

    if (!user?.value?.is_superuser) {
      if (hasPerm('accounts.max_ingredient_bo')) {
        return true
      } else if (hasPerm('accounts.max_ingredient_300')) {
        return ingredientsPrivateCount <= 299
      } else if (hasPerm('accounts.max_ingredient_10')) {
        return ingredientsPrivateCount <= 9
      }
      return false
    } else {
      return true
    }
  })
}
