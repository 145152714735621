export const spaces = {
  space0: 0,
  space1: '1px',
  space2: '2px',
  space3: '4px',
  space4: '8px',
  space5: '10px',
  space6: '12px',
  space7: '16px',
  space8: '20px',
  space9: '24px',
  space10: '28px',
  space11: '32px',
  space12: '36px',
  space13: '40px',
  space14: '44px',
  space15: '48px',
  space16: '56px',
  space17: '64px',
  space18: '72px',
  space19: '80px',
  space20: '96px',
  space21: '112px',
  space22: '120px',
  space23: '128px',
  space24: '144px',
  space25: '160px',
  space26: '192px',
  space27: '208px',
  space28: '224px',
  space29: '256px',
  space30: '288px',
  space31: '320px',
  spaceMax: '384px'
}
export const baseColors = {
  turquoise100: '#aadede',
  turquoise200: '#80cdce',
  turquoise300: '#55bcbe',
  turquoise400: '#2aacad',
  turquoise50: '#ecf5f6',
  turquoise500: '#009b9d',
  turquoise600: '#008183',
  turquoise700: '#006769',
  turquoise800: '#004e4f',
  turquoise900: '#003434',
  alpha00: '#fff',
  alpha100: '#1a1a1a1f',
  alpha200: '#1a1a1a2e',
  alpha300: '#1a1a1a42',
  alpha400: '#1a1a1a5c',
  alpha50: '#1a1a1a14',
  alpha500: '#1a1a1a7a',
  alpha600: '#1a1a1a99',
  alpha700: '#1a1a1ab2',
  alpha800: '#1a1a1acc',
  alpha900: '#1a1a1a',
  blueGray100: '#f1f5f9',
  blueGray200: '#e1e7ef',
  blueGray300: '#c8d6e5',
  blueGray400: '#9eacc0',
  blueGray50: '#f8fafc',
  blueGray500: '#65758b',
  blueGray600: '#48566a',
  blueGray700: '#344256',
  blueGray800: '#1d283a',
  blueGray900: '#141e35',
  bronze100: '#f5f5f4',
  bronze200: '#e7e5e4',
  bronze300: '#d6d3d1',
  bronze400: '#a8a29f',
  bronze50: '#fafaf9',
  bronze500: '#78726d',
  bronze600: '#56524e',
  bronze700: '#44403c',
  bronze800: '#292524',
  bronze900: '#211e1b',
  cyan100: '#aadede',
  cyan200: '#80cdce',
  cyan300: '#55bcbe',
  cyan400: '#2aacad',
  cyan50: '#ecf5f6',
  cyan500: '#009b9d',
  cyan600: '#008183',
  cyan700: '#006769',
  cyan800: '#004e4f',
  cyan900: '#003434',
  emerald100: '#d1fae5',
  emerald200: '#adfcd7',
  emerald300: '#6ee7b7',
  emerald400: '#36d399',
  emerald50: '#f0fdf7',
  emerald500: '#10b77f',
  emerald600: '#059467',
  emerald700: '#047656',
  emerald800: '#066046',
  emerald900: '#064b39',
  fuchsia100: '#f9e5ff',
  fuchsia200: '#f5d2fe',
  fuchsia300: '#f0abfc',
  fuchsia400: '#e87bf9',
  fuchsia50: '#fdf5ff',
  fuchsia500: '#d948ef',
  fuchsia600: '#bf27d3',
  fuchsia700: '#a31daf',
  fuchsia800: '#85198f',
  fuchsia900: '#711a75',
  gray100: '#f4f4f5',
  gray200: '#e1e1e2',
  gray300: '#d0d0d4',
  gray400: '#a1a1aa',
  gray50: '#fcfcfc',
  gray500: '#7a7a83',
  gray600: '#52525b',
  gray700: '#7c7c7c',
  gray800: '#545454',
  gray900: '#2b2b2b',
  green100: '#defce9',
  green200: '#bbf7d0',
  green300: '#85efac',
  green400: '#4ade80',
  green50: '#f2fdf5',
  green500: '#1ac057',
  green600: '#16a249',
  green700: '#3f3f46',
  green800: '#27272a',
  green900: '#202024',
  indigo100: '#e0e8ff',
  indigo200: '#c8d3fe',
  indigo300: '#aab8ff',
  indigo400: '#8c96fc',
  indigo50: '#f3f6ff',
  indigo500: '#6b6ef8',
  indigo600: '#5048e5',
  indigo700: '#463acb',
  indigo800: '#372fa2',
  indigo900: '#312e7f',
  lime100: '#ebfcca',
  lime200: '#d9f99f',
  lime300: '#bbef5f',
  lime400: '#96db29',
  lime50: '#f7fee7',
  lime500: '#7bc111',
  lime600: '#609c0c',
  lime700: '#49790b',
  lime800: '#406312',
  lime900: '#355214',
  metal100: '#f3f4f6',
  metal200: '#e5e7eb',
  metal300: '#ccd1d8',
  metal400: '#9aa2b1',
  metal50: '#f4f9fa',
  metal500: '#787f8e',
  metal600: '#636d7d',
  metal700: '#424e62',
  metal800: '#334256',
  metal900: '#26314a',
  orange100: '#ffedd6',
  orange200: '#fed6a9',
  orange300: '#fdba72',
  orange400: '#fb923c',
  orange50: '#fff6eb',
  orange500: '#f67416',
  orange600: '#e9590c',
  orange700: '#c03f0c',
  orange800: '#9b3b12',
  orange900: '#7d2d12',
  pink100: '#fce8f4',
  pink200: '#fbd0e8',
  pink300: '#f9a9d5',
  pink400: '#f471b5',
  pink50: '#fdf2f8',
  pink500: '#ec4699',
  pink600: '#db2979',
  pink700: '#bf185d',
  pink800: '#9b174c',
  pink900: '#811842',
  purple100: '#f2e5ff',
  purple200: '#ead6ff',
  purple300: '#d8b4fe',
  purple400: '#bf83fc',
  purple50: '#faf5ff',
  purple500: '#a855f7',
  purple600: '#9234ea',
  purple700: '#7e22ce',
  purple800: '#6a21a6',
  purple900: '#591c87',
  red100: '#ffdcdc',
  red200: '#ffc9c9',
  red300: '#ffa0a0',
  red400: '#ff6565',
  red50: '#fef1f1',
  red500: '#ef4343',
  red600: '#dc2828',
  red700: '#ba1c1c',
  red800: '#981b1b',
  red900: '#811d1d',
  rose100: '#ffe5e7',
  rose200: '#fecdd3',
  rose300: '#fda5af',
  rose400: '#fb6f84',
  rose50: '#fff0f1',
  rose500: '#f43e5c',
  rose600: '#e21d48',
  rose700: '#bf123d',
  rose800: '#a1123a',
  rose900: '#861336',
  skyBlue100: '#e1f3fe',
  skyBlue200: '#bae5fd',
  skyBlue300: '#7ed4fc',
  skyBlue400: '#3abff8',
  skyBlue50: '#f0f9ff',
  skyBlue500: '#1badf0',
  skyBlue600: '#0284c5',
  skyBlue700: '#0369a0',
  skyBlue800: '#075783',
  skyBlue900: '#0c4a6e',
  teal100: '#cbfbf0',
  teal200: '#a0fbe8',
  teal300: '#5dead5',
  teal400: '#2bd4bd',
  teal50: '#e7fff8',
  teal500: '#14b8a5',
  teal600: '#0d968b',
  teal700: '#0f756d',
  teal800: '#115f5a',
  teal900: '#134e4a',
  violet100: '#ebe7fe',
  violet200: '#ded7fe',
  violet300: '#c3b4fd',
  violet400: '#a689fa',
  violet50: '#f7f6ff',
  violet500: '#895af6',
  violet600: '#7f3fed',
  violet700: '#6b26d9',
  violet800: '#5a21b5',
  violet900: '#4d1d95',
  yellow100: '#fef9c3',
  yellow200: '#fef08b',
  yellow300: '#ffe771',
  yellow400: '#facc14',
  yellow50: '#fefce7',
  yellow500: '#e7b008',
  yellow600: '#c88a04',
  yellow700: '#a26107',
  yellow800: '#864e0e',
  yellow900: '#733f12'
}

export const textColor = {
  textPrimary: baseColors.alpha900,
  textSecondary: baseColors.alpha700,
  textTertiary: baseColors.alpha400
}

export const blurs = {
  blur0: 0,
  blur1: '2px',
  blur2: '3px',
  blur3: '4px',
  blur4: '8px',
  blur5: '10px',
  blur6: '16px',
  blur7: '24px',
  blur8: '40px',
  blur9: '64px',
  blur10: '80px',
  blurMax: '100px'
}
export const fillbaseColors = {
  fill00: baseColors.alpha00,
  fill100: baseColors.gray100,
  fill200: baseColors.gray200,
  fill300: baseColors.gray300,
  fill400: baseColors.gray400,
  fill50: baseColors.gray50,
  fill600: baseColors.gray600
}
export const statebaseColors = {
  baseBackground: baseColors.blueGray50,
  baseBorder: baseColors.gray200,
  baseDisabled: baseColors.alpha200,
  destructiveActive: baseColors.red600,
  destructiveHover: baseColors.red400,
  destructiveSelected: baseColors.red300,
  highlightActive: baseColors.orange400,
  highlightHover: baseColors.orange300,
  highlightSelected: baseColors.orange200,
  primaryActive: baseColors.teal600,
  primaryHover: baseColors.turquoise500,
  primarySelected: baseColors.turquoise300,
  successActive: baseColors.green600,
  successHover: baseColors.green400,
  successSelected: baseColors.green300
}

export const radius = {
  radi0: 0,
  radi1: '2px',
  radi2: '4px',
  radi3: '6px',
  radi4: '8px',
  radi5: '10px',
  radi6: '12px',
  radi7: '16px',
  radi8: '22px',
  radi9: '28px',
  radi10: '1000px'
}

export const colors = {
  ...baseColors,
  ...fillbaseColors,
  ...statebaseColors,
  ...textColor
}
