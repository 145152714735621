import type { VariantPropsOf } from '@/utils/variants'
import type { PropType } from 'vue'

import { computed, useSlots } from 'vue'

import { T } from '@ui/T'

import BaseIcon from '@/components/BaseIcon.vue'
import { variants } from '@/utils/variants'

import s from './style.module.scss'

const styles = variants({
  base: s.root,
  variants: {
    disabled: {
      true: s.disabled
    },
    selected: {
      true: s.selected
    },
    active: {
      true: s.active
    }
  }
})

type Props = VariantPropsOf<typeof styles> & {
  enabled?: boolean
  leftIcon?: IconName
  rightIcon?: IconName
}

export const KcalmarSelectItem = (props: Props) => {
  const slots = useSlots()
  const classes = computed(() => {
    return styles({
      disabled: !(props.enabled ?? true),
      selected: props.selected,
      active: props.active
    })
  })

  return (
    <li class={classes.value}>
      {props.leftIcon && <BaseIcon size="24" name={props.leftIcon} />}
      <T as="span">{slots.default?.()}</T>
      {props.rightIcon && <BaseIcon size="24" name={props.rightIcon} />}
    </li>
  )
}

KcalmarSelectItem.props = {
  leftIcon: String as PropType<Props['leftIcon']>,
  rightIcon: String as PropType<Props['rightIcon']>,
  disabled: Boolean,
  active: Boolean,
  selected: Boolean,
  enabled: Boolean
}
