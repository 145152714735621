import type { Nutrients } from '@/utils/nutrients'

import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import { useGlobalStore } from '@/store/globalStore'

export const useChosenNutrients = () => {
  const store = useGlobalStore()
  const { hasPerm, showAppUpgradePlanModal, setChosenNutrients } = store
  const { chosenNutrients } = storeToRefs(store)

  const chosenNutrientsLength = computed(() => {
    return chosenNutrients.value.length + 1
  })
  const chosenNutrientsModalVisible = ref(false)

  const showChosenNutrientsModal = () => {
    if (hasPerm('accounts.diet_microelements')) {
      chosenNutrientsModalVisible.value = true
    } else {
      showAppUpgradePlanModal()
    }
  }
  const hideChosenNutrientsModal = () => {
    chosenNutrientsModalVisible.value = false
  }
  const loadCustomNutrients = (nutrientsList: Nutrients[]) => {
    setChosenNutrients(nutrientsList)
    hideChosenNutrientsModal()
  }

  return {
    chosenNutrients,
    chosenNutrientsLength,
    chosenNutrientsModalVisible,
    showChosenNutrientsModal,
    hideChosenNutrientsModal,
    loadCustomNutrients
  }
}
