import type {
  DietListIngredient,
  IngredientCategory,
  IngredientCategoryIdMap,
  Measurements,
  MeasurementsIdMap
} from '@/types/Diet'
import type { Nutrients, SpecificNutrientsValues } from '@/utils/nutrients'

import { api } from '@/services/apiService'

export type SubGroup = {
  id: number
  name: string
  parent: number
}
export type Group = {
  children: SubGroup[]
  id: number
  name: string
  parent: null
}

export type ListIngredient = {
  base: number
  calories: number
  carbohydrates: number
  category: keyof IngredientCategoryIdMap
  fat: number
  fiber: number
  fiber_energy: number
  grams: number
  group: number[]
  id: number
  is_diary_free: boolean
  is_gluten_free: boolean
  is_public: boolean
  is_super_food: boolean
  is_vegan: boolean
  is_vegetarian: boolean
  manufacturer: number
  measurement: keyof MeasurementsIdMap
  name: string
  price: number
  protein: number
  user: number
} & SpecificNutrientsValues

export const ingredientsService = {
  fetchIngredientOptions() {
    const url = 'ingredients/fetch_ingredient_options/'
    return api.get<{
      ingredient_categories: IngredientCategory[]
      measurement_names: Measurements[]
    }>(url)
  },
  fetchIngredient(ingredientId: number | string) {
    const url = `/ingredients/${ingredientId}/`
    return api.get<ListIngredient>(url)
  },
  fetchIngredients(chosenNutrientsList: Nutrients[]) {
    let queryString = ''
    for (const param of chosenNutrientsList) {
      queryString += `search[]=${param}&`
    }
    const url = `ingredients/?${queryString}/`
    return api.get<DietListIngredient[]>(url)
  },
  fetchIngredientGroups() {
    const url = 'ingredients/fetch_ingredient_groups/'
    return api.get<Group[]>(url)
  },
  izzConditionsConfirm() {
    const url = 'dietitian/izz-conditions-confirm/'
    return api.post(url)
  },
  copyIngredient(ingredientId: number) {
    const url = `/ingredients/${ingredientId}/copy/`
    return api.post<{ id: string }>(url)
  },
  toggleFavoriteIngredient(ingredientId: number) {
    const url = `ingredients/${ingredientId}/toggle_favorite/`
    return api.post(url)
  },
  deleteIngredient(ingredientId: number) {
    const url = `ingredients/${ingredientId}/`
    return api.delete(url)
  },
  updateIngredient(ingredientId: number, data: ListIngredient) {
    const url = `ingredients/${ingredientId}/`
    return api.put(url, data)
  },
  createIngredient(data: ListIngredient) {
    const url = 'ingredients/'
    return api.post(url, data)
  }
}
