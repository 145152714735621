import type { VNode } from 'vue'

import { Comment, Fragment, Text } from 'vue'

export function filterEmpty(children: VNode[] = []) {
  const nodes: VNode[] = []
  children.forEach((child) => {
    if (Array.isArray(child)) {
      nodes.push(...child)
    } else if (child.type === Fragment) {
      nodes.push(...filterEmpty(child.children as VNode[]))
    } else {
      nodes.push(child)
    }
  })
  return nodes.filter((node) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!node) {
      return
    }
    return !(
      node.type === Comment ||
      (node.type === Fragment && node.children?.length === 0) ||
      (node.type === Text && (node.children as string).trim() === '')
    )
  })
}
