/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import AppLayout from '@/layouts/AppLayout.vue'
import DishListWrapper from '@/pages/dishes/DishListWrapper.vue'

// import dishesListEdit from '@/pages/dishes/dishesListEdit.vue'

export const dishesRouter = {
  path: '/dishes',
  name: 'dishes',
  component: AppLayout,
  props: true,
  meta: {
    auth: true
  },
  children: [
    {
      path: 'list/',
      name: 'DishesList',
      component: DishListWrapper,
      props: true,
      meta: {
        title: 'Potrawy'
      }
    }
    // {
    //   path: 'edit/:dishId(\\d+)',
    //   name: 'DishesListEdit',
    //   component: dishesListEdit,
    //   meta: {
    //     title: 'Edycja potrawy'
    //   }
    // },
    // {
    //   path: 'add/',
    //   name: 'dishesListAdd',
    //   component: dishesListEdit,
    //   meta: {
    //     title: 'Dodawanie potrawy'
    //   }
    // }
  ]
}
