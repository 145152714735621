import type { Nutrients } from '@/utils/nutrients'
import type {
  Announcement,
  AppMessage,
  DietType,
  Message,
  State,
  User,
  UserFlag,
  UserIcons
} from './types'

import '@mobily/ts-belt'

import { A, D, F, G, O, pipe } from '@mobily/ts-belt'
import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'

import { universityService } from '@/services/universityService'
import { allNutriens } from '@/utils/nutrients'
import { reportError } from '@/utils/reportError'
import { stringify } from '@/utils/stringify'
import { userService } from '@/services/userService'

export const useGlobalStore = defineStore('global', {
  state: (): State => ({
    user: undefined,
    announcements: [],
    enabledIconsNames: [],
    showedIconsNames: [],
    errorElement: false,
    errorGlobal: false,
    errorView: false,
    isLeftMenuCollapsed: false,
    messages: [],
    nameOfVisibleModal: '',
    searchedPhrase: '',
    leaderStudents: [],
    // New fields
    appUpgradePlanModal: false,
    appMessages: [],
    appMessagesCounter: 0,
    shareDietsButtonEnabled: true,
    plan_name: 'basic',
    chosenNutrients: ['protein', 'fat', 'carbohydrates', 'fiber', 'carbohydrate_exchanger']
  }),
  actions: {
    pushAnnouncement(announcement: Announcement) {
      const announcements = this.announcements
      const inevitableLossesList = announcements.filter((a) => a.iconName === 'delete_sweep')
      if (announcement.iconName === 'delete_sweep') {
        if (inevitableLossesList.length > 0) {
          return
        } else {
          announcements.push(announcement)
        }
      } else {
        announcements.push(announcement)
      }
      this.announcements = announcements
    },
    deleteAnnouncementByIndex(announcementIndex: number) {
      const announcements = this.announcements
      announcements.splice(announcementIndex, 1)
      this.announcements = announcements
    },
    deleteAnnouncementsByIconName(iconName: IconName) {
      this.announcements = this.announcements.filter(
        (announcement) => announcement.iconName !== iconName
      )
    },
    deleteAnnouncements() {
      this.announcements = this.announcements.splice(0, this.announcements.length)
    },
    toggleLeftMenuCollapsed() {
      this.isLeftMenuCollapsed = !this.isLeftMenuCollapsed
      localStorage.setItem('isLeftMenuCollapsed', this.isLeftMenuCollapsed.toString())
    },
    enabledIconsNamesAdd(iconName: UserIcons) {
      if (!this.enabledIconsNames.includes(iconName)) {
        this.enabledIconsNames.push(iconName)
      }
    },
    enabledIconsNamesDelete(iconName: UserIcons) {
      this.enabledIconsNames = this.enabledIconsNames.filter((name) => name !== iconName)
    },
    showedIconsNamesAdd(iconName: UserIcons) {
      if (!this.showedIconsNames.includes(iconName)) {
        this.showedIconsNames.push(iconName)
      }
    },
    showedIconsNamesDelete(iconName: UserIcons) {
      this.showedIconsNames = this.showedIconsNames.filter((name) => name !== iconName)
    },
    pushMessages(message: Message) {
      this.messages.push(message)
    },
    shiftMessages() {
      this.messages = this.messages.filter((message) => message.type !== 'toast')
    },
    closeMessage(messageIndex: number) {
      this.messages = this.messages.splice(messageIndex, 1)
    },
    hideModal() {
      this.nameOfVisibleModal = ''
    },
    showModal(value: string) {
      this.nameOfVisibleModal = value
    },
    updateUserData(data: Partial<User>) {
      this.user = D.merge(this.user, data)
    },
    setSearchedPhrase(value: string) {
      this.searchedPhrase = value
    },

    // REFACTOR New Actions
    incrementDishesPrivateCount() {
      if (this.user) {
        this.user.dishes_private_count++
      }
    },
    changeDietCounter({ dietType, value }: { dietType: DietType; value: number }) {
      if (this.user && dietType !== 'patient') {
        this.user.counters.diets[dietType] += value
      }
    },
    changeIngredientCounter({ value }: { value: number }) {
      if (this.user) {
        this.user.counters.ingredients += value
      }
    },
    changeDishCounter({ value }: { value: number }) {
      if (this.user) {
        this.user.counters.dishes += value
      }
    },
    /**
     * Displays info and error messages
     * @param  {String} options.type    Type of message, should be 'info' or 'error'
     * @param  {String} options.title   Message title
     * @param  {String} options.content Message content
     * @param  {Number} options.timeout Timeout after what message should be deleted, pass null for never
     * @return {Number}                 Returning message id for update/delete
     */
    createMessage({
      type = 'info',
      title,
      content = '',
      timeout = 5000
    }: Partial<Omit<AppMessage, 'id'>> & { title: string }) {
      this.appMessagesCounter += 1
      const id = this.appMessagesCounter

      this.appMessages.push({
        id,
        type,
        title,
        content,
        timeout
      })
      if (timeout) {
        setTimeout(() => {
          this.deleteMessage(id)
        }, timeout)
      }
      return id
    },
    updateMessage({
      id,
      title = '',
      content = ''
    }: {
      content?: string
      id: number
      title?: string
    }) {
      A.forEach(this.appMessages, (message) => {
        if (message.id === id) {
          if (title) {
            message.title = title
          }
          if (content) {
            message.content = content
          }
        }
      })
    },
    deleteMessage(id: number) {
      this.appMessages = this.appMessages.filter((message) => message.id !== id)
    },
    deleteMessages() {
      this.appMessages = []
    },
    setUser({ user }: { user: User }) {
      this.user = user

      const scope = Sentry.getCurrentScope()
      scope.setExtra('plan_name', user.current_plan_name)
      scope.setUser({
        id: user.id,
        email: user.email
      })
      if (G.isNotNullable(dataLayer) && G.isArray(dataLayer)) {
        const data = {
          event: 'account_type_update',
          account_type: user.account_type
        }
        dataLayer.push(data)
      }
    },
    showAppUpgradePlanModal() {
      this.appUpgradePlanModal = true
    },
    hideAppUpgradePlanModal() {
      this.appUpgradePlanModal = false
    },
    incrementPrintedDiets() {
      if (this.user) {
        this.user.printed_diets++
      }
    },
    toggleFavoriteIngredient(ingredientId: number) {
      if (this.user) {
        const favoriteIngredients = this.user.favourite_ingredients
        const ingredientIndex = favoriteIngredients.indexOf(ingredientId)
        if (ingredientIndex === -1) {
          favoriteIngredients.push(ingredientId)
          this.createMessage({ title: 'Dodano produkt do ulubionych' })
        } else {
          favoriteIngredients.splice(ingredientIndex, 1)
          this.createMessage({ title: 'Usunięto produkt z ulubionych' })
        }
      }
    },
    setChosenNutrients(nutrients: Nutrients[]) {
      this.chosenNutrients = nutrients

      localStorage.setItem('chosenNutrients', stringify(nutrients))
    },
    checkSavedChosenNutrientsList() {
      let savedChosenNutrientsList: Nutrients[] = this.chosenNutrients
      const storageNutrients = localStorage.getItem('chosenNutrients')
      if (storageNutrients) {
        try {
          if (storageNutrients.length > 0) {
            const parsedNutrients = JSON.parse(storageNutrients) as Nutrients[]
            savedChosenNutrientsList = pipe(
              O.fromPredicate(parsedNutrients, G.isArray),
              O.getWithDefault([] as Nutrients[])
            )
          }
        } catch {
          // vue2 to vue3 migration
          savedChosenNutrientsList = storageNutrients
            .split(',')
            .filter((item) => allNutriens.includes(item as Nutrients)) as Nutrients[]
        }
        this.setChosenNutrients(savedChosenNutrientsList)
      }
    },
    async updateLeaderStudents() {
      try {
        const response = await universityService.fetchLeaderStudents()
        this.leaderStudents = response.data
      } catch (err) {
        reportError(err, 'Error while fetching leader students')
      }
    },
    async updateUserFlag(flag: UserFlag, value = true) {
      if (this.user?.user_flags) {
        try {
          await userService.updateFlag(flag, value)
          this.user.user_flags = value
            ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              this.user.user_flags.filter((f) => f !== flag)
            : F.toMutable(A.uniq([...this.user.user_flags, flag]))
        } catch (err) {
          reportError(err, `Error while updating user flag ${flag}`)
        }
      }
    }
    // END OF REFACTOR New Actions
  },
  getters: {
    leaderOptions(state) {
      return state.leaderStudents
        .filter((obj) => {
          return state.user?.email === obj.student_email
        })
        .map((obj) => {
          return {
            text: `${obj.leader_email} (${obj.leader_first_name} ${obj.leader_last_name})`,
            value: obj.leader_email
          }
        })
    },
    getPossessiveAdjective: () => (number: number, adjective: string) => {
      if (adjective === 'go') {
        if (number > 1) {
          return 'je'
        } else {
          return adjective
        }
      }
      return ''
    },
    messagesForDisplay: (state) => {
      // clone messages for prevent changing state
      return [...state.messages].reverse()
    },
    isIconEnabled: (state) => (iconName: UserIcons) => state.enabledIconsNames.includes(iconName),
    isIconShowed: (state) => (iconName: UserIcons) => state.showedIconsNames.includes(iconName),

    isDietListSystemDietEnabled(state) {
      const userPlanName = state.plan_name
      return ['basic', 'master', 'premium', 'vip'].includes(userPlanName)
    },
    hasPerm: (state) => (permission: string) => {
      const permissions = state.user?.permissions ?? []
      return permissions.includes(permission)
    },
    hasAddDishPermission(state) {
      const dishesPrivateCount = state.user?.dishes_private_count
      if (!state.user?.is_superuser && dishesPrivateCount) {
        if (this.hasPerm('accounts.max_dish_bo')) {
          return true
        } else if (this.hasPerm('accounts.max_dish_2000')) {
          if (dishesPrivateCount > 1999) {
            return false
          }
        } else if (this.hasPerm('accounts.max_dish_1500')) {
          if (dishesPrivateCount > 1499) {
            return false
          }
        } else if (this.hasPerm('accounts.max_dish_1000')) {
          if (dishesPrivateCount > 999) {
            return false
          }
        } else if (this.hasPerm('accounts.max_dish_10')) {
          if (dishesPrivateCount > 9) {
            return false
          }
        }
      }
      return true
    }
  }
})
