<template>
  <div class="search">
    <div class="row-f">
      <BaseInputSearch
        test-id="IngredientsListSearch"
        :placeholder="'Wyszukaj produkt po nazwie...'"
        :debounce-after="750"
        :model-value="searchedPhrase"
        @update:model-value="filterDiets($event)"
      />
      <div
        class="row-f search-buttons"
        :class="{ extended: extendedFilters }"
      >
        <StyledButton
          v-if="areFiltersActive(ingredientsType)"
          variant="secondary"
          @click="resetExtendedFilters()"
        >
          Wyczyść filtry
        </StyledButton>
        <StyledButton
          variant="secondary"
          @click="toggleExtendedFilters()"
        >
          {{
            extendedFilters ? 'Ukryj zaawansowane' : 'Zaawansowane'
          }}
        </StyledButton>
      </div>
    </div>
    <IngredientsExtendedFilters v-show="extendedFilters" />
  </div>
</template>

<script lang="ts" setup>
import BaseInputSearch from '@/components/BaseInputSearch.vue'
import IngredientsExtendedFilters from '@/components/ingredients/IngredientsExtendedFilters.vue'
import { useIngredientsProvider } from '@/types/IngredientList'

import { StyledButton } from '../StyledButton'

const {
  extendedFilters,
  ingredientsType,
  toggleExtendedFilters,
  resetExtendedFilters,
  areFiltersActive,
  filterDiets,
  searchedPhrase
} = useIngredientsProvider()
</script>

<style scoped>
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search {
  padding: 28px 28px 28px 42px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 150;
}

.search .row-f {
  display: flex;
  justify-content: space-between;
}

.search .search-box {
  width: 80%;
  margin-right: 16px;
}

.search-buttons {
  display: flex;
  column-gap: 16px;
}

.search-buttons.extended {
  position: absolute;
  bottom: 16px;
  right: 16px;
  column-gap: 43px;
}
</style>
