<template>
  <div class="base-autocomplete">
    <Combobox
      immediate
      :model-value="props.modelValue"
      :virtual="{
        options: filteredOptions.length > 0 ? filteredOptions : [emptyOption],
        disabled: (option) => {
          const o = option as Option<T>
          return Boolean(o.disabled || o.empty)
        }
      }"
      @update:model-value="selectOption"
    >
      <div class="base-autocomplete__input-wrapper">
        <ComboboxInput
          :display-value="
            (option) => {
              return (option as Option<T>).text
            }
          "
          class="base-autocomplete__input"
          :placeholder="placeholder"
          @change="query = $event.target.value"
        />

        <ComboboxButton
          v-slot="{ open }"
          as="template"
          class="base-autocomplete__icon"
        >
          <BaseIcon :name="open ? 'expand_more' : 'expand_less'" />
        </ComboboxButton>
      </div>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions
          v-slot="{ option }"
          class="base-autocomplete__options"
          as="div"
        >
          <div
            v-if="option.empty"
            class="base-autocomplete__empty"
          >
            Brak wyników
          </div>
          <ComboboxOption
            v-else
            :key="option.value"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
            :disabled="option.disabled"
          >
            <div
              class="base-autocomplete__option"
              :class="{ 'base-autocomplete__option--focus': active || selected }"
            >
              <span class="base-autocomplete__option-text"> {{ option.text }}</span>
            </div>
          </ComboboxOption>
        </ComboboxOptions>
      </transition>
    </Combobox>
  </div>
</template>

<script lang="ts" setup generic="T extends string | number">
import type { PropType } from 'vue'

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import { computed, ref } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'

type Option<T extends string | number> = {
  disabled?: boolean
  empty?: boolean
  text: string
  value: T
}

const props = defineProps({
  modelValue: {
    type: Object as PropType<Option<T> | undefined>
  },
  options: {
    type: Array as PropType<Option<T>[]>,
    required: true
  },
  placeholder: {
    type: String,
    required: false
  }
})

const emptyOption = { name: 'Brak wyników', disabled: true, empty: true }
const emit = defineEmits(['update:modelValue'])

const query = ref('')
const options = computed(() => props.options.slice().sort((a, b) => a.text.localeCompare(b.text)))
const filteredOptions = computed(() =>
  query.value === ''
    ? options.value
    : options.value.filter((v) =>
        v.text
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value.toLowerCase().replace(/\s+/g, ''))
      )
)
const selectOption = (option: Option<T>) => {
  emit('update:modelValue', option)
}
</script>

<style scoped>
.base-autocomplete {
  position: relative;
}

.base-autocomplete__input-wrapper {
  position: relative;
  width: 100%;
}

.base-autocomplete__input {
  width: 100%;
  line-height: 15px;
  font-size: 12px;
  padding: 5px 20px 5px 8px;
  border: 0;
  outline: 0;
  border-bottom: 1px solid var(--color-tertiary-text);
}

.base-autocomplete__input-wrapper:hover .base-autocomplete__input {
  border-bottom: 1px solid var(--color-primary-text);
}

.base-autocomplete__input-wrapper .base-autocomplete__input:focus {
  border-bottom: 1px solid var(--color-main-100);
}

.base-autocomplete__icon {
  position: absolute;
  top: 3px;
  right: 0;
  cursor: pointer;
  color: var(--color-tertiary-text);
}

.base-autocomplete__input-wrapper:hover .base-autocomplete__icon {
  color: var(--color-primary-text);
}

.base-autocomplete__input-wrapper.active .base-autocomplete__icon {
  color: var(--color-main-100);
}

.base-autocomplete__options {
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  overflow-y: auto;
  box-shadow: 0 1px 6px 0 rgb(155 155 155 / 50%);
  max-height: 250px;
  scrollbar-width: thin;
  min-width: 100%;
  max-width: 100%;
}

.base-autocomplete__options::-webkit-scrollbar {
  width: 6px;
}

.base-autocomplete__option {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  height: 26px;
  width: 100%;
  overflow: hidden;
  align-items: center;
  font-size: 12px;
  line-height: 26px;
  z-index: 101;
  cursor: pointer;
  padding: 0 10px;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.base-autocomplete__option-text {
  max-width: 100%;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.base-autocomplete__option:hover,
.base-autocomplete__option--focus {
  outline: none;
  background: var(--color-main-10);
}

.base-autocomplete__empty {
  font-size: 12px;
  height: 26px;
  line-height: 26px;
  padding: 0 10px;
}
</style>
