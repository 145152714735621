<template>
  <div
    class="form-field"
    :class="{ 'form-field--error': !!errorMessage, success: meta.valid }"
  >
    <label
      class="form-field__label"
      :for="name"
    >{{ props.label }}</label>
    <BaseInputDate
      v-model="inputValue"
      :test-id="testId"
      :max-date="maxDate"
      :min-date="minDate"
    />
    <p
      v-show="errorMessage"
      class="form-field__error"
    >
      {{ errorMessage }}
    </p>
    <BaseIcon
      v-if="errorMessage"
      class="form-field__icon"
      type="warning"
      size="18"
      name="error"
    />
  </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseInputDate from '@/components/BaseInputDate.vue'
import { watch } from 'vue'

const props = defineProps({
  value: {
    type: String
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: ''
  },
  forceFocus: {
    type: Boolean,
    default: false
  },
  maxDate: {
    type: Date,
    required: false
  },
  minDate: {
    type: Date,
    required: false
  },
  testId: {
    type: String,
    required: true
  }
})

const {
  value: inputValue,
  errorMessage,
  meta
} = useField<string>(() => props.name, undefined, {
  label: props.label
})

watch(inputValue, console.log)
</script>
<style>
.form-field--error {
  .dp__input {
    border-color: #e00000;
    color: #e00000;
  }

  .dp__input::placeholder {
    border-color: #e00000;
    color: #e00000;
  }

  .dp__input:focus {
    background: #fff;
  }

}

.form-field {
  /* stylelint-disable-next-line no-descending-specificity */
  .dp__input {
    width: 100%;
    height: 32px;
    padding: 0 34px 0 12px;
    color: var(--color-primary-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    box-shadow: 1px 2px 2px 0 #0000001f inset;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .dp__input:focus {
    background: var(--color-main-10);
    border-color: var(--color-main-100);
  }

  .dp__input:hover {
    border-color: var(--color-main-100);
  }
}
</style>
