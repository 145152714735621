<template>
  <transition-group
    :class="'app-messages'"
    name="app-messages"
    tag="div"
  >
    <div
      v-for="message in globalStore.appMessages"
      :key="message.id"
      class="app-message"
      :class="message.type"
    >
      <div
        class="app-message__title"
        :class="{
          'with-padding': !message.timeout
        }"
      >
        {{ message.title }}
      </div>
      <div
        v-if="message.content"
        class="app-message__content"
        v-html="message.content"
      />
      <div
        v-if="!message.timeout"
        class="app-message__delete"
      >
        <IconButton
          name="close"
          variant="secondary"
          @click="closeMessage(message.id)"
        />
      </div>
    </div>
  </transition-group>
</template>

<script lang="ts" setup>
import { useGlobalStore } from '@/store/globalStore'

import { IconButton } from './IconButton'

const globalStore = useGlobalStore()

const closeMessage = (id: number) => {
  globalStore.deleteMessage(id)
}
</script>

<style>
.app-messages {
  position: fixed;
  bottom: 20px;
  right: 30px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 500;
}

.app-message {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  transition: all 0.5s;
  background: var(--color-main-100);
  margin-top: 8px;
  max-width: 520px;
}

.app-message.error {
  background: var(--color-destructive-100);
}

.app-message__title {
  line-height: 28px;
  font-family: Montserrat-SemiBold;
  font-size: 14px;
}

.app-message__title.with-padding {
  padding-right: 38px;
}

.app-message__content {
  margin-top: 5px;
  font-size: 12px;
  line-height: 18px;
  max-height: 193px;
  overflow: auto;
  padding-right: 10px;
  scrollbar-width: thin;
}

.app-message__delete {
  position: absolute;
  right: 10px;
  top: 10px;
}

.base-button.base-button--icon.app-message__delete-button .base-icon {
  color: #fff;
}

.base-button.base-button--icon.app-message__delete-button:hover .base-icon {
  color: var(--color-main-110);
}

.app-message.error .base-button.base-button--icon.app-message__delete-button:hover {
  background: #fff;
}

.app-message.error .base-button.base-button--icon.app-message__delete-button:hover .base-icon {
  color: var(--color-destructive-100);
}

/*
   * transition-group component styles see
   * https://v2.vue'js.org/v2/guide/transitions.html#List-Transitions
   * for more info
   */
.app-messages-enter,
.app-messages-leave-to {
  opacity: 0;
}
</style>
