<template>
  <div
    ref="searchRef"
    :class="$style.search"
  >
    <Disclosure v-slot="{ open }">
      <Space
        align-y="center"
        direction="columns"
        :padding-x="8"
        :padding-y="8"
      >
        <KcalmarInput
          v-model="searchedPhrase"
          placeholder="Wyszukaj pacjenta"
          size="small"
          variant="underline"
        >
          <template #rightContent>
            <BaseIcon name="search" />
          </template>
        </KcalmarInput>
        <Space
          align-x="end"
          direction="columns"
          class="w-20%"
          :style="{ transform: `translateY(${open ? filtersHeight : 0}px)` }"
        >
          <DisclosureButton as="template">
            <KcalmarButton
              variant="ghost"
              size="small"
            >
              {{ open ? 'Ukryj zaawansowane' : 'Zaawansowane' }}
            </KcalmarButton>
          </DisclosureButton>
        </Space>
      </Space>
      <div v-show="open">
        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform opacity-0"
          enter-to-class="transform  opacity-100"
          leave-active-class="transition duration-75 ease-out"
          leave-from-class="transform opacity-100"
          leave-to-class="transform  opacity-0"
        >
          <DisclosurePanel static>
            <T as="span">
              TODO FILTERS
            </T>
          </DisclosurePanel>
        </transition>
      </div>
    </Disclosure>
  </div>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { useElementSize } from '@vueuse/core'
import { ref, useCssModule } from 'vue'

import { KcalmarButton } from '@ui/KcalmarButton'
import { KcalmarInput } from '@ui/KcalmarInput'

import BaseIcon from '@/components/BaseIcon.vue'
import { Space } from '@/components/Space'
import { T } from '@/components/ui/T'

import { usePatientsProvider } from './provider'

const $style = useCssModule()
const filtersRef = ref<HTMLElement>()

const { height: filtersHeight } = useElementSize(filtersRef)
const { searchRef, searchedPhrase } = usePatientsProvider()
</script>

<style lang="scss" scoped module>
.search {
  background-color: #fff;
  position: sticky;
  z-index: 2;
  top: 0;
  overflow: hidden;
}
</style>
