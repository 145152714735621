<template>
  <textarea
    ref="textarea"
    class="base-textarea"
    :class="{ reset: !props.autosize }"
    :value="modelValue"
    rows="1"
    @input="handleInput"
  />
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

import { useResizeObserver } from '@vueuse/core'
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import eventBus from '@/eventBus'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  },
  autosize: {
    type: Boolean,
    default: false
  },
  getRef: {
    type: Function as PropType<(ref: HTMLTextAreaElement) => void>
  }
})

const emit = defineEmits(['update:modelValue'])
const textarea = ref<HTMLTextAreaElement | null>(null)
const textareaScrollHeight = ref(1)

const resize = () => {
  if (textarea.value && props.autosize) {
    textarea.value.style.height = '1px'
    textareaScrollHeight.value = textarea.value.scrollHeight

    textarea.value.style.height = `${textareaScrollHeight.value}px` // 10px from .base-textarea CSS
  }
}
const handleInput = (event: Event) => {
  const value = (event.target as HTMLTextAreaElement).value
  emit('update:modelValue', value)
}

useResizeObserver(textarea, resize)

onBeforeMount(() => {
  eventBus.on('resizeBaseTextArea', () => {
    if (textarea.value) {
      textarea.value.style.height = 'auto'
    }
  })
})

onBeforeUnmount(() => {
  eventBus.off('resizeBaseTextArea')
})

onMounted(() => {
  resize()
  if (props.getRef && textarea.value) {
    props.getRef(textarea.value)
  }
})

watch(
  [() => props.modelValue, textarea],
  () => {
    void nextTick(() => {
      resize()
    })
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped>
.reset {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
