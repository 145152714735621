import type { VariantPropsOf } from '@/utils/variants'
import type { PropType } from 'vue'

import { computed, useSlots } from 'vue'

import { DynamicButton } from '@ui/DynamicButton'

import BaseIcon from '@/components/BaseIcon.vue'
import { variants } from '@/utils/variants'

import s from './style.module.scss'

const styles = variants({
  base: s.root,
  variants: {
    size: {
      small: s['size--small'],
      default: s['size--default'],
      large: s['size--large']
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

type Props = VariantPropsOf<typeof styles> & {
  leftIcon?: IconName
  rightIcon?: IconName
}

const IconSize: Record<NonNullable<VariantPropsOf<typeof styles>['size']>, IconSize> = {
  small: '20',
  default: '24',
  large: '28'
}

export const KcalmarChip = (props: Props) => {
  const slots = useSlots()
  const classes = computed(() => {
    return styles({
      size: props.size
    })
  })

  const iconSize = IconSize[props.size ?? 'default']

  return (
    <DynamicButton class={classes.value}>
      {props.leftIcon && <BaseIcon size={iconSize} name={props.leftIcon} />}
      {slots.default?.()}
      {props.rightIcon && <BaseIcon size={iconSize} name={props.rightIcon} />}
    </DynamicButton>
  )
}

KcalmarChip.props = {
  size: String as PropType<Props['size']>,
  leftIcon: String as PropType<Props['leftIcon']>,
  rightIcon: String as PropType<Props['rightIcon']>,
  disabled: Boolean
}
