import type { VariantPropsOf } from '@/utils/variants'
import type { PropType, SetupContext, SlotsType, VNode } from 'vue'

import round from 'lodash/round'
import { computed, defineComponent, ref } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import { clamp } from '@/utils/common'
import { variants } from '@/utils/variants'

import s from './style.module.scss'

const labelStyles = variants({
  base: s.label,
  variants: {
    size: {
      small: s['label-size--small'],
      default: s['label-size--default'],
      large: s['label-size--large']
    },
    variant: {
      default: s['label-type__default'],
      underline: s['label-type__underline']
    },
    disabled: {
      true: s['label--disabled']
    },
    error: {
      true: s['label--error']
    }
  },
  compoundVariants: [
    {
      variants: {
        variant: 'default',
        error: true
      },
      className: s['label-type__default--error']
    },
    {
      variants: {
        variant: 'underline',
        error: true
      },
      className: s['label-type__underline--error']
    }
  ],
  defaultVariants: {
    size: 'default',
    variant: 'default'
  }
})

const inputStyles = variants({
  base: s.input,
  variants: {
    size: {
      small: s['input-size--small'],
      default: s['input-size--default'],
      large: s['input-size--large']
    },
    disabled: {
      true: s['input--disabled']
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

const boxStyles = variants({
  base: s.box,
  variants: {
    size: {
      small: s['box-size--small'],
      default: s['box-size--default'],
      large: s['box-size--large']
    },
    disabled: {
      true: s['box--disabled']
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

const labelTextStyles = variants({
  base: s['label-text'],
  variants: {
    size: {
      small: s['label-text__size--small'],
      default: s['label-text__size--default'],
      large: s['label-text__size--large']
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

const captionStyles = variants({
  base: s.caption,
  variants: {
    error: {
      true: s['caption--error']
    }
  }
})

type Props = Omit<VariantPropsOf<typeof labelStyles>, 'error'> & {
  caption?: string
  error?: string
  iconLeft?: IconName
  iconRight?: IconName
  label?: string
  leftLabel?: string
  max?: number
  min?: number
  modelValue?: string | number
  name?: string
  placeholder?: string
  precision?: number
  rightLabel?: string
}
type Emits = {
  onChange: (event: Event) => void
  'update:modelValue': (value: string | number) => void
}

type Slots = SlotsType<{
  leftContent?: () => VNode
  rightContent?: () => VNode
}>

const IconSize: Record<NonNullable<VariantPropsOf<typeof labelStyles>['size']>, IconSize> = {
  small: '20',
  default: '24',
  large: '28'
}

const Component = (props: Props, ctx: SetupContext<Emits, Slots>) => {
  const slots = ctx.slots
  const emit = ctx.emit
  const inputRef = ref<HTMLInputElement | null>()

  const labelClasses = computed(() => {
    return labelStyles({
      disabled: props.disabled,
      size: props.size,
      error: !!props.error,
      variant: props.variant
    })
  })
  const inputClasses = computed(() => {
    return inputStyles({
      disabled: props.disabled,
      size: props.size
    })
  })
  const boxClasses = computed(() => {
    return boxStyles({
      disabled: props.disabled,
      size: props.size
    })
  })
  const labelTextClasses = computed(() => {
    return labelTextStyles({
      size: props.size
    })
  })
  const captionClasses = computed(() => {
    return captionStyles({
      error: !!props.error
    })
  })
  const clampValue = (value: number) => {
    return clamp(round(value, props.precision ?? 1), props.min ?? 0, props.max ?? Infinity)
  }
  const handleInput = (event: Event) => {
    const value = (event.target as HTMLInputElement).value

    if (props.precision) {
      const parsedValue = parseFloat(value)
      if (!isNaN(parsedValue)) {
        emit('update:modelValue', clampValue(parsedValue))
      }
      return
    }

    emit('update:modelValue', value)
  }

  ctx.expose({ $el: inputRef })

  const iconSize = IconSize[props.size ?? 'default']

  return () => (
    <div class={s.root}>
      {props.label && <span class={labelTextClasses.value}>{props.label}</span>}
      <label class={labelClasses.value} for={props.name}>
        {props.iconLeft && <BaseIcon size={iconSize} name={props.iconLeft} />}
        {props.leftLabel && <span class={boxClasses.value}>{props.leftLabel}</span>}
        {slots.leftContent?.()}
        <input
          type="text"
          ref={inputRef}
          disabled={props.disabled}
          name={props.name}
          id={props.name}
          placeholder={props.placeholder}
          class={inputClasses.value}
          onInput={handleInput}
          onChange={(v) => {
            emit('onChange', v)
          }}
          {...ctx.attrs}
        />
        {slots.rightContent?.()}
        {props.rightLabel && <span class={boxClasses.value}>{props.rightLabel}</span>}
        {props.iconRight && <BaseIcon size={iconSize} name={props.iconRight} />}
      </label>
      {(props.caption ?? props.error) && (
        <span class={captionClasses.value}>{props.error ?? props.caption}</span>
      )}
    </div>
  )
}

export const KcalmarInput = defineComponent(Component, {
  props: {
    size: String as PropType<Props['size']>,
    variant: String as PropType<Props['variant']>,
    iconRight: String as PropType<Props['iconRight']>,
    iconLeft: String as PropType<Props['iconLeft']>,
    modelValue: [String, Number],
    name: {
      required: false,
      type: String
    },
    label: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    placeholder: {
      required: false,
      type: String
    },
    error: {
      required: false,
      type: String
    },
    caption: {
      required: false,
      type: String
    },
    leftLabel: {
      required: false,
      type: String
    },
    rightLabel: {
      required: false,
      type: String
    }
  },
  emits: ['onChange', 'update:modelValue']
})
