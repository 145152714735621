<template>
  <Space :space="0">
    <T
      size="sm"
      type="medium"
      as="span"
    >
      {{ props.title }}
    </T>
    <T
      size="xs"
      type="medium"
      as="span"
      class="text-tertiary"
    >
      {{ props.description }}
    </T>
  </Space>
</template>
<script lang="ts" setup>
import { Space } from '@ui/Space'
import { T } from '@ui/T'

const props = defineProps<{ description: string; title: string }>()
</script>
