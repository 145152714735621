<template>
  <div
    class="nav"
    :class="{ 'sticky-extended': extendedFilters }"
  >
    <div
      class="nav__element"
      :class="{ 'nav__element--active': ingredientsType === 'all' }"
      @click="setIngrediedientsFilterByType('all')"
    >
      <div class="nav__name">
        Wszystkie
      </div>
      <BaseBadge>
        <span v-if="areFiltersActive('all')">{{ ingredientsFilterByCounted }} / </span>{{ getAllCounter }}
      </BaseBadge>
    </div>
    <div
      class="nav__element"
      :class="{ 'nav__element--active': ingredientsType === 'is_public' }"
      @click="setIngrediedientsFilterByType('is_public')"
    >
      <div class="nav__name">
        Publiczne
      </div>
      <BaseBadge>
        <span v-if="areFiltersActive('is_public')">{{ ingredientsFilterByCounted }} / </span>
        {{ getPublicCounter }}
      </BaseBadge>
    </div>
    <div
      class="nav__element"
      :class="{ 'nav__element--active': ingredientsType === 'is_private' }"
      @click="setIngrediedientsFilterByType('is_private')"
    >
      <BaseIcon
        name="lock"
        type="disabled"
      />
      <div class="nav__name">
        Prywatne
      </div>
      <BaseBadge>
        <span v-if="areFiltersActive('is_private')">{{ ingredientsFilterByCounted }} / </span>{{ getPrivateCounter }}
      </BaseBadge>
    </div>
    <div
      class="nav__element"
      :class="{ 'nav__element--active': ingredientsType === 'is_favorite' }"
      @click="setIngrediedientsFilterByType('is_favorite')"
    >
      <BaseIcon
        type="disabled"
        name="favorite"
      />
      <div class="nav__name">
        Ulubione
      </div>
      <BaseBadge>
        <span v-if="areFiltersActive('is_favorite')">{{ ingredientsFilterByCounted }} / </span>{{ getFavoriteCounter }}
      </BaseBadge>
    </div>
    <Space
      fill
      align-x="end"
      align-y="center"
      direction="columns"
      :space="4"
    >
      <StyledButton
        class="add-ingredient"
        left-icon="add"
        @click="addProduct()"
      >
        Dodaj produkt
      </StyledButton>
      <BaseTooltip class="base-tooltip--right base-tooltip--top">
        <template #tooltip>
          Zmień wyświetlane wartości odżywcze
        </template>
        <IconButton
          type="close"
          name="settings"
          @click="showChosenNutrientsModal()"
        />
      </BaseTooltip>
    </Space>
  </div>
</template>

<script lang="ts" setup>
import BaseBadge from '@/components/BaseBadge.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import { IconButton } from '@/components/IconButton'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import { useIngredientsProvider } from '@/types/IngredientList'

const {
  extendedFilters,
  setIngrediedientsFilterByType,
  areFiltersActive,
  showChosenNutrientsModal,
  addProduct,
  getFavoriteCounter,
  ingredientsType,
  ingredientsFilterByCounted,
  getPublicCounter,
  getAllCounter,
  getPrivateCounter
} = useIngredientsProvider()
</script>

<style scoped>
.nav .add-ingredient {
  margin-left: auto;
}

.nav {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-top: 27px;
  position: sticky;
  top: 88px;
  z-index: 150;
  background: #f8f8f8;
}

.nav.sticky-extended {
  top: 422px;
}

.nav__element {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  padding: 14px 15px 10px;
  text-decoration: none;
  border-bottom: 4px solid transparent;
  cursor: pointer;
}

.nav__name {
  font-family: Montserrat-Medium;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-tertiary-text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 5px;
}

.nav__element:hover .nav__name {
  color: var(--color-primary-text);
}

.nav__element.nav__element--active .nav__name {
  color: var(--color-primary-text);
}

.base-badge {
  padding: 3px 6px;
}

.nav__element:hover .base-badge {
  background: var(--color-primary-text);
}

.nav__element.nav__element--active .base-badge {
  background: var(--color-main-100);
}

.nav__element .lock-icon,
.nav__element .favorite-icon {
  color: var(--color-tertiary-text);
}

.nav__element.nav__element--active :deep(.base-icon) {
  color: var(--color-main-100);
}

.nav__drop {
  padding: 4px 15px;
  margin: 4px 0 0;
}

.nav__drop--hover {
  background-color: #eef8f7;
  border-radius: 4px;
  padding: 3px 14px;
  margin: 4px 0;
  border: 1px solid #5dbab3;
}

.nav__drop-name {
  font-size: 9px;
  line-height: 12px;
}

.nav__element--active {
  border-bottom: 4px solid var(--color-main-100);
}

.ingredients-list {
  margin: 38px 0 16px;
  min-width: 950px;
}

.nav-actions {
  display: flex;
}
</style>
