import type { Ref } from 'vue'

import { computed, ref } from 'vue'

import { getIconBySortFn } from '@/utils/common'

export const useSortBy = <T extends string, Item extends Record<T, number | string>>(
  defaultSortBy: T
) => {
  const sortBy = ref<T>(defaultSortBy) as Ref<T>
  const sortByReverse = ref(false)

  const setSortBy = (type: T) => {
    if (sortBy.value !== type) {
      sortByReverse.value = false
    } else {
      sortByReverse.value = !sortByReverse.value
    }
    sortBy.value = type
  }
  const sortFn = (a: Item, b: Item) => {
    if (sortBy.value !== 'name') {
      const aSortBy = a[sortBy.value] === 'b.d.' ? -1 : a[sortBy.value]
      const bSortBy = b[sortBy.value] === 'b.d.' ? -1 : b[sortBy.value]

      if (aSortBy < bSortBy) return -1
      if (aSortBy > bSortBy) return 1
    } else {
      if (a[sortBy.value].toString().localeCompare(b[sortBy.value].toString()) < 0) return -1
      if (a[sortBy.value].toString().localeCompare(b[sortBy.value].toString()) > 0) return 1
    }
    return 0
  }

  const sort = (a: Item, b: Item) => {
    return sortFn(a, b) * (sortByReverse.value ? -1 : 1)
  }

  const getIconBySort = computed(() => getIconBySortFn(sortBy.value, sortByReverse.value))
  const setDefaultSort = () => {
    sortBy.value = defaultSortBy
    sortByReverse.value = false
  }

  return {
    getIconBySort,
    sortBy,
    sortByReverse,
    setDefaultSort,
    setSortBy,
    sort
  }
}
