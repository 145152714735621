<template>
  <div class="nutrients">
    <div class="header">
      Podstawowe wartości odżywcze
    </div>
    <div class="basic-nutrients">
      <div class="basic-nutrients__header">
        Nazwa
      </div>
      <div class="basic-nutrients__header basic-nutrients__header--assumption">
        Założenia diety
      </div>
      <div class="basic-nutrients__header basic-nutrients__header--norm">
        Suma / Docelowa wartość
      </div>
      <template
        v-for="nutrient in basicNutrients"
        :key="`name-${nutrient.key}`"
      >
        <div class="basic-nutrients__cell basic-nutrients__name">
          {{ nutrient.name }}
          <span class="basic-nutrients__measurement">({{ nutrientsMap[nutrient.key].measurement }})</span>
        </div>
        <div class="basic-nutrients__cell basic-nutrients__assumption">
          <template v-if="nutrient.key === 'calories'">
            <BaseInputNumber
              class="assumption-input"
              test-id="DietDetailDayNutrients__Calories"
              :class="{ disabled: energyDemandSport || !canDietBeEdited }"
              :readonly="energyDemandSport"
              :min="1"
              :max="9999"
              :precision="0"
              :model-value="diet?.data.norms.calories ?? 0"
              @update:model-value="inputHandler(nutrient.key, $event)"
              @click="energyDemandSport ? disabledInputHandler() : ''"
            />
            <span>(kcal)</span>
          </template>
          <template v-else-if="nutrient.key === 'protein'">
            <BaseInputNumber
              class="assumption-input"
              test-id="DietDetailDayNutrients__Protein"
              :class="{ disabled: energyDemandSport || !canDietBeEdited }"
              :readonly="energyDemandSport"
              :min="0"
              :max="100"
              :precision="0"
              :model-value="diet?.data.calculation.standard.protein ?? 0"
              @update:model-value="inputHandler(nutrient.key, $event)"
              @click="energyDemandSport ? disabledInputHandler() : ''"
            />
            <span>%</span>
          </template>
          <template v-else-if="nutrient.key === 'fat'">
            <BaseInputNumber
              class="assumption-input"
              test-id="DietDetailDayNutrients__Fat"
              :class="{ disabled: energyDemandSport || !canDietBeEdited }"
              :readonly="energyDemandSport"
              :min="0"
              :max="100"
              :precision="0"
              :model-value="diet?.data.calculation.standard.fat ?? 0"
              @update:model-value="inputHandler(nutrient.key, $event)"
              @click="energyDemandSport ? disabledInputHandler() : ''"
            />
            <span>%</span>
          </template>
          <template v-else-if="nutrient.key === 'carbohydrates'">
            <BaseInputNumber
              class="assumption-input"
              test-id="DietDetailDayNutrients__Carbohydrates"
              :class="{ disabled: energyDemandSport || !canDietBeEdited }"
              :readonly="energyDemandSport"
              :min="0"
              :max="100"
              :precision="0"
              :model-value="diet?.data.calculation.standard.carbohydrates ?? 0"
              @update:model-value="inputHandler(nutrient.key, $event)"
              @click="energyDemandSport ? disabledInputHandler() : ''"
            />
            <span>%</span>
          </template>
          <template v-else-if="nutrient.key === 'fiber'">
            <BaseInputNumber
              class="assumption-input"
              test-id="DietDetailDayNutrients__Fiber"
              :class="{ disabled: energyDemandSport || !canDietBeEdited }"
              :readonly="energyDemandSport"
              :min="0"
              :max="1000"
              :precision="1"
              :model-value="diet?.data.norms.fiber?.inputed ?? 0"
              @update:model-value="inputHandler(nutrient.key, $event)"
              @click="energyDemandSport ? disabledInputHandler() : ''"
            />
            <span>(g)</span>
          </template>
        </div>
        <div class="basic-nutrients__cell basic-nutrients__norm">
          <div
            class="norm-box"
            :class="nutrient.normClass[dayIndex]"
          >
            <div class="value-norm-icon">
              <div class="value">
                {{ nutrient.value[dayIndex] }}
              </div>
              <div class="norm-icon">
                <div class="separator">
                  /
                </div>
                <div class="norm">
                  {{ nutrient.norm }}
                </div>
                <div
                  class="tooltip base-tooltip base-tooltip--top base-tooltip--right"
                  @mouseover="setActiveTooltip(`norm-${nutrient.key}`)"
                  @mouseleave="setActiveTooltip('')"
                >
                  <div class="icon" />
                  <div
                    v-if="checkActiveTooltip(`norm-${nutrient.key}`)"
                    class="base-tooltip__content"
                  >
                    <div class="difference">
                      <div class="icon" />
                      <span class="difference-value">{{ ((nutrient.differenceValue[dayIndex] as number) ?? 0) > 0 ? '+' : '' }}
                        {{ nutrient.differenceValue[dayIndex] }} {{ nutrient.measurement }}
                      </span>
                      ({{ nutrient.valuePercent[dayIndex] }} %)
                    </div>
                    <div class="norm-title">
                      Docelowa wartość
                    </div>
                    <div class="norm">
                      {{ nutrient.norm }} {{ nutrient.measurement }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="header">
      Szczegółowe wartości odżywcze
    </div>
    <div
      v-if="globalStore.hasPerm('accounts.diet_microelements')"
      class="specific-nutrients"
    >
      <div
        v-for="nutrient in choosenSpecificNutrients"
        :key="`specific-${nutrient.key}`"
      >
        <div class="specific-nutrients__header">
          <div
            class="specific-nutrients__name"
            :title="nutrient.name"
          >
            {{ nutrient.name }}
          </div>
          <div
            v-if="S.isNotEmpty(nutrient.incompleteData[dayIndex] ?? '')"
            class="incomplete-data"
          >
            <div
              class="tooltip base-tooltip base-tooltip--top base-tooltip--center"
              @mouseover="setActiveTooltip(`incomplete-data-${nutrient.key}-${dayIndex}`)"
              @mouseleave="setActiveTooltip('')"
            >
              <i class="incomplete-data__icon base-icon">info</i>
              <div
                v-if="checkActiveTooltip(`incomplete-data-${nutrient.key}-${dayIndex}`)"
                class="base-tooltip__content"
              >
                <div class="incomplete-data__title">
                  Niepełne dane:
                </div>
                <div class="incomplete-data__info">
                  {{ nutrient.incompleteData[dayIndex] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="norm-box"
          :class="nutrient.normClass[dayIndex]"
        >
          <div class="measurement-value-norm-icon">
            <div class="measurement">
              ({{ displayMeasurement(nutrient.measurement) }})
            </div>
            <div class="value-norm-icon">
              <div class="value">
                {{ nutrient.value[dayIndex] }}
              </div>
              <div
                v-if="nutrient.norm > 0"
                class="norm-icon"
              >
                <div class="separator">
                  /
                </div>
                <div class="norm">
                  {{ nutrient.norm }}
                </div>
                <div
                  class="tooltip base-tooltip base-tooltip--top base-tooltip--center"
                  @mouseover="setActiveTooltip(`norm-${nutrient.key}`)"
                  @mouseleave="setActiveTooltip('')"
                >
                  <div class="icon" />
                  <div
                    v-if="checkActiveTooltip(`norm-${nutrient.key}`)"
                    class="base-tooltip__content"
                  >
                    <div class="difference">
                      <div class="icon" />
                      <span class="difference-value">{{ ((nutrient.differenceValue[dayIndex] as number) ?? 0) > 0 ? '+' : '' }}
                        {{ nutrient.differenceValue[dayIndex] }} {{ nutrient.measurement }}
                      </span>
                      ({{ nutrient.valuePercent[dayIndex] }} %)
                    </div>
                    <div class="norm-title">
                      Docelowa wartość
                    </div>
                    <div class="norm">
                      {{ nutrient.norm }} {{ nutrient.measurement }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="specific-nutrients-disabled"
      @click="globalStore.showAppUpgradePlanModal"
    />
  </div>
</template>

<script lang="ts" setup>
import type { NutrientMeasurement, Nutrients } from '@/utils/nutrients'

import { A, G, S } from '@mobily/ts-belt'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import BaseInputNumber from '@/components/BaseInputNumber.vue'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import { nutrientsMap } from '@/utils/nutrients'

const dietStore = useDietsStore()
const globalStore = useGlobalStore()
const { canDietBeEdited, diet, basicNutrients, chosenNutrients, specificNutrients } =
  storeToRefs(dietStore)
const route = useRoute()
const activeTooltip = ref('')

const dayIndex = computed(() => {
  if (G.isString(route.params.dayIndex)) {
    return parseInt(route.params.dayIndex)
  }
  return 0
})
const choosenSpecificNutrients = computed(() =>
  A.concat(chosenNutrients.value, specificNutrients.value)
)
const energyDemandSport = computed(() => diet.value?.data.calculation.type === 'sport')

const displayMeasurement = (measurement: NutrientMeasurement) => {
  return measurement || '-'
}

const disabledInputHandler = () => {
  globalStore.createMessage({
    title:
      'Zmień w ustawieniach diety sposób przeliczenia KBTW na Standardowy, aby odblokować edycję tego pola.'
  })
}
const setActiveTooltip = (nextTooltip: string) => {
  activeTooltip.value = nextTooltip
}
const checkActiveTooltip = (nextTooltip: string) => {
  return activeTooltip.value === nextTooltip
}
const inputHandler = (nutrientKey: Nutrients, value: number) => {
  if (nutrientKey === 'calories' || nutrientKey === 'fiber') {
    dietStore.updateNorm({ nutrient: nutrientKey, value })
  }
  if (['protein', 'fat', 'carbohydrates'].includes(nutrientKey)) {
    dietStore.updateCalculation({
      nutrient: nutrientKey as 'protein' | 'fat' | 'carbohydrates',
      value,
      type: 'standard'
    })
  }
}
</script>

<style scoped>
.header {
  background: var(--color-main-100);
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  line-height: 40px;
  font-family: Montserrat-SemiBold;
  font-size: 16px;
}

.basic-nutrients {
  padding-bottom: 24px;
  display: grid;
  grid-template-columns: minmax(130px, 1fr) minmax(130px, 1fr) minmax(190px, 1fr);
}

.basic-nutrients__header {
  border-bottom: 2px solid #f8f8f8;
  font-size: 12px;
  letter-spacing: 1.21px;
  line-height: 14px;
  padding: 0 12px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basic-nutrients__header--assumption {
  padding: 0;
}

.basic-nutrients__header--norm {
  justify-content: flex-end;
  text-align: right;
}

.basic-nutrients__cell {
  border-bottom: 2px solid #f8f8f8;
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 40px;
}

.basic-nutrients__name {
  font-size: 12px;
  line-height: 19px;
  font-family: Montserrat-Medium;
  letter-spacing: 1.21px;
  justify-content: flex-start;
}

.basic-nutrients__name .basic-nutrients__measurement {
  color: var(--color-tertiary-text);
  margin-left: 6px;
}

.basic-nutrients__assumption {
  justify-content: center;
  padding: 0;
}

.basic-nutrients__assumption .assumption-input {
  width: 72px;
  text-align: center;
  font-size: 14px;
}

.basic-nutrients__assumption .assumption-input.disabled {
  color: #000;
  border-bottom: none;
}

.basic-nutrients__assumption span {
  display: inline-block;
  width: 40px;
  margin-left: 6px;
  font-size: 14px;
}

.basic-nutrients__norm {
  justify-content: flex-end;
}

.specific-nutrients {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  gap: 10px;
  padding: 13px;
}

.specific-nutrients-disabled {
  height: 3000px;
  background-image: url('@/assets/img/diets/specific-nutrients-blured.png');
  padding-bottom: 50px;
  background-size: 100%;
}

.specific-nutrients__header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  min-height: 32px;
  position: relative;
}

.specific-nutrients__name {
  line-height: 16px;
  font-size: 12px;
  white-space: nowrap;
  padding: 0 32px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.incomplete-data {
  position: absolute;
  right: 6px;
  top: calc(50% - 8px);
  text-align: center;
}

.incomplete-data__icon {
  color: var(--color-tertiary-text);
  display: block;
  width: 19px;
  font-size: 18px;
  line-height: 16px;
  cursor: pointer;
}

.incomplete-data__title {
  text-align: center;
  font-family: Montserrat-Medium;
  margin-bottom: 8px;
}

.incomplete-data__info {
  white-space: pre;
}

.norm-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 9px;
}

.basic-nutrients__norm .norm-box {
  width: 160px;
  height: 100%;
  justify-content: flex-end;
}

.norm-box .value,
.norm-box .norm {
  -webkit-touch-callout: none;
  user-select: none;
}

.norm-box .measurement-value-norm-icon {
  display: flex;
  flex-direction: column;
}

.norm-box .value-norm-icon {
  display: flex;
}

.norm-box .norm-icon {
  display: flex;
}

.norm-box .measurement {
  font-family: Montserrat-Medium;
  font-size: 9px;
  line-height: 10px;
  color: var(--color-tertiary-text);
  margin-bottom: 3px;
}

.norm-box .separator {
  margin: 0 2px;
}

.norm-box .norm {
  color: var(--color-secondary-text);
  margin-right: 8px;
}

.norm-box .icon {
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.norm-box.red-up,
.norm-box.red-down {
  background: var(--color-red-10);
}

.norm-box.yellow-up,
.norm-box.yellow-down {
  background: var(--color-warning-10);
}

.norm-box.red-up .icon,
.norm-box.red-down .icon {
  background-image: url('@/assets/img/diets/norm-red.svg?url');
}

.norm-box.yellow-up .icon,
.norm-box.yellow-down .icon {
  background-image: url('@/assets/img/diets/norm-yellow.svg?url');
}

.norm-box.yellow-down .icon,
.norm-box.red-down .icon {
  transform: rotate(180deg);
}

.norm-box.ok .icon {
  height: 15px;
  width: 15px;
  background-size: 15px 15px;
  background-image: url('@/assets/img/diets/norm-green.svg?url');
}

.norm-box .tooltip {
  height: 16px;
  width: max-content;
  text-align: center;
}

.norm-box .difference {
  font-family: Montserrat-Medium;
  line-height: 16px;
  font-size: 12px;
  margin-bottom: 4px;
}

.norm-box .difference .icon {
  width: 10px;
  height: 10px;
  background-size: 10px 10px;
  margin-right: 8px;
}

.norm-box.red-up .difference-value,
.norm-box.red-down .difference-value {
  color: var(--color-destructive-100);
}

.norm-box.yellow-up .difference-value,
.norm-box.yellow-down .difference-value {
  color: var(--color-warning-100);
}

.norm-box.ok .difference-value {
  color: var(--color-success-100);
}

.norm-box .tooltip .norm-title {
  color: #7f7f7f;
  font-size: 10px;
  line-height: 13px;
}

.norm-box .tooltip .norm {
  font-family: Montserrat-Medium;
  color: var(--color-primary-text);
  font-size: 12px;
  line-height: 15px;
}
</style>
