import type {
  AllergensInfo,
  AllergensMap,
  DayNutrientsDayData,
  DayNutrientsDayDataNutrients,
  DietDayIndex
} from '@/types/Diet'
import type { NormClass, NutrientExtended, Nutrients } from '@/utils/nutrients'

import { A } from '@mobily/ts-belt'
import round from 'lodash/round'

import { basicNutrients, nutrientsMap } from '@/utils/nutrients'

export const getIngredientAllergens = (
  ingredientId: number,
  allergens: AllergensMap
): AllergensInfo => {
  return {
    allergies: allergens.allergies_id.includes(ingredientId),
    intolerances: allergens.intolerances_id.includes(ingredientId),
    like: allergens.like_id.includes(ingredientId),
    notLike: allergens.not_like_id.includes(ingredientId)
  }
}

export const getAllergensFromIngredients = (
  ingredientIds: number[],
  allergens: AllergensMap
): AllergensInfo => {
  return {
    allergies: ingredientIds.some((id) => allergens.allergies_id.includes(id)),
    intolerances: ingredientIds.some((id) => allergens.intolerances_id.includes(id)),
    like: ingredientIds.some((id) => allergens.like_id.includes(id)),
    notLike: ingredientIds.some((id) => allergens.not_like_id.includes(id))
  }
}

export const getNormClass = (nutrientKey: Nutrients, value: number): NormClass => {
  if (nutrientKey === 'glycemic_index') {
    if (value <= 55) return 'ok'
    if (value > 55 && value < 70) return 'yellow-up'
    return 'red-up'
  } else if (nutrientKey === 'glycemic_load') {
    if (value <= 80) return 'ok'
    if (value > 80 && value < 120) return 'yellow-up'
    return 'red-up'
  } else if (['cholesterol', 'sodium'].includes(nutrientKey)) {
    if (value > 20) return 'red-up'
    if (value > 10) return 'yellow-up'
  } else if (
    ['calcium', 'phosphorus', 'iron', 'copper', 'iodine', 'manganese'].includes(nutrientKey)
  ) {
    if (value > 20) return 'yellow-up'
    if (value < -20) return 'red-down'
    if (value < -10) return 'yellow-down'
  } else if (
    [
      'thiamin',
      'riboflavin',
      'niacin',
      'folate_total',
      'vitamin_B_12',
      'vitamin_B6',
      'vitamin_A_RAE',
      'vitamin_C',
      'potassium',
      'vitamin_D',
      'vitamin_E',
      'magnesium',
      'zinc',
      'fiber',
      'carotene_beta',
      'retinol'
    ].includes(nutrientKey)
  ) {
    if (value < -20) return 'red-down'
    if (value < -10) return 'yellow-down'
  } else {
    if (value > 20) return 'red-up'
    if (value > 10) return 'yellow-up'
    if (value < -20) return 'red-down'
    if (value < -10) return 'yellow-down'
  }
  return 'ok'
}

export const parseNutrient = (
  nutrientKey: Nutrients,
  incompleteData: DayNutrientsDayDataNutrients,
  dayData: DayNutrientsDayData,
  norm: number
) => {
  let daysDivider = 0
  let averageN3 = 0
  let averageN6 = 0

  if (nutrientKey === 'ratio_n_3_n_6') {
    const nutrient: NutrientExtended = {
      key: nutrientKey,
      name: nutrientsMap[nutrientKey].name,
      measurement: nutrientsMap[nutrientKey].measurement,
      norm,
      value: ['0', '0', '0', '0', '0', '0', '0'],
      valuePercent: [0, 0, 0, 0, 0, 0, 0],
      differenceValue: ['0', '0', '0', '0', '0', '0', '0'],
      differenceValuePercent: [0, 0, 0, 0, 0, 0, 0],
      normClass: ['ok', 'ok', 'ok', 'ok', 'ok', 'ok', 'ok'],
      average: '0 : 0',
      averagePercent: 0,
      differenceAverage: 0,
      differenceAveragePercent: 0,
      averageClass: undefined,
      incompleteData: ['', '', '', '', '', '', '']
    }

    for (let dayIndex: DietDayIndex = 0; dayIndex < nutrient.value.length; dayIndex++) {
      const incompleteDataIngredients = Array.from(incompleteData[dayIndex]?.[nutrient.key] ?? [])
      if (incompleteDataIngredients.length > 0) {
        nutrient.incompleteData[dayIndex] = incompleteDataIngredients.join(',\n')
      }

      nutrient.value[dayIndex] = dayData[dayIndex]?.[nutrientKey] ?? '0 : 0'
      const data = dayData[dayIndex]
      if (data) {
        if ('n_3' in data && 'n_6' in data) {
          const n3 = data.n_3 || 0
          const n6 = data.n_6 || 0
          averageN3 += n3
          averageN6 += n6
          daysDivider += 1
        }
      }
    }
    if (daysDivider > 0) {
      averageN3 = averageN3 / daysDivider
      averageN6 = averageN6 / daysDivider
      if (averageN3 > 0) {
        nutrient.average = `1 : ${round(averageN6 / averageN3, 1)}`
      } else {
        nutrient.average = `0 : ${round(averageN6, 1)}`
      }
    }

    return nutrient
  } else {
    const nutrient: NutrientExtended = {
      key: nutrientKey,
      name: nutrientsMap[nutrientKey].name,
      measurement: nutrientsMap[nutrientKey].measurement,
      norm,
      value: [0, 0, 0, 0, 0, 0, 0],
      valuePercent: [0, 0, 0, 0, 0, 0, 0],
      differenceValue: [0, 0, 0, 0, 0, 0, 0],
      differenceValuePercent: [0, 0, 0, 0, 0, 0, 0],
      normClass: ['', '', '', '', '', '', ''],
      average: 0,
      averagePercent: 0,
      differenceAverage: 0,
      differenceAveragePercent: 0,
      averageClass: undefined,
      incompleteData: ['', '', '', '', '', '', '']
    }
    for (let dayIndex: DietDayIndex = 0; dayIndex < nutrient.value.length; dayIndex++) {
      const incompleteDataIngredients = Array.from(incompleteData[dayIndex]?.[nutrient.key] ?? [])
      if (incompleteDataIngredients.length > 0) {
        nutrient.incompleteData[dayIndex] = incompleteDataIngredients.join(',\n')
      }

      const dayNutrient = dayData[dayIndex]?.[nutrientKey] ?? 0
      nutrient.value[dayIndex] = dayNutrient
      if (nutrientKey === 'n_3' || nutrientKey === 'n_6')
        nutrient.value[dayIndex] = round(dayNutrient, 1)
      if (dayNutrient !== 0) {
        nutrient.average += dayNutrient
        daysDivider += 1
      }
      if (norm === 0) {
        if (A.includes(basicNutrients, nutrientKey)) {
          nutrient.valuePercent[dayIndex] = 0
          nutrient.differenceValue[dayIndex] = dayNutrient
          nutrient.differenceValuePercent[dayIndex] = 100
          nutrient.normClass[dayIndex] = getNormClass(nutrientKey, 100)
        }
      } else {
        const percent = round(((dayNutrient - norm) / norm) * 100, 1)
        const differenceValue = round(dayNutrient - norm, 1)

        nutrient.valuePercent[dayIndex] = round((dayNutrient / norm) * 100, 1)
        nutrient.differenceValue[dayIndex] = differenceValue
        nutrient.differenceValuePercent[dayIndex] = percent
        if (['glycemic_index', 'glycemic_load'].includes(nutrientKey)) {
          nutrient.normClass[dayIndex] = getNormClass(nutrientKey, dayNutrient)
        } else {
          nutrient.normClass[dayIndex] = getNormClass(nutrientKey, percent)
        }
      }
    }
    if (daysDivider > 0) {
      nutrient.average = round(nutrient.average / daysDivider, 1)
      if (norm === 0) {
        if (A.includes(basicNutrients, nutrientKey)) {
          nutrient.averagePercent = 0
          nutrient.differenceAverage = nutrient.average
          nutrient.differenceAveragePercent = 100
          nutrient.averageClass = getNormClass(nutrientKey, 100)
        }
      } else {
        const average = nutrient.average
        const percent = round(((average - norm) / norm) * 100, 1)
        const differenceValue = round(average - norm, 1)

        nutrient.averagePercent = round((average / norm) * 100, 1)
        nutrient.differenceAverage = differenceValue
        nutrient.differenceAveragePercent = round(((average - norm) / norm) * 100, 1)
        if (['glycemic_index', 'glycemic_load'].includes(nutrientKey)) {
          nutrient.averageClass = getNormClass(nutrientKey, average)
        } else {
          nutrient.averageClass = getNormClass(nutrientKey, percent)
        }
      }
    }

    return nutrient
  }
}
