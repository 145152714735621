<template>
  <input
    ref="baseInput"
    class="base-input"
    :data-test-id="`${props.testId}__BaseInput`"
    :value="modelValue"
    @input="handleInput"
  >
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

import { onMounted, ref } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  forceFocus: {
    type: Boolean,
    default: false
  },
  getRef: {
    type: Function as PropType<(ref: HTMLInputElement) => void>
  },
  testId: {
    required: true,
    type: String
  }
})
const baseInput = ref<HTMLInputElement>()
const emit = defineEmits(['update:modelValue'])
const handleInput = (event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).value)
}

onMounted(() => {
  if (props.forceFocus && baseInput.value) {
    baseInput.value.focus()
  }
  if (props.getRef && baseInput.value) {
    props.getRef(baseInput.value)
  }
})
</script>
