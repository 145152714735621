<template>
  <div
    ref="navigationRef"
    :class="$style.navigation"
    :style="{ top: searchHeight + 'px' }"
  >
    <Space
      :padding-y="[12, 0]"
      direction="columns"
      align-x="between"
    >
      <Space
        direction="columns"
        :space="8"
      >
        <NavigationItem
          title="Wszystkie"
          :count="`${areFiltersActive && ingredientsType == 'all' ? ingredientsFilterByCounted + ' /' : ''} ${getAllCounter}`"
          :active="ingredientsType === 'all'"
          @click="setIngrediedientsFilterByType('all')"
        />
        <NavigationItem
          title="Publiczne"
          :count="`${areFiltersActive && ingredientsType === 'is_public' ? ingredientsFilterByCounted + ' /' : ''} ${getPublicCounter}`"
          :active="ingredientsType === 'is_public'"
          @click="setIngrediedientsFilterByType('is_public')"
        />
        <NavigationItem
          title="Prywatne"
          :count="`${areFiltersActive && ingredientsType === 'is_private' ? ingredientsFilterByCounted + ' /' : ''} ${getPrivateCounter}`"
          icon="lock"
          :active="ingredientsType === 'is_private'"
          @click="setIngrediedientsFilterByType('is_private')"
        />
        <NavigationItem
          title="Ulubione"
          :count="`${areFiltersActive && ingredientsType === 'is_favorite' ? ingredientsFilterByCounted + ' /' : ''} ${getFavoriteCounter}`"
          icon="favorite"
          :active="ingredientsType === 'is_favorite'"
          @click="setIngrediedientsFilterByType('is_favorite')"
        />
      </Space>
      <Space
        direction="columns"
        align-x="end"
        class="mb-8"
      >
        <KcalmarButton
          left-icon="add"
          variant="primary"
          size="small"
          @click="addProduct"
        >
          Dodaj produkt
        </KcalmarButton>
        <KcalmarIconButton
          variant="secondary"
          name="settings-4-fill"
          @click="showChosenNutrientsModal"
        />
      </Space>
    </Space>
  </div>
</template>

<script lang="ts" setup>
import { useCssModule } from 'vue'
import { useRouter } from 'vue-router'

import { KcalmarButton } from '@ui/KcalmarButton'
import { KcalmarIconButton } from '@ui/KcalmarIconButton'
import { NavigationItem } from '@ui/KcalmarTable/components/NavigationItem'

import { Space } from '@/components/Space'
import { useHasAddIngredientPermission } from '@/hooks/useHasAddIngredientPermission'
import { useGlobalStore } from '@/store/globalStore'

import { useIngredientsProvider } from './provider'

const $style = useCssModule()
const hasAddIngredientPermission = useHasAddIngredientPermission()
const router = useRouter()
const { showAppUpgradePlanModal } = useGlobalStore()
const {
  navigationRef,
  searchHeight,
  setIngrediedientsFilterByType,
  areFiltersActive,
  showChosenNutrientsModal,
  getFavoriteCounter,
  ingredientsType,
  ingredientsFilterByCounted,
  getPublicCounter,
  getAllCounter,
  getPrivateCounter
} = useIngredientsProvider()

const addProduct = async () => {
  if (hasAddIngredientPermission.value) {
    await router.push({ name: 'IngredientsListAdd' })
  } else {
    showAppUpgradePlanModal()
  }
}
</script>

<style lang="scss" scoped module>
.navigation {
  background-color: #f8f8f8;
  position: sticky;
  z-index: 2;
}
</style>
