<template>
  <DietListGroups diet-type="user" />
</template>

<script lang="ts" setup>
import DietListGroups from '@/components/diets/DietListGroups.vue'
import { DietListGroupsInject } from '@/components/diets/providers/dietListGroupsProvider'
import { useDietList } from '@/hooks/useDietList'
import { provide } from 'vue'

provide(DietListGroupsInject, useDietList({ dietType: 'user' }))
</script>
