<template>
  <VueDatePicker
    v-model="model"
    locale="pl"
    format="dd.MM.yyyy"
    :auto-apply="true"
    :clearable="false"
    :close-on-auto-apply="true"
    :max-date="maxDate"
    :min-date="minDate"
    :month-change-on-scroll="false"
    :hide-navigation="['time', 'hours', 'minutes', 'seconds']"
    :day-names="['P', 'W', 'Ś', 'C', 'P', 'S', 'N']"
    :text-input="true"
  >
    <template #input-icon />
  </VueDatePicker>
</template>

<script lang="ts" setup>
import { parse, isDate } from 'date-fns'
import VueDatePicker from '@vuepic/vue-datepicker'
import { computed } from 'vue'

import '@vuepic/vue-datepicker/dist/main.css'
import { match } from 'ts-pattern'
import { F, G } from '@mobily/ts-belt'

const props = defineProps<{
  testId: string
  modelValue: string | Date | undefined
  maxDate?: Date
  minDate?: Date
}>()
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return match(props.modelValue)
      .when(G.isString, (value) => {
        if (value.includes('-')) {
          return parse(value, 'dd-MM-yyyy', new Date())
        } else if (value.includes('.')) {
          return parse(value, 'dd.MM.yyyy', new Date())
        }
        return new Date()
      })
      .when(isDate, F.identity)
      .otherwise(() => {
        return new Date()
      })
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>

<style>

:root {
    /* General */
    --dp-font-family: 'Montserrat-Regular', sans-serif;
    --dp-border-radius: 0; /* Configurable border-radius */
    --dp-cell-border-radius: 24px; /* Specific border radius for the calendar cell */
    --dp-common-transition: all 0.1s ease-in; /* Generic transition applied on buttons and calendar cells */

    /* Sizing */
    --dp-button-height: 35px; /* Size for buttons in overlays */
    --dp-month-year-row-height: 35px; /* Height of the month-year select row */
    --dp-month-year-row-button-size: 35px; /* Specific height for the next/previous buttons */
    --dp-button-icon-height: 20px; /* Icon sizing in buttons */
    --dp-cell-size: 35px; /* Width and height of calendar cell */
    --dp-cell-padding: 5px; /* Padding in the cell */
    --dp-common-padding: 10px; /* Common padding used */
    --dp-input-icon-padding: 12px; /* Padding on the left side of the input if icon is present */
    --dp-input-padding: 6px 12px 6px 12px; /* Padding in the input */
    --dp-menu-min-width: 260px; /* Adjust the min width of the menu */
    --dp-action-buttons-padding: 2px 5px; /* Adjust padding for the action buttons in action row */
    --dp-row-margin:  5px 0; /* Adjust the spacing between rows in the calendar */
    --dp-calendar-header-cell-padding:  0.5rem; /* Adjust padding in calendar header cells */
    --dp-two-calendars-spacing:  10px; /* Space between multiple calendars */
    --dp-overlay-col-padding:  3px; /* Padding in the overlay column */
    --dp-time-inc-dec-button-size:  32px; /* Sizing for arrow buttons in the time picker */
    --dp-menu-padding: 6px 8px; /* Menu padding */

    /* Font sizes */
    --dp-font-size: 12px; /* Default font-size */
    --dp-preview-font-size: 1rem; /* Font size of the date preview in the action row */
    --dp-time-font-size: 1rem; /* Font size in the time picker */

    /* Transitions */
    --dp-animation-duration: 0.1s; /* Transition duration */
    --dp-menu-appear-transition-timing: cubic-bezier(.4, 0, 1, 1); /* Timing on menu appear animation */
    --dp-transition-timing: ease-out; /* Timing on slide animations */
}

.dp__main {
  width: auto;
}

.dp__theme_light {
    --dp-background-color: #fff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: var(--color-main-100);
    --dp-primary-disabled-color: var(--color-disabled-text);
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: var(--color-tertiary-text);
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: var(--color-main-100);
    --dp-border-color-focus: var(--color-main-100);
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}

.dp__input {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  text-align: center;
}

.dp__calendar_header_separator {
  display: none;
}

.dp__calendar_header_item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  color: var(--color-tertiary-text);
}
</style>
