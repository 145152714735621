/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import type { Choice } from '@/components/ingredients/IngredientsExtendedFilters.vue'
import type { DietSearchDish, DietSearchDishOrIngredient } from '@/types/Diet'
import type { Nutrients } from './nutrients'

import { A } from '@mobily/ts-belt'

export type SortBy = 'name' | 'quantity' | Nutrients

type WorkerData = {
  categoryChoices: Choice[]
  categoryValues: string[]
  dishSizesValues: number[]
  dishTypesValues: number[]
  dishesOrIngredients: DietSearchDishOrIngredient[]
  favouriteDishes: number[]
  favouriteIngredients: number[]
  ingredientBasesValues: number[]
  ingredientCategoriesValues: number[]
  ingredientTagsValues: string[]
  kcalMaxValue: number
  kcalMinValue: number
  mealTypesValues: number[]
  searchAllTagsStatus: boolean
  searchInValue: 'ingredients_in_dishes' | 'dishes' | 'ingredients'
  searchType: 'dishes' | 'ingredients'
  sortByValue: SortBy
}

type DietSearchDishOrIngredientWithSortValue = DietSearchDishOrIngredient & {
  sortValue: string | number
}
export default ({
  searchAllTagsStatus,
  kcalMaxValue,
  kcalMinValue,
  categoryChoices,
  categoryValues,
  ingredientBasesValues,
  ingredientCategoriesValues,
  ingredientTagsValues,
  dishSizesValues,
  favouriteIngredients,
  dishTypesValues,
  mealTypesValues,
  searchInValue,
  searchType,
  sortByValue,
  favouriteDishes,
  dishesOrIngredients: doi
}: WorkerData) => {
  let dishesOrIngredients = doi

  const workerRound = (number: number, places?: number) => {
    return parseFloat(number.toFixed(places))
  }

  const workerFilterCheckerAll = <T>(arr: T[], target: T[]) => target.every((v) => arr.includes(v))
  const workerFilterCheckerAny = <T>(arr: T[], target: T[]) => target.some((v) => arr.includes(v))

  function workerFilterKcalMin(data: DietSearchDishOrIngredient[]) {
    return data.filter((obj) => {
      if (searchInValue === 'ingredients') {
        return (
          workerRound((obj.calories * obj.quantity) / 100, 1) >= parseFloat(kcalMinValue.toString())
        )
      } else {
        if ('max_portions' in obj && obj.max_portions > 0) {
          return workerRound(obj.calories, 1) >= parseFloat(kcalMinValue.toString())
        }
      }
      return false
    })
  }

  function workerFilterKcalMax(data: DietSearchDishOrIngredient[]) {
    return data.filter((obj) => {
      if (searchInValue === 'ingredients') {
        return (
          workerRound((obj.calories * obj.quantity) / 100, 1) <= parseFloat(kcalMaxValue.toString())
        )
      } else {
        if ('max_portions' in obj && obj.max_portions > 0) {
          return workerRound(obj.calories, 1) <= parseFloat(kcalMaxValue.toString())
        }
      }
      return false
    })
  }

  function workerFilterDishCategory(data: DietSearchDishOrIngredient[]) {
    if (categoryValues.length > 0 && categoryValues.length !== categoryChoices.length) {
      data = data.filter((obj) => {
        let isFiltered = false
        if (categoryValues.includes('private')) {
          if (!obj.is_public) isFiltered = true
        }
        if (categoryValues.includes('public')) {
          if (obj.is_public) isFiltered = true
        }
        if (categoryValues.includes('favourite')) {
          if (searchType === 'dishes') {
            if (favouriteDishes.includes(obj.id)) isFiltered = true
          }
          if (searchType === 'ingredients') {
            if (favouriteIngredients.includes(obj.id)) isFiltered = true
          }
        }
        return isFiltered
      })
    }
    return data
  }

  function workerFilterIngredientTags(data: DietSearchDishOrIngredient[]) {
    if (ingredientTagsValues.length > 0) {
      if (searchAllTagsStatus) {
        ingredientTagsValues.forEach((_tag) => {
          const tag = _tag as keyof DietSearchDishOrIngredient
          data = data.filter((obj) => {
            return obj[tag] === 0 || obj[tag] === true
          })
        })
      } else {
        data = data.filter((obj) => {
          return ingredientTagsValues.some((_tag) => {
            const tag = _tag as keyof DietSearchDishOrIngredient
            return obj[tag] === 0 || obj[tag] === true
          })
        })
      }
    }
    return data
  }

  function workerFilterSize(data: DietSearchDishOrIngredient[]) {
    if (dishSizesValues.length > 0) {
      data = data.filter((obj) => {
        return 'size' in obj && dishSizesValues.includes(obj.size)
      })
    }
    return data
  }

  function workerFilterMealType(data: DietSearchDishOrIngredient[]) {
    if (mealTypesValues.length > 0) {
      data = data.filter((obj) => {
        if ('meal_types_ids' in obj && obj.meal_types_ids.length > 0) {
          return workerFilterCheckerAll(obj.meal_types_ids, mealTypesValues)
        } else {
          return true
        }
      })
    }
    return data
  }

  function workerFilterDishType(data: DietSearchDishOrIngredient[]) {
    if (dishTypesValues.length > 0) {
      data = data.filter((obj) => {
        if ('meal_types_ids' in obj) {
          if (searchAllTagsStatus) {
            return workerFilterCheckerAll(obj.dish_types_ids, dishTypesValues)
          } else {
            return workerFilterCheckerAny(obj.dish_types_ids, dishTypesValues)
          }
        }
        return false
      })
    }
    return data
  }

  function dishTypeAndIngredientTagsFilter(data: DietSearchDishOrIngredient[]) {
    if (dishTypesValues.length > 0 || ingredientTagsValues.length > 0) {
      if (dishTypesValues.length > 0 && ingredientTagsValues.length > 0) {
        let tempData = [...data]
        if (searchAllTagsStatus) {
          let dishReturn = false
          A.forEach(ingredientTagsValues, (tag) => {
            tempData = tempData.filter((obj) => {
              // @ts-expect-error
              dishReturn = obj[tag] === 0 || Boolean(obj[tag])
              if (dishReturn) {
                dishReturn =
                  'meal_types_ids' in obj &&
                  workerFilterCheckerAll(obj.dish_types_ids, dishTypesValues)
              }
              return dishReturn
            })
          })
        } else {
          let dishReturn = false
          tempData = tempData.filter((obj) => {
            // @ts-expect-error
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            dishReturn = obj[tag] === 0 || Boolean(obj[tag])

            if (!dishReturn && 'meal_types_ids' in obj) {
              dishReturn = workerFilterCheckerAny(obj.dish_types_ids, dishTypesValues)
            }
            return dishReturn
          })
        }
        return tempData
      } else if (dishTypesValues.length > 0) {
        data = workerFilterDishType(data)
      } else if (ingredientTagsValues.length > 0) {
        data = workerFilterIngredientTags(data)
      }
    }
    return data
  }

  function workerFilterIngredientBase(data: DietSearchDishOrIngredient[]) {
    if (ingredientBasesValues.length > 0) {
      data = data.filter((obj) => {
        return 'base' in obj && ingredientBasesValues.includes(obj.base)
      })
    }
    return data
  }

  function workerFilterIngredientCategory(data: DietSearchDishOrIngredient[]) {
    if (ingredientCategoriesValues.length > 0) {
      data = data.filter((obj) => {
        return 'category' in obj && ingredientCategoriesValues.includes(obj.category)
      })
    }
    return data
  }

  function workerGetNutrient(
    dishOrIngredient: DietSearchDishOrIngredient,
    nutrient: Nutrients,
    calculateQuantity: boolean,
    lossesMultiplier = 1
  ) {
    let nutrientValue = 0
    let doiMultiplier = 1
    let ingredientMultiplier = 1
    if ('ingredients' in dishOrIngredient) {
      let doiCalories = 0
      let doiProtein = 0
      let doiFat = 0
      let doiCarbohydrates = 0
      let doiFiber = 0
      let doiSucrose = 0
      let doiLactose = 0
      let doiGlucose = 0
      let doiFructose = 0

      if (calculateQuantity) {
        // Single portion
        doiMultiplier = lossesMultiplier / dishOrIngredient.max_portions
      } else {
        // All portions
        doiMultiplier = lossesMultiplier
      }
      A.forEach(dishOrIngredient.ingredients, (ingredient) => {
        ingredientMultiplier = workerRound(ingredient.quantity * doiMultiplier, 1) / 100

        const sucrose = ingredient.sucrose ?? 0
        const lactose = ingredient.lactose ?? 0
        const glucose = ingredient.glucose ?? 0
        const fructose = ingredient.fructose ?? 0
        doiSucrose += workerRound(sucrose * ingredientMultiplier, 1)
        doiLactose += workerRound(lactose * ingredientMultiplier, 1)
        doiGlucose += workerRound(glucose * ingredientMultiplier, 1)
        doiFructose += workerRound(fructose * ingredientMultiplier, 1)

        doiCalories += workerRound(ingredient.calories * ingredientMultiplier, 1)
        doiProtein += workerRound(ingredient.protein * ingredientMultiplier, 1)
        doiFat += workerRound(ingredient.fat * ingredientMultiplier, 1)
        doiFiber += workerRound(ingredient.fiber * ingredientMultiplier, 1)
        doiCarbohydrates += workerRound(ingredient.carbohydrates * ingredientMultiplier, 1)
      })

      if (nutrient === 'glycemic_index' || nutrient === 'glycemic_load') {
        A.forEach(dishOrIngredient.ingredients, (ingredient) => {
          ingredientMultiplier = (ingredient.quantity / 100) * doiMultiplier
          const nutrientsGlycemicIndex = ingredient.glycemic_index ?? 0
          const nutrientCarbohydrates = ingredient.carbohydrates ?? 0
          const ingredientCarbohydrates = workerRound(
            nutrientCarbohydrates * ingredientMultiplier,
            1
          )
          if (doiCarbohydrates > 0) {
            nutrientValue += (ingredientCarbohydrates / doiCarbohydrates) * nutrientsGlycemicIndex
          }
        })
        nutrientValue = workerRound(nutrientValue)
        if (nutrient === 'glycemic_load') nutrientValue = (nutrientValue * doiCarbohydrates) / 100
      } else if (nutrient === 'protein_fat_exchangers') {
        nutrientValue = (doiProtein * 4 + doiFat * 9) / 100
      } else if (nutrient === 'protein_energy' && doiCalories > 0) {
        nutrientValue = ((doiProtein * 4) / doiCalories) * 100
      } else if (nutrient === 'fat_energy' && doiCalories > 0) {
        nutrientValue = ((doiFat * 9) / doiCalories) * 100
      } else if (nutrient === 'carbohydrates_energy' && doiCalories > 0) {
        nutrientValue = ((doiCarbohydrates * 4 + doiFiber * 2) / doiCalories) * 100
      } else if (nutrient === 'waste') {
        let doiWaste = 0
        A.forEach(dishOrIngredient.ingredients, (ingredient) => {
          const nutrients = ingredient
          ingredientMultiplier = workerRound(ingredient.quantity * doiMultiplier, 1) / 100
          const nutrientsWaste = nutrients.waste ?? 0
          const nutrientsCalories = nutrients.calories ?? 0
          const ingredientCalories = workerRound(nutrientsCalories * ingredientMultiplier, 1)
          const ingredientWaste = (nutrientsWaste * ingredientCalories) / 100
          doiWaste += workerRound(ingredientWaste, 1)
        })
        if (doiCalories > 0) nutrientValue = (doiWaste / doiCalories) * 100
      } else if (nutrient === 'simple_sugars_sum') {
        nutrientValue = doiSucrose + doiLactose + doiGlucose + doiFructose
      } else if (nutrient === 'ratio_n_3_n_6') {
        let ingredientN3 = 0
        let ingredientN6 = 0
        A.forEach(dishOrIngredient.ingredients, (ingredient) => {
          const nutrients = ingredient
          ingredientMultiplier = workerRound(ingredient.quantity * doiMultiplier, 1) / 100
          const nutrientsN3 = nutrients.n_3 ?? 0
          const nutrientsN6 = nutrients.n_6 ?? 0
          ingredientN3 += nutrientsN3 * ingredientMultiplier
          ingredientN6 += nutrientsN6 * ingredientMultiplier
        })

        if (ingredientN3 > 0) return `1 : ${workerRound(ingredientN6 / ingredientN3, 1)}`
        return `0 : ${workerRound(ingredientN6, 1)}`
      } else {
        A.forEach(dishOrIngredient.ingredients, (ingredient) => {
          const nutrients = ingredient
          ingredientMultiplier = workerRound(ingredient.quantity * doiMultiplier, 1) / 100
          const value = nutrients[nutrient] ?? 0

          if (['n_3', 'n_6'].includes(nutrient)) {
            nutrientValue += value * ingredientMultiplier
          } else {
            nutrientValue += workerRound(value * ingredientMultiplier, 1)
          }
        })
      }
    } else {
      nutrientValue = dishOrIngredient[nutrient] ?? 0

      if (calculateQuantity) {
        const quantity = dishOrIngredient.quantity ?? 0
        doiMultiplier = workerRound(quantity * lossesMultiplier, 1) / 100
      } else {
        doiMultiplier = lossesMultiplier
      }

      if (nutrient === 'glycemic_index') {
        nutrientValue = dishOrIngredient.glycemic_index ?? 0
      } else if (nutrient === 'glycemic_load') {
        const doiGlycemicIndex = dishOrIngredient.glycemic_index ?? 0
        const nutrientsCarbohydrates = dishOrIngredient.carbohydrates ?? 0
        const doiCarbohydrates = workerRound(nutrientsCarbohydrates * doiMultiplier, 1)
        nutrientValue = (doiCarbohydrates * doiGlycemicIndex) / 100
      } else if (nutrient === 'protein_fat_exchangers') {
        const nutrientsProtein = dishOrIngredient.protein ?? 0
        const doiProtein = workerRound(nutrientsProtein * doiMultiplier, 1)
        const nutrientsFat = dishOrIngredient.fat ?? 0
        const doiFat = workerRound(nutrientsFat * doiMultiplier, 1)
        nutrientValue = (doiProtein * 4 + doiFat * 9) / 100
      } else if (nutrient === 'protein_energy') {
        const nutrientsCalories = dishOrIngredient.calories ?? 0
        const doiCalories = workerRound(nutrientsCalories * doiMultiplier, 1)
        const nutrientsProtein = dishOrIngredient.protein ?? 0
        const doiProtein = workerRound(nutrientsProtein * doiMultiplier, 1)
        if (doiCalories > 0) nutrientValue = ((doiProtein * 4) / doiCalories) * 100
      } else if (nutrient === 'fat_energy') {
        const nutrientsCalories = dishOrIngredient.calories ?? 0
        const doiCalories = workerRound(nutrientsCalories * doiMultiplier, 1)
        const nutrientsFat = dishOrIngredient.fat ?? 0
        const doiFat = workerRound(nutrientsFat * doiMultiplier, 1)
        if (doiCalories > 0) nutrientValue = ((doiFat * 9) / doiCalories) * 100
      } else if (nutrient === 'carbohydrates_energy') {
        const nutrientsCalories = dishOrIngredient.calories ?? 0
        const doiCalories = workerRound(nutrientsCalories * doiMultiplier, 1)
        const nutrientsCarbohydrates = dishOrIngredient.carbohydrates || 0
        const doiCarbohydrates = workerRound(nutrientsCarbohydrates * doiMultiplier, 1)
        const nutrientsFiber = dishOrIngredient.fiber ?? 0
        const doiFiber = workerRound(nutrientsFiber * doiMultiplier, 1)
        if (doiCalories > 0)
          nutrientValue = ((doiCarbohydrates * 4 + doiFiber * 2) / doiCalories) * 100
      } else if (nutrient === 'waste') {
        nutrientValue = dishOrIngredient.waste ?? 0
      } else if (nutrient === 'simple_sugars_sum') {
        const nutrientsSucrose = dishOrIngredient.sucrose ?? 0
        const nutrientsLactose = dishOrIngredient.lactose ?? 0
        const nutrientsGlucose = dishOrIngredient.glucose ?? 0
        const nutrientsFructose = dishOrIngredient.fructose ?? 0
        const doiSucrose = workerRound(nutrientsSucrose * doiMultiplier, 1)
        const doiLactose = workerRound(nutrientsLactose * doiMultiplier, 1)
        const doiGlucose = workerRound(nutrientsGlucose * doiMultiplier, 1)
        const doiFructose = workerRound(nutrientsFructose * doiMultiplier, 1)
        nutrientValue = doiSucrose + doiLactose + doiGlucose + doiFructose
      } else if (nutrient === 'ratio_n_3_n_6') {
        const nutrientsN3 = dishOrIngredient.n_3 ?? 0
        const nutrientsN6 = dishOrIngredient.n_6 ?? 0
        const doiN3 = nutrientsN3 * doiMultiplier
        const doiN6 = nutrientsN6 * doiMultiplier

        if (doiN3 > 0) return `1 : ${workerRound(doiN6 / doiN3, 1)}`

        return `0 : ${workerRound(doiN6, 1)}`
      } else {
        nutrientValue = nutrientValue * doiMultiplier
      }
    }
    return workerRound(nutrientValue, 1)
  }

  function workerCalculateNutrients(
    data: DietSearchDishOrIngredient[]
  ): DietSearchDishOrIngredientWithSortValue[] {
    if (!['name', 'quantity'].includes(sortByValue)) {
      A.forEach(data, (_obj) => {
        const obj = _obj as DietSearchDishOrIngredientWithSortValue
        if (obj) {
          obj.sortValue = workerGetNutrient(obj, sortByValue as Nutrients, true)
        }
      })
    }

    return data as DietSearchDishOrIngredientWithSortValue[]
  }

  function workerSortByName(a: DietSearchDishOrIngredient, b: DietSearchDishOrIngredient) {
    if (a.name.localeCompare(b.name) < 0) return -1
    if (a.name.localeCompare(b.name) > 0) return 1
    return 0
  }

  function workerSortByQuantity(
    a: DietSearchDishOrIngredientWithSortValue,
    b: DietSearchDishOrIngredientWithSortValue
  ) {
    let aValue = a.quantity
    let bValue = b.quantity
    if (searchInValue === 'dishes' || searchInValue === 'ingredients_in_dishes') {
      aValue = aValue / (a as DietSearchDish).max_portions
      bValue = bValue / (b as DietSearchDish).max_portions
    }
    if (aValue > bValue) return -1
    if (aValue < bValue) return 1
    return 0
  }

  function workerSortByNutrient(
    a: DietSearchDishOrIngredientWithSortValue,
    b: DietSearchDishOrIngredientWithSortValue
  ) {
    const aValue = a.sortValue
    const bValue = b.sortValue
    if (aValue > bValue) return -1
    if (aValue < bValue) return 1
    return 0
  }

  function workerSortDishesAndIngredients(
    a: DietSearchDishOrIngredientWithSortValue,
    b: DietSearchDishOrIngredientWithSortValue
  ) {
    if (sortByValue === 'name') {
      return workerSortByName(a, b)
    }
    if (sortByValue === 'quantity') {
      return workerSortByQuantity(a, b)
    }
    return workerSortByNutrient(a, b)
  }

  dishesOrIngredients = workerFilterKcalMin(dishesOrIngredients)
  dishesOrIngredients = workerFilterKcalMax(dishesOrIngredients)
  dishesOrIngredients = workerFilterDishCategory(dishesOrIngredients)
  dishesOrIngredients = workerFilterIngredientTags(dishesOrIngredients)

  if (searchType === 'dishes') {
    dishesOrIngredients = workerFilterSize(dishesOrIngredients)
    dishesOrIngredients = workerFilterMealType(dishesOrIngredients)
    dishesOrIngredients = dishTypeAndIngredientTagsFilter(dishesOrIngredients)
  } else {
    dishesOrIngredients = workerFilterIngredientBase(dishesOrIngredients)
    dishesOrIngredients = workerFilterIngredientCategory(dishesOrIngredients)
  }
  const dishesOrIngredientsWithSortValue = workerCalculateNutrients(dishesOrIngredients)
  dishesOrIngredientsWithSortValue.sort(workerSortDishesAndIngredients)

  return dishesOrIngredients
}
