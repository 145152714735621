<template>
  <ul :class="$style.root">
    <slot />
  </ul>
</template>

<script lang="ts" setup>
import { useCssModule } from 'vue'

const $style = useCssModule()
</script>

<style lang="scss" module scoped>
.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-y: auto;
}
</style>
