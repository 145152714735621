import type { VariantPropsOf } from '@/utils/variants'
import type { PropType } from 'vue'

import { A, O, pipe, S } from '@mobily/ts-belt'
import { computed } from 'vue'

import StarSVG from '@/assets/img/star.svg?url'
import { variants } from '@/utils/variants'

import s from './style.module.scss'

const styles = variants({
  base: s.root,
  variants: {
    size: {
      xs: s['size--xs'],
      small: s['size--small'],
      default: s['size--default'],
      large: s['size--large'],
      xlarge: s['size--xlarge'],
      xxlarge: s['size--xxlarge']
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

const starStyles = variants({
  base: s.star,
  variants: {
    size: {
      xs: s['star-size--xs'],
      small: s['star-size--small'],
      default: s['star-size--default'],
      large: s['star-size--large'],
      xlarge: s['star-size--xlarge'],
      xxlarge: s['star-size--xxlarge']
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

export type Props = VariantPropsOf<typeof styles> & {
  isPremium?: boolean
  name?: string
  url?: string
}

export const KcalmarAvatar = (props: Props) => {
  const classes = computed(() => {
    return styles({
      size: props.size
    })
  })
  const starClasses = computed(() => {
    return starStyles({
      size: props.size
    })
  })

  const initials = computed(() => {
    return pipe(
      O.fromNullable(props.name),
      O.flatMap(S.match(/(^\S\S?|\b\S)?/g)),
      O.map(A.join('')),
      O.flatMap(S.match(/(^\S|\S$)?/g)),
      O.map(A.join('')),
      O.map(S.toUpperCase),
      O.getWithDefault('Uż')
    )
  })

  return (
    <div class={classes.value}>
      {props.url ? <img src={props.url} alt={props.name} /> : initials.value}
      {props.isPremium && <img class={starClasses.value} src={StarSVG} />}
    </div>
  )
}

KcalmarAvatar.props = {
  isPremium: Boolean as PropType<Props['isPremium']>,
  size: String as PropType<Props['size']>,
  name: String as PropType<Props['name']>,
  url: String as PropType<Props['url']>
}
