<template>
  <div class="dish-or-ingredient">
    <div class="menu">
      <BaseTooltip
        v-if="extended && isDish"
        class="menu-item base-tooltip--bottom base-tooltip--right"
      >
        <IconButton
          name="zoom_in_map"
          icon-size="22"
          @click="closeExtended"
        />
        <template #tooltip>
          Zamknij widok rozszerzony
        </template>
      </BaseTooltip>
      <BaseTooltip
        v-else-if="!extended && isDish"
        class="menu-item base-tooltip--bottom base-tooltip--right"
      >
        <IconButton
          name="zoom_out_map"
          icon-size="22"
          @click="showExtened"
        />
        <template #tooltip>
          Edycja zaawansowana i<br>
          wartości odżywcze
        </template>
      </BaseTooltip>
      <BaseTooltip
        v-if="isDish"
        class="menu-item base-tooltip--bottom base-tooltip--right"
      >
        <IconButton
          name="save"
          icon-size="22"
          :disabled="dishSaveInProgress"
          @click="saveDishHandler"
        />
        <template #tooltip>
          Zapisz potrawę do<br>bazy prywatnej
        </template>
      </BaseTooltip>
      <BaseTooltip
        v-if="canDietBeEdited"
        class="menu-item base-tooltip--bottom base-tooltip--right"
      >
        <IconButton
          name="delete"
          icon-size="22"
          variant="outline"
          type="destructive"
          @click="deleteDishOrIngredientHandler"
        />
        <template #tooltip>
          <template v-if="isDish">
            Usuń potrawę z jadłospisu
          </template>
          <template v-else>
            Usuń produkt z jadłospisu
          </template>
        </template>
      </BaseTooltip>
      <BaseTooltip class="menu-item base-tooltip--bottom base-tooltip--right">
        <IconButton
          name="close"
          icon-size="22"
          @click="closeDishOrIngredientHandler"
        />
        <template #tooltip>
          <template v-if="isDish">
            Zamknij widok potrawy
          </template>
          <template v-else>
            Zamknij widok produktu
          </template>
        </template>
      </BaseTooltip>
    </div>
    <template v-if="dishOrIngredientCopy && dishOrIngredientCopy.type === 'dish'">
      <DietDishForm
        :key="refreshDishOrIngredient"
        :mode="'edit'"
        :extended="extended"
        :dish="dishOrIngredientCopy"
        :dish-index="dishIndex"
        :chosen-nutrients="chosenNutrients"
        :losses-multiplier="lossesMultiplier"
        @add-ingredient="addIngredient"
        @delete-ingredient="deleteIngredient"
        @replace-ingredient="replaceIngredient"
        @update-ingredient-in-dish-quantity="updateIngredientDishQuantity"
        @update-name="updateName"
        @update-quantity="updateQuantity"
        @update-size="updateSize"
        @update-used-portions="updateUsedPortions"
        @update-max-portions="updateMaxPortions"
        @update-preparation-time="updatePreparationTime"
        @update-preparation-steps="updatePreparationSteps"
        @update-meal-type="updateMealType"
        @update-dish-type="updateDishType"
        @update-dish-types="updateDishTypes"
      />
    </template>

    <template v-if="dishOrIngredientCopy && dishOrIngredientCopy.type === 'ingredient'">
      <DietIngredientForm
        :key="refreshDishOrIngredient"
        :extended="extended"
        :mode="'edit'"
        :ingredient="dishOrIngredientCopy"
        :chosen-nutrients="chosenNutrients"
        :losses-multiplier="lossesMultiplier"
        @update-quantity="updateQuantity"
        @update-size="updateSize"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { DietIngredient, StoreDietDish } from '@/types/Diet'
import type { Nutrients } from '@/utils/nutrients'

import { A, D, F, O, pipe } from '@mobily/ts-belt'
import { klona } from 'klona'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import BaseTooltip from '@/components/BaseTooltip.vue'
import DietDishForm from '@/components/diets/DietDishForm.vue'
import DietIngredientForm from '@/components/diets/DietIngredientForm.vue'
import { IconButton } from '@/components/IconButton'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'

type Data = {
  ingredient: DietIngredient
  ingredientIndex: number
}

const defaultNutrients: Nutrients[] = [
  'protein',
  'fat',
  'carbohydrates',
  'fiber',
  'carbohydrate_exchanger'
]

const refreshDishOrIngredient = ref(0)
const globalStore = useGlobalStore()
const dietStore = useDietsStore()
const { diet, details, extended, lossesMultiplier, dishSaveInProgress, canDietBeEdited } =
  storeToRefs(dietStore)

const dishIndex = computed(() => {
  if (diet.value && details.value) {
    return `${details.value.dayIndex}-${details.value.mealIndex}-${details.value.dishOrIngredientIndex}`
  }
  return ''
})
const dishOrIngredient = computed(() => {
  if (diet.value && details.value) {
    const { mealIndex, dayIndex, dishOrIngredientIndex } = details.value

    return pipe(
      O.fromNullable(diet.value.data.meals[mealIndex]),
      O.flatMap(D.get('days')),
      O.flatMap(A.get(dayIndex)),
      O.flatMap(D.get('dishes_and_ingredients')),
      O.flatMap(A.get(dishOrIngredientIndex)),
      O.match(F.identity, () => {
        dietStore.updateDetails()
        return undefined
      })
    )
  }
  return undefined
})
const dishOrIngredientCopy = computed(() => {
  return klona(dishOrIngredient.value)
})
const isDish = computed(() => {
  return dishOrIngredient.value?.type === 'dish'
})
const chosenNutrients = computed<Nutrients[]>(() => {
  if (globalStore.hasPerm('accounts.diet_microelements')) {
    return diet.value?.data.choosen_nutrients ?? defaultNutrients
  } else {
    return defaultNutrients
  }
})

const addIngredient = (ingredient: DietIngredient) => {
  if (details.value) {
    dietStore.addIngredientToDish({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      ingredient
    })
  }
}
const replaceIngredient = (data: Data) => {
  if (details.value) {
    dietStore.replaceIngredientInDish({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      ingredientIndex: data.ingredientIndex,
      ingredient: data.ingredient
    })
  }
}
const deleteIngredient = ({ originalIndex }: { originalIndex: number }) => {
  if (details.value) {
    dietStore.deleteIngredientInDish({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      ingredientIndex: originalIndex
    })
  }
}
const updateIngredientDishQuantity = ({
  ingredientIndex,
  quantity
}: {
  ingredientIndex: number
  quantity: number
}) => {
  if (details.value) {
    dietStore.updateIngredientInDishQuantity({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      ingredientIndex,
      quantity
    })
  }
}
const saveDishHandler = () => {
  if (globalStore.hasAddDishPermission && dishOrIngredient.value?.type === 'dish') {
    const dish = dishOrIngredient.value
    if (dish.name) {
      dietStore.updpateDishSaveInProgressStatus(true)
      dietStore.debouncedSaveDish(dish)
    } else {
      globalStore.createMessage({
        title: 'Nazwa potrawy jest wymagana'
      })
    }
  } else {
    globalStore.showAppUpgradePlanModal()
  }
}
const updateName = (name: string) => {
  if (details.value) {
    dietStore.updateDishName({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      name
    })
  }
}
const updateSize = (size: number) => {
  if (details.value) {
    dietStore.updateDishSize({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      size
    })
  }
}
const updateQuantity = (quantity: number) => {
  if (details.value) {
    dietStore.updateIngredientQuantity({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      quantity
    })
    refreshDishOrIngredient.value++
  }
}
const updateUsedPortions = (usedPortions: number) => {
  if (details.value) {
    dietStore.updateDishUsedPortions({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      usedPortions
    })
    refreshDishOrIngredient.value++
  }
}

const updateMaxPortions = (maxPortions: number) => {
  if (details.value) {
    dietStore.updateDishMaxPortions({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      maxPortions
    })
    refreshDishOrIngredient.value++
  }
}

const updatePreparationTime = (preparationTime: number) => {
  if (details.value) {
    dietStore.updateDishPreparationTime({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      preparationTime
    })
  }
}

const updatePreparationSteps = (preparationSteps: string) => {
  if (details.value) {
    dietStore.updateDishPreparationSteps({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      preparationSteps
    })
  }
}

const updateMealType = (mealType: StoreDietDish['meal_types'][number]) => {
  if (details.value) {
    dietStore.updateDishMealType({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      mealType
    })
  }
}

const updateDishType = (dishType: StoreDietDish['dish_types'][number]) => {
  if (details.value) {
    dietStore.updateDishDishType({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      dishType
    })
  }
}
const updateDishTypes = (dishTypesList: StoreDietDish['dish_types']) => {
  if (details.value) {
    dietStore.setDishDishTypes({
      mealIndex: details.value.mealIndex,
      dayIndex: details.value.dayIndex,
      dishOrIngredientIndex: details.value.dishOrIngredientIndex,
      dishTypesList
    })
  }
}
const deleteDishOrIngredientHandler = () => {
  if (details.value) {
    dietStore.deleteDishOrIngredient(
      details.value.mealIndex,
      details.value.dayIndex,
      details.value.dishOrIngredientIndex
    )
    dietStore.updateDetails()
  }
}

const closeDishOrIngredientHandler = () => {
  dietStore.updateDetails()
}
const showExtened = () => {
  dietStore.updateExtended(true)
}
const closeExtended = () => {
  dietStore.updateExtended(false)
}
</script>

<style scoped>
.dish-or-ingredient {
  max-height: calc(100% - 36px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 16px;
  position: relative;
  box-shadow: 2px 4px 4px 0 hsl(0deg 0% 91% / 50%);
}

.menu {
  position: absolute;
  right: 16px;
  top: 21px;
  display: flex;
}

.menu-item {
  margin-left: 6px;
}
</style>
