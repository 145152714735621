import type { VariantPropsOf } from '@/utils/variants'

import { computed, useSlots } from 'vue'

import { T } from '@ui/T'

import BaseIcon from '@/components/BaseIcon.vue'
import { variants } from '@/utils/variants'

import s from './style.module.scss'

const styles = variants({
  base: s.root,
  variants: {
    expanded: {
      true: s.expanded
    },
    disabled: {
      true: s.disabled
    }
  }
})

type Props = VariantPropsOf<typeof styles> & {
  enabled?: boolean
}

export const KcalmarSelectButton = (props: Props) => {
  const slots = useSlots()
  const classes = computed(() => {
    return styles({
      disabled: !(props.enabled ?? true),
      expanded: props.expanded
    })
  })

  return (
    <button type="button" class={classes.value}>
      <T as="span">{slots.default?.()}</T>
      <BaseIcon size="24" name={props.expanded ? 'arrow_drop_up' : 'arrow_drop_down'} />
    </button>
  )
}

KcalmarSelectButton.props = {
  disabled: Boolean,
  expanded: Boolean
}
