<template>
  <KcalmarTable
    ref="tableRef"
    :sticky-height="searchHeight + navigationHeight"
    :columns="columns"
    :data="data"
    :default-sort-by="'name'"
    :is-loading="isLoading"
    :on-row-click="handleRowClick"
  />
</template>
<script lang="ts" setup>
import type { ColumnDef } from '@tanstack/vue-table'
import type { CustomPatient } from './provider'

import { createColumnHelper } from '@tanstack/vue-table'
import { format } from 'date-fns'
import { computed, h } from 'vue'
import { useRouter } from 'vue-router'

import { KcalmarTable } from '@ui/KcalmarTable'
import { TableCellCaption } from '@ui/KcalmarTable/components/TableCellCaption'
import { TableCellTags } from '@ui/KcalmarTable/components/TableCellTags'

import { usePatientsProvider } from './provider'

const { searchHeight, navigationHeight, data, isLoading, tableRef } = usePatientsProvider()
const router = useRouter()

const columnHelper = createColumnHelper<CustomPatient>()
const columns = computed<ColumnDef<CustomPatient>[]>(
  () =>
    [
      columnHelper.accessor((v) => v.name, {
        id: 'name',
        header: 'Pacjent',
        size: 250,
        cell: (v) =>
          h(TableCellCaption, {
            title: v.row.original.name,
            description: v.row.original.email
          }),
        enableHiding: false
      }),
      columnHelper.accessor((v) => v.description, {
        id: 'description',
        header: 'Opis'
      }),
      columnHelper.accessor((v) => v.tags, {
        header: 'Tagi',
        enableSorting: false,
        cell: (v) =>
          h(TableCellTags, {
            tags: v.getValue()
          })
      }),
      columnHelper.accessor((v) => v.calories, {
        id: 'calories',
        header: 'Kcal',
        size: 130
      }),
      columnHelper.accessor((v) => v.added_at, {
        id: 'added_at',
        header: 'Data dodania',
        size: 220,
        cell: (v) =>
          h(TableCellCaption, {
            title: format(v.row.original.added_at, 'dd.MM.yyyy'),
            description: v.row.original.status
          })
      })
    ] as ColumnDef<CustomPatient>[]
)

const handleRowClick = async (patient: CustomPatient) => {
  await router.push({
    name: 'Patient',
    params: { patientId: patient.id }
  })
}
</script>
