import type { AxiosError, AxiosRequestConfig } from 'axios'

import axios from 'axios'

import {
  OFFLINE_ERROR_CONTENT,
  OFFLINE_ERROR_TITLE,
  SERVER_ERROR_CONTENT,
  SERVER_ERROR_TITLE
} from '@/const'
import eventBus from '@/eventBus'
import { authService } from '@/services/authService'

export type Config = AxiosRequestConfig & {
  _queued?: boolean
  _retry?: boolean
  handledError?: boolean
}

type RetryQueueItem = {
  config: AxiosRequestConfig
  reject: (error?: Error) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolve: (value: any) => void
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = []

const attachTokenToRequest = (request: Config) => {
  if (!request.headers) {
    request.headers = {}
  }
  request.headers.Authorization = `Bearer ${authService.getAccessToken()}`
}

// Flag to prevent multiple token refresh requests
let isRefreshing = false

export const api = axios.create({
  baseURL: import.meta.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

api.interceptors.request.use(
  (config) => {
    const accessToken = authService.getAccessToken()
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    throw error
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error: AxiosError & { config: Config }) => {
    if (error.config._retry || error.config._queued) {
      throw error
    }

    if (error.code === 'ERR_NETWORK') {
      eventBus.emit('showError', {
        error,
        title: OFFLINE_ERROR_TITLE,
        content: OFFLINE_ERROR_CONTENT
      })
    } else {
      if (error.response) {
        const originalRequest: Config = error.config

        if (error.response.status === 401) {
          if (!isRefreshing) {
            isRefreshing = true
            originalRequest._retry = true

            try {
              await authService.refreshAccessToken()
              attachTokenToRequest(originalRequest)
              isRefreshing = false
              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                attachTokenToRequest(config)

                // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
                api.request(config).then(resolve).catch(reject)
              })

              refreshAndRetryQueue.length = 0

              return await api.request(originalRequest)
            } catch {
              isRefreshing = false
              authService.deleteTokens()
              authService.redirectToLogoutPage()
            }
            return
          } else {
            return new Promise<void>((resolve, reject) => {
              originalRequest._queued = true
              refreshAndRetryQueue.push({
                config: originalRequest,
                resolve,
                reject
              })
            })
          }
        } else if (!error.config.handledError) {
          eventBus.emit('showError', {
            error,
            title: SERVER_ERROR_TITLE,
            content: `${SERVER_ERROR_CONTENT}<br/><br/>Error: ${JSON.stringify(error)}`
          })
        }
      } else {
        eventBus.emit('showError', { error })
      }
    }
    throw error
  }
)
