import { A, D, F, G, O, pipe } from '@mobily/ts-belt'
import { BroadcastChannel } from 'broadcast-channel'
import { differenceInYears, format, parse } from 'date-fns'
import { klona } from 'klona'
import round from 'lodash/round'
import { compressToUTF16, decompressFromUTF16 } from 'lz-string'
import { defineStore } from 'pinia'
import { match } from 'ts-pattern'
import { computed, ref } from 'vue'

import { dietsService } from '@/services/dietsService'
import { dishService } from '@/services/dishService'
import { queryClient } from '@/services/queryClient'
import type {
  AllergensMap,
  Comment,
  DietCalculationData,
  DietCalculationType,
  DietData,
  DietDish,
  DietDisplayType,
  DietIngredient,
  DietSearchDishIngredient,
  DietSearchDishOrIngredient,
  DishInFormType,
  DishSearchFilters,
  PatientDiet,
  PatientGender,
  PatientLactationPeriod,
  PatientPregnancyTrimester,
  RelatedDish,
  Schedule,
  ScheduleDishOrIngredient,
  StoreDiet,
  StoreDietDish,
  StoreDietMeal,
  StoreDietMealDay,
  SystemDiet,
  UniversityDiet,
  UserDiet
} from '@/types/Diet'
import { clamp } from '@/utils/common'
import { normsNutrients, normsRanges } from '@/utils/norms'
import type { NutrientExtended, Nutrients } from '@/utils/nutrients'
import {
  basicNutrients as BasicNutrients,
  specificNutrients as SpecificNutrients
} from '@/utils/nutrients'
import { reportError } from '@/utils/reportError'
import { stringify } from '@/utils/stringify'

import { useGlobalStore } from './globalStore'
import type { DietDetails, DietsStoreState, DietType, DragMode, SaveStatus } from './types'

const getRepeatedDishesIngredient = () =>
  pipe(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    O.fromExecution(() => JSON.parse(localStorage.getItem('repeatedDishes') ?? '[]')),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    O.flatMap((v) => O.fromPredicate(v, G.isArray)),
    O.getWithDefault([] as number[])
  )

type CopyDay = [StoreDietMealDay['dishes_and_ingredients'], StoreDietMealDay['comments']]
type Message =
  | {
      diet: DietsStoreState['diet']
      type: 'updateDiet'
    }
  | {
      id: number
      type: 'createUndo'
    }
  | {
      id: number
      type: 'undo'
    }
  | {
      id: number
      type: 'redo'
    }
  | {
      data: {
        id: number
        value: boolean
      }
      type: 'setRepeatedDishesActive'
    }
  | {
      data: {
        id: number
        value: boolean
      }
      type: 'setIsDietVisible'
    }

const DietsStoreChannel = new BroadcastChannel<Message>('dietsStore')

// @ts-ignore-error - temporary solution
export const makeDietStore = () => {
  const diet = ref<StoreDiet | undefined>(undefined)
  const dietType = ref<DietType | undefined>(undefined)
  const schedule = ref<Schedule | undefined>(undefined)
  const basicNutrients = ref<NutrientExtended[]>([])
  const chosenNutrients = ref<NutrientExtended[]>([])
  const specificNutrients = ref<NutrientExtended[]>([])
  const relatedDishes = ref<Record<number | string, RelatedDish> | undefined>(undefined)
  const repeatedDishesAndIngredients = ref<string[]>([])
  const repeatedDishesAndIngredientsActive = ref<boolean>(false)
  const isDietVisible = ref<boolean>(true)
  const lossesMultiplier = ref<number>(1)
  const patientAge = ref<number>(30)
  const copiedDietStatus = ref<boolean>(false)
  const copiedDietCalories = ref<number>(2000)
  const copiedDayStatus = ref<boolean>(false)
  const dragMode = ref<string>('move')
  const incompleteDataInfo = ref<boolean>(false)
  const incompleteDataInfoClosed = ref<boolean>(false)
  const allergensInfo = ref<boolean>(false)
  const averageActive = ref<boolean>(true)
  const allergensClosed = ref<boolean>(false)
  const lossesClosed = ref<boolean>(false)
  const extended = ref<boolean>(false)
  const details = ref<DietDetails | undefined>(undefined)
  const dietSaveStatus = ref<SaveStatus>('initialized')
  const dietSaveInProgress = ref<boolean>(false)
  const dietSaveNext = ref<boolean>(false)
  const dishSaveInProgress = ref<boolean>(false)
  const undoHistory = ref<StoreDiet[]>([])
  const redoHistory = ref<StoreDiet[]>([])

  const canDietBeEdited = computed(() => {
    if (dietType.value) {
      return ['patient', 'user', 'university'].includes(dietType.value)
    }
    return false
  })
  const dietAllergens = computed<AllergensMap>(() => {
    const emptyAllergens = {
      allergies_id: [],
      intolerances_id: [],
      like_id: [],
      not_like_id: []
    }
    if (diet.value && 'allergens' in diet.value) {
      return diet.value.allergens
    }
    return emptyAllergens
  })
  const patientId = computed(() => {
    if (diet.value && 'dietitian_patient' in diet.value) {
      return diet.value.dietitian_patient.patient
    }
    return undefined
  })
  const patientName = computed(() => {
    if (diet.value && 'dietitian_patient' in diet.value) {
      return diet.value.dietitian_patient.patient_name || ''
    }
    return undefined
  })
  const caloriesFromNorms = computed(() => {
    const patient = diet.value?.data.patient
    let calories = 0
    let extraCalories = 0
    if (patient) {
      calories = 10 * patient.weight + 6.25 * patient.height - 5 * patientAge.value
      if (patient.gender === 'male') {
        calories += 5
      } else {
        calories -= 161
        if (patient.pregnancy.active) {
          if (patient.pregnancy.trimester === 'two') extraCalories += 360
          if (patient.pregnancy.trimester === 'three') extraCalories += 475
        }
        if (patient.lactation.active) {
          if (patient.lactation.period === 'zero_six_months') extraCalories += 505
          if (patient.lactation.period === 'above_six_months') extraCalories += 400
        }
      }
      calories = round(calories * patient.activity_level) + extraCalories
    }
    return calories
  })
  const getRelatedCoordinates = computed(() => {
    // Simple wrapper for dietsService
    return (mealIndex: number, dayIndex: number, dishOrIngredientIndex: number) => {
      return dietsService.getRelatedCoordinates(
        relatedDishes.value,
        diet.value?.data.meals,
        mealIndex,
        dayIndex,
        dishOrIngredientIndex
      )
    }
  })
  const hasDetails = computed(() => {
    return Boolean(diet.value?.data)
  })

  const sendProductToFile = async ({
    email,
    productName,
    productId
  }: {
    email: string
    productId: number
    productName: string
  }) => {
    const product_ids: number[] = [
      128280, 128285, 128286, 128288, 128291, 128293, 128294, 128296, 128298, 128283
    ]
    if (
      productName.includes('USP Zdrowie') ||
      productName.includes('Naturell') ||
      product_ids.includes(productId)
    ) {
      const productToSend = {
        type: 'products',
        user: email,
        name: productName
      }
      try {
        await dietsService.saveUsedUspOrNaturellProductInFile(productToSend)
      } catch (err) {
        reportError(err, 'Error while sending product to file', {
          productToSend
        })
      }
    }
  }
  const updateNutrients = () => {
    const dietNormsPermission = useGlobalStore().hasPerm('accounts.diet_norms_edit_values')

    if (diet.value) {
      const dayNutrients = Object.freeze(
        dietsService.getDayNutrients(diet.value, lossesMultiplier.value)
      )
      const chosenNutrientsKeys = A.difference(diet.value.data.choosen_nutrients, BasicNutrients)
      const specificNutrientsKeys = A.difference(
        SpecificNutrients,
        diet.value.data.choosen_nutrients
      )
      basicNutrients.value = dietsService.getNutrients(
        BasicNutrients,
        dayNutrients,
        diet.value.data.norms,
        dietNormsPermission
      )

      chosenNutrients.value = dietsService.getNutrients(
        chosenNutrientsKeys,
        dayNutrients,
        diet.value.data.norms,
        dietNormsPermission
      )

      specificNutrients.value = dietsService.getNutrients(
        specificNutrientsKeys,
        dayNutrients,
        diet.value.data.norms,
        dietNormsPermission
      )

      incompleteDataInfo.value = dayNutrients.incompleteDataInfo
      allergensInfo.value = dayNutrients.allergensInfo
    }
  }
  const resetDietsStore = () => {
    diet.value = undefined
    dietType.value = undefined
    schedule.value = undefined
    basicNutrients.value = []
    chosenNutrients.value = []
    specificNutrients.value = []
    relatedDishes.value = undefined
    repeatedDishesAndIngredients.value = []
    repeatedDishesAndIngredientsActive.value = false
    isDietVisible.value = true
    lossesMultiplier.value = 1
    patientAge.value = 30
    copiedDietStatus.value = false
    copiedDietCalories.value = 2000
    copiedDayStatus.value = false
    dragMode.value = 'move'
    incompleteDataInfo.value = false
    incompleteDataInfoClosed.value = false
    allergensInfo.value = false
    averageActive.value = true
    allergensClosed.value = false
    lossesClosed.value = false
    extended.value = false
    details.value = undefined
    dietSaveStatus.value = 'initialized'
    dietSaveInProgress.value = false
    dietSaveNext.value = false
    dishSaveInProgress.value = false
    undoHistory.value = []
    redoHistory.value = []
  }

  const getParsedPatientBirthdate = (birthdate: string) => {
    if (birthdate.includes('-')) {
      return parse(birthdate, 'dd-MM-yyyy', new Date())
    } else if (birthdate.includes('.')) {
      return parse(birthdate, 'dd.MM.yyyy', new Date())
    } else {
      return new Date()
    }
  }
  const initDiets = (skipMessages = false) => {
    let _lossesMultiplier = 1
    let _patientAge = 30
    if (diet.value) {
      // Calculate lossesMultiplier
      if (diet.value.data.inevitable_losses.active) {
        _lossesMultiplier = round(1 - diet.value.data.inevitable_losses.value / 100, 2)
      }

      // Calculate patientAge
      const parsedBirthdate = getParsedPatientBirthdate(diet.value.data.patient.birthdate)
      diet.value.data.patient.birthdate = format(parsedBirthdate, 'dd-MM-yyyy')
      _patientAge = differenceInYears(new Date(), parsedBirthdate)
    }
    lossesMultiplier.value = _lossesMultiplier
    patientAge.value = _patientAge
    if (skipMessages) {
      allergensClosed.value = false
      lossesClosed.value = false
      incompleteDataInfo.value = false
      incompleteDataInfoClosed.value = false
    }

    if (diet.value && 'is_diet_visible' in diet.value) {
      isDietVisible.value = diet.value.is_diet_visible
    }

    const repeatedDishesActive = getRepeatedDishesIngredient()
    repeatedDishesAndIngredientsActive.value = repeatedDishesActive.includes(diet.value?.id ?? -1)
    averageActive.value = localStorage.getItem('averageActive') !== 'false'
    copiedDayStatus.value = !!localStorage.getItem('copiedDay')
    const copiedDiet = localStorage.getItem('copiedDiet')
    if (copiedDiet) {
      try {
        const parsedDiet = JSON.parse(decompressFromUTF16(copiedDiet)) as StoreDiet | undefined
        if (parsedDiet) {
          copiedDietStatus.value = true
          copiedDietCalories.value = parsedDiet.data.norms.calories
        }
      } catch (error) {
        if (error instanceof SyntaxError) {
          localStorage.removeItem('copiedDiet')
        } else {
          throw error
        }
      }
    }
  }
  const updateInevitableLossesActive = (active: boolean, skipSaveDiet = false) => {
    if (diet.value) {
      diet.value.data.inevitable_losses.active = active
      if (diet.value.data.inevitable_losses.active) {
        lossesMultiplier.value = round(1 - diet.value.data.inevitable_losses.value / 100, 2)
      } else {
        lossesMultiplier.value = 1
      }

      if (!skipSaveDiet) {
        updateSchedule()
        updateNutrients()
        saveDiet()
      }
    }
  }
  const updateRelatedDishes = () => {
    if (diet.value) {
      const hashes = dietsService.getHashes(diet.value.data.meals)
      relatedDishes.value = Object.freeze(dietsService.getRelatedDishes(hashes))
    } else {
      relatedDishes.value = undefined
    }
  }
  const updateNorms = () => {
    if (diet.value) {
      // Diet data
      const norms = diet.value.data.norms
      const calculation = diet.value.data.calculation
      // Patient data
      const gender = diet.value.data.patient.gender
      let weight = diet.value.data.patient.weight

      // Update protein, fat, carbohydrates norms
      let protein, fat, carbohydrates
      weight = weight > 0 ? weight : 0.1 // Fix weight if needed
      if (calculation.type === 'sport') {
        // Update norms based on sport calculation type
        const sport = calculation.sport
        protein = weight * sport.protein
        fat = weight * sport.fat
        carbohydrates = weight * sport.carbohydrates

        const energyProtein = protein * 4
        const energyFat = fat * 9
        const energyCarbohydrates = carbohydrates * 4

        norms.calories = round(energyProtein + energyFat + energyCarbohydrates)
      } else {
        // Update norms based on standard calculation type
        const standard = calculation.standard
        protein = (norms.calories * standard.protein) / 100 / 4
        fat = (norms.calories * standard.fat) / 100 / 9
        carbohydrates = (norms.calories * standard.carbohydrates) / 100 / 4
      }
      // Round norms
      protein = round(protein)
      fat = round(fat)
      carbohydrates = round(carbohydrates)
      // Update state values
      norms.protein.inputed = protein
      norms.protein.calculated = protein
      norms.fat.inputed = fat
      norms.fat.calculated = fat
      norms.carbohydrates.inputed = carbohydrates
      norms.carbohydrates.calculated = carbohydrates

      // Update other norms
      const normIndex = dietsService.getNormIndex(diet.value.data.patient, patientAge.value)
      A.forEach(normsNutrients, (nutrientKey) => {
        let norm = null // Norm is default null what means that there is no data for it
        // Get norms based on tables
        const genderNorms = normsRanges[gender]
        if (nutrientKey in genderNorms) {
          norm = genderNorms[nutrientKey as keyof (typeof normsRanges)['male']][normIndex]
        }

        // Handle edge cases
        if (nutrientKey === 'energy_value_kj') {
          norm = round(norms.calories * 4.184)
        } else if (nutrientKey === 'fiber') {
          norm = round((10 * norms.calories) / 1000)
        } else if (nutrientKey === 'c_18_2') {
          norm = round(((4 * norms.calories) / 100 / 9) * 10) / 10
        } else if (nutrientKey === 'n_3') {
          let normC205 = 0
          let normC226 = 0
          if (normsRanges[gender].c_20_5[normIndex])
            normC205 = normsRanges[gender].c_20_5[normIndex] ?? 0
          if (normsRanges[gender].c_22_6[normIndex])
            normC226 = normsRanges[gender].c_22_6[normIndex] ?? 0
          norm = round(normC205 + normC226, 2)
        } else if (nutrientKey === 'n_6') {
          const normC182 = round(((4 * norms.calories) / 100 / 9) * 10) / 10
          let normC204 = 0
          // Brak normy c_18_2
          // if (has(normsRanges, [gender, 'c_18_2', normIndex]))
          //   normC182 = normsRanges[gender].c_18_2[normIndex] ?? 0
          if (normsRanges[gender].c_20_4[normIndex])
            normC204 = normsRanges[gender].c_20_4[normIndex] ?? 0
          norm = round(normC182 + normC204, 2)
        }
        // Update norms in the store
        if (diet.value) {
          if (nutrientKey in norms) {
            const inputed = norms[nutrientKey].inputed
            const calculated = norms[nutrientKey].calculated
            if (inputed === calculated || (G.isNullable(calculated) && inputed === 0)) {
              diet.value.data.norms[nutrientKey].inputed = norm ?? 0
            }
            diet.value.data.norms[nutrientKey].calculated = norm ?? 0
          } else {
            diet.value.data.norms[nutrientKey] = {
              calculated: norm ?? 0,
              inputed: norm ?? 0
            }
          }
        }
      })
    }
  }
  const updateSchedule = () => {
    if (diet.value) {
      let _repeatedDishesAndIngredients: string[] = []
      if (repeatedDishesAndIngredientsActive.value) {
        _repeatedDishesAndIngredients = repeatedDishesAndIngredients.value
      }
      schedule.value = Object.freeze(
        dietsService.getSchedule(
          diet.value.data.meals,
          diet.value.data.norms.calories,
          diet.value.data.energy_distribution,
          diet.value.data.choosen_nutrients,
          lossesMultiplier.value,
          _repeatedDishesAndIngredients
        )
      )
    } else {
      schedule.value = undefined
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _saveDiet = async (source: string, sendMessage: boolean): Promise<any> => {
    if (dietSaveInProgress.value && source !== 'next') {
      dietSaveNext.value = true
    } else {
      if (diet.value && dietType.value) {
        dietSaveInProgress.value = true
        try {
          const response = await dietsService.saveDiet(diet.value, dietType.value)
          if (response.status === 200) {
            if (dietSaveNext.value) {
              dietSaveNext.value = false

              return await _saveDiet('next', sendMessage)
            } else {
              dietSaveInProgress.value = false
              dietSaveStatus.value = 'success'
              if (sendMessage) {
                await DietsStoreChannel.postMessage({
                  type: 'updateDiet',
                  diet: klona(diet.value)
                })
              }
            }
          } else {
            dietSaveInProgress.value = false
            dietSaveNext.value = false
            dietSaveStatus.value = 'failed'
          }
          return response
        } catch (error) {
          dietSaveInProgress.value = false
          dietSaveNext.value = false
          dietSaveStatus.value = 'failed'
          reportError(error, 'Error while saving diet')
          throw error
        }
      }
    }
  }

  const debouncedSaveDiet = F.debounce((source: string, sendMessage: boolean) => {
    _saveDiet(source, sendMessage).catch(() => {
      dietSaveStatus.value = 'error'
    })
  }, 750)
  const saveDiet = (source = 'first', sendMessage = true) => {
    dietSaveStatus.value = 'inProgress'
    debouncedSaveDiet(source, sendMessage)
  }
  const updateDiet = ({
    diet: _diet,
    dietType: _dietType = undefined,
    resetState = false,
    skipMessages = false
  }: {
    diet: PatientDiet | SystemDiet | UniversityDiet | UserDiet
    dietType?: DietType
    resetState?: boolean
    skipMessages?: boolean
  }) => {
    if (resetState) {
      resetDietsStore()
    }
    if (_dietType) {
      dietType.value = _dietType
    }
    // TODO - transform data to StoreDiet
    diet.value = _diet as StoreDiet
    initDiets(skipMessages)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (diet.value) {
      const hasPerm = useGlobalStore().hasPerm
      if (!hasPerm('accounts.diet_inevitable_losses')) {
        updateInevitableLossesActive(false, true)
      }
      if (!hasPerm('accounts.diet_norms')) {
        diet.value.data.norms.active = false
      }
      if (!hasPerm('accounts.diet_microelements')) {
        diet.value.data.choosen_nutrients = [
          'protein',
          'fat',
          'carbohydrates',
          'fiber',
          'carbohydrate_exchanger'
        ]
      }
      updateRelatedDishes()
      updateNorms()
      updateSchedule()
      updateNutrients()
    }
  }
  const copyDiet = () => {
    if (diet.value) {
      copiedDietStatus.value = true
      copiedDietCalories.value = diet.value.data.norms.calories || 0
      localStorage.setItem('copiedDiet', compressToUTF16(stringify(diet.value)))
      useGlobalStore().createMessage({
        title: 'Jadłospis został skopiowany'
      })
    }
  }
  const createUndo = (sendMessage = true) => {
    redoHistory.value = []
    if (undoHistory.value.length >= 5) {
      undoHistory.value.shift()
    }
    if (diet.value) {
      undoHistory.value.push(klona(diet.value) as StoreDiet)
      if (sendMessage) {
        void DietsStoreChannel.postMessage({
          id: diet.value.id,
          type: 'createUndo'
        })
      }
    }
  }
  const clearUndoRedo = () => {
    redoHistory.value = []
    undoHistory.value = []
  }
  const undo = (sendMessage = true) => {
    if (diet.value) {
      const copiedDiet = klona(diet.value)
      if (redoHistory.value.length >= 5) {
        redoHistory.value.pop()
      }
      redoHistory.value.unshift(copiedDiet)

      const previousDiet = undoHistory.value[undoHistory.value.length - 1]

      if (previousDiet) {
        updateDiet({
          diet: previousDiet
        })
        undoHistory.value.pop()
      }
      saveDiet(undefined, sendMessage)
      if (sendMessage) {
        void DietsStoreChannel.postMessage({
          id: diet.value.id,
          type: 'undo'
        })
      }
    }
  }
  const redo = (sendMessage = true) => {
    if (diet.value) {
      const copiedDiet = klona(diet.value)

      if (undoHistory.value.length >= 5) {
        undoHistory.value.shift()
      }
      undoHistory.value.push(copiedDiet)

      const nextDiet = redoHistory.value[0]

      if (nextDiet) {
        updateDiet({
          diet: nextDiet
        })

        redoHistory.value.shift()
      }
      saveDiet()
      if (sendMessage) {
        void DietsStoreChannel.postMessage({
          id: diet.value.id,
          type: 'redo'
        })
      }
    }
  }
  const pasteDiet = (calories: number | string) => {
    createUndo()
    const localCopiedDiet = localStorage.getItem('copiedDiet')
    if (localCopiedDiet) {
      // Prepare data
      try {
        const copiedDiet = JSON.parse(decompressFromUTF16(localCopiedDiet)) as StoreDiet | undefined
        if (copiedDiet?.data) {
          const copiedData = copiedDiet.data
          const copiedCalories = copiedData.norms.calories

          // Delete data that we do not need
          Reflect.deleteProperty(copiedData, 'id')
          Reflect.deleteProperty(copiedData, 'group')
          Reflect.deleteProperty(copiedData, 'name')
          Reflect.deleteProperty(copiedData, 'start_date')

          // Check if calories should be scaled
          const newCalories = Number.parseInt(calories.toString())
          if (isNaN(newCalories)) {
            throw new Error('Calories are not a number')
          }
          if (newCalories !== copiedCalories) {
            copiedData.norms.calories = newCalories
            const caloriesMultiplier = newCalories / copiedCalories
            if (copiedData.calculation.type === 'sport') {
              const sportCalculations = copiedData.calculation.sport
              sportCalculations.protein = round(sportCalculations.protein * caloriesMultiplier, 2)
              sportCalculations.fat = round(sportCalculations.fat * caloriesMultiplier, 2)
              sportCalculations.carbohydrates = round(
                sportCalculations.carbohydrates * caloriesMultiplier,
                2
              )
              copiedData.calculation.sport = sportCalculations
            }
            const meals = copiedData.meals
            for (const meal of meals) {
              const days = meal.days
              for (const day of days) {
                const dishesAndIngredients = day.dishes_and_ingredients
                for (const dishOrIngredient of dishesAndIngredients) {
                  if (dishOrIngredient.type === 'dish') {
                    const ingredients = dishOrIngredient.ingredients
                    for (const ingredient of ingredients) {
                      ingredient.quantity = round(ingredient.quantity * caloriesMultiplier, 1)
                    }
                  } else {
                    dishOrIngredient.quantity = round(
                      dishOrIngredient.quantity * caloriesMultiplier,
                      1
                    )
                  }
                }
              }
            }
          }
          if (diet.value && 'dietitian_patient' in diet.value) {
            copiedDiet.data.patient = diet.value.data.patient
          }
          if (diet.value) {
            const actualDiet = klona(diet.value)
            const mergedData = D.merge(actualDiet.data, copiedDiet.data) as StoreDiet['data']

            actualDiet.data = mergedData
            updateDiet({ diet: actualDiet })
          } else {
            updateDiet({ diet: copiedDiet })
          }

          saveDiet()
          localStorage.removeItem('copiedDiet')
        }
      } catch (e) {
        reportError(e, 'Error while pasting diet')
      }
    }
    copiedDietStatus.value = false
    copiedDietCalories.value = diet.value?.data.norms.calories ?? 0
    useGlobalStore().createMessage({ title: 'Jadłospis został wklejony' })
  }
  const deleteCopiedDiet = () => {
    copiedDietStatus.value = false
    copiedDietCalories.value = diet.value?.data.norms.calories ?? 0
    localStorage.removeItem('copiedDiet')
    useGlobalStore().createMessage({ title: 'Schowek został wyczyszczony' })
  }
  const deleteDiet = () => {
    if (diet.value) {
      const meals = diet.value.data.meals
      A.forEach(meals, (meal) => {
        A.forEach(meal.days, (day) => {
          day.dishes_and_ingredients = []
          day.comments = []
        })
      })
      diet.value.data.week_comment = ''
    }
    updateRelatedDishes()
    updateNorms()
    updateSchedule()

    updateNutrients()
    useGlobalStore().createMessage({ title: 'Wyczyszczono jadłospis' })
    saveDiet()
  }

  const updateDietName = (name: string) => {
    if (diet.value) {
      diet.value.data.name = name
      saveDiet()
    }
  }
  const updateDietDescription = (description: string) => {
    if (diet.value) {
      diet.value.data.description = description
      saveDiet()
    }
  }
  const updateDishSearchFilters = ({
    key,
    value
  }: { key: keyof DishSearchFilters; value: boolean }) => {
    if (diet.value) {
      diet.value.data.dish_search_filters[key] = value
      saveDiet()
    }
  }
  const updatechosenNutrients = (_chosenNutrients: Nutrients[]) => {
    if (diet.value) {
      diet.value.data.choosen_nutrients = _chosenNutrients
      commitDiet()
    }
  }
  const updateEnergyDistribution = (energyDistribution: boolean) => {
    if (diet.value) {
      diet.value.data.energy_distribution = energyDistribution
      commitDiet()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const debouncedSaveDish = F.debounce(async (dish: DietDish | StoreDietDish | DishInFormType) => {
    if (diet.value) {
      await dishService.createDish(dish)

      const globalStore = useGlobalStore()
      globalStore.incrementDishesPrivateCount()
      globalStore.createMessage({
        title: 'Zapisano potrawę w bazie potraw'
      })
      await queryClient.refetchQueries({
        queryKey: ['searchDishesAndIngredients'],
        exact: false
      })

      dishSaveInProgress.value = false
    }
  }, 750)
  const updpateDishSaveInProgressStatus = (status: boolean) => {
    dishSaveInProgress.value = status
  }
  const commitDiet = () => {
    updateSchedule()
    updateNutrients()
    saveDiet()
  }
  const updateNormsActive = (active: boolean) => {
    if (diet.value) {
      diet.value.data.norms.active = active
    }
    commitDiet()
  }
  const updateNorm = ({ nutrient, value }: { nutrient: Nutrients; value: number }) => {
    if (diet.value) {
      createUndo()
      if (nutrient === 'calories') {
        diet.value.data.norms.calories = value
      } else {
        if (nutrient in diet.value.data.norms) {
          diet.value.data.norms[nutrient].inputed = value
        }
      }
      updateNorms()
      commitDiet()
    }
  }
  const createMeal = () => {
    if (diet.value) {
      diet.value.data.meals.push({
        name: 'Śniadanie',
        hour: '23:30',
        energy_distribution: 0,
        type: 1,
        days: [
          { hour: '23:30', dishes_and_ingredients: [], comments: [] },
          { hour: '23:30', dishes_and_ingredients: [], comments: [] },
          { hour: '23:30', dishes_and_ingredients: [], comments: [] },
          { hour: '23:30', dishes_and_ingredients: [], comments: [] },
          { hour: '23:30', dishes_and_ingredients: [], comments: [] },
          { hour: '23:30', dishes_and_ingredients: [], comments: [] },
          { hour: '23:30', dishes_and_ingredients: [], comments: [] }
        ]
      })
      updateRelatedDishes()
      commitDiet()
    }
  }
  const updateMealName = (mealIndex: number, name: string) => {
    if (diet.value) {
      const meal = diet.value.data.meals[mealIndex]

      if (meal) {
        meal.name = name
      }
      commitDiet()
    }
  }
  const updateMealHour = (mealIndex: number, hour: string) => {
    if (diet.value) {
      const meals = diet.value.data.meals
      // Copy comments for later use
      const comments = A.makeWithIndex(meals.length, (index) => {
        return meals[index]?.days.map((day) => klona(day.comments))
      })
      // Update meal hour
      const meal = meals[mealIndex]
      if (meal) {
        meal.hour = hour
        A.forEach(meal.days, (day) => {
          day.hour = hour
        })
      }
      // Sort meals
      meals.sort((a, b) => {
        const hourA = new Date('1970-01-01T' + a.hour + 'Z')
        const hourB = new Date('1970-01-01T' + b.hour + 'Z')
        if (hourA < hourB) return -1
        if (hourA > hourB) return 1
        return 0
      })
      // Set comments from old order
      A.forEachWithIndex(meals, (currentMealIndex, { days }) => {
        A.forEachWithIndex(days, (dayIndex, day) => {
          const dayComments = comments[currentMealIndex]?.[dayIndex] ?? []
          day.comments = [...dayComments]
        })
      })
      commitDiet()
    }
  }
  const updateMealType = (mealIndex: number, type: StoreDietMeal['type']) => {
    const meal = diet.value?.data.meals[mealIndex]
    if (meal) {
      meal.type = type
      commitDiet()
    }
  }

  const updateMealEnergyDistribution = (mealIndex: number, energyDistribution: number) => {
    const meals = diet.value?.data.meals
    const meal = meals?.[mealIndex]
    if (meal) {
      meal.energy_distribution = energyDistribution
      dietsService.mealsEnergyDistribution(meals, mealIndex)
      commitDiet()
    }
  }
  const deleteMeal = (mealIndex: number) => {
    if (diet.value) {
      const meals = diet.value.data.meals
      if (meals.length) {
        // Save first comment for later use
        const firstComments: (Comment | null)[] = []
        if (mealIndex === 0) {
          A.forEach(meals[0]?.days ?? [], (day) => {
            const comment = day.comments[0] ?? null
            firstComments.push(klona(comment))
          })
        }
        meals.splice(mealIndex, 1)
        dietsService.mealsEnergyDistribution(meals, mealIndex - 1)
        // Set first comment if first dish was removed
        if (mealIndex === 0) {
          A.forEachWithIndex(meals[0]?.days ?? [], (dayIndex, day) => {
            const dayComments = day.comments
            if (dayComments.length === 0) {
              dayComments.unshift(null)
            } // Add second comment if no comment exists
            if (dayComments.length < 2) {
              dayComments.unshift(null)
            } // Add first comment if there are not 2 comments
            if (firstComments[dayIndex] && G.isArray(dayComments)) {
              dayComments[0] = firstComments[dayIndex] ?? null // Set first comment
            }
            if (G.isNullable(dayComments[0]) && G.isNullable(dayComments[1])) dayComments.splice(0) // Delete comments if 2 are null
          })
        }
      }
      updateRelatedDishes()
      commitDiet()
    }
  }
  const copyDay = (dayIndex: number) => {
    if (diet.value) {
      const meals = diet.value.data.meals
      const copiedDay = A.map(meals, (meal) => {
        const dishesAndIngredients = meal.days[dayIndex]?.dishes_and_ingredients
        const comments = meal.days[dayIndex]?.comments
        return [dishesAndIngredients, comments] as CopyDay
      })
      localStorage.setItem('copiedDay', compressToUTF16(stringify(copiedDay)))
      copiedDayStatus.value = true
      useGlobalStore().createMessage({ title: 'Skopiowano dzień' })
    }
  }
  const pasteDay = (dayIndex: number) => {
    const copiedDay = localStorage.getItem('copiedDay')
    if (copiedDay && diet.value) {
      try {
        const parsedDiet = (JSON.parse(decompressFromUTF16(copiedDay)) ?? []) as CopyDay[]
        createUndo()
        const meals = diet.value.data.meals
        const lastMealIndex = meals.length - 1
        A.forEachWithIndex(parsedDiet, (mealIndex, day) => {
          const [dishesAndIngredients, comments] = day
          const currentDay = diet.value?.data.meals[mealIndex]?.days[dayIndex]
          if (lastMealIndex >= mealIndex && currentDay) {
            currentDay.dishes_and_ingredients = dishesAndIngredients
            currentDay.comments = comments
          }
        })
        updateRelatedDishes()
        commitDiet()
      } catch (e) {
        reportError(e, 'Error while pasting day')
      }
    }
    useGlobalStore().createMessage({ title: 'Wklejono dzień' })
  }
  const deleteDay = (dayIndex: number) => {
    if (diet.value) {
      createUndo()
      const meals = diet.value.data.meals
      A.forEach(meals, (meal) => {
        const day = meal.days[dayIndex]
        if (day) {
          day.dishes_and_ingredients = []
        }
      })
      updateRelatedDishes()
      commitDiet()
      useGlobalStore().createMessage({ title: 'Usunięto dzień' })
    }
  }

  const updateDayHour = (mealIndex: number, dayIndex: number, dayHour: string) => {
    if (diet.value) {
      const day = diet.value.data.meals[mealIndex]?.days[dayIndex]
      if (day) {
        createUndo()
        day.hour = dayHour
        updateSchedule()
        saveDiet()
      }
    }
  }
  const addDish = (dish: StoreDietDish, mealIndex: number, dayIndex: number) => {
    if (diet.value) {
      createUndo()
      diet.value.data.meals[mealIndex]?.days[dayIndex]?.dishes_and_ingredients.push(dish)
      updateRelatedDishes()
      commitDiet()
    }
  }
  const addIngredient = (ingredient: DietIngredient, mealIndex: number, dayIndex: number) => {
    if (diet.value) {
      createUndo()
      ingredient.quantity = ingredient.grams
      diet.value.data.meals[mealIndex]?.days[dayIndex]?.dishes_and_ingredients.push(ingredient)
      commitDiet()
    }
  }
  const replaceIngredient = (oldIngredientId: number, newIngredient: DietIngredient) => {
    createUndo()

    if (diet.value) {
      const meals = diet.value.data.meals
      A.forEach(meals, (meal) => {
        A.forEach(meal.days, (day) => {
          A.forEachWithIndex(
            day.dishes_and_ingredients,
            (dishOrIngredientIndex, dishOrIngredient) => {
              if (dishOrIngredient.type === 'dish') {
                const ingredients = dishOrIngredient.ingredients
                A.forEachWithIndex(ingredients, (ingredientIndex, ingredient) => {
                  if (ingredient.ingredient_id === oldIngredientId) {
                    const newIngredientClone = klona(newIngredient)
                    newIngredientClone.quantity = ingredient.quantity
                    ingredients.splice(ingredientIndex, 1, klona(newIngredientClone))
                  }
                })
              } else {
                if (dishOrIngredient.ingredient_id === oldIngredientId) {
                  const newIngredientClone = klona(newIngredient)
                  newIngredientClone.quantity = dishOrIngredient.quantity
                  day.dishes_and_ingredients.splice(dishOrIngredientIndex, 1, newIngredientClone)
                }
              }
            }
          )
        })
      })
    }
    updateRelatedDishes()
    commitDiet()
  }
  const moveDishOrIngredient = (
    targetMealIndex: number,
    targetDayIndex: number,
    mealIndex: number,
    dayIndex: number,
    dishOrIngredientIndex: number
  ) => {
    if (!isNaN(mealIndex) && diet.value) {
      createUndo()

      const dishOrIngredient =
        diet.value.data.meals[mealIndex]?.days[dayIndex]?.dishes_and_ingredients[
          dishOrIngredientIndex
        ]
      if (dishOrIngredient) {
        diet.value.data.meals[targetMealIndex]?.days[targetDayIndex]?.dishes_and_ingredients.push(
          dishOrIngredient
        )
        diet.value.data.meals[mealIndex]?.days[dayIndex]?.dishes_and_ingredients.splice(
          dishOrIngredientIndex,
          1
        )
      }
      updateRelatedDishes()

      details.value = undefined
      commitDiet()
    }
  }
  const copyDishOrIngredient = (
    targetMealIndex: number,
    targetDayIndex: number,
    mealIndex: number,
    dayIndex: number,
    dishOrIngredientIndex: number,
    copyOnlyOnePortion = false
  ) => {
    if (diet.value) {
      const dishOrIngredient = klona(
        diet.value.data.meals[mealIndex]?.days[dayIndex]?.dishes_and_ingredients[
          dishOrIngredientIndex
        ]
      )
      if (dishOrIngredient) {
        createUndo()
        if ('used_portions' in dishOrIngredient && copyOnlyOnePortion) {
          dishOrIngredient.used_portions = 1
        }
        diet.value.data.meals[targetMealIndex]?.days[targetDayIndex]?.dishes_and_ingredients.push(
          dishOrIngredient
        )
        updateRelatedDishes()
        commitDiet()
      }
    }
  }
  const deleteDishOrIngredient = (
    mealIndex: number,
    dayIndex: number,
    dishOrIngredientIndex: number
  ) => {
    if (diet.value) {
      createUndo()
      diet.value.data.meals[mealIndex]?.days[dayIndex]?.dishes_and_ingredients.splice(
        dishOrIngredientIndex,
        1
      )
      updateRelatedDishes()
      details.value = undefined
      commitDiet()
    }
  }
  const addIngredientToDish = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    ingredient
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    ingredient: DietIngredient
    mealIndex: number
  }) => {
    if (diet.value) {
      createUndo()
      const coordinates = dietsService.getRelatedCoordinates(
        relatedDishes.value,
        diet.value.data.meals,
        mealIndex,
        dayIndex,
        dishOrIngredientIndex
      )
      A.forEach(coordinates, (coordinate) => {
        const doi =
          diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
            ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
        if (doi && 'ingredients' in doi) {
          doi.ingredients.push(ingredient)
        }
      })
      updateRelatedDishes()
      commitDiet()
    }
  }
  const deleteIngredientInDish = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    ingredientIndex
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    ingredientIndex: number
    mealIndex: number
  }) => {
    if (diet.value) {
      createUndo()
      const coordinates = dietsService.getRelatedCoordinates(
        relatedDishes.value,
        diet.value.data.meals,
        mealIndex,
        dayIndex,
        dishOrIngredientIndex
      )
      A.forEach(coordinates, (coordinate) => {
        const doi =
          diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
            ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
        if (doi && 'ingredients' in doi) {
          doi.ingredients.splice(ingredientIndex, 1)
        }
      })
      updateRelatedDishes()
      commitDiet()
    }
  }
  const replaceIngredientInDish = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    ingredientIndex,
    ingredient
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    ingredient: DietIngredient
    ingredientIndex: number
    mealIndex: number
  }) => {
    createUndo()
    const coordinates = dietsService.getRelatedCoordinates(
      relatedDishes.value,
      diet.value?.data.meals,
      mealIndex,
      dayIndex,
      dishOrIngredientIndex
    )

    A.forEach(coordinates, (coordinate) => {
      const doi =
        diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
          ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
      if (doi && 'ingredients' in doi) {
        doi.ingredients.splice(ingredientIndex, 1, ingredient)
      }
    })
    updateRelatedDishes()
    commitDiet()
  }
  const setDishDishTypes = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    dishTypesList
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    dishTypesList: StoreDietDish['dish_types']
    mealIndex: number
  }) => {
    createUndo()
    const coordinates = dietsService.getRelatedCoordinates(
      relatedDishes.value,
      diet.value?.data.meals,
      mealIndex,
      dayIndex,
      dishOrIngredientIndex
    )
    A.forEach(coordinates, (coordinate) => {
      const dishOrIngredient =
        diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
          ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
      if (dishOrIngredient && 'dish_types' in dishOrIngredient) {
        dishOrIngredient.dish_types = []
        const dishTypes = dishOrIngredient.dish_types
        dishTypesList.forEach((type) => {
          dishTypes.push(type)
        })
        dishTypes.sort()
      }
    })

    updateRelatedDishes()
    commitDiet()
  }
  const updateIngredientInDishQuantity = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    ingredientIndex,
    quantity
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    ingredientIndex: number
    mealIndex: number
    quantity: number
  }) => {
    createUndo()
    const coordinates = dietsService.getRelatedCoordinates(
      relatedDishes.value,
      diet.value?.data.meals,
      mealIndex,
      dayIndex,
      dishOrIngredientIndex
    )
    A.forEach(coordinates, (coordinate) => {
      const doi =
        diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
          ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
      if (doi && 'ingredients' in doi) {
        const ingredient = doi.ingredients[ingredientIndex]
        if (ingredient) {
          ingredient.quantity = quantity
        }
      }
    })
    updateRelatedDishes()
    commitDiet()
  }

  const updateDishName = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    name
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    mealIndex: number
    name: string
  }) => {
    createUndo()
    const coordinates = dietsService.getRelatedCoordinates(
      relatedDishes.value,
      diet.value?.data.meals,
      mealIndex,
      dayIndex,
      dishOrIngredientIndex
    )
    A.forEach(coordinates, (coordinate) => {
      const doi =
        diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
          ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
      if (doi) {
        doi.name = name
      }
    })

    updateRelatedDishes()
    commitDiet()
  }
  const updateDishSize = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    size
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    mealIndex: number
    size: number
  }) => {
    createUndo()
    const coordinates = dietsService.getRelatedCoordinates(
      relatedDishes.value,
      diet.value?.data.meals,
      mealIndex,
      dayIndex,
      dishOrIngredientIndex
    )

    A.forEach(coordinates, (coordinate) => {
      const doi =
        diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
          ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
      if (doi && 'size' in doi) {
        doi.size = size
      }
    })
    updateRelatedDishes()
    commitDiet()
  }
  const updateDishUsedPortions = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    usedPortions
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    mealIndex: number
    usedPortions: number
  }) => {
    createUndo()
    const dish =
      diet.value?.data.meals[mealIndex]?.days[dayIndex]?.dishes_and_ingredients[
        dishOrIngredientIndex
      ]
    if (dish && 'used_portions' in dish) {
      const dishUsedPortions = dish.used_portions
      const dishMaxPortions = dish.max_portions
      const allUsedPortions = dietsService.getDishAllUsedPortions(
        relatedDishes.value,
        diet.value?.data.meals,
        mealIndex,
        dayIndex,
        dishOrIngredientIndex
      )
      const usedPortionsLimit = dishMaxPortions - allUsedPortions + dishUsedPortions

      if (dishUsedPortions === dishMaxPortions && usedPortions > dishMaxPortions) {
        usedPortions = dishUsedPortions
        useGlobalStore().createMessage({
          title: 'Wszystkie porcje tego posiłku zostały już wykorzystane'
        })
      } else if (usedPortions > usedPortionsLimit) {
        usedPortions = dishUsedPortions
        useGlobalStore().createMessage({
          title: 'Wprowadzona wartość jest większa od ilości dostępnych porcji'
        })
      }

      dish.used_portions = usedPortions

      updateRelatedDishes()
      commitDiet()
    }
  }
  const updateDishMaxPortions = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    maxPortions
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    maxPortions: number
    mealIndex: number
  }) => {
    createUndo()
    const dish =
      diet.value?.data.meals[mealIndex]?.days[dayIndex]?.dishes_and_ingredients[
        dishOrIngredientIndex
      ]
    if (dish && 'max_portions' in dish) {
      const dishMaxPortions = dish.max_portions
      const allUsedPortions = dietsService.getDishAllUsedPortions(
        relatedDishes.value,
        diet.value?.data.meals,
        mealIndex,
        dayIndex,
        dishOrIngredientIndex
      )
      if (maxPortions < allUsedPortions) {
        maxPortions = dishMaxPortions
        useGlobalStore().createMessage({
          title: 'Użyta ilość porcji przewyższa maksymalną ilość porcji potrawy'
        })
      }

      const coordinates = dietsService.getRelatedCoordinates(
        relatedDishes.value,
        diet.value?.data.meals,
        mealIndex,
        dayIndex,
        dishOrIngredientIndex
      )

      A.forEach(coordinates, (coordinate) => {
        const data =
          diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
            ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
        if (data && 'max_portions' in data) {
          data.max_portions = maxPortions
        }
      })
      updateRelatedDishes()
      commitDiet()
    }
  }
  const updateDishPreparationTime = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    preparationTime
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    mealIndex: number
    preparationTime: number
  }) => {
    if (diet.value) {
      createUndo()
      const coordinates = dietsService.getRelatedCoordinates(
        relatedDishes.value,
        diet.value.data.meals,
        mealIndex,
        dayIndex,
        dishOrIngredientIndex
      )

      A.forEach(coordinates, (coordinate) => {
        const data =
          diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
            ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
        if (data && 'preparation_time' in data) {
          data.preparation_time = preparationTime
        }
      })
      updateRelatedDishes()
      commitDiet()
    }
  }
  const updateDishPreparationSteps = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    preparationSteps
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    mealIndex: number
    preparationSteps: string
  }) => {
    if (diet.value) {
      createUndo()
      const coordinates = dietsService.getRelatedCoordinates(
        relatedDishes.value,
        diet.value.data.meals,
        mealIndex,
        dayIndex,
        dishOrIngredientIndex
      )

      A.forEach(coordinates, (coordinate) => {
        const data =
          diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
            ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
        if (data && 'preparation_steps' in data) {
          data.preparation_steps = preparationSteps
        }
      })
      updateRelatedDishes()
      commitDiet()
    }
  }
  const updateDishMealType = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    mealType
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    mealIndex: number
    mealType: StoreDietDish['meal_types'][number]
  }) => {
    if (diet.value) {
      createUndo()
      const coordinates = dietsService.getRelatedCoordinates(
        relatedDishes.value,
        diet.value.data.meals,
        mealIndex,
        dayIndex,
        dishOrIngredientIndex
      )

      A.forEach(coordinates, (coordinate) => {
        const dishOrIngredient =
          diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
            ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
        if (dishOrIngredient && 'meal_types' in dishOrIngredient) {
          const mealTypes = dishOrIngredient.meal_types
          const mealTypeIndex = mealTypes.indexOf(mealType)
          if (mealTypeIndex === -1) {
            mealTypes.push(mealType)
          } else {
            mealTypes.splice(mealTypeIndex, 1)
          }
          mealTypes.sort()
        }
      })
      updateRelatedDishes()
      commitDiet()
    }
  }
  const updateDishDishType = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    dishType
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    dishType: StoreDietDish['dish_types'][number]
    mealIndex: number
  }) => {
    if (diet.value) {
      createUndo()
      const coordinates = dietsService.getRelatedCoordinates(
        relatedDishes.value,
        diet.value.data.meals,
        mealIndex,
        dayIndex,
        dishOrIngredientIndex
      )

      A.forEach(coordinates, (coordinate) => {
        const dishOrIngredient =
          diet.value?.data.meals[coordinate.mealIndex]?.days[coordinate.dayIndex]
            ?.dishes_and_ingredients[coordinate.dishOrIngredientIndex]
        if (dishOrIngredient && 'dish_types' in dishOrIngredient) {
          const dishTypes = dishOrIngredient.dish_types
          const dishTypeIndex = dishTypes.indexOf(dishType)
          if (dishTypeIndex === -1) {
            dishTypes.push(dishType)
          } else {
            dishTypes.splice(dishTypeIndex, 1)
          }
          dishTypes.sort()
        }
      })
      updateRelatedDishes()
      commitDiet()
    }
  }
  const updateIngredientQuantity = ({
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    quantity
  }: {
    dayIndex: number
    dishOrIngredientIndex: number
    mealIndex: number
    quantity: number
  }) => {
    if (diet.value) {
      const doi =
        diet.value.data.meals[mealIndex]?.days[dayIndex]?.dishes_and_ingredients[
          dishOrIngredientIndex
        ]
      if (doi && 'quantity' in doi) {
        createUndo()
        doi.quantity = quantity
        updateRelatedDishes()
        commitDiet()
      }
    }
  }
  const updateRepeatedDishesAndIngredients = (_repeatedDishesAndIngredients: string[]) => {
    repeatedDishesAndIngredients.value = _repeatedDishesAndIngredients
    updateSchedule()
  }
  const toggleRepeatedDishesAndIngredientsActive = () => {
    if (diet.value) {
      try {
        const repeatedDishesActiveDiets = getRepeatedDishesIngredient()
        repeatedDishesAndIngredientsActive.value = !repeatedDishesAndIngredientsActive.value
        void DietsStoreChannel.postMessage({
          type: 'setRepeatedDishesActive',
          data: {
            id: diet.value.id,
            value: repeatedDishesAndIngredientsActive.value
          }
        })
        updateSchedule()

        if (repeatedDishesActiveDiets.length > 0) {
          if (repeatedDishesAndIngredientsActive.value) {
            if (!repeatedDishesActiveDiets.includes(diet.value.id))
              repeatedDishesActiveDiets.push(diet.value.id)
          } else {
            const index = repeatedDishesActiveDiets.indexOf(diet.value.id)
            repeatedDishesActiveDiets.splice(index, 1)
          }
        } else {
          repeatedDishesActiveDiets.push(diet.value.id)
        }
        localStorage.setItem('repeatedDishes', stringify(repeatedDishesActiveDiets))
      } catch (e) {
        reportError(e, 'Error while toggling repeated dishes')
        localStorage.setItem('repeatedDishes', '[]')
      }
    }
  }
  const toggleIsDietVisible = async (_isDietVisible: boolean) => {
    if (diet.value) {
      const dietId = diet.value.id
      try {
        await dietsService.setIsDietVisible(dietId, _isDietVisible)
        isDietVisible.value = _isDietVisible
        void DietsStoreChannel.postMessage({
          type: 'setIsDietVisible',
          data: {
            id: dietId,
            value: _isDietVisible
          }
        })
      } catch (err) {
        reportError(err, 'Error while toggling diet visibility', { dietId })
      }
    }
  }
  const updateWeekComment = (weekComment?: string) => {
    if (diet.value) {
      // @ts-ignore
      diet.value.data.week_comment = weekComment
      saveDiet()
    }
  }
  const updateCommentTitle = ({
    mealIndex,
    dayIndex,
    commentIndex,
    title
  }: {
    commentIndex: number
    dayIndex: number
    mealIndex: number
    title: string
  }) => {
    if (diet.value) {
      const comments = diet.value.data.meals[mealIndex]?.days[dayIndex]?.comments ?? []
      // Set struct for comment if needed
      if (mealIndex === 0) {
        if (comments.length === 0) {
          comments.push(null)
          comments.push(null)
        }
      } else {
        if (comments.length === 0) comments.push(null)
      }
      // Update comment
      const comment = comments[commentIndex]
      if (G.isNullable(comment)) {
        if (title !== '') {
          comments[commentIndex] = { title, content: '' }
        }
      } else {
        if (title === '' && comment.content === '') {
          comments[commentIndex] = null
        } else {
          comment.title = title
        }
      }
      // Clear struct for comment if needed
      if (mealIndex === 0) {
        if (G.isNullable(comments[0]) && G.isNullable(comments[1])) comments.splice(0)
      } else {
        if (G.isNullable(comments[0])) comments.splice(0)
      }
      saveDiet()
    }
  }
  const updateCommentContent = ({
    mealIndex,
    dayIndex,
    commentIndex,
    content
  }: {
    commentIndex: number
    content: string
    dayIndex: number
    mealIndex: number
  }) => {
    if (diet.value) {
      const comments = diet.value.data.meals[mealIndex]?.days[dayIndex]?.comments ?? []
      // Set struct for comment if needed
      if (mealIndex === 0) {
        if (comments.length === 0) {
          comments.push(null)
          comments.push(null)
        }
      } else {
        if (comments.length === 0) comments.push(null)
      }
      // Update comment
      const comment = comments[commentIndex]
      if (G.isNullable(comment)) {
        if (content !== '') {
          comments[commentIndex] = { title: '', content }
        }
      } else {
        if (comment.title === '' && content === '') {
          comments[commentIndex] = null
        } else {
          comment.content = content
        }
      }
      // Clear struct for comment if needed
      if (mealIndex === 0) {
        if (G.isNullable(comments[0]) && G.isNullable(comments[1])) comments.splice(0)
      } else {
        if (G.isNullable(comments[0])) comments.splice(0)
      }
      saveDiet()
    }
  }
  const deleteComment = ({
    mealIndex,
    dayIndex,
    commentIndex
  }: {
    commentIndex: number
    dayIndex: number
    mealIndex: number
  }) => {
    if (diet.value) {
      const comments = diet.value.data.meals[mealIndex]?.days[dayIndex]?.comments ?? []
      if (mealIndex === 0) {
        if (commentIndex === 0 && G.isNullable(comments[1])) comments.splice(0)
        else if (commentIndex === 1 && G.isNullable(comments[0])) comments.splice(0)
        else {
          comments[commentIndex] = null
        }
      } else {
        comments.splice(0)
      }
      saveDiet()
    }
  }
  const updatePatientWeight = (weight: number) => {
    if (diet.value) {
      diet.value.data.patient.weight = weight
      updateNorms()
      saveDiet()
    }
  }
  const updatePatientHeight = (height: number) => {
    if (diet.value) {
      diet.value.data.patient.height = height
      saveDiet()
    }
  }
  const updatePatientBirthdate = (birthdate: Date) => {
    const formattedBirthdate = format(new Date(birthdate), 'dd-MM-yyyy')
    if (diet.value && formattedBirthdate !== diet.value.data.patient.birthdate) {
      diet.value.data.patient.birthdate = formattedBirthdate

      patientAge.value = differenceInYears(new Date(), birthdate)

      updateNutrients()
      updateNorms()
      saveDiet()
    }
  }
  const updatePatientGender = (gender: PatientGender) => {
    if (diet.value) {
      diet.value.data.patient.gender = gender

      updateNutrients()

      updateNorms()
      saveDiet()
    }
  }
  const updatePatientPregnancyActive = (active: boolean) => {
    if (diet.value) {
      diet.value.data.patient.pregnancy.active = active

      updateNutrients()

      updateNorms()
      saveDiet()
    }
  }
  const updatePatientPregnancyTrimester = (trimester: PatientPregnancyTrimester) => {
    if (diet.value) {
      diet.value.data.patient.pregnancy.trimester = trimester

      updateNutrients()

      updateNorms()
      saveDiet()
    }
  }
  const updatePatientLactationActive = (active: boolean) => {
    if (diet.value) {
      diet.value.data.patient.lactation.active = active

      updateNutrients()

      updateNorms()
      saveDiet()
    }
  }
  const updatePatientLactationPeriod = (period: PatientLactationPeriod) => {
    if (diet.value) {
      diet.value.data.patient.lactation.period = period

      updateNutrients()

      updateNorms()
      saveDiet()
    }
  }
  const updatePatientActivityLevel = (activityLevel: number) => {
    if (diet.value) {
      diet.value.data.patient.activity_level = activityLevel
      saveDiet()
    }
  }
  const updateCalculationType = (type: DietCalculationType) => {
    if (diet.value) {
      diet.value.data.calculation.type = type
      updateNorms()
      commitDiet()
    }
  }
  const updateCalculation = <T extends DietCalculationType, X extends keyof DietCalculationData>({
    nutrient,
    value,
    type
  }: {
    nutrient: X
    type: T
    value: DietData['calculation'][T][X]
  }) => {
    if (diet.value) {
      const dietData = diet.value.data
      const norms = dietData.norms
      const calories = norms.calories
      const standard = dietData.calculation.standard
      const sport = dietData.calculation.sport
      const patient = dietData.patient
      const weight = patient.weight > 0 ? patient.weight : 0.1
      value = G.isString(value) ? Number.parseFloat(value) : value
      if (type === dietData.calculation.type) {
        if (type === 'sport') {
          // Update value
          sport[nutrient] = value
          const sportSum = sport.protein + sport.fat + sport.carbohydrates
          if (sportSum < 0.2) {
            sport[nutrient] = 0.2
          }
          // Get sum after update value
          const gramsSum =
            sport.protein * 4 * weight + sport.fat * 9 * weight + sport.carbohydrates * 4 * weight

          standard.protein = clamp(round(((weight * sport.protein * 4) / gramsSum) * 100), 0, 100)
          standard.fat = clamp(round(((weight * sport.fat * 9) / gramsSum) * 100), 0, 100)
          standard.carbohydrates = clamp(
            round(((weight * sport.carbohydrates * 4) / gramsSum) * 100),
            0,
            100
          )
        } else {
          standard[nutrient] = value
          if (nutrient === 'protein') {
            const fatValue = 100 - standard.carbohydrates - standard.protein
            if (fatValue < 0) {
              standard.fat = 0
              standard.carbohydrates = standard.carbohydrates + fatValue
            } else {
              standard.fat = fatValue
            }
          } else if (nutrient === 'fat') {
            const carbohydratesValue = 100 - standard.protein - standard.fat
            if (carbohydratesValue < 0) {
              standard.carbohydrates = 0
              standard.protein = standard.protein + carbohydratesValue
            } else {
              standard.carbohydrates = carbohydratesValue
            }
          } else if (nutrient === 'carbohydrates') {
            const proteinValue = 100 - standard.fat - standard.carbohydrates
            if (proteinValue < 0) {
              standard.protein = 0
              standard.fat = standard.fat + proteinValue
            } else {
              standard.protein = proteinValue
            }
          }
          sport.protein = clamp(round((calories * standard.protein) / 100 / 4 / weight, 1), 0, 100)
          sport.fat = clamp(round((calories * standard.fat) / 100 / 9 / weight, 1), 0, 100)
          sport.carbohydrates = clamp(
            round((calories * standard.carbohydrates) / 100 / 4 / weight, 1),
            0,
            100
          )
        }
        const diff = 100 - standard.protein - standard.fat - standard.carbohydrates
        if (standard.protein > 0) {
          standard.protein = clamp(standard.protein - diff, 0, 100)
        } else if (standard.fat > 0) {
          standard.fat = clamp(standard.fat - diff, 0, 100)
        } else if (standard.carbohydrates > 0) {
          standard.carbohydrates = clamp(standard.carbohydrates - diff, 0, 100)
        }
      }
    }

    updateNorms()
    commitDiet()
  }
  const updateAllergensClosedActive = (active: boolean) => {
    allergensClosed.value = active
  }
  const updateLossesClosedActive = (active: boolean) => {
    lossesClosed.value = active
  }
  const updateInevitableLossesValue = (value: DietData['inevitable_losses']['value']) => {
    if (diet.value && 'value' in diet.value.data.inevitable_losses) {
      diet.value.data.inevitable_losses.value = value
      if (diet.value.data.inevitable_losses.active) {
        lossesMultiplier.value = round(1 - diet.value.data.inevitable_losses.value / 100, 2)
      } else {
        lossesMultiplier.value = 1
      }
      updateSchedule()
      updateNutrients()
      commitDiet()
    }
  }
  // Visaul helpers
  const updateIncompleteDataInfoClosedActive = (active: boolean) => {
    incompleteDataInfoClosed.value = active
  }
  const toggleAverageActive = () => {
    localStorage.setItem('averageActive', (!averageActive.value).toString())
    averageActive.value = !averageActive.value
  }
  const updateDragMode = (_dragMode: DragMode) => {
    dragMode.value = _dragMode
  }
  const updateExtended = (_extended: boolean) => {
    extended.value = _extended
  }
  const updateDetails = (_details?: DietDetails) => {
    extended.value = false
    details.value = _details
  }

  const fetchAndAddDishOrIngredient = async ({
    id,
    type,
    mealIndex,
    dayIndex
  }: {
    dayIndex: number
    id: number
    mealIndex: number
    type: 'dish' | 'ingredient'
  }) => {
    if (type === 'dish') {
      return dishService.fetchDish(id).then((response) => {
        addDish(response, mealIndex, dayIndex)
      })
    } else {
      return dietsService.fetchIngredient(id).then((response) => {
        addIngredient(response.data, mealIndex, dayIndex)
      })
    }
  }
  const fetchAndReplaceIngredient = async ({
    oldIngredientId,
    newIngredientId
  }: {
    newIngredientId: number
    oldIngredientId: number
  }) => {
    const response = await dietsService.fetchIngredient(newIngredientId)
    replaceIngredient(oldIngredientId, response.data)
  }
  const handleMessage = (message: Message) => {
    match(message)
      .with({ type: 'updateDiet' }, (msg) => {
        if (msg.diet?.id && msg.diet.id === diet.value?.id) {
          updateDiet({
            diet: msg.diet,
            skipMessages: true
          })
          updateDetails()
        }
      })
      .with({ type: 'createUndo' }, ({ id }) => {
        if (id === diet.value?.id) {
          createUndo(false)
        }
      })
      .with({ type: 'undo' }, ({ id }) => {
        if (id === diet.value?.id) {
          undo(false)
        }
      })
      .with({ type: 'redo' }, ({ id }) => {
        if (id === diet.value?.id) {
          redo(false)
        }
      })
      .with({ type: 'setRepeatedDishesActive' }, ({ data: { value, id } }) => {
        if (id === diet.value?.id) {
          repeatedDishesAndIngredientsActive.value = value
        }
      })
      .with({ type: 'setIsDietVisible' }, ({ data: { value, id } }) => {
        if (id === diet.value?.id) {
          isDietVisible.value = value
        }
      })
      .otherwise(F.ignore)
  }
  const getDishOrIngredientAllergens = (
    ingredient:
      | ScheduleDishOrIngredient
      | DietSearchDishOrIngredient
      | DietSearchDishIngredient
      | DietIngredient
  ) => {
    return dietsService.getDishOrIngredientAllergens(
      ingredient,
      dietAllergens.value,
      useGlobalStore().hasPerm('accounts.patient_allergens')
    )
  }

  const updateDietDisplayType = (type: DietDisplayType) => {
    if (diet.value) {
      diet.value.data.diet_type = type
    }
    saveDiet()
  }

  return {
    diet,
    dietType,
    schedule,
    basicNutrients,
    chosenNutrients,
    specificNutrients,
    relatedDishes,
    repeatedDishesAndIngredients,
    repeatedDishesAndIngredientsActive,
    isDietVisible,
    lossesMultiplier,
    patientAge,
    copiedDietStatus,
    copiedDietCalories,
    copiedDayStatus,
    dragMode,
    incompleteDataInfo,
    incompleteDataInfoClosed,
    allergensInfo,
    averageActive,
    allergensClosed,
    lossesClosed,
    extended,
    details,
    dietSaveStatus,
    dietSaveInProgress,
    dietSaveNext,
    dishSaveInProgress,
    undoHistory,
    redoHistory,
    canDietBeEdited,
    dietAllergens,
    patientId,
    patientName,
    caloriesFromNorms,
    getRelatedCoordinates,
    hasDetails,

    sendProductToFile,
    clearUndoRedo,
    updateAllergensClosedActive,
    updateLossesClosedActive,
    updateInevitableLossesValue,
    updatePatientWeight,
    updatePatientHeight,
    updatePatientBirthdate,
    updatePatientGender,
    pasteDiet,
    createMeal,
    deleteComment,
    deleteDay,
    deleteDiet,
    deleteCopiedDiet,
    debouncedSaveDiet,
    debouncedSaveDish,
    updateNorm,
    updateMealHour,
    updateMealName,
    updateMealEnergyDistribution,
    updateDietName,
    pasteDay,
    updateDietDescription,
    moveDishOrIngredient,
    updateDishDishType,
    addIngredientToDish,
    updateDishSize,
    copyDay,
    copyDiet,
    copyDishOrIngredient,
    updateDishSearchFilters,
    updateEnergyDistribution,
    updateDishName,
    updatechosenNutrients,
    updateExtended,
    updateCalculation,
    updateCalculationType,
    updatePatientActivityLevel,
    updateDayHour,
    updateDishMaxPortions,
    updateDishMealType,
    updateDishPreparationTime,
    updateDishPreparationSteps,
    updateDishUsedPortions,
    updateRelatedDishes,
    updateRepeatedDishesAndIngredients,
    deleteMeal,
    deleteDishOrIngredient,
    updateIngredientInDishQuantity,
    updateIngredientQuantity,
    updatePatientPregnancyActive,
    updpateDishSaveInProgressStatus,
    updateNormsActive,
    updateMealType,
    updatePatientPregnancyTrimester,
    updatePatientLactationActive,
    updatePatientLactationPeriod,
    deleteIngredientInDish,
    replaceIngredient,
    replaceIngredientInDish,
    setDishDishTypes,
    toggleRepeatedDishesAndIngredientsActive,
    toggleIsDietVisible,
    updateWeekComment,
    updateCommentTitle,
    updateCommentContent,
    updateDragMode,
    updateIncompleteDataInfoClosedActive,
    toggleAverageActive,
    fetchAndAddDishOrIngredient,
    fetchAndReplaceIngredient,
    handleMessage,
    getDishOrIngredientAllergens,
    updateDetails,
    saveDiet,
    undo,
    redo,
    resetDietsStore,
    updateDiet,
    updateInevitableLossesActive,
    updateDietDisplayType
  }
}

type DietsStore = ReturnType<typeof makeDietStore>

type State = Pick<
  DietsStore,
  | 'diet'
  | 'dietType'
  | 'schedule'
  | 'basicNutrients'
  | 'chosenNutrients'
  | 'specificNutrients'
  | 'relatedDishes'
  | 'repeatedDishesAndIngredients'
  | 'repeatedDishesAndIngredientsActive'
  | 'isDietVisible'
  | 'lossesMultiplier'
  | 'patientAge'
  | 'copiedDietStatus'
  | 'copiedDietCalories'
  | 'copiedDayStatus'
  | 'dragMode'
  | 'incompleteDataInfo'
  | 'incompleteDataInfoClosed'
  | 'allergensInfo'
  | 'averageActive'
  | 'allergensClosed'
  | 'lossesClosed'
  | 'extended'
  | 'details'
  | 'dietSaveStatus'
  | 'dietSaveInProgress'
  | 'dietSaveNext'
  | 'dishSaveInProgress'
  | 'undoHistory'
  | 'redoHistory'
  | 'canDietBeEdited'
  | 'dietAllergens'
  | 'patientId'
  | 'patientName'
  | 'caloriesFromNorms'
  | 'getRelatedCoordinates'
  | 'hasDetails'
>

type Actions = Omit<
  DietsStore,
  | 'diet'
  | 'dietType'
  | 'schedule'
  | 'basicNutrients'
  | 'chosenNutrients'
  | 'specificNutrients'
  | 'relatedDishes'
  | 'repeatedDishesAndIngredients'
  | 'repeatedDishesAndIngredientsActive'
  | 'isDietVisible'
  | 'lossesMultiplier'
  | 'patientAge'
  | 'copiedDietStatus'
  | 'copiedDietCalories'
  | 'copiedDayStatus'
  | 'dragMode'
  | 'incompleteDataInfo'
  | 'incompleteDataInfoClosed'
  | 'allergensInfo'
  | 'averageActive'
  | 'allergensClosed'
  | 'lossesClosed'
  | 'extended'
  | 'details'
  | 'dietSaveStatus'
  | 'dietSaveInProgress'
  | 'dietSaveNext'
  | 'dishSaveInProgress'
  | 'undoHistory'
  | 'redoHistory'
  | 'canDietBeEdited'
  | 'dietAllergens'
  | 'patientId'
  | 'patientName'
  | 'caloriesFromNorms'
  | 'getRelatedCoordinates'
  | 'hasDetails'
>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDietsStore = defineStore<'dietStore', State, any, Actions>(
  'dietStore',
  // @ts-expect-error
  makeDietStore
)

DietsStoreChannel.onmessage = (message) => {
  useDietsStore().handleMessage(message)
}
