import type { RouteRecordSingleViewWithChildren } from 'vue-router'

import AppLayout from '@/layouts/AppLayout.vue'
import DashboardBaseVue from '@/pages/dashboard/DashboardBase.vue'
// import UiVue from '@/pages/ui/KcalmarComponents.vue'
import { useGlobalStore } from '@/store/globalStore'

export const dashboardRouter: RouteRecordSingleViewWithChildren = {
  path: '/dashboard',
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  component: AppLayout,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      component: DashboardBaseVue,
      name: 'DashboardBase',
      meta: {
        title: 'Dashboard'
      },
      beforeEnter(_to, _from, next) {
        const store = useGlobalStore()
        const permissions = store.user?.permissions ?? []
        if (permissions.includes('accounts.kcalmar_plan')) {
          next()
        } else {
          window.location.assign(import.meta.env.VUE_APP_HOST + '/dietetyk/account/payments/')
        }
      }
    }
    // {
    //   path: 'ui',
    //   component: UiVue,
    //   name: 'Ui',
    //   meta: {
    //     title: 'Components'
    //   }
    // }
  ]
}
