<template>
  <PatientsListSearch />
  <PatientsListNavigation />
  <PatientsListTable />
</template>

<script lang="ts" setup>
import type { ComponentExposed } from 'vue-component-type-helpers'
import type { CustomPatient } from './provider'

import { useQuery } from '@tanstack/vue-query'
import { useElementSize } from '@vueuse/core'
import { provide, ref } from 'vue'

import { KcalmarTable } from '@/components/ui/KcalmarTable'
import { useFuseSearch } from '@/hooks/useFuseSearch'

import PatientsListNavigation from './PatientsListNavigation.vue'
import PatientsListSearch from './PatientsListSearch.vue'
import PatientsListTable from './PatientsListTable.vue'
import { PatientsListInject } from './provider'

const { data, isLoading } = useQuery({ queryKey: ['patients'], queryFn: () => [] })
const navigationRef = ref<HTMLDivElement>()
const searchRef = ref<HTMLDivElement>()
const { height: searchHeight } = useElementSize(searchRef)
const { height: navigationHeight } = useElementSize(navigationRef)
const searchedPhrase = ref('')
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents, @typescript-eslint/no-unsafe-assignment
const tableRef = ref<ComponentExposed<typeof KcalmarTable<CustomPatient>> | null>(null)

const searchedPatients = useFuseSearch(data, searchedPhrase, ['name', 'email'])

provide(PatientsListInject, {
  searchedPhrase,
  searchRef,
  navigationRef,
  searchHeight,
  isLoading,
  navigationHeight,
  data: searchedPatients,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  tableRef
})
</script>
