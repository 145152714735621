<template>
  <div class="nutrients">
    <div
      v-if="basicNutrientsVisible"
      class="nutrients-basic"
    >
      <div class="header">
        Podstawowe wartości odżywcze
      </div>
      <div
        v-for="nutrient in basicNutrients"
        :key="`${nutrient.key}`"
        class="nutrient"
        :class="{ extended: averageActive }"
      >
        <div class="name">
          {{ nutrient.name }}
        </div>
        <div
          v-for="(value, dayIndex) in nutrient.value"
          :key="dayIndex"
          class="day"
          :class="nutrient.normClass[dayIndex]"
        >
          <div class="measurement">
            ({{ displayMeasurement(nutrient.measurement) }})
          </div>
          <div class="value-norm-icon">
            <div class="value">
              {{ value }}
            </div>
            <div class="norm-icon">
              <div class="separator">
                /
              </div>
              <div class="norm">
                {{ nutrient.norm }}
              </div>
              <div
                class="tooltip base-tooltip base-tooltip--top base-tooltip--center"
                @mouseover="setActiveTooltip(`norm-${nutrient.key}-${dayIndex}`)"
                @mouseleave="setActiveTooltip('')"
              >
                <div class="icon" />
                <div
                  v-if="checkActiveTooltip(`norm-${nutrient.key}-${dayIndex}`)"
                  class="base-tooltip__content"
                >
                  <div class="difference">
                    <div class="icon" />
                    <span class="difference-value">{{ ((nutrient.differenceValue[dayIndex] as number) ?? 0) > 0 ? '+' : '' }}
                      {{ nutrient.differenceValue[dayIndex] }} {{ nutrient.measurement }}
                    </span>
                    ({{ nutrient.valuePercent[dayIndex] }} %)
                  </div>
                  <div class="norm-title">
                    Docelowa wartość
                  </div>
                  <div class="norm">
                    {{ nutrient.norm }} {{ nutrient.measurement }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="day average"
          :class="nutrient.averageClass"
        >
          <div class="measurement">
            ({{ displayMeasurement(nutrient.measurement) }})
          </div>
          <div class="value-norm-icon">
            <div class="value">
              {{ nutrient.average }}
            </div>
            <div class="norm-icon">
              <div class="separator">
                /
              </div>
              <div class="norm">
                {{ nutrient.norm }}
              </div>
              <div
                class="tooltip base-tooltip base-tooltip--top base-tooltip--right"
                @mouseover="setActiveTooltip(`norm-${nutrient.key}-average`)"
                @mouseleave="setActiveTooltip('')"
              >
                <div class="icon" />
                <div
                  v-if="checkActiveTooltip(`norm-${nutrient.key}-average`)"
                  class="base-tooltip__content"
                >
                  <div class="difference">
                    <div class="icon" />
                    <span class="difference-value">{{ nutrient.differenceAverage }} {{ nutrient.measurement }}
                    </span>
                    ({{ nutrient.averagePercent }} %)
                  </div>
                  <div class="norm-title">
                    Docelowa wartość
                  </div>
                  <div class="norm">
                    {{ nutrient.norm }} {{ nutrient.measurement }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="chosenNutrientsVisible"
      class="nutrients-choosen"
    >
      <div class="header">
        Wybrane szczegółowe wartości odżywcze
      </div>
      <template v-if="globalStore.hasPerm('accounts.diet_microelements')">
        <div
          v-for="nutrient in chosenNutrients"
          :key="`${nutrient.key}`"
          class="nutrient"
          :class="{ extended: averageActive }"
        >
          <div class="name">
            {{ nutrient.name }}
          </div>
          <div
            v-for="(value, dayIndex) in nutrient.value"
            :key="dayIndex"
            class="day"
            :class="nutrient.normClass[dayIndex]"
          >
            <div class="measurement">
              ({{ displayMeasurement(nutrient.measurement) }})
            </div>
            <div class="value-norm-icon">
              <div class="value">
                {{ value }}
              </div>
              <div
                v-if="nutrient.norm > 0"
                class="norm-icon"
              >
                <div class="separator">
                  /
                </div>
                <div class="norm">
                  {{ nutrient.norm }}
                </div>
                <div
                  class="tooltip base-tooltip base-tooltip--top base-tooltip--center"
                  @mouseover="setActiveTooltip(`norm-${nutrient.key}-${dayIndex}`)"
                  @mouseleave="setActiveTooltip('')"
                >
                  <div class="icon" />
                  <div
                    v-if="checkActiveTooltip(`norm-${nutrient.key}-${dayIndex}`)"
                    class="base-tooltip__content"
                  >
                    <div class="difference">
                      <div class="icon" />
                      <span class="difference-value">{{ ((nutrient.differenceValue[dayIndex] as number) ?? 0) > 0 ? '+' : '' }}
                        {{ nutrient.differenceValue[dayIndex] }} {{ nutrient.measurement }}
                      </span>
                      ({{ nutrient.valuePercent[dayIndex] }} %)
                    </div>
                    <div class="norm-title">
                      Docelowa wartość
                    </div>
                    <div class="norm">
                      {{ nutrient.norm }} {{ nutrient.measurement }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="S.isNotEmpty(nutrient.incompleteData[dayIndex] ?? '')"
                class="incomplete-data"
              >
                <div
                  class="tooltip base-tooltip base-tooltip--top base-tooltip--center"
                  @mouseover="setActiveTooltip(`incomplete-data-${nutrient.key}-${dayIndex}`)"
                  @mouseleave="setActiveTooltip('')"
                >
                  <i class="incomplete-data__icon base-icon">info</i>
                  <div
                    v-if="checkActiveTooltip(`incomplete-data-${nutrient.key}-${dayIndex}`)"
                    class="base-tooltip__content"
                  >
                    <div class="incomplete-data__title">
                      Niepełne dane:
                    </div>
                    <div class="incomplete-data__info">
                      {{ nutrient.incompleteData[dayIndex] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="day average"
            :class="nutrient.averageClass"
          >
            <div class="measurement">
              ({{ displayMeasurement(nutrient.measurement) }})
            </div>
            <div class="value-norm-icon">
              <div class="value">
                {{ nutrient.average }}
              </div>
              <div
                v-if="nutrient.norm > 0"
                class="norm-icon"
              >
                <div class="separator">
                  /
                </div>
                <div class="norm">
                  {{ nutrient.norm }}
                </div>
                <div
                  class="tooltip base-tooltip base-tooltip--top base-tooltip--right"
                  @mouseover="setActiveTooltip(`norm-${nutrient.key}-average`)"
                  @mouseleave="setActiveTooltip('')"
                >
                  <div class="icon" />
                  <div
                    v-if="checkActiveTooltip(`norm-${nutrient.key}-average`)"
                    class="base-tooltip__content"
                  >
                    <div class="difference">
                      <div class="icon" />
                      <span class="difference-value">{{ nutrient.differenceAverage }} {{ nutrient.measurement }}
                      </span>
                      ({{ nutrient.averagePercent }} %)
                    </div>
                    <div class="norm-title">
                      Docelowa wartość
                    </div>
                    <div class="norm">
                      {{ nutrient.norm }} {{ nutrient.measurement }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        v-else
        class="nutrients-choosen-disabled"
        :class="{ extended: averageActive }"
        @click="globalStore.showAppUpgradePlanModal"
      />
    </div>
    <div
      v-if="A.isNotEmpty(specificNutrientsLimited)"
      id="nutrients-specific"
      class="nutrients-specific"
    >
      <div class="header">
        Szczegółowe wartości odżywcze
      </div>
      <template v-if="globalStore.hasPerm('accounts.diet_microelements')">
        <div
          v-for="nutrient in specificNutrientsLimited"
          :key="`${nutrient.key}`"
          class="nutrient"
          :class="{ extended: averageActive }"
        >
          <div class="name">
            {{ nutrient.name }}
          </div>
          <div
            v-for="(value, dayIndex) in nutrient.value"
            :key="dayIndex"
            class="day"
            :class="nutrient.normClass[dayIndex]"
          >
            <div class="measurement">
              ({{ displayMeasurement(nutrient.measurement) }})
            </div>
            <div class="value-norm-icon">
              <div class="value">
                {{ value }}
              </div>
              <div
                v-if="nutrient.norm > 0"
                class="norm-icon"
              >
                <div class="separator">
                  /
                </div>
                <div class="norm">
                  {{ nutrient.norm }}
                </div>
                <div
                  class="tooltip base-tooltip base-tooltip--top base-tooltip--center"
                  @mouseover="setActiveTooltip(`norm-${nutrient.key}-${dayIndex}`)"
                  @mouseleave="setActiveTooltip('')"
                >
                  <div class="icon" />
                  <div
                    v-if="checkActiveTooltip(`norm-${nutrient.key}-${dayIndex}`)"
                    class="base-tooltip__content"
                  >
                    <div class="difference">
                      <div class="icon" />
                      <span class="difference-value">
                        {{ ((nutrient.differenceValue[dayIndex] as number) ?? 0) > 0 ? '+' : '' }}
                        {{ nutrient.differenceValue[dayIndex] }} {{ nutrient.measurement }}
                      </span>
                      ({{ nutrient.valuePercent[dayIndex] }} %)
                    </div>
                    <div class="norm-title">
                      Docelowa wartość
                    </div>
                    <div class="norm">
                      {{ nutrient.norm }} {{ nutrient.measurement }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="S.isNotEmpty(nutrient.incompleteData[dayIndex] ?? '')"
                class="incomplete-data"
              >
                <div
                  class="tooltip base-tooltip base-tooltip--top base-tooltip--center"
                  @mouseover="setActiveTooltip(`incomplete-data-${nutrient.key}-${dayIndex}`)"
                  @mouseleave="setActiveTooltip('')"
                >
                  <i class="incomplete-data__icon base-icon">info</i>
                  <div
                    v-if="checkActiveTooltip(`incomplete-data-${nutrient.key}-${dayIndex}`)"
                    class="base-tooltip__content"
                  >
                    <div class="incomplete-data__title">
                      Niepełne dane:
                    </div>
                    <div class="incomplete-data__info">
                      {{ nutrient.incompleteData[dayIndex] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="day average"
            :class="nutrient.averageClass"
          >
            <div class="measurement">
              ({{ displayMeasurement(nutrient.measurement) }})
            </div>
            <div class="value-norm-icon">
              <div class="value">
                {{ nutrient.average }}
              </div>
              <div
                v-if="nutrient.norm > 0"
                class="norm-icon"
              >
                <div class="separator">
                  /
                </div>
                <div class="norm">
                  {{ nutrient.norm }}
                </div>
                <div
                  class="tooltip base-tooltip base-tooltip--top base-tooltip--right"
                  @mouseover="setActiveTooltip(`norm-${nutrient.key}-average`)"
                  @mouseleave="setActiveTooltip('')"
                >
                  <div class="icon" />
                  <div
                    v-if="checkActiveTooltip(`norm-${nutrient.key}-average`)"
                    class="base-tooltip__content"
                  >
                    <div class="difference">
                      <div class="icon" />
                      <span class="difference-value">{{ nutrient.differenceAverage }} {{ nutrient.measurement }}
                      </span>
                      ({{ nutrient.averagePercent }} %)
                    </div>
                    <div class="norm-title">
                      Docelowa wartość
                    </div>
                    <div class="norm">
                      {{ nutrient.norm }} {{ nutrient.measurement }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        v-else
        class="nutrients-specific-disabled"
        :class="{ extended: averageActive }"
        @click="globalStore.showAppUpgradePlanModal"
      />
    </div>
    <div
      v-if="globalStore.hasPerm('accounts.diet_microelements')"
      class="show-more"
    >
      <StyledButton
        variant="outline"
        @click="toggleShowAllNutrients()"
      >
        {{
          showAllNutrients ? 'Pokaż mniej ...' : 'Pokaż więcej ...'
        }}
      </StyledButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { NutrientMeasurement } from '@/utils/nutrients'

import { A, S } from '@mobily/ts-belt'
import { useDebounceFn, useEventListener } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import { StyledButton } from '@/components/StyledButton'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'

const dietStore = useDietsStore()
const globalStore = useGlobalStore()
const { averageActive, basicNutrients, chosenNutrients, specificNutrients } = storeToRefs(dietStore)

const showAllNutrients = ref(false)
const activeTooltip = ref()

const basicNutrientsVisible = computed(() => {
  return basicNutrients.value.length > 0
})
const chosenNutrientsVisible = computed(() => {
  return chosenNutrients.value.length > 0
})
const specificNutrientsLimited = computed(() => {
  if (showAllNutrients.value) {
    return specificNutrients.value
  } else {
    return specificNutrients.value.slice(0, 1)
  }
})

const toggleShowAllNutrients = () => {
  showAllNutrients.value = !showAllNutrients.value
}
const hideAllNutrientsOnScroll = () => {
  const elem = document.getElementById('nutrients-specific')
  if (elem) {
    if (elem.getBoundingClientRect().top > window.innerHeight) {
      showAllNutrients.value = false
    }
  }
}
const setActiveTooltip = (value?: string) => {
  activeTooltip.value = value
}
const checkActiveTooltip = (value: string) => {
  return activeTooltip.value === value
}
const displayMeasurement = (measurement: NutrientMeasurement) => {
  return measurement || '-'
}

const debounceHideAllNutrientsOnScroll = useDebounceFn(hideAllNutrientsOnScroll, 100)

useEventListener('scroll', debounceHideAllNutrientsOnScroll)
</script>

<style scoped>
.nutrients {
  background: #fff;
}

.header {
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  text-transform: uppercase;
  font-family: Montserrat-SemiBold;
  color: #fff;
  background: var(--color-main-100);
  text-align: center;
}

.nutrient {
  display: grid;
  grid-template-columns: repeat(7, minmax(150px, 1fr)) 30px;
  margin: 0 4px;
}

.nutrient.extended {
  grid-template-columns: repeat(7, minmax(150px, 1fr)) 142px;
}

.name {
  grid-column: 1 / span 8;
  background: #f8f8f8;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
}

.day {
  margin-right: 6px;
  padding: 7px 4px 7px 8px;
  height: 46px;
}

.day.red-up,
.day.red-down {
  background: var(--color-red-10);
}

.day.yellow-up,
.day.yellow-down {
  background: var(--color-warning-10);
}

.average {
  background: var(--color-main-10);
  display: none;
  margin-right: 0;
}

.nutrient.extended .average {
  display: block;
}

.measurement {
  font-family: Montserrat-Medium;
  font-size: 9px;
  line-height: 10px;
  color: var(--color-tertiary-text);
  margin-bottom: 1px;
}

.value-norm-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
  line-height: 18px;
  font-size: 14px;
}

.average .value {
  font-family: Montserrat-SemiBold;
}

.norm-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.separator {
  margin: 0 2px;
}

.norm {
  color: var(--color-secondary-text);
  margin-right: 8px;
}

.icon {
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.red-up .icon,
.red-down .icon {
  background-image: url('@/assets/img/diets/norm-red.svg?url');
}

.yellow-up .icon,
.yellow-down .icon {
  background-image: url('@/assets/img/diets/norm-yellow.svg?url');
}

.yellow-down .icon,
.red-down .icon {
  transform: rotate(180deg);
}

.ok .icon {
  height: 15px;
  width: 15px;
  background-size: 15px 15px;
  background-image: url('@/assets/img/diets/norm-green.svg?url');
}

.tooltip {
  height: 16px;
  width: max-content;
  text-align: center;
}

.difference {
  font-family: Montserrat-Medium;
  line-height: 16px;
  height: 16px;
  font-size: 12px;
  margin-bottom: 4px;
}

.difference .icon {
  width: 10px;
  height: 10px;
  background-size: 10px 10px;
  margin-right: 8px;
}

.red-up .difference-value,
.red-down .difference-value {
  color: var(--color-destructive-100);
}

.yellow-up .difference-value,
.yellow-down .difference-value {
  color: var(--color-warning-100);
}

.ok .difference-value {
  color: var(--color-success-100);
}

.tooltip .norm-title {
  color: #7f7f7f;
  font-size: 10px;
  line-height: 13px;
}

.tooltip .norm {
  font-family: Montserrat-Medium;
  color: var(--color-primary-text);
  font-size: 12px;
  line-height: 15px;
}

.incomplete-data {
  margin-left: 8px;
}

.incomplete-data__icon {
  color: var(--color-tertiary-text);
  display: block;
  width: 19px;
  height: 16px;
  font-size: 20px;
  line-height: 16px;
  cursor: pointer;
}

.incomplete-data__title {
  text-align: center;
  font-family: Montserrat-Medium;
  margin-bottom: 8px;
}

.incomplete-data__info {
  white-space: pre;
}

.show-more {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nutrients-choosen-disabled,
.nutrients-choosen-disabled.extended,
.nutrients-specific-disabled,
.nutrients-specific-disabled.extended {
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.nutrients-choosen-disabled {
  height: 92px;
  background-image: url('@/assets/img/diets/nutrients-choosen.png');
}

.nutrients-choosen-disabled.extended {
  height: 92px;
  background-image: url('@/assets/img/diets/nutrients-choosen-extended.png');
}

.nutrients-specific-disabled {
  height: 170px;
  background-image: url('@/assets/img/diets/nutrients-specific.png');
}

.nutrients-specific-disabled.extended {
  height: 170px;
  background-image: url('@/assets/img/diets/nutrients-specific-extended.png');
}
</style>
