<template>
  <BaseModal
    :modal-class="'add-diet-modal'"
    @close="hideAddDietModal"
    @confirm="addDiet"
    @click="deactivateSelects"
  >
    <template #title>
      Dodawanie jadłospisu
    </template>
    <div class="modal-label">
      Wprowadź nazwę jadłospisu
    </div>
    <BaseInput
      v-model="addDietModal.name"
      test-id="AddDietModal__Name"
      class="add-diet-modal__input"
      :maxlength="85"
      :placeholder="'Twoja nazwa jadłospisu'"
      :force-focus="true"
    />
    <div class="modal-label">
      Wybierz grupę dla jadłospisu
    </div>
    <BaseSelect
      v-model="addDietModal.groupId"
      :options="addDietModalGroups"
    />
    <Space
      :space="6"
      class="mt-24"
    >
      <Space :space="2">
        <T
          as="span"
          size="sm"
        >
          Wybierz format wyświetlania dni
        </T>
        <T
          as="span"
          size="xs"
          color="textSecondary"
        >
          Zmiana formatu wyświetlania dni wpłynie na wygląd wydruku PDF
        </T>
      </Space>
      <KcalmarRadio
        name="days_of_week"
        label="Dni tygodnia"
        description="Przykład: Poniedziałek, Wtorek, Środa..."
        :model-value="addDietModal.dietDisplayType === 'days_of_week'"
        @update:model-value="addDietModal.dietDisplayType = 'days_of_week'"
      />
      <KcalmarRadio
        name="numeric"
        label="Numeryczny"
        description="Przykład: Dzień 1, Dzień 2, Dzień 3..."
        :model-value="addDietModal.dietDisplayType === 'numeric'"
        @update:model-value="addDietModal.dietDisplayType = 'numeric'"
      />
    </Space>
    <template #actions>
      <StyledButton
        variant="outline"
        @click.once="hideAddDietModal"
      >
        Anuluj
      </StyledButton>
      <StyledButton
        :disabled="addDietButtonDisabled"
        @click.once="addDiet"
      >
        Dodaj jadłospis
      </StyledButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import BaseInput from '@/components/BaseInput.vue'
import BaseModal from '@/components/BaseModal.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import { useDietListGroupsProvider } from '../providers/dietListGroupsProvider'
import { KcalmarRadio } from '@/components/ui/KcalmarRadio'
import { T } from '@/components/ui/T'
import { onBeforeUnmount } from 'vue'

const {
  addDiet,
  addDietButtonDisabled,
  addDietModalGroups,
  addDietModal,
  deactivateSelects,
  hideAddDietModal
} = useDietListGroupsProvider()

onBeforeUnmount(() => {
  addDietModal.value.dietDisplayType = 'days_of_week'
})
</script>

<style scoped>

.modal-label {
  font-size: 10px;
  color: var(--color-tertiary-text);
}

.add-group-modal__input {
  width: 100%;
}

.add-diet-modal__input {
  width: 100%;
  margin-bottom: 20px;
}

</style>

<style>
.add-diet-modal {
  overflow: visible !important;
}
</style>
