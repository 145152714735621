import type { VariantPropsOf } from '@/utils/variants'
import type { Colors } from '@ui/KcalmarIcon'
import type { PropType } from 'vue'

import { computed } from 'vue'

import { KcalmarIcon } from '@ui/KcalmarIcon'

import { variants } from '@/utils/variants'

import s from './style.module.scss'

const styles = variants({
  base: s.root,
  variants: {
    variant: {
      primary: s['type--primary'],
      secondary: s['type--secondary'],
      destructive: s['type--destructive'],
      ghost: s['type--ghost'],
      transparent: s['type--transparent']
    },
    size: {
      xs: s['size--xsmall'],
      small: s['size--small'],
      default: s['size--default'],
      large: s['size--large'],
      xl: s['size--xl']
    },
    disabled: {
      true: s.disabled
    }
  },
  defaultVariants: {
    variant: 'primary',
    size: 'default'
  }
})

type Props = VariantPropsOf<typeof styles> & {
  iconClass?: string
  iconColor?: Colors
  iconSize?: IconSize
  name: RemixIconName
}

const IconSize: Record<NonNullable<VariantPropsOf<typeof styles>['size']>, IconSize> = {
  xs: '16',
  small: '20',
  default: '24',
  large: '28',
  xl: '32'
}

export const KcalmarIconButton = (props: Props) => {
  const classes = computed(() => {
    return styles({
      variant: props.variant,
      disabled: props.disabled,
      size: props.size
    })
  })
  const iconSize = props.iconSize ?? IconSize[props.size ?? 'default']

  return (
    <button class={classes.value} disabled={props.disabled}>
      <KcalmarIcon
        size={iconSize}
        name={props.name}
        class={props.iconClass}
        color={props.iconColor}
      />
    </button>
  )
}

KcalmarIconButton.props = {
  variant: String as PropType<Props['variant']>,
  size: String as PropType<Props['size']>,
  name: String as PropType<Props['name']>,
  iconClass: String as PropType<Props['iconClass']>,
  iconSize: String as PropType<Props['iconSize']>,
  iconColor: String as PropType<Colors>,
  disabled: Boolean
}
