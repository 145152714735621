<template>
  <div class="ingredients-list">
    <IngredientsListSearch />
    <IngredientsListNav />
    <div class="list">
      <div
        class="header row"
        :class="[
          `column-ingredient-${chosenNutrientsLength}`,
          { 'sticky-extended': extendedFilters }
        ]"
      >
        <div class="column" />
        <div class="header__name column">
          <div
            class="column__sortable"
            :class="{ active: sortBy === 'name' }"
            @click="setSortBy('name')"
          >
            Nazwa produktu
            <BaseIcon :name="getIconBySort('name')" />
          </div>
        </div>
        <div class="column">
          Baza
        </div>
        <div class="column">
          Miara opisowa
        </div>
        <div class="column">
          <BaseTooltip class="base-tooltip--center base-tooltip--top">
            <template #tooltip>
              <div>Wartość energetyczna</div>
              <div>Wartości podane w tabeli dotyczą 100 g części jadalnej produktu</div>
            </template>
            <div
              class="column__sortable"
              :class="{ active: sortBy === 'calories' }"
              @click="setSortBy('calories')"
            >
              <div class="column-positioned">
                {{ getNutrientHeader('calories') }}
              </div>
              <BaseIcon :name="getIconBySort('calories')" />
            </div>
          </BaseTooltip>
        </div>
        <div
          v-for="nutrient in chosenNutrients"
          :key="`ingredient-${nutrient}`"
          class="column"
        >
          <BaseTooltip class="base-tooltip--center base-tooltip--top">
            <template #tooltip>
              <div>{{ getNutrientName(nutrient) }}</div>
              <div>Wartości podane w tabeli dotyczą 100 g części jadalnej produktu</div>
            </template>
            <div
              class="column__sortable"
              :class="{ active: sortBy === nutrient, disabled: nutrient === 'ratio_n_3_n_6' }"
              @click="setSortBy(nutrient)"
            >
              <div class="column-positioned">
                {{ getNutrientHeader(nutrient) }}
              </div>
              <BaseIcon
                v-if="nutrient !== 'ratio_n_3_n_6'"
                :name="getIconBySort(nutrient)"
              />
            </div>
          </BaseTooltip>
        </div>
        <div class="column" />
      </div>
      <div
        v-if="loading"
        class="list-loader"
      >
        <div class="list-loader-bar">
          <div class="list-loader-bar__progress" />
        </div>
      </div>
      <div
        v-else-if="
          ingredientsFilterByCounted === 0 && areFiltersActive(ingredientsType) && !loading
        "
        class="empty-results"
      >
        <BaseIcon
          type="info"
          name="info"
        />
        Nie znaleziono produktów
      </div>
      <div
        v-else-if="ingredientsFilterByCounted > 0"
        class="ingredients"
      >
        <IngredientsListItem
          v-for="ingredient in ingredientsFilterBySliced"
          :key="`ingredient-${ingredient.id}`"
          :ingredient="ingredient"
          :on-delete="showIngredientDeleteModal"
          :chosen-nutrients-length="chosenNutrientsLength"
          :has-add-ingredient-permission="hasAddIngredientPermission"
        />
        <BasePagination
          :count="ingredientsFilterByCounted"
          :current-page="currentPage"
          :per-page="perPage"
          @on-page-change="(v) => (currentPage = v)"
        />
      </div>
      <div
        v-else
        class="empty-products"
      >
        <div v-if="ingredientsType === 'is_private'">
          Twoja lista <span>prywatnych</span> produktów jest pusta. <br>
          <Space
            :space="4"
            align-x="center"
            align-y="center"
          >
            Dodaj produkty prywatne klikając w

            <StyledButton
              class="add-ingredient"
              left-icon="add"
              @click="addProduct()"
            >
              Dodaj produkt
            </StyledButton>
          </Space>
        </div>
        <div v-else-if="ingredientsType === 'is_favorite'">
          Twoja lista <span>ulubionych</span> produktów jest pusta. <br>
          Oznacz produkty ulubione klikając na
          <BaseIcon
            variant="outline"
            name="favorite"
          />
          przy każdym produkcie na liście produktów.
        </div>
      </div>
    </div>
    <div
      v-if="izzConditionsConfirmed && !loading"
      class="izz-confirmed-box"
    >
      <img src="@/assets/img/izz.png">
      <div class="izz-content">
        Warunki korzystania z Bazy Danych Produktów i Potraw Instytutu Żywności i Żywienia.
        <StyledButton @click="showIngredientsIzzModal">
          Zobacz więcej
        </StyledButton>
      </div>
    </div>
    <div
      v-else-if="!loading"
      class="izz-unconfirmed-box"
    >
      <img src="@/assets/img/izz.png">
      <div class="izz-content">
        Aby mieć dostęp do <span class="izz-color">dodatkowych 800 produktów</span><br>
        Zaakceptuj
        <span
          class="modal-link"
          @click="showIngredientsIzzModal"
        > warunki </span>
        korzystania z Bazy Danych Produktów i Potraw Instytutu Żywności i Żywienia.
        <StyledButton @click.once="izzConditionsConfirm()">
          Akceptuję
        </StyledButton>
      </div>
    </div>
    <BaseModal
      v-if="G.isNotNullable(ingredientToDelete)"
      @close="hideIngredientDeleteModal"
      @confirm="deleteIngredient"
    >
      <template #title>
        Usuwanie produktu
      </template>
      <div class="ingredient-delete-modal-info">
        Czy na pewno chcesz usunąć ten produkt?
      </div>
      <template #actions>
        <StyledButton
          variant="outline"
          @click.once="hideIngredientDeleteModal"
        >
          Anuluj
        </StyledButton>
        <StyledButton @click.once="deleteIngredient">
          Usuń
        </StyledButton>
      </template>
    </BaseModal>
    <ChosenNutrientsModal
      v-if="chosenNutrientsModalVisible"
      :chosen-nutrients="chosenNutrients"
      @close="hideChosenNutrientsModal"
      @load-custom-nutrients="loadCustomNutrients($event)"
    />
    <IngredientsIzzModal
      v-if="ingredientsIzzModalVisible"
      :izz-conditions-confirmed-prop="izzConditionsConfirmed"
      @hide-ingredients-izz-modal="hideIngredientsIzzModal"
      @izz-conditions-confirmed="
        ;(izzConditionsConfirmed = true), (ingredientsIzzModalVisible = false)
      "
    />
  </div>
</template>

<script lang="ts" setup>
import type { DietListIngredient } from '@/types/Diet'
import type { IngredientType } from '@/types/IngredientList'
import type { Nutrients } from '@/utils/nutrients'

import { A, G } from '@mobily/ts-belt'
import { klona } from 'klona'
import round from 'lodash/round'
import { storeToRefs } from 'pinia'
import { computed, onMounted, provide, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseModal from '@/components/BaseModal.vue'
import BasePagination from '@/components/BasePagination.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import ChosenNutrientsModal from '@/components/ingredients/ChosenNutrientsModal.vue'
import IngredientsIzzModal from '@/components/ingredients/IngredientsIzzModal.vue'
import IngredientsListItem from '@/components/ingredients/IngredientsListItem.vue'
import IngredientsListNav from '@/components/ingredients/IngredientsListNav.vue'
import IngredientsListSearch from '@/components/ingredients/IngredientsListSearch.vue'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import { useChosenNutrients } from '@/hooks/useChosenNutrients'
import { useFuseSearch } from '@/hooks/useFuseSearch'
import { useHasAddIngredientPermission } from '@/hooks/useHasAddIngredientPermission'
import { useSortBy } from '@/hooks/useSortBy'
import { ingredientsService } from '@/services/ingredientsService'
import { useGlobalStore } from '@/store/globalStore'
import { IngredientsFiltersInject } from '@/types/IngredientList'
import { nutrientsMap } from '@/utils/nutrients'
import { reportError } from '@/utils/reportError'

type SortBy = Nutrients | 'name'
type SortObj = DietListIngredient['nutrients'] & Record<'name', DietListIngredient['name']>

const router = useRouter()
const perPage = 20
const ingredients = ref<DietListIngredient[]>([])
const loading = ref(true)
const ingredientsType = ref<IngredientType>('all')
const { sortBy, setSortBy, sort, getIconBySort } = useSortBy<SortBy, SortObj>('name')
const {
  chosenNutrients,
  chosenNutrientsLength,
  chosenNutrientsModalVisible,
  showChosenNutrientsModal,
  hideChosenNutrientsModal,
  loadCustomNutrients
} = useChosenNutrients()
const searchedPhrase = ref('')

const extendedFilters = ref(false)
const ingredientTypeForFilters = ref<IngredientType[]>([])
const ingredientCategoryForFilters = ref<number[]>([])
const ingredientBaseForFilters = ref<number[]>([])
const ingredientTagForFilters = ref<(keyof DietListIngredient)[]>([])
const kcalMinValue = ref<number>(0)
const kcalMaxValue = ref<number>(0)
const resetFiltersValue = ref(0)
const izzConditionsConfirmed = ref(true)
const ingredientsIzzModalVisible = ref(false)
const getFavoritesIngredientsIds = ref<number[]>([])
const ingredientToDelete = ref<number>()
const currentPage = ref(1)
const searchedIngredients = useFuseSearch(ingredients, searchedPhrase, ['name'])
const globalStore = useGlobalStore()
const { user } = storeToRefs(globalStore)
const { createMessage, showAppUpgradePlanModal, changeIngredientCounter } = globalStore
const hasAddIngredientPermission = useHasAddIngredientPermission()

const ingredientsFilterBy = computed(() => {
  const returnedIngredientByCategoryList: DietListIngredient[] = []
  const favoriteIngredients = getFavoritesIngredientsIds
  let newIngredients = klona(searchedIngredients.value)

  if (!izzConditionsConfirmed.value) {
    newIngredients = newIngredients.filter((ingredient) => ingredient.base_name !== 'IŻŻ')
  }
  if (ingredientsType.value === 'is_public') {
    newIngredients = newIngredients.filter((ingredient) => ingredient.is_public)
  }
  if (ingredientsType.value === 'is_private') {
    newIngredients = newIngredients.filter((ingredient) => !ingredient.is_public)
  }
  if (ingredientsType.value === 'is_favorite') {
    newIngredients = newIngredients.filter((ingredient) =>
      favoriteIngredients.value.includes(ingredient.id)
    )
  }

  if (ingredientTypeForFilters.value.length > 0) {
    if (ingredientTypeForFilters.value.includes('is_public')) {
      newIngredients.forEach((ingredient) => {
        if (ingredient.is_public && !returnedIngredientByCategoryList.includes(ingredient)) {
          returnedIngredientByCategoryList.push(ingredient)
        }
      })
    }
    if (ingredientTypeForFilters.value.includes('is_private')) {
      newIngredients.forEach((ingredient) => {
        if (!ingredient.is_public && !returnedIngredientByCategoryList.includes(ingredient)) {
          returnedIngredientByCategoryList.push(ingredient)
        }
      })
    }
    if (ingredientTypeForFilters.value.includes('is_favorite')) {
      newIngredients.forEach((ingredient) => {
        if (
          favoriteIngredients.value.includes(ingredient.id) &&
          !returnedIngredientByCategoryList.includes(ingredient)
        ) {
          returnedIngredientByCategoryList.push(ingredient)
        }
      })
    }

    newIngredients = returnedIngredientByCategoryList
  }
  if (ingredientCategoryForFilters.value.length > 0) {
    newIngredients = newIngredients.filter((ingredient) =>
      ingredientCategoryForFilters.value.includes(ingredient.category)
    )
  }
  if (ingredientBaseForFilters.value.length > 0) {
    newIngredients = newIngredients.filter((ingredient) =>
      ingredientBaseForFilters.value.includes(ingredient.base)
    )
  }
  if (ingredientTagForFilters.value.length > 0) {
    A.forEach(ingredientTagForFilters.value, (tag) => {
      newIngredients = newIngredients.filter((ingredient) => {
        return ingredient[tag] === 0 || ingredient[tag] === true
      })
    })
  }
  if (kcalMinValue.value !== 0) {
    newIngredients = newIngredients.filter((ingredient) => {
      return ingredient.nutrients.calories >= kcalMinValue.value
    })
  }
  if (kcalMaxValue.value !== 0) {
    newIngredients = newIngredients.filter((ingredient) => {
      return ingredient.nutrients.calories <= kcalMaxValue.value
    })
  }

  newIngredients = newIngredients.sort((a, b) =>
    sort({ ...a.nutrients, name: a.name }, { ...b.nutrients, name: b.name })
  )

  return newIngredients
})

const ingredientsFilterBySliced = computed(() => {
  const firstIndex = (currentPage.value - 1) * perPage
  const lastIndex = currentPage.value * perPage
  return ingredientsFilterBy.value.slice(firstIndex, lastIndex)
})
const ingredientsFilterByCounted = computed(() => {
  return ingredientsFilterBy.value.length
})

const kcalMin = computed(() => {
  const data = ingredients
  return round(Math.min(...data.value.map((o) => o.nutrients.calories)), 1)
})
const kcalMax = computed(() => {
  const data = ingredients
  return round(Math.max(...data.value.map((o) => o.nutrients.calories)), 1)
})

const showIngredientDeleteModal = (ingredientId: number) => {
  ingredientToDelete.value = ingredientId
}
const hideIngredientDeleteModal = () => {
  ingredientToDelete.value = undefined
}
const addProduct = () => {
  if (hasAddIngredientPermission.value) {
    void router.push({ name: 'IngredientsListAdd' })
  } else {
    showAppUpgradePlanModal()
  }
}

const deleteIngredient = async () => {
  if (ingredientToDelete.value) {
    await ingredientsService.deleteIngredient(ingredientToDelete.value)
    changeIngredientCounter({
      value: -1
    })
    await loadIngredients()
    hideIngredientDeleteModal()
    createMessage({ title: 'Usunięto produkt' })
  }
}

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
const izzConditionsConfirm = async () => {
  if (G.isNotNullable(dataLayer) && G.isArray(dataLayer)) {
    dataLayer.push({
      event: 'izz_database_accepted',
      button_location: 'list_bottom'
    })
  }
  try {
    await ingredientsService.izzConditionsConfirm()
    izzConditionsConfirmed.value = true
  } catch (err) {
    reportError(err, 'Error during izzConditionsConfirm')
  }
}
const hideIngredientsIzzModal = () => {
  ingredientsIzzModalVisible.value = false
}
const showIngredientsIzzModal = () => {
  ingredientsIzzModalVisible.value = true
}
const resetFilters = () => {
  resetFiltersValue.value++
  ingredientTypeForFilters.value = []
  ingredientCategoryForFilters.value = []
  ingredientTagForFilters.value = []
  ingredientBaseForFilters.value = []
  searchedPhrase.value = ''
  scrollToTop()
}
const setKcalMin = (value: number) => {
  kcalMinValue.value = value
  currentPage.value = 1
  scrollToTop()
}
const setKcalMax = (value: number) => {
  kcalMaxValue.value = value
  currentPage.value = 1
  scrollToTop()
}
const resetKcalMinMax = () => {
  kcalMinValue.value = 0
  kcalMaxValue.value = 0
}

const resetExtendedFilters = () => {
  resetFilters()
}
const setIngredientTypeForFilters = (ingredientTypesList: IngredientType[]) => {
  ingredientTypeForFilters.value = ingredientTypesList
  currentPage.value = 1
  scrollToTop()
}
const setIngredientCategoryForFilters = (ingredientCategoryList: number[]) => {
  ingredientCategoryForFilters.value = ingredientCategoryList
  currentPage.value = 1
  scrollToTop()
}
const setIngredientBaseForFilters = (ingredientBaseList: number[]) => {
  ingredientBaseForFilters.value = ingredientBaseList
  currentPage.value = 1
  scrollToTop()
}
const setIngredientTagForFilters = (ingredientTagList: string[]) => {
  ingredientTagForFilters.value = ingredientTagList as (keyof DietListIngredient)[]
  currentPage.value = 1
  scrollToTop()
}
const toggleExtendedFilters = () => {
  extendedFilters.value = !extendedFilters.value
}
const getNutrientHeader = (nutrient: Nutrients) => {
  let measurement = ''
  if (nutrientsMap[nutrient].measurement) {
    measurement = '(' + nutrientsMap[nutrient].measurement + ')'
  }
  return nutrientsMap[nutrient].shortcut + measurement
}
const getNutrientName = (nutrient: Nutrients) => {
  return nutrientsMap[nutrient].name
}

const areFiltersActive = (ingredientType: IngredientType) => {
  let areActive = false
  if (ingredientsType.value === ingredientType) {
    if (
      searchedPhrase.value ||
      ingredientTypeForFilters.value.length > 0 ||
      ingredientTypeForFilters.value.length > 0 ||
      ingredientCategoryForFilters.value.length > 0 ||
      ingredientBaseForFilters.value.length > 0 ||
      ingredientTagForFilters.value.length > 0 ||
      kcalMinValue.value > kcalMin.value ||
      (kcalMaxValue.value < kcalMax.value && kcalMaxValue.value !== 0)
    ) {
      areActive = true
    }
  }

  return areActive
}

const filterDiets = (phrase: string) => {
  searchedPhrase.value = phrase
  currentPage.value = 1
}

const getAllCounter = computed(() => {
  if (!izzConditionsConfirmed.value) {
    return ingredients.value.filter((ingredient) => ingredient.base_name !== 'IŻŻ').length
  }
  return ingredients.value.length
})
const getPublicCounter = computed(() => {
  if (!izzConditionsConfirmed.value) {
    return ingredients.value.filter((ingredient) => ingredient.base_name !== 'IŻŻ').length
  }
  return ingredients.value.filter((ingredient) => ingredient.is_public).length
})
const getPrivateCounter = computed(() => {
  return ingredients.value.filter((ingredient) => !ingredient.is_public).length
})
const getFavoriteCounter = computed(() => {
  let _ingredient = ingredients.value
  if (!izzConditionsConfirmed.value) {
    _ingredient = _ingredient.filter((ingredient) => ingredient.base_name !== 'IŻŻ')
  }
  const favoriteIngredients = getFavoritesIngredientsIds
  return _ingredient.filter((ingredient) => favoriteIngredients.value.includes(ingredient.id))
    .length
})
const setIngrediedientsFilterByType = (type: IngredientType) => {
  ingredientsType.value = type
  currentPage.value = 1
  resetFilters()
}
const loadIngredients = async () => {
  loading.value = true
  const ingredientsResponse = await ingredientsService.fetchIngredients(chosenNutrients.value)
  ingredients.value = ingredientsResponse.data
  loading.value = false
}

onMounted(async () => {
  createMessage({
    title: 'Wartości podane w tabeli dotyczą 100 g części jadalnej produktu',
    timeout: 0
  })

  getFavoritesIngredientsIds.value = user?.value?.favourite_ingredients ?? []

  await loadIngredients()
  if (!user?.value?.izz_conditions_confirm) {
    izzConditionsConfirmed.value = false
    ingredientsIzzModalVisible.value = true
  }
})

watch(chosenNutrients, loadIngredients)

provide(IngredientsFiltersInject, {
  extendedFilters,
  kcalMax,
  kcalMin,
  ingredientsType,
  resetFiltersValue,
  izzConditionsConfirmed,
  ingredientsIzzModalVisible,
  setIngredientTypeForFilters,
  setIngrediedientsFilterByType,
  setIngredientCategoryForFilters,
  setIngredientBaseForFilters,
  setIngredientTagForFilters,
  setKcalMin,
  setKcalMax,
  resetKcalMinMax,
  showIngredientsIzzModal,
  toggleExtendedFilters,
  resetExtendedFilters,
  areFiltersActive,
  filterDiets,
  searchedPhrase,
  showChosenNutrientsModal,
  addProduct,
  ingredientsFilterByCounted,
  getAllCounter,
  getFavoriteCounter,
  getPublicCounter,
  getPrivateCounter
})
</script>
<style scoped>
.row {
  display: grid;
  align-items: center;
  grid-column-gap: 10px;
}

.column-ingredient-6 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(6, 100px) 20px 105px;
}

.column-ingredient-5 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(5, 100px) 20px 105px;
}

.column-ingredient-4 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(4, 100px) 20px 105px;
}

.column-ingredient-3 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(3, 100px) 20px 105px;
}

.column-ingredient-2 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(2, 100px) 20px 105px;
}

.column-ingredient-1 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(1, 100px) 20px 105px;
}

@media screen and (width <= 1580px) {
  .column-ingredient-6 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(6, 74px) 20px 105px;
  }

  .column-ingredient-5 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(5, 74px) 20px 105px;
  }

  .column-ingredient-4 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(4, 74px) 20px 105px;
  }

  .column-ingredient-3 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(3, 74px) 20px 105px;
  }

  .column-ingredient-2 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(2, 74px) 20px 105px;
  }

  .column-ingredient-1 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(1, 74px) 20px 105px;
  }

  .column__sortable .column-positioned {
    word-break: break-all;
  }
}

.list {
  background: #fff;
  padding: 0 12px 12px;
  font-size: 12px;
  position: relative;
  margin-bottom: 17px;
}

.list-loader {
  background: rgb(255 255 255 / 40%);
  display: flex;
  position: absolute;
  inset: 0;
  align-items: top;
  justify-content: center;
  z-index: 1000;
  cursor: wait;
}

.list-loader-bar {
  background-color: transparent;
  border-radius: 9999px;
  width: calc(100% - 24px);
  margin: 52px 12px;
  height: 2px;
  position: relative;
  overflow: hidden;
}

.list-loader-bar__progress {
  background-color: var(--color-main-100);
  border-radius: 9999px;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 50%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: progress-bar;
}

@keyframes progress-bar {
  from {
    left: -50%;
  }

  to {
    left: 100%;
  }
}

.column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.column.base {
  font-family: Montserrat-Medium;
}

.column__sortable {
  height: 52px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.column__sortable.disabled {
  pointer-events: none;
  margin-right: 14px;
}

.column__sortable .base-icon {
  cursor: pointer;
}

.empty-products span.green .base-icon {
  margin-top: 1px;
}

.header .column.settings .base-icon {
  margin-left: 0;
}

.column__sortable.active,
.header .column .column__sortable.active .base-icon {
  color: var(--color-main-100);
}

.header {
  height: 52px;
  border-bottom: 2px solid #eaeaea;
  margin-bottom: 12px;
  font-family: Montserrat-Medium;
  color: var(--color-main-100);
  position: sticky;
  z-index: 150;
  top: 136px;
  background: #fff;
}

.header.sticky-extended {
  top: 470px;
}

.header .column {
  color: var(--color-primary-text);
}

.header .column.settings {
  justify-content: flex-end;
  margin-right: 16px;
}

.column.favorite {
  height: 40px;
}

.header .column :deep(.base-icon) {
  margin-left: 4px;
}

.header__name {
  justify-content: left;
}

.empty-results {
  display: flex;
  align-items: center;
}

.empty-results :deep(.base-icon) {
  margin-right: 5px;
}

.izz-confirmed-box {
  min-height: 65px;
}

.izz-confirmed-box,
.izz-unconfirmed-box {
  padding: 8px 18px 16px;
  background: #fff;
  color: var(--color-primary-text);
  max-width: 496px;
  font-size: 12px;
  font-family: Montserrat-Regular;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.izz-unconfirmed-box {
  padding-top: 18px;
}

.izz-confirmed-box .base-button {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.izz-confirmed-box .izz-content {
  line-height: 1.67;
  max-width: 336px;
}

.izz-unconfirmed-box .izz-content {
  line-height: 1.67;
  max-width: 355px;
}

.izz-unconfirmed-box .base-button {
  min-width: 96px;
  margin-left: auto;
  margin-top: -15px;
}

.izz-confirmed-box .modal-link,
.izz-unconfirmed-box .modal-link {
  font-family: Montserrat-SemiBold;
  color: var(--color-main-100);
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}

.izz-unconfirmed-box .modal-link {
  margin-left: 0;
}

.izz-unconfirmed-box .izz-color {
  color: var(--color-main-100);
  font-family: Montserrat-SemiBold;
}

.izz-unconfirmed-box img,
.izz-confirmed-box img {
  height: 60px;
  width: 115px;
  box-sizing: border-box;
  padding-bottom: 9px;
}

.empty-products {
  font-family: Montserrat-Medium;
  border: 2px dashed var(--color-main-100);
  margin: 32px 0 20px;
  padding: 19px 0;
  text-align: center;
  line-height: 1.4;
}

.empty-products span {
  font-family: Montserrat-Bold;
}

.empty-products span.green {
  color: var(--color-main-100);
}

.column__sortable .column-positioned {
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
