<template>
  <div
    v-if="izzConditionsConfirmed && !loading"
    class="mt-space-8 izz-confirmed-box"
  >
    <img src="@/assets/img/izz.png">
    <div class="izz-content">
      Warunki korzystania z Bazy Danych Produktów i Potraw Instytutu Żywności i Żywienia.
      <StyledButton @click="showIngredientsIzzModal">
        Zobacz więcej
      </StyledButton>
    </div>
  </div>
  <div
    v-else-if="!loading"
    class="izz-unconfirmed-box"
  >
    <img src="@/assets/img/izz.png">
    <div class="izz-content">
      Aby mieć dostęp do <span class="izz-color">dodatkowych 800 produktów</span><br>
      Zaakceptuj
      <span
        class="modal-link"
        @click="showIngredientsIzzModal"
      > warunki </span>
      korzystania z Bazy Danych Produktów i Potraw Instytutu Żywności i Żywienia.
      <StyledButton @click.once="izzConditionsConfirm()">
        Akceptuję
      </StyledButton>
    </div>
  </div>
  <BaseModal
    v-if="ingredientsIzzModalVisible"
    :modal-class="'ingredients-izz-modal'"
    @close="hideIngredientsIzzModal"
  >
    <template #title>
      Warunki korzystania z Bazy danych produktów IŻŻ
    </template>
    <div class="content">
      <div>
        Wartość odżywcza produktów pochodzi ze źródła: Baza danych
        <span>„Tabele wartości odżywczej produktów spożywczych i potraw – Instytut Żywności i Żywienia,
          Warszawa 2017, Wydanie IV rozszerzone i uaktualnione.”</span>
        i zostały wykorzystane w ramach kalkulatora za zgodą Instytutu Żywności i Żywienia w
        Warszawie. Użytkownik Kalkulatora zobowiązany jest do przestrzegania postanowień licencji
        wskazanych poniżej.
      </div>
      <div>Użytkownik kalkulatora jest uprawniony do:</div>
      <div>
        - wyświetlania wartości odżywczych produktów i posiłków obliczonych przy użyciu kalkulatora,
        na urządzeniu użytkownika kalkulatora w trybie online,
      </div>
      -uzyskania przez użytkownika kalkulatora wersji drukowanej lub elektronicznej w formacie PDF,
      wartości odżywczych produktów i posiłków, obliczonych przy użyciu kalkulatora i przekazania go
      osobie trzeciej,
      <div>
        - wykorzystania prezentowanych danych i udostępniania osobom trzecim, wyłącznie za
        pośrednictwem serwisu Kcalmar.com; Kcalmar.pro oraz aplikacji mobilnych „Kcalmar dieta i
        przepisy”.
      </div>
      <div>
        - Użytkownik kalkulatora nie jest uprawniony do dokonywania opracowań lub przystosowań,
        wprowadzania zmian lub uzupełnień odnośnie do wyświetlanych danych, które pochodzą z bazy
        danych „Tabele wartości odżywczej produktów spożywczych i potraw. Instytut Żywności i
        Żywienia, Warszawa 2017, Wydanie IV rozszerzone i uaktualnione” (dalej: „Baza”).
      </div>
      <div>
        - Użytkownik kalkulatora nie posiada prawa do dokonywania tłumaczenia jakiejkolwiek części
        prezentowanych mu danych.
      </div>
      - Użytkownik aplikacji kalkulatora nie jest nadto uprawniony do wypożyczania, wynajmowania ani
      do innych form przekazywania osobom trzecim udzielonej w ramach korzystania z kalkulatora
      licencji poza przypadkami opisanymi powyżej.
      <div>
        -Na użytkowniku kalkulatora spoczywa obowiązek zabezpieczenia danych pochodzących z Bazy
        przed ich nieuprawnionym rozpowszechnianiem i kopiowaniem.
      </div>
    </div>
    <template #actions>
      <div
        v-if="!izzConditionsConfirmed"
        class="actions"
      >
        <StyledButton
          variant="outline"
          @click.once="hideIngredientsIzzModal"
        >
          Anuluj
        </StyledButton>
        <StyledButton @click.once="izzConditionsConfirm()">
          Akceptuję
        </StyledButton>
      </div>
      <div
        v-else
        class="actions confirmed"
      >
        <StyledButton
          variant="outline"
          @click.once="hideIngredientsIzzModal"
        >
          Zamknij
        </StyledButton>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import BaseModal from '@/components/BaseModal.vue'
import { StyledButton } from '@/components/StyledButton'
import { ingredientsService } from '@/services/ingredientsService'
import { reportError } from '@/utils/reportError'

import { useIngredientsProvider } from './provider'

const { izzConditionsConfirmed, ingredientsIzzModalVisible, loading } = useIngredientsProvider()

const showIngredientsIzzModal = () => {
  ingredientsIzzModalVisible.value = true
}
const hideIngredientsIzzModal = () => {
  ingredientsIzzModalVisible.value = false
}
const izzConditionsConfirm = async () => {
  try {
    await ingredientsService.izzConditionsConfirm()
    izzConditionsConfirmed.value = true
  } catch (error) {
    reportError(error, 'Error during izzConditionsConfirm')
  }
  hideIngredientsIzzModal()
}
</script>
<style>
.ingredients-izz-modal {
  width: 748px !important;
  padding: 32px 80px !important;
}

.base-modal-title {
  font-size: 24px !important;
  font-family: Montserrat-Medium !important;
}
</style>
<style scoped>
.content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.content div span {
  font-family: Montserrat-Medium;
  font-size: 12px;
}

.actions {
  width: 270px;
  display: flex;
  justify-content: space-between;
}

.actions.confirmed {
  justify-content: flex-end;
}

.izz-confirmed-box {
  min-height: 65px;
}

.izz-confirmed-box,
.izz-unconfirmed-box {
  padding: 8px 18px 16px;
  background: #fff;
  color: var(--color-primary-text);
  max-width: 496px;
  font-size: 12px;
  font-family: Montserrat-Regular;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.izz-unconfirmed-box {
  padding-top: 18px;
}

.izz-confirmed-box .base-button {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.izz-confirmed-box .izz-content {
  line-height: 1.67;
  max-width: 336px;
}

.izz-unconfirmed-box .izz-content {
  line-height: 1.67;
  max-width: 355px;
}

.izz-unconfirmed-box .base-button {
  min-width: 96px;
  margin-left: auto;
  margin-top: -15px;
}

.izz-confirmed-box .modal-link,
.izz-unconfirmed-box .modal-link {
  font-family: Montserrat-SemiBold;
  color: var(--color-main-100);
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}

.izz-unconfirmed-box .modal-link {
  margin-left: 0;
}

.izz-unconfirmed-box .izz-color {
  color: var(--color-main-100);
  font-family: Montserrat-SemiBold;
}

.izz-unconfirmed-box img,
.izz-confirmed-box img {
  height: 60px;
  width: 115px;
  box-sizing: border-box;
  padding-bottom: 9px;
}
</style>
