<template>
  <DishList />
</template>

<script lang="ts" setup>
import type { DishSearchByIngredientResponse, DishSearchIngredient } from '@/services/dishService'
import type { DishInFormType } from '@/types/Diet'

import { D } from '@mobily/ts-belt'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { klona } from 'klona'
import { storeToRefs } from 'pinia'
import { onBeforeMount, provide, ref } from 'vue'

import { useChosenNutrients } from '@/hooks/useChosenNutrients'
import { useSortBy } from '@/hooks/useSortBy'
import DishList from '@/pages/dishes/DishList.vue'
import { dishService } from '@/services/dishService'
import { useGlobalStore } from '@/store/globalStore'

import { DishListInject } from './provider'

const queryClient = useQueryClient()
const globalStore = useGlobalStore()
const { hasPerm, showAppUpgradePlanModal, createMessage } = globalStore
const { user } = storeToRefs(globalStore)
const favoriteDishesIds = ref<number[]>(user?.value?.favourite_dishes ?? [])
const searchAllTagsStatus = ref(true)
const checkSearchAllTagsStatus = () => {
  searchAllTagsStatus.value = sessionStorage.getItem('searchAllTags') !== 'false'
}
const { sortBy, sortByReverse, setSortBy, sort, getIconBySort } = useSortBy<
  keyof DishInFormType,
  // @ts-expect-error
  DishInFormType
>('name')
const currentDishId = ref<undefined | number>()
const extendedForm = ref(false)
const dishToDelete = ref<number | undefined>(undefined)

const hideDishForm = () => {
  extendedForm.value = false
  currentDishId.value = undefined
}
const scrollDishContainerToTop = () => {
  const dishContainer = document.getElementById('dynamic-container')
  if (dishContainer) {
    dishContainer.scrollTop = 0
  }
}
const showDishForm = (dishId: number) => {
  currentDishId.value = dishId
  scrollDishContainerToTop()
}

const toggleExtended = (forcedValue?: boolean) => {
  extendedForm.value = forcedValue ?? !extendedForm.value
}

const toggleFavorite = async (dishId: number) => {
  if (hasPerm('accounts.dish_favourite')) {
    await toggleFavoriteAsync(dishId)
  } else {
    showAppUpgradePlanModal()
  }
}

const {
  chosenNutrients,
  chosenNutrientsLength,
  showChosenNutrientsModal,
  hideChosenNutrientsModal,
  chosenNutrientsModalVisible,
  loadCustomNutrients
} = useChosenNutrients()

const isDishFavorite = (dishId: number | undefined) => {
  if (!dishId) {
    return false
  }
  return favoriteDishesIds.value.includes(dishId)
}

const showDishDeleteModal = (dishId?: number) => {
  dishToDelete.value = dishId
}

const hideDishDeleteModal = () => {
  dishToDelete.value = undefined
}

const {
  data: dishes,
  isFetching: loading,
  refetch: refetchDishes
} = useQuery({
  queryKey: ['dishes', chosenNutrients],
  queryFn: () => dishService.fetchDishes(chosenNutrients.value),
  select: (data) => [...data].sort(sort),
  initialData: [] as DishInFormType[]
})

const { data: dishesWithIngredients, isFetched: dishesWithIngredientsLoaded } = useQuery({
  queryKey: ['dishSearchByIngredient'],
  queryFn: () => dishService.dishSearchByIngredient(),
  initialData: {} as DishSearchByIngredientResponse
})

const { data: searchIngredients } = useQuery({
  queryKey: ['searchIngredients', chosenNutrients],
  queryFn: () => dishService.fetchSearchIngredients(chosenNutrients.value),
  initialData: [] as DishSearchIngredient[]
})

const { mutateAsync: toggleFavoriteAsync } = useMutation({
  mutationFn: (id: number) => dishService.toggleFavoriteDish(id),
  onSuccess: (__, id) => {
    if (!isDishFavorite(id)) {
      favoriteDishesIds.value.push(id)
      createMessage({ title: 'Dodano potrawę do ulubionych' })
    } else {
      favoriteDishesIds.value = favoriteDishesIds.value.filter((i) => i !== id)
      createMessage({ title: 'Usunięto potrawę z ulubionych' })
    }
  }
})

const { mutateAsync: addNewDishAsync } = useMutation({
  mutationFn: (data: DishInFormType) => {
    return dishService.createDish(data)
  },
  onSuccess: ({ id }, params) => {
    queryClient.setQueryData(['dishes', chosenNutrients], (oldData: DishInFormType[]) => {
      return [{ ...params, id, portions: params.max_portions }, ...oldData]
    })
    queryClient.setQueryData(
      ['dishSearchByIngredient'],
      (oldData: DishSearchByIngredientResponse) => {
        return D.set(oldData, id.toString(), {
          dish_types: [],
          ingredients: [],
          meal_types: []
        })
      }
    )
    showDishForm(id)
  }
})

const { mutateAsync: deleteDishAsync } = useMutation({
  mutationFn: (id: number) => {
    return dishService.deleteDish(id)
  },
  onSuccess: (__, id) => {
    hideDishForm()
    queryClient.setQueryData(['dishes', chosenNutrients], (oldData: DishInFormType[]) =>
      oldData.filter((dish) => dish.id !== id)
    )
    hideDishDeleteModal()
    createMessage({ title: 'Usunięto potrawę' })
  }
})

const { mutateAsync: copyDishAsync } = useMutation({
  mutationFn: (dishId: number) => {
    return dishService.copyDish(dishId)
  },
  onSuccess: ({ id: dishId }, originalDishId) => {
    hideDishDeleteModal()
    hideDishForm()
    if (isDishFavorite(originalDishId)) {
      favoriteDishesIds.value.push(dishId)
    }
    queryClient.setQueryData(
      ['dishSearchByIngredient'],
      (oldData: DishSearchByIngredientResponse) => {
        return D.set(oldData, dishId.toString(), oldData[originalDishId])
      }
    )
    queryClient.setQueryData(['dishes', chosenNutrients], (oldData: DishInFormType[]) => {
      const oldDish = oldData.find((dish) => dish.id === originalDishId)
      if (oldDish) {
        const newDish = klona<DishInFormType>(oldDish)
        newDish.name = newDish.name + ' - kopia'
        newDish.id = dishId
        newDish.is_public = false
        return [newDish, ...oldData]
      }
      return oldData
    })
    createMessage({ title: 'Skopiowano potrawę' })
  }
})

provide(DishListInject, {
  addNewDishAsync,
  chosenNutrients,
  chosenNutrientsLength,
  chosenNutrientsModalVisible,
  copyDishAsync,
  currentDishId,
  deleteDishAsync,
  dishes,
  dishesWithIngredients,
  dishToDelete,
  extendedForm,
  favoriteDishesIds,
  hideChosenNutrientsModal,
  hideDishDeleteModal,
  hideDishForm,
  isDishFavorite,
  loadCustomNutrients,
  dishesWithIngredientsLoaded,
  loading,
  refetchDishes,
  searchAllTagsStatus,
  searchIngredients,
  showChosenNutrientsModal,
  showDishDeleteModal,
  showDishForm,
  toggleExtended,
  toggleFavorite,
  sortBy,
  sortByReverse,
  setSortBy,
  sort,
  getIconBySort
})

onBeforeMount(() => {
  checkSearchAllTagsStatus()
})
</script>
