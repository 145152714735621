<template>
  <div
    ref="navigationRef"
    :class="$style.navigation"
    :style="{ top: searchHeight + 'px' }"
  >
    <Space
      :padding-y="[12, 0]"
      direction="columns"
      align-x="end"
      class="mb-8"
    >
      <KcalmarButton
        left-icon="add"
        variant="primary"
        size="small"
      >
        Dodaj pacjenta
      </KcalmarButton>
      <KcalmarIconButton
        variant="secondary"
        name="settings-4-fill"
      />
    </Space>
  </div>
</template>

<script lang="ts" setup>
import { useCssModule } from 'vue'

import { KcalmarButton } from '@ui/KcalmarButton'
import { KcalmarIconButton } from '@ui/KcalmarIconButton'

import { Space } from '@/components/Space'

import { usePatientsProvider } from './provider'

const $style = useCssModule()
const { navigationRef, searchHeight } = usePatientsProvider()
</script>

<style lang="scss" scoped module>
.navigation {
  background-color: #f8f8f8;
  position: sticky;
  z-index: 2;
}
</style>
