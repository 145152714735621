<template>
  <div class="shopping-list">
    <div class="menu">
      <div class="menu-ingredient-count">
        {{ ingredientsCount }}
      </div>
      <div class="menu-day-select-label">
        Lista na
      </div>
      <BaseSelect
        v-if="hasPerm('accounts.shopping_list_filter_by_day')"
        class="menu-day-select"
        :options="listForOptions"
        :model-value="listFor"
        @update:model-value="setListFor"
      />
      <div
        v-else
        class="select-dummy"
        @click="globalStore.showAppUpgradePlanModal"
      >
        Cały tydzień
      </div>
      <BaseCheckbox
        v-if="hasPerm('accounts.shopping_list_sort')"
        class="menu-group"
        :label="'Grupowanie'"
        :model-value="groupBy"
        @update:model-value="toggleGroupBy"
      />
      <div
        v-else
        @click="globalStore.showAppUpgradePlanModal"
      >
        <BaseCheckbox
          class="menu-group"
          :label="'Grupowanie'"
          :model-value="groupBy"
          disabled
        />
      </div>
    </div>
    <div class="header">
      <div
        class="header-name"
        :class="{ sorted: sortBy === 'name' }"
        @click="
          hasPerm('accounts.shopping_list_sort')
            ? setSortBy('name')
            : globalStore.showAppUpgradePlanModal()
        "
      >
        Nazwa produktu
        <BaseIcon
          class="icon sort"
          size="18"
          :name="getIconBySort('name')"
        />
      </div>
      <div
        class="header-quantity"
        :class="{ sorted: sortBy === 'quantity' }"
        @click="
          hasPerm('accounts.shopping_list_sort')
            ? setSortBy('quantity')
            : globalStore.showAppUpgradePlanModal()
        "
      >
        Ilość (g)
        <BaseIcon
          class="icon sort"
          size="18"
          :name="getIconBySort('quantity')"
        />
      </div>
      <div class="header-measurement">
        Miara opisowa
      </div>
      <div class="header-actions" />
    </div>
    <div class="list">
      <template v-if="shoppingList.ingredients.length > 0">
        <template v-if="groupBy">
          <template
            v-for="group in shoppingList.groups"
            :key="`group-${group}`"
          >
            <div
              class="group"
              :class="{ expanded: !closedGroups.includes(group) }"
              @click="toggleGroup(group)"
            >
              <BaseIcon
                class="icon expander"
                size="18"
                :name="closedGroups.includes(group) ? 'expand_more' : 'expand_less'"
              />

              <div class="group-name">
                {{ group }}
              </div>
            </div>
            <template v-for="(ingredient, ingredientIndex) in shoppingList.ingredients">
              <template v-if="ingredient.group === group && !closedGroups.includes(group)">
                <div
                  :key="`group-${group}-ing-${ingredient.id}-ing-container`"
                  class="ing-container"
                  :class="{
                    active:
                      expandedIngredient === ingredient.id ||
                      activeAddDishOrIngredient === ingredientIndex
                  }"
                >
                  <div class="ingredient-name">
                    <div
                      v-if="activeAddDishOrIngredient !== ingredientIndex"
                      class="ingredient-name-with-allergens"
                    >
                      <BaseTooltip
                        class="base-tooltip--top base-tooltip--center ingredient-name-tooltip"
                        :class="{ hide: !canDietBeEdited }"
                        @click.stop="handleChangeIngredientClick(ingredientIndex)"
                      >
                        <div
                          class="ingredient-name-button"
                          :class="{ expanded: expandedIngredient === ingredient.id }"
                        >
                          {{ ingredient.name }}
                        </div>
                        <template #tooltip>
                          Kliknij, aby zamienić składnik w całym jadłospisie
                        </template>
                      </BaseTooltip>
                      <div
                        v-if="ingredient.notLike && hasPerm('accounts.patient_allergens')"
                        class="allergens not-like"
                      />
                      <div
                        v-if="ingredient.like && hasPerm('accounts.patient_allergens')"
                        class="allergens like"
                      />
                      <div
                        v-if="ingredient.allergies && hasPerm('accounts.patient_allergens')"
                        class="allergens allergies"
                      />
                      <div
                        v-if="ingredient.intolerances && hasPerm('accounts.patient_allergens')"
                        class="allergens intolerances"
                      />
                    </div>
                    <DishOrIngredientAddInput
                      v-else
                      test-id="DietDetailShoppingListGroupBy"
                      class="add-ingredient-input"
                      mode="ingredient"
                      :filters="dishFlags"
                      @select="replaceIngredient(ingredient.id, $event.dishOrIngredientId)"
                      @keyup.esc="setActiveAddDishOrIngredient()"
                    />
                  </div>
                  <div class="ingredient-quantity">
                    {{ ingredient.quantity }}
                  </div>
                  <div class="ingredient-measurement">
                    {{ ingredient.measurementQuantity }} x {{ ingredient.measurement }}
                  </div>
                  <div
                    class="ingredient-actions"
                    :class="{ expanded: expandedIngredient === ingredient.id }"
                  >
                    <BaseTooltip
                      class="base-tooltip--top base-tooltip--right ingredient-expander-tooltip"
                    >
                      <BaseIcon
                        class="icon expander"
                        size="18"
                        :name="expandedIngredient === ingredient.id ? 'expand_less' : 'expand_more'"
                        @click="toggleIngredient(ingredient.id)"
                      />

                      <template #tooltip>
                        <template v-if="expandedIngredient === ingredient.id">
                          Zwiń informacje o potrawach
                        </template>
                        <template v-else>
                          Rozwiń, aby zobaczyć w jakich potrawach i w których dniach występuje
                          produkt
                        </template>
                      </template>
                    </BaseTooltip>
                  </div>
                </div>
                <div
                  v-if="ingredient.id === expandedIngredient"
                  :key="`group-${group}-ing-${ingredient.id}`"
                  class="dishes-and-ingredients"
                >
                  <template
                    v-for="(dishOrIngredient, dishOrIngredientIndex) in getDishesAndIngredients(
                      ingredient,
                      true
                    )"
                    :key="`group-${group}-ing-${ingredient.id}-doi-${dishOrIngredientIndex}-name`"
                  >
                    <div class="dish-or-ingredient-name">
                      {{ dishOrIngredient.name }}
                    </div>
                    <div class="dish-or-ingredient-days">
                      <template
                        v-for="(day, dayIndex) in dishOrIngredient.days"
                        :key="`group-${group}-ing-${ingredient.id}-doi-${dishOrIngredientIndex}-days-${dayIndex}`"
                      >
                        <BaseTooltip
                          class="base-tooltip--top base-tooltip--right dish-or-ingredient-day-tooltip"
                          :class="{ disabled: !day }"
                        >
                          <div
                            :key="`group-${group}-ing-${ingredient.id}-doi-${dishOrIngredientIndex}-days-${dayIndex}`"
                            class="dish-or-ingredient-day"
                            :class="{ active: !!day }"
                            @click="
                              hasPerm('accounts.shopping_list_sort')
                                ? redirectToDietDetailDay(day)
                                : globalStore.showAppUpgradePlanModal()
                            "
                          >
                            {{ dayShort(dayIndex) }}
                          </div>
                          <template #tooltip>
                            Przejdź do widoku dziennego
                          </template>
                        </BaseTooltip>
                      </template>
                    </div>
                  </template>
                </div>
              </template>
            </template>
          </template>
        </template>
        <template v-else>
          <template
            v-for="(ingredient, ingredientIndex) in shoppingList.ingredients"
            :key="`group-${ingredientIndex}-ing-${ingredient.id}-ing-container`"
          >
            <div
              class="ing-container"
              :class="{
                active:
                  expandedIngredient === ingredient.id ||
                  activeAddDishOrIngredient === ingredientIndex
              }"
            >
              <div class="ingredient-name">
                <div
                  v-if="activeAddDishOrIngredient !== ingredientIndex"
                  class="ingredient-name-with-allergens"
                >
                  <BaseTooltip
                    class="base-tooltip--top base-tooltip--center ingredient-name-tooltip"
                    :class="{ hide: !canDietBeEdited }"
                    @click.stop="handleChangeIngredientClick(ingredientIndex)"
                  >
                    <div
                      class="ingredient-name-button"
                      :class="{ expanded: expandedIngredient === ingredient.id }"
                    >
                      {{ ingredient.name }}
                    </div>
                    <template #tooltip>
                      Kliknij, aby zamienić składnik w całym jadłospisie
                    </template>
                  </BaseTooltip>
                  <div
                    v-if="ingredient.notLike"
                    class="allergens not-like"
                  />
                  <div
                    v-if="ingredient.like"
                    class="allergens like"
                  />
                  <div
                    v-if="ingredient.allergies"
                    class="allergens allergies"
                  />
                  <div
                    v-if="ingredient.intolerances"
                    class="allergens intolerances"
                  />
                </div>
                <DishOrIngredientAddInput
                  v-else
                  test-id="DietDetailShoppingList"
                  class="add-ingredient-input"
                  mode="ingredient"
                  @select="replaceIngredient(ingredient.id, $event.dishOrIngredientId)"
                  @keyup.esc="setActiveAddDishOrIngredient()"
                />
              </div>
              <div class="ingredient-quantity">
                {{ ingredient.quantity }}
              </div>
              <div class="ingredient-measurement">
                {{ ingredient.measurementQuantity }} x {{ ingredient.measurement }}
              </div>
              <div
                :key="`ing-${ingredient.id}-actions`"
                class="ingredient-actions"
                :class="{ expanded: expandedIngredient === ingredient.id }"
              >
                <BaseTooltip
                  class="base-tooltip--top base-tooltip--right ingredient-expander-tooltip"
                >
                  <BaseIcon
                    class="icon expander"
                    size="18"
                    :name="expandedIngredient === ingredient.id ? 'expand_less' : 'expand_more'"
                    @click="toggleIngredient(ingredient.id)"
                  />

                  <template #tooltip>
                    <template v-if="expandedIngredient === ingredient.id">
                      Zwiń informacje o potrawach
                    </template>
                    <template v-else>
                      Rozwiń, aby zobaczyć w jakich potrawach i w których dniach występuje produkt
                    </template>
                  </template>
                </BaseTooltip>
              </div>
            </div>
            <div
              v-if="ingredient.id === expandedIngredient"
              :key="`ing-${ingredient.id}`"
              class="dishes-and-ingredients"
            >
              <template
                v-for="(dishOrIngredient, dishOrIngredientIndex) in getDishesAndIngredients(
                  ingredient,
                  false
                )"
                :key="`ing-${ingredient.id}-doi-${dishOrIngredientIndex}-name`"
              >
                <div class="dish-or-ingredient-name">
                  {{ dishOrIngredient.name }}
                </div>
                <div class="dish-or-ingredient-days">
                  <template
                    v-for="(day, dayIndex) in dishOrIngredient.days"
                    :key="`group-${group}-ing-${ingredient.id}-doi-${dishOrIngredientIndex}-days-${dayIndex}`"
                  >
                    <BaseTooltip
                      class="base-tooltip--top base-tooltip--right dish-or-ingredient-day-tooltip"
                    >
                      <div
                        :key="`group-ing-${ingredient.id}-doi-${dishOrIngredientIndex}-days-${dayIndex}`"
                        class="dish-or-ingredient-day"
                        :class="{ active: !!day }"
                        @click="redirectToDietDetailDay(day)"
                      >
                        {{ dayShort(dayIndex) }}
                      </div>
                      <template #tooltip>
                        Przejdź do widoku dziennego
                      </template>
                    </BaseTooltip>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </template>
      </template>
      <div
        v-else
        class="list-empty"
      >
        Brak produktów na liście zakupów.
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Coordinates, DietIngredient, StoreDietDish } from '@/types/Diet'

import { A, F, G } from '@mobily/ts-belt'
import { useEventListener } from '@vueuse/core'
import round from 'lodash/round'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import DishOrIngredientAddInput from '@/components/DishOrIngredientAddInput.vue'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import { dayMapShort, numericMap, getIconBySortFn, groupMap } from '@/utils/common'
import { reportError } from '@/utils/reportError'

type Group = string

type ShoppingListIngredient = {
  allergies: boolean
  group: string
  id: number
  intolerances: boolean
  like: boolean
  measurement: DietIngredient['measurement']
  measurementQuantity: number
  name: string
  notLike: boolean
  quantity: number
}
type ShoppingListDOI = {
  days: (Coordinates | null)[]
  name: string
}
type ShoppingList = {
  dishesAndIngredients: Record<number, ShoppingListDOI[]>
  groups: string[]
  ingredients: ShoppingListIngredient[]
}

const dayslistForOptions = [
  { value: 'all', text: 'Cały tydzień' },
  { value: '0', text: 'Poniedziałek' },
  { value: '1', text: 'Wtorek' },
  { value: '2', text: 'Środa' },
  { value: '3', text: 'Czwartek' },
  { value: '4', text: 'Piątek' },
  { value: '5', text: 'Sobota' },
  { value: '6', text: 'Niedziela' }
]

const numericListForOptions = [
  { value: 'all', text: 'Wszystkie dni' },
  { value: '0', text: 'Dzień 1' },
  { value: '1', text: 'Dzień 2' },
  { value: '2', text: 'Dzień 3' },
  { value: '3', text: 'Dzień 4' },
  { value: '4', text: 'Dzień 5' },
  { value: '5', text: 'Dzień 6' },
  { value: '6', text: 'Dzień 7' }
]

const listFor = ref('all')
const groupBy = ref(true)
const sortBy = ref('name')
const sortByReverse = ref(false)
const closedGroups = ref<string[]>([])
const expandedIngredient = ref()
const activeAddDishOrIngredient = ref<number>()

const route = useRoute()
const router = useRouter()
const globalStore = useGlobalStore()
const dietsStore = useDietsStore()
const { hasPerm } = globalStore
const { diet, dietAllergens, canDietBeEdited } = storeToRefs(dietsStore)
const listForOptions = computed(() => {
  return diet.value?.data.diet_type === 'days_of_week' ? dayslistForOptions : numericListForOptions
})
const dishFlags = computed(() => {
  return diet.value?.data.dish_search_filters
})
const shoppingList = computed(() => {
  const currentShoppingList: ShoppingList = {
    groups: [],
    ingredients: [],
    dishesAndIngredients: {}
  }

  if (G.isNullable(diet.value)) {
    return currentShoppingList
  }

  const meals = diet.value.data.meals

  if (meals.length > 0) {
    const daysLength = meals[0]?.days.length ?? 0
    A.forEach(A.makeWithIndex(daysLength, F.identity), (dayIndex) => {
      A.forEachWithIndex(meals, (mealIndex, meal) => {
        const dishesAndIngredients = meal.days[dayIndex]?.dishes_and_ingredients

        A.forEachWithIndex(
          dishesAndIngredients ?? [],
          (dishOrIngredientIndex, dishOrIngredient) => {
            const ingredients =
              dishOrIngredient.type === 'dish' ? dishOrIngredient.ingredients : [dishOrIngredient]

            let firstEncounter = true
            const coordinates = dietsStore.getRelatedCoordinates(
              mealIndex,
              dayIndex,
              dishOrIngredientIndex
            )
            coordinates.sort((a, b) => {
              if (a.dayIndex !== b.dayIndex) return a.dayIndex < b.dayIndex ? -1 : 1
              if (a.mealIndex !== b.mealIndex) return a.mealIndex < b.mealIndex ? -1 : 1
              return a.dishOrIngredientIndex < b.dishOrIngredientIndex ? -1 : 1
            })

            if (coordinates.length > 1) {
              const firstCoordinate = coordinates[0]
              if (
                firstCoordinate &&
                (firstCoordinate.dayIndex !== dayIndex ||
                  firstCoordinate.mealIndex !== mealIndex ||
                  firstCoordinate.dishOrIngredientIndex !== dishOrIngredientIndex)
              ) {
                firstEncounter = false
              }
            }
            A.forEach(ingredients, (ingredient) => {
              if (
                firstEncounter &&
                (listFor.value === 'all' || parseInt(listFor.value) === dayIndex)
              ) {
                if ('category' in ingredient) {
                  const group = groupMap[ingredient.category]
                  if (group) {
                    addGroupToShoppingList(currentShoppingList, group)
                    addIngredientToShoppingList(currentShoppingList, ingredient, group)
                  }
                }
              }

              addDishOrIngredientToShoppingList(
                currentShoppingList,
                dishOrIngredient,
                ingredient,
                coordinates
              )
            })
          }
        )
      })
    })
  }

  if (sortBy.value === 'name') {
    currentShoppingList.groups.sort((a, b) => {
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })
    currentShoppingList.ingredients.sort((a, b) => {
      const aName = a.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/\u0142/g, 'l')
      const bName = b.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/\u0142/g, 'l')
      if (aName < bName) return -1
      if (aName > bName) return 1
      return 0
    })
    if (sortByReverse.value) {
      currentShoppingList.groups.reverse()
      currentShoppingList.ingredients.reverse()
    }
  } else {
    currentShoppingList.groups.sort((a, b) => {
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })
    currentShoppingList.ingredients.sort((a, b) => {
      return a.quantity - b.quantity
    })
    if (sortByReverse.value) {
      currentShoppingList.ingredients.reverse()
    }
  }
  return currentShoppingList
})
const ingredientsCount = computed(() => {
  const count = shoppingList.value.ingredients.length
  if (count === 1) return `${count} Produkt`
  if (count > 1 && count < 4) return `${count} Produty`
  return `${count} Produktów`
})

const handleChangeIngredientClick = (ingredientIndex: number) => {
  if (!canDietBeEdited.value) {
    return
  } else if (hasPerm('accounts.shopping_list_ingredient_change')) {
    setActiveAddDishOrIngredient(ingredientIndex)
  } else {
    globalStore.showAppUpgradePlanModal()
  }
}
const dayShort = (dayIndex: number) => {
  return diet.value?.data.diet_type === 'days_of_week'
    ? dayMapShort[dayIndex]
    : numericMap[dayIndex]
}
const getDishesAndIngredients = (
  ingredient: ShoppingList['ingredients'][number],
  sortByName: boolean
) => {
  const dishesAndIngredients =
    shoppingList.value.dishesAndIngredients[ingredient.id] ?? ([] as ShoppingListDOI[])

  if (sortByName && G.isArray(dishesAndIngredients)) {
    dishesAndIngredients.sort((a, b) => {
      const aName = a.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/\u0142/g, 'l')
      const bName = b.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/\u0142/g, 'l')
      if (aName < bName) return -1
      if (aName > bName) return 1
      return 0
    })
  }
  return dishesAndIngredients
}
const addGroupToShoppingList = (list: ShoppingList, group: Group) => {
  if (!list.groups.includes(group)) {
    list.groups.push(group)
  }
  return list
}
const addIngredientToShoppingList = (
  list: ShoppingList,
  ingredient: DietIngredient,
  group: Group
) => {
  const id = ingredient.ingredient_id
  const quantity = ingredient.quantity
  const measurementQuantity = quantity / ingredient.grams

  let ingredientElement = list.ingredients.find((i) => i.id === id)
  // Add/Update ingredient in shopping list
  if (ingredientElement === undefined) {
    ingredientElement = {
      id,
      allergies: dietAllergens.value.allergies_id.includes(id),
      intolerances: dietAllergens.value.intolerances_id.includes(id),
      like: dietAllergens.value.like_id.includes(id),
      notLike: dietAllergens.value.not_like_id.includes(id),
      group,
      name: ingredient.name,
      measurement: ingredient.measurement,
      quantity: round(quantity, 1),
      measurementQuantity: round(measurementQuantity, 2)
    }
    list.ingredients.push(ingredientElement)
  } else {
    ingredientElement.quantity = round(ingredientElement.quantity + quantity, 1)
    ingredientElement.measurementQuantity = round(
      ingredientElement.measurementQuantity + measurementQuantity,
      2
    )
  }
}
const addDishOrIngredientToShoppingList = (
  sList: ShoppingList,
  dishOrIngredient: StoreDietDish | DietIngredient,
  ingredient: DietIngredient,
  coordinates: Coordinates[]
) => {
  const id = ingredient.ingredient_id

  if (!sList.dishesAndIngredients[id]) {
    sList.dishesAndIngredients[id] = [] as ShoppingListDOI[]
  }
  const list = sList.dishesAndIngredients[id]
  let doi: ShoppingListDOI | undefined = list.find((v) => v.name === dishOrIngredient.name)
  if (doi === undefined) {
    doi = {
      name: dishOrIngredient.name,
      days: [null, null, null, null, null, null, null]
    }
    list.push(doi)
  }

  A.forEach(coordinates, (coordinate) => {
    if (G.isNullable(doi.days[coordinate.dayIndex])) {
      doi.days[coordinate.dayIndex] = coordinate
    }
  })
}
const toggleGroup = (groupName: string) => {
  const groupIndex = closedGroups.value.indexOf(groupName)
  if (groupIndex === -1) {
    closedGroups.value.push(groupName)
  } else {
    closedGroups.value.splice(groupIndex, 1)
  }
}
const toggleIngredient = (ingredientId: number) => {
  if (expandedIngredient.value === ingredientId) {
    expandedIngredient.value = undefined
  } else {
    expandedIngredient.value = ingredientId
  }
}
const setListFor = (value: string) => {
  listFor.value = value
}
const toggleGroupBy = () => {
  groupBy.value = !groupBy.value
}
const redirectToDietDetailDay = (day: Coordinates | null) => {
  if (day) {
    sessionStorage.setItem('redirectMealIndex', day.mealIndex.toString())
    sessionStorage.setItem('redirectDishOrIngredientIndex', day.dishOrIngredientIndex.toString())
    void router.push({
      name: 'DietDetailDay',
      params: {
        dietType: route.params.dietType,
        dietId: route.params.dietId,
        dayIndex: day.dayIndex
      }
    })
  }
}
const setSortBy = (by: string) => {
  if (sortBy.value === by) {
    sortByReverse.value = !sortByReverse.value
  } else {
    sortBy.value = by
    sortByReverse.value = false
  }
}
const setActiveAddDishOrIngredient = (active?: number) => {
  activeAddDishOrIngredient.value = active
}
const replaceIngredient = async (oldIngredientId: number, newIngredientId: number) => {
  try {
    await dietsStore.fetchAndReplaceIngredient({ oldIngredientId, newIngredientId })
    setActiveAddDishOrIngredient()
  } catch (err) {
    reportError(err, 'Error during replacing ingredient', { oldIngredientId, newIngredientId })
  }
}
const hideMenu = () => {
  activeAddDishOrIngredient.value = undefined
}
const getIconBySort = computed(() => getIconBySortFn(sortBy.value, sortByReverse.value))

useEventListener(document, 'click', hideMenu)
</script>

<style scoped>
.allergens {
  display: block;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  flex: 0 0 8px;
}

.allergens.not-like {
  background: var(--color-warning-60);
}

.allergens.like {
  background: var(--color-success-60);
}

.allergens.allergies {
  background: #faa0a8;
}

.allergens.intolerances {
  background: #a0a8fa;
}

.shopping-list {
  background: #fff;
  margin-bottom: 24px;
  padding: 12px;
}

.menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 32px;
  margin-bottom: 30px 12px;
  font-size: 12px;
}

.menu-ingredient-count {
  margin-left: 30px;
  font-family: Montserrat-SemiBold;
  color: var(--color-main-100);
}

.menu-day-select-label {
  margin-left: 24px;
  font-size: 14px;
  font-family: Montserrat-Medium;
}

.menu-day-select {
  margin-left: 17px;
  width: 111px;
}

.select-dummy {
  margin-left: 17px;
  width: 110px;
  color: var(--color-tertiary-text);
  border-bottom: 1px solid var(--color-tertiary-text);
  padding: 4px 21px 4px 8px;
  box-sizing: border-box;
  position: relative;
}

.select-dummy::after {
  content: '';
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -4px;
  width: 4px;
  height: 4px;
  color: var(--color-tertiary-text);
  border-bottom: 1px solid var(--color-primary-text);
  border-left: 1px solid var(--color-primary-text);
  transform: rotate(-45deg);
}

.menu-group {
  margin-left: 24px;
}

.header {
  border-bottom: 2px solid #eaeaea;
  display: grid;
  grid-template-columns: 1fr 125px 125px 250px;
  gap: 12px;
  font-size: 12px;
  margin-bottom: 12px;
  font-family: Montserrat-Medium;
}

.header-name {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-quantity {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
}

.header-measurement {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.sort {
  color: var(--color-primary-text);
  cursor: pointer;
}

.sorted .sort {
  color: var(--color-main-100);
}

.header-name.sorted {
  color: var(--color-main-100);
}

.header-quantity.sorted {
  color: var(--color-main-100);
}

.ingredient-name-button {
  display: inline-block;
  cursor: pointer;
  word-wrap: break-word;
  word-break: break-word;
  margin-right: 6px;
}

.ingredient-name-button:hover {
  font-family: Montserrat-SemiBold;
}

.ingredient-quantity {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 16px;
}

.ingredient-measurement {
  display: flex;
  align-items: center;
  justify-content: left;
}

.ing-container {
  display: contents;
}

.ing-container:hover .ingredient-name-button,
.ing-container:hover .ingredient-quantity,
.ing-container:hover .ingredient-measurement,
.ing-container.active .ingredient-name-button,
.ing-container.active .ingredient-quantity,
.ing-container.active .ingredient-measurement {
  font-family: Montserrat-SemiBold;
}

.expander {
  cursor: pointer;
}

.list {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 125px) minmax(0, 125px) minmax(0, 250px);
  gap: 12px;
  font-size: 12px;
}

.list-empty {
  grid-column: span 4;
  padding: 0 30px;
}

.group {
  background: #f5f5f5;
  grid-column: 1 / span 4;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.group-name {
  font-family: Montserrat-Medium;
}

.group .expander {
  margin: auto 6px;
}

.ingredient-name {
  padding: 0 30px;
}

.ingredient-name-with-allergens {
  display: flex;
  align-items: center;
}

.ingredient-name-button.expanded {
  font-family: Montserrat-SemiBold;
}

.ingredient-actions {
  display: flex;
  justify-content: right;
  align-items: center;
}

.ingredient-actions .expander {
  color: var(--color-primary-text);
}

.dishes-and-ingredients {
  border-radius: 4px;
  background: var(--color-main-10);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: span 4;
  padding: 6px 6px 6px 36px;
  row-gap: 12px;
}

.dish-or-ingredient-name {
  display: flex;
  align-items: center;
}

.dish-or-ingredient-days {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

.dish-or-ingredient-day {
  height: 24px;
  line-height: 24px;
  padding: 0 6px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  font-family: Montserrat-Medium;
  margin-left: 2px;
}

.dish-or-ingredient-day.active {
  font-family: Montserrat-SemiBold;
  background: var(--color-main-100);
  color: #fff;
  cursor: pointer;
}

.dish-or-ingredient-day.active:hover {
  background: var(--color-main-110);
}

.ingredient-expander-tooltip {
  margin-right: 15px;
}

.ingredient-expander-tooltip :deep(.base-tooltip__content) {
  max-width: 200px;
}

.ingredient-name :deep(.base-tooltip) {
  max-width: 100%;
}

.ingredient-name :deep(.base-tooltip.hide) .base-tooltip__content {
  display: none;
}

.ingredient-name :deep(.base-tooltip.hide) .ingredient-name-button {
  cursor: default;
}

.dish-or-ingredient-days :deep(.base-tooltip.disabled) {
  pointer-events: none;
}

.add-ingredient-input {
  width: 350px;
}
</style>
