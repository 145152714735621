<template>
  <i
    :class="classes"
    v-bind="$attrs"
  >
    {{ name }}
  </i>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

type Props = {
  hoverType?: IconType
  name: IconName
  size?: IconSize
  type?: IconType
  variant?: IconVariant
}
const props = withDefaults(defineProps<Props>(), {
  type: 'default',
  variant: 'fill',
  size: '20'
})

const classes = computed(() => {
  return [
    `base-icon`,
    `base-icon--${props.type}`,
    `base-icon--${props.variant}`,
    `base-icon--hover-${props.hoverType ?? props.type}`,
    `size-${props.size}`
  ]
})
</script>
