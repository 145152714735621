<template>
  <h1>Cześć, {{ user?.first_name ?? '' }}! <img src="@/assets/img/icons/emoji-hello.svg?url"></h1>
  <div
    v-if="!user?.izz_conditions_confirm"
    class="announces"
  >
    <div class="announce">
      <div class="announce__inner">
        <h2>Wymagana zgoda</h2>
        <div class="announce__content">
          <p>
            Aby mieć dostęp do produktów z bazy IŻŻ, zaakceptuj warunki korzystania z&nbsp;Bazy
            Danych Produktów i&nbsp;Potraw Instytutu Żywnosci i&nbsp;Żywienia.
          </p>
          <div>
            <router-link
              class="btt-green"
              :to="{ name: 'IngredientsList' }"
            >
              Wyświetl
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DashboardAnnounces />
  <div class="dashboard">
    <div class="container">
      <div class="container__view">
        <DashboardStats />
        <DashboardActivity />
        <DashboardDynamicContent />
      </div>
      <DashboardFacebook />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue'

import DashboardActivity from '@/pages/dashboard/components/DashboardActivity.vue'
import DashboardAnnounces from '@/pages/dashboard/components/DashboardAnnounces.vue'
import DashboardDynamicContent from '@/pages/dashboard/components/DashboardDynamicContent.vue'
import DashboardFacebook from '@/pages/dashboard/components/DashboardFacebook.vue'
import DashboardStats from '@/pages/dashboard/components/DashboardStats.vue'
import { useDashboardStore } from '@/store/dashboardStore'
import { useGlobalStore } from '@/store/globalStore'
import { reportError } from '@/utils/reportError'

const globalStore = useGlobalStore()
const dashboardStore = useDashboardStore()
const user = computed(() => globalStore.user)

const startDashboardData = async () => {
  try {
    await Promise.all([dashboardStore.fetchStats(), dashboardStore.fetchPatientsOptions()])
  } catch (error) {
    reportError(error, 'Error while fetching dashboard data')
  }
}
onMounted(() => {
  void startDashboardData()
})
</script>

<style scoped>
.announces {
  width: 100%;
}

.announce {
  background: #fff;
  margin-bottom: 16px;
  border-radius: 8px;
}

.announce__inner {
  position: relative;
  padding: 16px;
}

.announces button {
  background: transparent;
  cursor: pointer;
}

.announces h2 {
  color: var(--color-primary-text);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  font-family: Montserrat-Medium;
  line-height: 28px;
  text-align: left;
  margin: 0 0 8px;
  display: flex;
  align-items: center;
  position: relative;
}

.announces h2 .material-icon {
  position: absolute;
  left: -21px;
}

.announce p,
.announce .announce__content {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  margin: 0;
  color: var(--color-secondary-text);
}

.announce p {
  padding: 0 40px 0 0;
}

.announce .announce__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.announce .btt-green {
  border-radius: 4px;
  padding: 8px 32px;
  background: var(--color-main-100);
  box-shadow: 0 1px 2px 0 #0000001f;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat-Medium;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  transition: all 0.2s ease;
  text-decoration: none;
  display: block;
}

.announce .btt-green:hover {
  background: #008183;
}

.dashboard {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  justify-content: space-between;
  background: #fff;
  padding: 17px 16px;
}

.container {
  display: flex;
  width: 100%;
}

.container__view {
  margin-right: 26px;
  width: calc(100% - 370px);
}

h1 {
  font-family: Montserrat-SemiBold;
  margin: 0 0 15px;
  padding: 0 0 0 16px;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.021em;
  text-align: left;
}

h1 img {
  width: 25px;
}

.add-button-icon {
  pointer-events: none;
  margin-right: 7px;
  vertical-align: middle;
}

.add-button-text {
  display: inline-block;
  line-height: 14px;
  vertical-align: middle;
}

#modal-create-group-button .add-button-icon {
  color: var(--color-main-100);
}

#modal-create-group-button:hover .add-button-icon :deep(i) {
  color: #fff;
}

.diet-list-search-bar {
  margin-top: 38px;
  margin-bottom: 28px;
  padding: 27px 18px;
}

.diet-list-search-bar :deep(.material-icon),
.diet-list-search-bar :deep(.material-icon) .material-icon__image {
  transition: none;
  color: var(--color-tertiary-text);
}

.diet-list-search-bar :deep(.material-icon.red-clear-icon),
.diet-list-search-bar :deep(.material-icon.red-clear-icon) .material-icon__image {
  transition: none;
  color: var(--color-destructive-100);
}

.diet-list-search-bar :deep(.placeholder.with-big-font),
.diet-list-search-bar :deep(input) {
  font-family: Montserrat-Regular;
  color: var(--color-primary-text);
  font-size: 16px;
  margin-left: 0;
  top: 55%;
}
</style>
