<template>
  <BaseModal
    v-if="globalStore.appUpgradePlanModal"
    @close="globalStore.hideAppUpgradePlanModal"
  >
    <div class="app-upgrade-plan-modal__content">
      <img
        class="app-upgrade-plan-modal__image"
        src="@/assets/img/plan-upgrade.png"
        alt="Opcja niedostępna"
      >
      <p class="app-upgrade-plan-modal__title">
        Ups!
      </p>
      <p class="app-upgrade-plan-modal__text">
        Ta opcja <span>nie jest dostępna</span> w Twoim obecnym planie.
      </p>
      <p class="app-upgrade-plan-modal__text">
        Przejdź na wyższy plan w aplikacji i zapewnij sobie dostep do wszystkich funkcji.
      </p>
    </div>
    <template #actions>
      <StyledButton
        variant="outline"
        @click.once="globalStore.hideAppUpgradePlanModal"
      >
        Anuluj
      </StyledButton>
      <StyledButton
        class="base-button--primary"
        @click.once="redirectToPricing"
      >
        Wybierz pakiet
      </StyledButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import BaseModal from '@/components/BaseModal.vue'
import { StyledButton } from '@/components/StyledButton'
import { useGlobalStore } from '@/store/globalStore'

const globalStore = useGlobalStore()

const redirectToPricing = () => {
  window.location.href = import.meta.env.VUE_APP_BASE_PRICING_URL
}
</script>

<style scoped>
.app-upgrade-plan-modal__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-upgrade-plan-modal__image {
  width: 276px;
  height: 199px;
}

.app-upgrade-plan-modal__title,
.app-upgrade-plan-modal__text {
  font-size: 20px;
  margin: 16px 0 0;
  text-align: center;
}

.app-upgrade-plan-modal__title {
  font-size: 24px;
  color: var(--color-main-100);
  font-family: Montserrat-Medium;
}

.app-upgrade-plan-modal__text span {
  font-family: Montserrat-SemiBold;
}
</style>
