import type { AppMessage } from './store/types'

import mitt from 'mitt'

export type GroupDict = {
  groupId: number
  parent: number
  type: 'group' | 'subgroup'
}

export type Types = {
  deactivateSelects: unknown
  hideAutocompleteOptions: unknown
  ingredientDrop: GroupDict
  resizeBaseTextArea: unknown
  showError: Partial<Omit<AppMessage, 'id' | 'type'>> & { error?: GlobalError }
  undoOrRedo: unknown
}

const emitter = mitt<Types>()

export default emitter
