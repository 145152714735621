<template>
  <div class="fb">
    <iframe
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fkcalmar.pro&tabs=timeline&width=344&height=900&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
      scrolling="no"
      frameborder="0"
      allowfullscreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    />
    <a
      class="join"
      href="https://www.facebook.com/groups/631512733667078"
      target="_blank"
    >Dołącz do grupy</a>
  </div>
</template>

<style scoped>
.fb iframe {
  width: 344px;
  height: 900px;
  border: 0;
  overflow: hidden;
}

.join {
  width: 100%;
  background: #1777f2;
  color: #fff;
  display: block;
  text-decoration: none;
  font-family: Montserrat-Medium;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: center;
  padding: 6px 0;
  margin-top: 5px;
}
</style>
