<template>
  <KcalmarTable
    ref="tableRef"
    :table-class="$style.table"
    :sticky-height="searchHeight + navigationHeight"
    :columns="columns"
    :data="ingredients"
  />
  <DefineNameComponent
    v-slot="{
      props: {
        row: {
          original: { is_public, name }
        }
      }
    }"
  >
    <Space
      :space="6"
      direction="columns"
    >
      <div class="w-20 h-20">
        <BaseIcon
          v-if="!is_public"
          class="lock-icon"
          name="lock"
        />
      </div>
      <div :class="$style.ellipsis">
        {{ name }}
      </div>
    </Space>
  </DefineNameComponent>
  <DefineActionsComponent
    v-slot="{
      props: {
        row: {
          original: { id, is_public }
        }
      }
    }"
  >
    <Space
      direction="columns"
      :space="6"
      :class="$style.actions"
    >
      <BaseTooltip class="base-tooltip--right base-tooltip--top">
        <template #tooltip>
          {{ isIngredientFavorite(id) ? 'Usuń z ulubionych' : 'Dodaj do ulubionych' }}
        </template>
        <KcalmarIconButton
          icon-size="20"
          variant="transparent"
          size="xs"
          :name="isIngredientFavorite(id) ? 'heart-line' : 'heart-fill'"
          icon-class="text-fill-400!"
          @click="toggleFavorite(id)"
        />
      </BaseTooltip>
      <BaseTooltip class="base-tooltip--right base-tooltip--top">
        <template #tooltip>
          Skopiuj produkt
        </template>
        <KcalmarIconButton
          icon-size="20"
          variant="transparent"
          size="xs"
          name="file-copy-line"
          icon-class="text-fill-400!"
          @click="copyIngredient(id)"
        />
      </BaseTooltip>
      <BaseTooltip
        v-if="!is_public"
        class="base-tooltip--right base-tooltip--top"
      >
        <template #tooltip>
          Edytuj produkt
        </template>
        <KcalmarIconButton
          icon-size="20"
          variant="transparent"
          size="xs"
          name="pencil-line"
          icon-class="text-fill-400!"
          @click="editProduct(id)"
        />
      </BaseTooltip>
      <BaseTooltip
        v-if="!is_public"
        class="base-tooltip--right base-tooltip--top"
      >
        <template #tooltip>
          Usuń produkt
        </template>
        <KcalmarIconButton
          icon-size="20"
          variant="transparent"
          size="xs"
          name="delete-bin-line"
          icon-class="text-fill-400!"
          @click="showIngredientDeleteModal(id)"
        />
      </BaseTooltip>
    </Space>
  </DefineActionsComponent>
</template>
<script lang="ts" setup>
import type { DietListIngredient } from '@/types/Diet'
import type { CellContext, ColumnDef } from '@tanstack/vue-table'

import { createColumnHelper } from '@tanstack/vue-table'
import { createReusableTemplate } from '@vueuse/core'
import { computed, h, ref, useCssModule } from 'vue'

import { KcalmarIconButton } from '@ui/KcalmarIconButton'
import { KcalmarTable } from '@ui/KcalmarTable'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import { Space } from '@/components/Space'
import { getIngredientBaseName } from '@/utils/ingredients'
import { getNutrientHeader } from '@/utils/nutrients'

import { useIngredientsProvider } from './provider'

const $style = useCssModule()
const [DefineActionsComponent, ActionsComponent] = createReusableTemplate<{
  props: CellContext<DietListIngredient, unknown>
}>()
const [DefineNameComponent, NameComponent] = createReusableTemplate<{
  props: CellContext<DietListIngredient, unknown>
}>()

const tableRef = ref(null)
const {
  getChosenNutrients,
  searchHeight,
  navigationHeight,
  toggleFavorite,
  isIngredientFavorite,
  editProduct,
  showIngredientDeleteModal,
  copyIngredient,
  ingredientsFilterBy: ingredients
} = useIngredientsProvider()

const columnHelper = createColumnHelper<DietListIngredient>()
const columns = computed<ColumnDef<DietListIngredient>[]>(
  () =>
    [
      columnHelper.accessor((v) => v.name, {
        id: 'name',
        header: 'Nazwa produktu',
        minSize: 150,
        enableHiding: false,
        cell: (v) => {
          return h(NameComponent, { props: v })
        }
      }),
      columnHelper.display({
        id: 'base',
        header: 'Baza',
        size: 130,
        cell: (info) => {
          return getIngredientBaseName(info.row.original.base_name)
        },
        meta: {
          align: 'right'
        }
      }),
      columnHelper.display({
        id: 'grams',
        header: 'Miara opisowa',
        cell: (info) => {
          const ingredient = info.row.original
          return `${ingredient.measurement} (${ingredient.grams}g)`
        },
        size: 170,
        enableSorting: false,
        meta: {
          align: 'right'
        }
      }),
      ...getChosenNutrients().map((nutrient) =>
        columnHelper.accessor((v) => v.nutrients[nutrient], {
          id: nutrient,
          size: 110,
          header: () => getNutrientHeader(nutrient),
          meta: {
            align: 'right'
          }
        })
      ),
      columnHelper.display({
        id: 'actions',
        size: 148,
        cell: (v) => {
          return h(ActionsComponent, { props: v })
        },
        meta: {
          collapsed: false
        }
      })
    ] as ColumnDef<DietListIngredient>[]
)
</script>

<style lang="scss" scoped module>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:deep(.table) {
  tbody {
    tr .actions > * {
      display: none;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
    }

    tr .actions > *:first-child {
      display: flex;
    }

    tr:hover .actions > * {
      display: flex;
    }
  }
}
</style>
