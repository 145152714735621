<template>
  <div
    class="base-checkbox"
    :class="{ hover: hover, disabled: disabled }"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="clickHandler()"
  >
    <div
      class="base-checkbox__tick"
      :class="{ checked: modelValue, partial: !modelValue && partial }"
    />
    <label
      v-if="label"
      class="base-checkbox__label"
    >
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const props = defineProps({
  modelValue: {
    required: true,
    type: Boolean
  },
  partial: {
    type: Boolean,
    default: false
  },
  label: {
    type: String
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const hover = ref(false)
const emit = defineEmits(['update:modelValue'])

const clickHandler = () => {
  emit('update:modelValue', !props.modelValue)
}
</script>
