<template>
  <BaseModal
    v-if="G.isNotNullable(ingredientToDelete)"
    @close="hideIngredientDeleteModal"
    @confirm="deleteIngredient"
  >
    <template #title>
      Usuwanie produktu
    </template>
    <div>Czy na pewno chcesz usunąć ten produkt?</div>
    <template #actions>
      <StyledButton
        variant="outline"
        @click.once="hideIngredientDeleteModal"
      >
        Anuluj
      </StyledButton>
      <StyledButton @click.once="deleteIngredient">
        Usuń
      </StyledButton>
    </template>
  </BaseModal>
</template>
<script lang="ts" setup>
import { G } from '@mobily/ts-belt'

import BaseModal from '@/components/BaseModal.vue'
import { StyledButton } from '@/components/StyledButton'

import { useIngredientsProvider } from './provider'

const { ingredientToDelete, deleteIngredient } = useIngredientsProvider()

const hideIngredientDeleteModal = () => {
  ingredientToDelete.value = undefined
}
</script>
