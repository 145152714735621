<template>
  <div class="stats">
    <div class="selection">
      <h2>{{ rangeLabel }}</h2>
      <button
        class="selection__btt-filter"
        data-test-id="DashboardStatsFilter"
        @click="showOptions = !showOptions"
      >
        <BaseIcon
          size="22"
          variant="outline"
          name="segment"
          type="secondary"
        />

        <div
          class="options"
          :class="{ visible: showOptions }"
        >
          <span>Statystyki:</span>
          <button
            class="option"
            data-test-id="DashboardStats_Choice_All"
            :class="{ active: 'all' === activeChoice }"
            @click="choose('all', 'W całym okresie')"
          >
            W całym okresie
          </button>
          <button
            class="option"
            data-test-id="DashboardStats_Choice_Week"
            :class="{ active: 'week' === activeChoice }"
            @click="choose('week', 'W tym tygodniu')"
          >
            W tym tygodniu
          </button>
          <button
            class="option"
            data-test-id="DashboardStats_Choice_Month"
            :class="{ active: 'month' === activeChoice }"
            @click="choose('month', 'W tym miesiącu')"
          >
            W tym miesiącu
          </button>
          <button
            class="option"
            data-test-id="DashboardStats_Choice_Year"
            :class="{ active: 'year' === activeChoice }"
            @click="choose('year', 'W tym roku')"
          >
            W tym roku
          </button>
        </div>
      </button>
    </div>
    <div class="details">
      <div class="details__item">
        <div>
          <span>Liczba jadłospisów:</span>
          <span>{{ statResumes }}</span>
        </div>
      </div>
      <div class="details__item">
        <div>
          <span>Liczba pacjentów:</span>
          <span>{{ statPatients }}</span>
        </div>
      </div>
      <div class="details__item">
        <div>
          <span>Liczba potraw:</span>
          <span>{{ statDishes }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Period } from '@/store/types'

import { computed, ref } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import { useDashboardStore } from '@/store/dashboardStore'

const activeChoice = ref<Period>('year')
const rangeLabel = ref('W tym roku')
const showOptions = ref(false)

const state = useDashboardStore()

const parseStat = (value: string | number) => {
  if (parseInt(value.toString()) > 0) {
    return `+${value}`
  }
  return '-'
}

const statResumes = computed(() => {
  const value = state.getDashboardStats(activeChoice.value)?.diets ?? '0'
  return parseStat(value)
})
const statPatients = computed(() => {
  const value = state.getDashboardStats(activeChoice.value)?.patients ?? '0'
  return parseStat(value)
})
const statDishes = computed(() => {
  const value = state.getDashboardStats(activeChoice.value)?.dishes ?? '0'
  return parseStat(value)
})

const choose = (value: Period, label: string) => {
  activeChoice.value = value
  rangeLabel.value = label
}
</script>

<style scoped>
h2 {
  font-size: 20px;
  font-style: normal;
  font-family: Montserrat-Medium;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.017em;
  text-align: left;
  margin-top: 0;
}

.selection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.details {
  display: flex;
  flex-direction: row;
  padding-left: 9px;
}

.details__item {
  width: calc(100% / 3);
}

.details__item > div {
  border-left: 1px solid #ccc;
  padding: 0 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.options span {
  padding: 10px 0 10px 16px;
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat-Medium;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
}

.details > .details__item:first-child > div {
  border-left: 0;
}

.details__item div > span:first-child {
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat-Medium;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  margin-right: 15px;
}

.details__item div > span:first-child + span {
  color: var(--color-main-100);
  font-size: 28px;
  font-style: normal;
  font-family: Montserrat-Medium;
  line-height: 36px;
  letter-spacing: -0.021em;
  text-align: left;
}

button {
  background: transparent;
  padding: 0;
  cursor: pointer;
  border: 0;
}

.options {
  display: none;
  position: absolute;
  text-align: left;
  width: 248px;
  background: #fff;
  border: 1px solid #e6e6e6;
  box-shadow: 0 4px 8px 0 #0000001a;
  transform: translateX(calc(-100% + 10px));
  flex-direction: column;
  z-index: 10;
  top: 25px;
  left: 10px;
  border-radius: 4px;
}

.options.visible {
  display: flex;
}

.options button {
  text-align: left;
  padding: 6px 0 6px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  border: 0;
}

.options button.active {
  background: var(--color-main-10);
  color: var(--color-main-100);
}

.options button:hover {
  background: var(--color-main-10);
  color: var(--color-main-100);
}

.selection__btt-filter {
  position: relative;
  width: 22px;
}
</style>
