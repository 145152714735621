// import type { Ref } from 'vue'

// import { G, S } from '@mobily/ts-belt'
// import { toValue } from '@vueuse/core'
// import Fuse from 'fuse.js'
// import { computed, ref, watch } from 'vue'

// export const useFuseSearch = <T extends Record<string, unknown>>(
//   data: T[] | Ref<T[]> | Ref<undefined>,
//   search: Ref<string> | string,
//   keys: string[] | Ref<string[]>
// ) => {
//   const createFuse = () => {
//     const value = toValue(data) ?? []
//     return new Fuse(value, {
//       keys: toValue(keys) as string[],
//       threshold: 0.1
//       // threshold: 0.2,
//       // distance: 1500
//     })
//   }
//   const fuse = ref(createFuse())

//   watch(
//     () => toValue(data) ?? [],
//     (collection) => {
//       fuse.value.setCollection(collection)
//     },
//     { deep: true }
//   )

//   watch(
//     () => toValue(keys),
//     () => {
//       fuse.value = createFuse()
//     },
//     { deep: true }
//   )

//   return computed(() => {
//     const phrase = toValue(search)

//     if (G.isString(phrase) && S.isNotEmpty(phrase)) {
//       return fuse.value.search(phrase).map((i) => i.item)
//     }

//     return toValue(data) ?? []
//   })
// }
import type { Ref } from 'vue'

import { G, S } from '@mobily/ts-belt'
import { toValue } from '@vueuse/core'
import { computed } from 'vue'

export const useFuseSearch = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  // @ts-expect-error
  K extends Extract<keyof T, string> = string
>(
  data: T[] | Ref<T[]> | Ref<undefined>,
  search: Ref<string> | string,
  keys: K[] | Ref<K[]>
) => {
  return computed(() => {
    const phrase = toValue(search)
    const list = toValue(data) ?? []
    const properties = toValue(keys)

    if (G.isString(phrase) && S.isNotEmpty(phrase)) {
      return list.filter((item) => {
        // quick fix for [ingredients.name]
        return properties.some((property) => {
          if (property.includes('.')) {
            const [first, ...rest] = property.split('.')
            //@ts-expect-error
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            return item[first]?.find(
              (i: T[K]) =>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                i[rest.join('')]?.toString().toLowerCase().includes(phrase.toLowerCase()) as boolean
            )
          }
          if (property in item) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            return item[property].toString().toLowerCase().includes(phrase.toLowerCase())
          }
          return false
        })
      })
    }

    return list
  })
}
