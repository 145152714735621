<template>
  <div>Sentry Test</div>
</template>

<script lang="ts" setup>
import { onBeforeMount } from 'vue'

onBeforeMount(() => {
  throw new Error('Sentry test')
})
</script>
