<template>
  <div
    v-cloak
    id="app-layout"
    :class="$style.root"
    class="next"
  >
    <div :class="[isCollapsed && $style.collapsed, $styles.nav]">
      <KcalmarIconButton
        name="arrow-left-s-fill"
        variant="secondary"
        size="small"
        :class="$style.hamburger"
        @click="toggleCollapsed"
      />

      <router-link
        :class="$style.logo"
        :to="{ name: 'DashboardBase' }"
      >
        <img :src="SvgLogo">
      </router-link>

      <KcalmarNav :class="$style.content">
        <KcalmarNavItem
          to="/next"
          icon="book-open-line"
          :hidden-text="isCollapsed"
        >
          Jadłospisy
        </KcalmarNavItem>
        <KcalmarNavItem
          icon="cake-3-line"
          :hidden-text="isCollapsed"
          :to="{ name: 'DishesList' }"
        >
          Potrawy
        </KcalmarNavItem>
        <KcalmarNavItem
          icon="shopping-bag-3-line"
          :hidden-text="isCollapsed"
          :to="{ name: 'IngredientsList' }"
        >
          Produkty
        </KcalmarNavItem>
        <KcalmarNavItem
          icon="group-line"
          :hidden-text="isCollapsed"
          :href="`${domain}/dietetyk/patient/table/`"
        >
          Pacjenci
        </KcalmarNavItem>
        <KcalmarNavItem
          icon="graduation-cap-line"
          :hidden-text="isCollapsed"
          :href="`${domain}/dietetyk/szkolenia/aplikacja/koszyk/2023/`"
        >
          Szkolenia
        </KcalmarNavItem>
        <KcalmarNavItem
          icon="profile-line"
          :hidden-text="isCollapsed"
          :href="`${domain}/dietetyk/profil/`"
        >
          Profil publiczny
        </KcalmarNavItem>
        <KcalmarNavItem
          icon="message-2-line"
          :hidden-text="isCollapsed"
          :href="`${domain}/dietetyk/zapytaj-dietetyka/lista/`"
        >
          Zapytaj dietetyka
        </KcalmarNavItem>
      </KcalmarNav>
      <Space :space="4">
        <KcalmarNav>
          <KcalmarNavItem
            :href="`${domain}/dietetyk/profil/`"
            icon="notification-3-line"
            :hidden-text="isCollapsed"
          >
            Powiadomienia
          </KcalmarNavItem>

          <KcalmarNavItem
            :ghost="true"
            :href="`${domain}/dietetyk/profil/`"
            :hidden-text="isCollapsed"
          >
            <template #leftContent>
              <KcalmarAvatar
                size="small"
                :name="name"
              />
            </template>
            {{ name }}
          </KcalmarNavItem>
        </KcalmarNav>
      </Space>
    </div>
    <div class="app-content">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref, useCssModule } from 'vue'

import SvgLogo from '@/assets/img/logo.svg?url'

import '@/assets/css/next.css'

import { KcalmarIconButton } from '@ui/KcalmarIconButton'
import { KcalmarNav, KcalmarNavItem } from '@ui/KcalmarNav'

import { Space } from '@/components/Space'
import { KcalmarAvatar } from '@/components/ui/KcalmarAvatar'
import { useGlobalStore } from '@/store/globalStore'

type Collapsed = 'not-collapsed' | 'collapsed'

const domain = import.meta.env.VUE_APP_HOST
const $styles = useCssModule()

const globalStore = useGlobalStore()

const collapsed = ref<Collapsed>(
  (localStorage.getItem('navCollapsed') ?? 'not-collapsed') as Collapsed
)

const user = computed(() => globalStore.user)
const name = computed(() => {
  if (!user.value?.first_name && !user.value?.last_name) {
    return 'Użytkownik'
  }
  return `${user.value.first_name} ${user.value.last_name}`
})
const isCollapsed = computed(() => collapsed.value === 'collapsed')

const toggleCollapsed = () => {
  if (collapsed.value === 'not-collapsed') {
    collapsed.value = 'collapsed'
  } else {
    collapsed.value = 'not-collapsed'
  }
  localStorage.setItem('navCollapsed', collapsed.value)
}

onBeforeMount(() => {
  if (window.innerWidth < 1200) {
    collapsed.value = 'collapsed'
  }
})
</script>

<style scoped lang="scss" module>
.root {
  display: flex;
}

.nav {
  position: sticky;
  top: 0;
  width: 230px;
  min-width: 230px;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 24px 12px;
  gap: $space-9;
  transition: all 0.3s ease-in-out;
}

.content {
  flex: 1;
}

.logo {
  display: flex;
  justify-content: center;
}

.logo img {
  max-width: 68px;
  width: 100%;
  display: block;
}

.collapsed .logo {
  width: 100%;
}

.hamburger {
  position: absolute;
  right: -18px;
  top: 16px;
}

/* Collapsed classes */
.collapsed {
  width: 76px;
  min-width: 76px;
}

.collapsed .hamburger {
  transform: rotate(180deg);
}
</style>
