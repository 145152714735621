import { push } from 'notivue'

const message =
  'Korzystasz z nieaktualnej wersji przeglądarki. Rekomendujemy zmianę przeglądarki, bądź jej aktualizacje.\
                    Korzystając z nieaktualnej przeglądarki, nie jesteśmy w stanie zapewnić bezbłędnego oraz szybkiego działania\
                    naszej aplikacji zgodnie z naszym regulaminem.'
const duration = Number.POSITIVE_INFINITY
export const checkOutdatedBrowser = () => {
  const ua = navigator.userAgent
  let browser
  let tem
  const M = /(opera|chrome|safari|firefox|msie|trident(?=\/))\/(\d*\.\d+),?/i.exec(ua) ?? []
  if (M[1] === 'Chrome') {
    tem = /\b(OPR|Edge)\/(\d+)/.exec(ua)
    if (tem !== null) {
      browser = tem.slice(1).join(' ').replace('OPR', 'Opera').split(' ')
      if (browser[0] === 'Opera' && Number(browser[1]) < 107) {
        push.warning({ message, duration })
      }
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  browser = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = /version\/(\d*\.\d+)/i.exec(ua)) !== null) browser.splice(1, 1, tem[1])
  if ((browser[0] === 'Chrome' || browser[0] === 'Firefox') && Number(browser[1]) < 120) {
    push.warning({ message, duration })
  }
  if (browser[0] === 'Safari' && Number(browser[1]) < 15.6) {
    push.warning({ message, duration })
  }
}
