<template>
  <div
    class="base-tooltip"
    @mouseover="show = true"
    @mouseleave="show = false"
  >
    <slot />
    <div
      v-if="show"
      class="base-tooltip__content"
    >
      <slot name="tooltip" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const show = ref(false)
</script>
