import type { Nutrients } from '@/utils/nutrients'
import type { Ref } from 'vue'

import { useQuery } from '@tanstack/vue-query'
import { storeToRefs } from 'pinia'

import { dietsService } from '@/services/dietsService'
import { useDietsStore } from '@/store/dietsStore'
import type { DishSearchFilters } from '@/types/Diet'

export const useSearchDishesAndIngredients = (nutrients: Ref<Nutrients[]>) => {
  const { hasDetails } = storeToRefs(useDietsStore())

  return useQuery({
    queryKey: ['searchDishesAndIngredients', nutrients],
    queryFn: () => dietsService.fetchSearchDishesAndIngredients(nutrients.value),
    staleTime: 36000,
    enabled: hasDetails,
    retry: 3
  })
}

export const useSearchDishesAndIngredientsSimple = (flags: Ref<DishSearchFilters | undefined>) => {
  return useQuery({
    queryKey: ['searchDishesAndIngredientsSimple', flags] as const,
    queryFn: (v) =>
      dietsService.fetchSearchDishesAndIngredientsSimple(
        v.queryKey[1] ?? {
          diary_free: false,
          gluten_free: false,
          superfood: false,
          vegan: false,
          vegetarian: false
        }
      ),
    staleTime: 36000,
    retry: 3
  })
}
