<template>
  <BaseModal
    :modal-class="'ingredients-chosen-nutrients'"
    @close="close"
    @confirm="chosenNutrientsConfirm"
  >
    <template #title>
      Zmiana kolumn w tabeli
    </template>
    <div class="ingredients-info">
      <BaseIcon
        type="info"
        name="info"
      /> Przeciągnij z listy do 5 elementów które mają się
      wyświetlać w tabeli produktów (z zachowaniem kolejności).
    </div>
    <div class="chosen-nutrients-counter">
      Wartości wybranych składników odżywczych:
      <span>{{ chosenNutrientsList.length }} / 5</span>
    </div>
    <div class="nutrients-content">
      <div class="shown-nutrients-box">
        <div class="shown-nutrients">
          Wyświetlane wartości odżywcze
        </div>
        <div
          v-if="chosenNutrientsList.length > 0"
          class="chosen-nutrients__dropzone"
          @drop.prevent="dropNutrient"
          @dragover.prevent
        >
          <div
            v-for="nutrient in chosenNutrientsList"
            :key="`chosen-nutrient-${nutrient}`"
            class="chosen-nutrient"
          >
            {{ nutrientsMap[nutrient].name }}
            <BaseIcon
              class="chosen-nutrient__icon-check"
              size="16"
              name="check_circle"
            />
            <BaseIcon
              class="chosen-nutrient__icon-cancel"
              type="warning"
              size="16"
              name="cancel"
              @click="deleteNutrient(nutrient)"
            />
          </div>
        </div>
        <div
          v-else
          class="chosen-nutrients__dropzone_empty"
        >
          Przeciągnij element z listy po prawej, bądź zaznacz go bezpośrednio z listy.
        </div>
      </div>
      <div class="settings-box nutrients">
        <BaseSelect
          class="nutrients__category"
          :options="nutrientsOptions"
          :model-value="nutrientCategory"
          @update:model-value="updateNutrientCategory"
        />
        <div class="nutrients__list">
          <div
            v-for="nutrient in nutrientsInCategory"
            :key="nutrient.key"
            class="nutrient"
            :class="nutrientClass(nutrient.key)"
            :draggable="canDragNutrient(nutrient.key)"
            @dragstart="dragStartNutrient($event, nutrient.key)"
            @click="updateNutrient(nutrient.key)"
          >
            {{ nutrient.name }}
            <BaseIcon
              v-if="canDeleteNutrient(nutrient.key)"
              class="nutrient__icon-check"
              size="16"
              name="check_circle"
            />
            <BaseIcon
              v-if="canDeleteNutrient(nutrient.key)"
              class="nutrient__icon-cancel"
              type="warning"
              size="16"
              name="cancel"
              @click.stop="deleteNutrient(nutrient.key)"
            />
          </div>
        </div>
      </div>
    </div>
    <template #actions>
      <StyledButton
        variant="outline"
        @click.once="close"
      >
        Anuluj
      </StyledButton>
      <StyledButton @click.once="chosenNutrientsConfirm">
        Zapisz
      </StyledButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import type { Nutrients, NutrientsCategories } from '@/utils/nutrients'
import type { PropType } from 'vue'

import { A, D } from '@mobily/ts-belt'
import { computed, ref } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseModal from '@/components/BaseModal.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import { StyledButton } from '@/components/StyledButton'
import { nutrientsCategories, nutrientsMap } from '@/utils/nutrients'

type NutrientsCategory = {
  key: Nutrients
  name: string
}

const props = defineProps({
  chosenNutrients: {
    required: true,
    type: Array as PropType<Nutrients[]>
  }
})

const nutrientsOptions = Array.from(nutrientsCategories, (category) => {
  return { value: category, text: category }
})

const emit = defineEmits(['close', 'loadCustomNutrients'])

const nutrientCategory = ref<NutrientsCategories>('Składniki odżywcze')
const chosenNutrientsList = ref([...props.chosenNutrients])

const nutrientsInCategory = computed(() => {
  return A.reduce(D.toPairs(nutrientsMap), [] as NutrientsCategory[], (acc, [key, value]) => {
    if (value.category === nutrientCategory.value) {
      if (key !== 'calories') {
        acc.push({ key: key as Nutrients, name: value.name })
      }
    }
    return acc
  })
})

const close = () => {
  emit('close')
}
const dragStartNutrient = (event: DragEvent, nutrient: Nutrients) => {
  if (event.dataTransfer) {
    event.dataTransfer.dropEffect = 'copy'
    event.dataTransfer.setData('text/plain', nutrient)
  }
}
const dropNutrient = (event: DragEvent) => {
  if (event.dataTransfer) {
    const nutrient = event.dataTransfer.getData('text/plain') as Nutrients
    if (nutrient in nutrientsMap) {
      updateNutrient(nutrient)
    }
  }
}
const deleteNutrient = (nutrient: Nutrients) => {
  chosenNutrientsList.value = chosenNutrientsList.value.filter((elem) => nutrient !== elem)
}
const updateNutrient = (nutrient: Nutrients) => {
  if (!chosenNutrientsList.value.includes(nutrient)) {
    chosenNutrientsList.value.push(nutrient)
  }
}
const updateNutrientCategory = (category: NutrientsCategories) => {
  nutrientCategory.value = category
}
const canDragNutrient = (nutrient: Nutrients) => {
  return chosenNutrientsList.value.length <= 5 && !chosenNutrientsList.value.includes(nutrient)
}
const canDeleteNutrient = (nutrient: Nutrients) => {
  return chosenNutrientsList.value.includes(nutrient)
}
const chosenNutrientsConfirm = () => {
  emit('loadCustomNutrients', chosenNutrientsList.value)
}
const nutrientClass = (nutrient: Nutrients) => {
  if (chosenNutrientsList.value.includes(nutrient)) {
    return 'selected'
  } else if (chosenNutrientsList.value.length >= 5) {
    return 'disabled'
  }
  return ''
}
</script>

<style>
.ingredients-chosen-nutrients {
  width: 748px !important;
  padding: 32px 80px !important;
  box-sizing: content-box;
}

.base-modal-title {
  font-size: 24px !important;
  font-family: Montserrat-Medium !important;
}
</style>

<style scoped>
.shown-nutrients-box {
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgb(43 43 43 / 20%);
  width: 336px;
  background-color: #fff;
  box-sizing: content-box;
}

.shown-nutrients {
  position: relative;
  line-height: 15px;
  padding: 12px 21px 12px 8px;
  border-bottom: 1px solid #ededed;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.nutrients-content {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.settings-box {
  width: 338px;
  box-shadow: 0 8px 16px 0 rgb(43 43 43 / 20%);
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
  box-sizing: content-box;
}

.settings {
  display: grid;
  grid-template-columns: 1fr 350px;
  gap: 12px;
  grid-template-areas:
    'name-description-filters name-description-filters'
    'meals meals'
    'chosen-nutrients nutrients';
  margin-bottom: 12px;
}

.name-description-filters {
  grid-area: name-description-filters;
}

.chosen-nutrients {
  grid-area: chosen-nutrients;
}

.nutrients {
  grid-area: nutrients;
}

.title {
  font-family: Montserrat-Medium;
  font-size: 24px;
}

.label {
  font-family: Montserrat-Medium;
  font-size: 12px;
  margin-top: 22px;
  margin-bottom: 8px;
}

.info {
  font-size: 12px;
  line-height: 20px;
}

.info .base-icon {
  margin-right: 6px;
}

.name {
  font-size: 16px;
  height: 19px;
  line-height: 19px;
  width: 100%;
  max-width: 800px;
}

.delete-confirmation-text span {
  font-family: Montserrat-SemiBold;
}

.description {
  max-height: 200px;
  max-width: 800px;
  height: 15px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.description::-webkit-scrollbar {
  width: 0;
}

:deep(.base-select__selected) {
  padding: 12px 21px 12px 8px;
  border-bottom: 1px solid #ededed;
}

.chosen-nutrients__dropzone {
  border-radius: 4px;
  min-height: 64px;
  height: 235px;
  padding: 8px;
  display: flex;
  flex-flow: column wrap;
}

.chosen-nutrients__dropzone_empty {
  font-family: Montserrat-Bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d2d2d2;
  max-width: 306px;
  padding: 8px;
  height: 235px;
  margin: 0 auto;
}

.chosen-nutrient {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #eef8f7;
  margin: 6px;
  height: 26px;
  padding: 0 8px;
  line-height: 26px;
  border-radius: 13px;
  font-size: 10px;
  cursor: default;
  white-space: nowrap;
  width: max-content;
}

.chosen-nutrient :deep(.base-icon) {
  margin-left: 8px;
}

.chosen-nutrient:hover {
  background: var(--color-red-10);
}

.chosen-nutrient:hover .chosen-nutrient__icon-check {
  display: none;
}

.chosen-nutrient__icon-cancel {
  display: none;
  cursor: pointer;
}

.chosen-nutrient:hover .chosen-nutrient__icon-cancel {
  display: block;
}

.chosen-nutrients .info {
  margin: 16px 0;
}

.chosen-nutrients__info {
  font-size: 12px;
  line-height: 20px;
}

.chosen-nutrient__info strong {
  font-family: Montserrat-Medium;
}

.nutrients__category {
  margin-bottom: 8px;
}

.nutrients__list {
  height: 235px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  align-items: flex-start;
}

.nutrients__list::-webkit-scrollbar {
  width: 6px;
}

.nutrient {
  background: #f2f2f2;
  font-size: 10px;
  border-radius: 13px;
  line-height: 26px;
  padding: 0 8px;
  height: 26px;
  margin: 3px 0;
  display: flex;
  align-items: center;
}

.nutrient:hover {
  background: var(--color-main-10);
  cursor: pointer;
}

.nutrient.disabled {
  pointer-events: none;
  cursor: default;
  background: #f2f2f2;
  color: var(--color-tertiary-text);
}

.nutrient.selected :deep(.base-icon) {
  margin-left: 8px;
}

.nutrient.selected {
  background: var(--color-main-10);
  cursor: default;
}

.nutrient.selected:hover {
  background: var(--color-red-10);
}

.nutrient:hover .nutrient__icon-check {
  display: none;
}

.nutrient.selected .nutrient__icon-cancel {
  display: none;
  cursor: pointer;
}

.nutrient:hover .nutrient__icon-cancel {
  display: block;
}

.ingredients-info {
  margin-bottom: 6px;
}

.chosen-nutrients-counter span {
  font-family: Montserrat-Medium;
}
</style>
