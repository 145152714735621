<template>
  <BaseModal
    @close="hidePrintDietModal"
    @click="deactivateSelects"
  >
    <template #title>
      Pobierz PDF
    </template>
    <div>Wybierz opcje poniżej by pokazać/ukryć elementy na swoim PDF:</div>
    <div>
      <div class="print-modal-box print-modal-theme">
        <label class="print-modal-title print-modal-theme-title">Wybierz szablon:</label>
        <BaseSelect
          v-model="print.theme"
          class="print-modal-theme-select"
          :options="printThemes"
        />
      </div>
      <div class="print-modal-box">
        <label class="print-modal-title">Grupuj po:</label>
        <div class="print-modal-inner-box">
          <div @click="checkPermissions">
            <BaseRadio
              :label="'Dniach'"
              :disabled="!printConfig"
              :model-value="print.groupBy === 'days'"
              @update:model-value="print.groupBy = 'days'"
            />
          </div>
          <div @click="checkPermissions">
            <BaseRadio
              :label="'Posiłkach'"
              :disabled="!printConfig"
              :model-value="print.groupBy === 'meals'"
              @update:model-value="print.groupBy = 'meals'"
            />
          </div>
        </div>
      </div>
      <div class="print-modal-box">
        <label class="print-modal-title">Sortuj po:</label>
        <div class="print-modal-inner-box">
          <div @click="checkPermissions">
            <BaseRadio
              :label="'Nazwie'"
              :disabled="!printConfig"
              :model-value="print.sortBy === 'alphabetically'"
              @update:model-value="print.sortBy = 'alphabetically'"
            />
          </div>
          <div @click="checkPermissions">
            <BaseRadio
              :label="'Gramaturze'"
              :disabled="!printConfig"
              :model-value="print.sortBy === 'grams'"
              @update:model-value="print.sortBy = 'grams'"
            />
          </div>
        </div>
      </div>
      <div class="print-modal-box">
        <label class="print-modal-title">Pokaż/ukryj dni:</label>
        <div class="print-modal-inner-box">
          <div
            class="days-container"
            @click="checkPermissions"
          >
            <BaseCheckbox
              v-for="day in D.keys(daysSelection)"
              :key="day"
              :label="daysMap[day]"
              :model-value="daysSelection[day]"
              @update:model-value="v => daysSelection[day] = v"
            />
          </div>
        </div>
      </div>
      <div class="print-modal-box">
        <label class="print-modal-title">Pokaż/ukryj wybrane opcje:</label>
        <div
          class="print-modal-inner-box"
          @click="checkPermissions"
        >
          <div class="print-modal-inner-box-options">
            <BaseCheckbox
              v-model="print.showWeek"
              :label="'Podsumowanie tygodniowe'"
              :disabled="!printConfig"
            />
            <BaseCheckbox
              v-model="print.showComments"
              :label="'Komentarze'"
              :disabled="!printConfig"
            />
            <BaseCheckbox
              v-model="print.showShoppingList"
              :label="'Lista zakupów'"
              :disabled="!printConfig"
            />
            <BaseCheckbox
              v-model="print.showPreparationTime"
              :label="'Czas przygotowania'"
              :disabled="!printConfig"
            />
            <BaseCheckbox
              v-model="print.showQRCodes"
              :label="'Kody QR do aplikacji'"
              :disabled="!printConfig"
            />
          </div>
          <div class="print-modal-inner-box-options">
            <BaseCheckbox
              v-model="print.printInevitableLosses"
              :label="'Straty talerzowe'"
              :disabled="!printConfig || !lossesActive"
            />
            <BaseTooltip
              v-if="isNumericDiet"
              class="tooltip base-tooltip--top base-tooltip--left"
            >
              <template #tooltip>
                Wybrano numeryczny format wyświetlania. Daty nie będą widoczne na wydruku PDF.
              </template>
              <BaseCheckbox
                :model-value="false"
                :label="'Daty'"
                :disabled="true"
              />
            </BaseTooltip>
            <BaseCheckbox
              v-else
              v-model="print.showDate"
              :label="'Daty'"
              :disabled="!printConfig || isNumericDiet"
            />
            <BaseCheckbox
              v-model="print.showMealHour"
              :label="'Godziny posiłków'"
              :disabled="!printConfig"
            />
            <BaseCheckbox
              v-model="print.showNutrients"
              :label="'Wartości odżywcze'"
              :disabled="!printConfig"
              @update:model-value="
                print.showNutrients === false ? (print.showChosenNutrients = false) : ''
              "
            />
            <BaseCheckbox
              v-model="print.showChosenNutrients"
              :label="'Wybrane wartości odżywcze'"
              :disabled="!printConfig"
              @update:model-value="
                print.showChosenNutrients === true ? (print.showNutrients = true) : ''
              "
            />
            <BaseCheckbox
              v-model="print.showKcalmarLogo"
              :label="'Stopka z logotypem Kcalmar i informacją o aplikacji'"
              :disabled="!printConfig || !hasPerm('accounts.print_disable_footer')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="print-modal-box">
      <label class="print-modal-title">Inne:</label>
      <div @click="checkPermissions">
        <BaseCheckbox
          v-model="print.separateDaysOrMeals"
          :label="'Dni/posiłki na osobnych stronach'"
          :disabled="!printConfig"
        />
      </div>
    </div>
    <div
      v-if="testPrint"
      class="test-print-warning"
    >
      <span class="paragraph">Ta opcja jest niedostępna dla konta testowego. Pobierz przykładowy wydruk, wykup
        dostęp lub skorzystaj z poniższej opcji.</span>
      <span>Każda osoba może jednorazowo wykupić 5-dniowy pakiet Premium w cenie 12,29 zł w celu
        przetestowania zaawansowanych funkcji programu. W przypadku zainteresowania, prosimy o
        kontakt mailowy
        <a href="mailto:kontakt@kcalmar.com&subject=5 dni Premium">kontakt@kcalmar.com</a></span>
    </div>
    <template #actions>
      <div
        v-if="isLimitedPrintVersion"
        class="print-modal__diet-limit"
      >
        <p v-if="printDietLimit > 0">
          Pozostała liczba jadłospisów do wydrukowania w wersji akademickiej:
          {{ printDietLimit }}
        </p>
        <p
          v-else
          class="print-modal__diet-limit--warning"
        >
          Wykorzystałeś już limit wydrukowanych jadłospisów w wersji akademickiej, przejdź na
          wyższy plan.
        </p>
      </div>

      <StyledButton
        variant="outline"
        @click.once="hidePrintDietModal"
      >
        Anuluj
      </StyledButton>
      <StyledButton
        v-if="!printLimitExceeded && !testPrint"
        @click="
          !hasPerm('accounts.print_test') ||
            hasPerm('accounts.print_full') ||
            isLimitedPrintVersion
            ? printDietModalConfirm()
            : (testPrint = true)
        "
      >
        Pobierz
      </StyledButton>
      <StyledButton
        v-if="printLimitExceeded"
        type="error"
        @click="redirectToPlanChange"
      >
        Zmień plan
      </StyledButton>
      <StyledButton
        v-if="testPrint"
        @click="redirectToPlanChange"
      >
        Kup dostęp
      </StyledButton>
      <StyledButton
        v-if="testPrint"
        @click="printDietModalConfirm"
      >
        Przykładowy wydruk
      </StyledButton>
    </template>
  </BaseModal>
</template>
<script lang="ts" setup>
import { A, D, pipe } from '@mobily/ts-belt'
import { storeToRefs } from 'pinia'

import { computed, onMounted, ref, toRef } from 'vue'

import { byDefault } from '@/utils/byDefault'
import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseModal from '@/components/BaseModal.vue'
import BaseRadio from '@/components/BaseRadio.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import { StyledButton } from '@/components/StyledButton'
import eventBus from '@/eventBus'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import { stringify } from '@/utils/stringify'
import type { DietType } from '@/store/types'
import { dayMapShort, numericMap } from '@/utils/common'
import BaseTooltip from '@/components/BaseTooltip.vue'

const props = defineProps<{
  dietId: number
  dietType: DietType
}>()

const dietType = toRef(props.dietType)
const dietId = toRef(props.dietId)

const globalStore = useGlobalStore()
const dietStore = useDietsStore()

const { hasPerm, showAppUpgradePlanModal, incrementPrintedDiets } = globalStore
const { user } = storeToRefs(globalStore)
const { diet } = storeToRefs(dietStore)

const emit = defineEmits(['close'])
const daysMap = diet.value?.data.diet_type === 'days_of_week' ? dayMapShort : numericMap

const dietitianPatient = computed(() => {
  if (diet.value && 'dietitian_patient' in diet.value) {
    return diet.value.dietitian_patient
  }

  return undefined
})

const daysSelection = ref(
  D.mapWithKey(
    {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true
    },
    (key) => dietitianPatient.value?.pdf_days.includes(key.toString()) ?? true
  )
)
const isNumericDiet = computed(() => diet.value?.data.diet_type === 'numeric')
const testPrint = ref(false)
const lossesActive = computed(() => {
  return diet.value?.data.inevitable_losses.active
})
const print = ref({
  theme: 'kcalmar',
  groupBy: byDefault(dietitianPatient.value?.pdf_group_by, 'days'),
  sortBy: byDefault(dietitianPatient.value?.pdf_sort_by, 'grams'),
  showWeek: byDefault(dietitianPatient.value?.pdf_show_week, true),
  showNutrients: byDefault(dietitianPatient.value?.pdf_show_nutrients, true),
  showChosenNutrients: byDefault(dietitianPatient.value?.pdf_show_chosen_nutrients, false),
  showPreparationTime: byDefault(dietitianPatient.value?.pdf_show_preparation_time, true),
  showKcalmarLogo: byDefault(dietitianPatient.value?.pdf_show_kcalmar_logo, true),
  printInevitableLosses: Boolean(lossesActive.value),
  showComments: byDefault(dietitianPatient.value?.pdf_show_comments, true),
  showShoppingList: byDefault(dietitianPatient.value?.pdf_show_shopping_list, true),
  showDate: !isNumericDiet.value && byDefault(dietitianPatient.value?.pdf_show_date, true),
  showMealHour: byDefault(dietitianPatient.value?.pdf_show_meal_hour, true),
  showQRCodes: byDefault(dietitianPatient.value?.pdf_show_qr_codes, true),
  separateDaysOrMeals: byDefault(dietitianPatient.value?.pdf_separate_days_or_meals, false)
})
const printConfig = computed(() => {
  return hasPerm('accounts.print_config')
})
const isLimitedPrintVersion = computed(() => {
  return (
    (user?.value?.permissions.includes('accounts.print_limited') &&
      !user.value.permissions.includes('accounts.print_full')) ??
    false
  )
})
const printDietLimit = computed(() => {
  const printedDiets = user?.value?.printed_diets ?? import.meta.env.VUE_APP_PRINTED_DIET_LIMIT
  return import.meta.env.VUE_APP_PRINTED_DIET_LIMIT - printedDiets
})
const printLimitExceeded = computed(() => {
  return isLimitedPrintVersion.value && printDietLimit.value <= 0
})

const printThemes = computed(() => {
  let themes = [
    { value: 'kcalmar', text: 'Podstawowy' },
    { value: 'leaf', text: 'Liść' },
    { value: 'holiday_one', text: 'Świąteczny Śnieżki' },
    { value: 'holiday_two', text: 'Świąteczny Renifer' },
    { value: 'holiday_three', text: 'Świąteczny Klasyczny' },
    { value: 'easter', text: 'Wielkanoc' },
    { value: 'easter_print', text: 'Wielkanoc Druk' },
    { value: 'spring_one', text: 'Wiosna Pierwszy' },
    { value: 'spring_two', text: 'Wiosna Drugi' },
    { value: 'spring_two_print', text: 'Wiosna Drugi Druk' },
    { value: 'autumn', text: 'Jesień' },
    { value: 'banan', text: 'Banan' },
    { value: 'friuts_and_vegetables_one', text: 'Owoce i Warzywa Pierwszy' },
    { value: 'friuts_and_vegetables_two', text: 'Owoce i Warzywa Drugi' },
    { value: 'friuts_and_vegetables_three', text: 'Owoce i Warzywa Trzeci' },
    { value: 'gold_frame', text: 'Złota ramka' },
    { value: 'gold_frame_flowers', text: 'Złota ramka kwiaty' },
    { value: 'gold_frame_leafs', text: 'Złota ramka liście' },
    { value: 'clover_flower', text: 'Kwiat koniczyny' },
    // Master
    { value: 'vege', text: 'Vege' },
    { value: 'for_kids', text: 'Dla dzieci' },
    { value: 'vision_deffect', text: 'Duża czcionka' },
    { value: 'minimal_grey', text: 'Minimal Szary' },
    { value: 'minimal_green', text: 'Minimal Zielony' },
    { value: 'minimal_pink', text: 'Minimal Różowy' },
    { value: 'mediterranean', text: 'Śródziemnorski' },
    // Premium
    { value: 'for_sport', text: 'Dla sportowca' },
    { value: 'rysunki_blue', text: 'Rysunki Niebieski' },
    { value: 'rysunki_green', text: 'Rysunki Zielony' },
    { value: 'rysunki_yellow', text: 'Rysunki Żółty' },
    { value: 'pixel_red', text: 'Pixel Czerwony' },
    { value: 'pixel_orange', text: 'Pixel Pomarańczowy' },
    { value: 'pastel', text: 'Pastelowy' },
    // VIP
    { value: 'pixel_green', text: 'Pixel Zielony' },
    { value: 'cosmos', text: 'Kosmos' },
    { value: 'design_green', text: 'Deseń Zielony' },
    { value: 'design_pink', text: 'Deseń Różowy' },
    { value: 'ingredient_italic', text: 'Składniki Kursywa' },
    { value: 'ingredient_bold', text: 'Składniki Pogrubione' }
  ]
  if (hasPerm('accounts.print_template_15')) themes = themes.slice(0, 39)
  else if (hasPerm('accounts.print_template_11')) themes = themes.slice(0, 34)
  else if (hasPerm('accounts.print_template_10')) themes = themes.slice(0, 33)
  else if (hasPerm('accounts.print_template_5')) themes = themes.slice(0, 26)
  else themes = themes.slice(0, 19)

  if (hasPerm('accounts.diet_nfz')) {
    themes.push(
      { value: 'nfz_leaf_top', text: 'NFZ Liść Góra' },
      { value: 'nfz_leaf_contour', text: 'NFZ Liść Kontur' },
      { value: 'nfz_fruits', text: 'NFZ Owoce' }
    )
  }
  return themes
})
const checkPermissions = () => {
  if (!printConfig.value) {
    showAppUpgradePlanModal()
  }
}
const deactivateSelects = () => {
  eventBus.emit('deactivateSelects')
}
const redirectToPlanChange = () => {
  hidePrintDietModal()
  const url = import.meta.env.VUE_APP_BASE_CLIENT_CREATOR_URL
  window.open(url, '_blank')
}

const hidePrintDietModal = () => {
  emit('close')
  testPrint.value = false
}
const printDietModalConfirm = () => {
  const params = {
    type: 'system',
    theme: encodeURIComponent(print.value.theme),
    group_by: encodeURIComponent(print.value.groupBy),
    sort_by: encodeURIComponent(print.value.sortBy),
    show_week: encodeURIComponent(print.value.showWeek),
    show_date: encodeURIComponent(print.value.showDate),
    show_meal_hour: encodeURIComponent(print.value.showMealHour),
    show_comments: encodeURIComponent(print.value.showComments),
    show_nutrients: encodeURIComponent(print.value.showNutrients),
    show_chosen_nutrients: encodeURIComponent(print.value.showChosenNutrients),
    show_shopping_list: encodeURIComponent(print.value.showShoppingList),
    show_preparation_time: encodeURIComponent(print.value.showPreparationTime),
    show_kcalmar_logo: encodeURIComponent(print.value.showKcalmarLogo),
    show_qr_codes: encodeURIComponent(print.value.showQRCodes),
    print_inevitable_losses: encodeURIComponent(print.value.printInevitableLosses),
    separate_days_or_meals: encodeURIComponent(print.value.separateDaysOrMeals),
    days: encodeURIComponent(stringify(D.values(daysSelection.value))),
    diet_type: encodeURIComponent(isNumericDiet.value ? 'numeric' : 'days_of_week')
  }

  if (hasPerm('accounts.print_full') || hasPerm('accounts.print_limited')) {
    params.type = dietType.value
  }
  // prepare params dict into url get params
  const paramsToUrl = pipe(
    D.keys(params),
    A.map((paramName) => `${paramName}=${params[paramName]}`),
    A.join('&')
  )
  // build url, start from test and then print full
  let url = `${import.meta.env.VUE_APP_HOST}/pdf/new-diet/648411/?${paramsToUrl}`
  if (hasPerm('accounts.print_test')) {
    url = `${import.meta.env.VUE_APP_HOST}/pdf/new-diet/${
      import.meta.env.VUE_APP_SYSTEM_DIET_ID
    }/?${paramsToUrl}`
  }
  if (hasPerm('accounts.print_full') || hasPerm('accounts.print_limited')) {
    url = `${import.meta.env.VUE_APP_HOST}/pdf/new-diet/${dietId.value}/?${paramsToUrl}`
  }
  window.open(
    url,
    '_blank' // new window or tab.
  )
  incrementPrintedDiets()
  hidePrintDietModal()
}

onMounted(() => {
  const themesList: string[] = []
  if (dietitianPatient.value?.theme) {
    Object.entries(printThemes.value).forEach(([, object]) => themesList.push(object.value))
    if (themesList.includes(dietitianPatient.value.theme)) {
      print.value.theme = dietitianPatient.value.theme
    }
  }
})
</script>

<style scoped>
.print-modal__diet-limit p {
  font-size: 12px;
  margin: 0;
  max-width: 215px;
}

.print-modal__diet-limit p.print-modal__diet-limit--warning {
  color: red;
}

.test-print-warning {
  display: inline-grid;
  margin-top: 24px;
  color: red;
}

.test-print-warning .paragraph {
  margin-bottom: 5px;
}

.print-modal-box {
  display: flex;
  flex-direction: column;
}

.print-modal-title {
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #000;
  margin: 20px 0;
}

.print-modal-theme {
  flex-direction: row;
  align-items: center;
}

.print-modal-theme-title {
  margin: 20px 0 0;
}

.print-modal-theme-select {
  margin: 20px 0 0 4px;
  width: 180px;
}

.print-modal-theme-select :deep(.base-select__options) {
  max-height: 392px;
}

.print-modal-inner-box {
  display: flex;
  gap: 0 20px;
}

.print-modal-inner-box-options {
  display: flex;
  flex-direction: column;
  gap: 12px 0;
}

.days-container {
  display: flex;
  gap: 20px;
  flex-flow: wrap;
}

.tooltip :deep(.base-tooltip__content) {
  max-width: 220px;
}
</style>
