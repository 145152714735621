export const ingredientDefault = {
  name: '',
  category: 1,
  manufacturer: null,
  is_public: false,
  user: null,
  base: null,
  price: null,
  measurement: 1,
  grams: 1,
  calories: 0,
  protein: 0,
  fat: 0,
  carbohydrates_total: 0,
  carbohydrates: 0,
  fiber: 0,
  carbohydrate_exchanger: 0,
  is_vegan: false,
  is_vegetarian: false,
  is_diary_free: false,
  is_gluten_free: false,
  is_super_food: false,
  water: null,
  sodium: null,
  potassium: null,
  calcium: null,
  phosphorus: null,
  magnesium: null,
  iron: null,
  zinc: null,
  copper: null,
  manganese: null,
  vitamin_A_RAE: null,
  retinol: null,
  carotene_beta: null,
  vitamin_D: null,
  vitamin_E: null,
  thiamin: null,
  riboflavin: null,
  niacin: null,
  vitamin_B6: null,
  folate_total: null,
  vitamin_B_12: null,
  vitamin_C: null,
  c_4_null: null,
  c_6_null: null,
  c_8_null: null,
  c_1null_null: null,
  c_12_null: null,
  c_14_null: null,
  c_15_null: null,
  c_16_null: null,
  c_17_null: null,
  c_18_null: null,
  c_2null_null: null,
  nkt: null,
  c_14_1: null,
  c_15_1: null,
  c_16_1: null,
  c_17_1: null,
  c_18_1: null,
  c_2null_1: null,
  c_22_1: null,
  jnkt: null,
  c_18_2: null,
  c_18_3: null,
  c_18_4: null,
  c_2null_3: null,
  c_2null_4: null,
  c_2null_5: null,
  c_22_5: null,
  c_22_6: null,
  wnkt: null,
  cholesterol: null,
  isoleucine: null,
  leucine: null,
  lysine: null,
  methionine: null,
  cystine: null,
  phenylalanine: null,
  tyrosine: null,
  threonine: null,
  tryptophan: null,
  valine: null,
  arginine: null,
  histidine: null,
  alanine: null,
  aspartic_acid: null,
  glutamic_acid: null,
  glycine: null,
  proline: null,
  serine: null,
  sucrose: null,
  lactose: null,
  starch: null,
  waste: null,
  energy_value_according_regulation_kcal: null,
  energy_value_kj: null,
  energy_value_according_regulation_kj: null,
  animal_protein: null,
  plant_protein: null,
  protein_according_regulation: null,
  protein_fat_exchangers: null,
  ash: null,
  salt: null,
  iodine: null,
  n_6: null,
  n_3: null,
  glucose: null,
  fructose: null,
  protein_energy: null,
  fat_energy: null,
  carbohydrates_energy: null,
  fiber_energy: null,
  glycemic_index: null,
  group: []
}

export const basicIngredientNutrients = [
  'calories',
  'protein',
  'fat',
  'carbohydrates_total',
  'carbohydrates',
  'fiber',
  'carbohydrate_exchanger'
] as const

// 97 elements
export const specificIngredientNutrients = [
  // Podstawowe składniki odżywcze
  'energy_value_kj',
  'energy_value_according_regulation_kcal',
  'energy_value_according_regulation_kj',
  'animal_protein',
  'plant_protein',
  'protein_according_regulation',
  // Składniki mineralne
  'sodium',
  'potassium',
  'calcium',
  'phosphorus',
  'magnesium',
  'iron',
  'zinc',
  'copper',
  'iodine',
  'manganese',
  // Witaminy
  'thiamin',
  'riboflavin',
  'niacin',
  'folate_total',
  'vitamin_B_12',
  'vitamin_B6',
  'vitamin_A_RAE',
  'vitamin_C',
  'vitamin_D',
  'vitamin_E',
  'carotene_beta',
  'retinol',
  // Aminokwasy;
  'isoleucine',
  'leucine',
  'lysine',
  'methionine',
  'cystine',
  'phenylalanine',
  'tyrosine',
  'threonine',
  'tryptophan',
  'valine',
  'arginine',
  'histidine',
  'alanine',
  'aspartic_acid',
  'glutamic_acid',
  'glycine',
  'proline',
  'serine',
  // Kwasy tłuszczowe
  'c_4_0',
  'c_6_0',
  'c_8_0',
  'c_10_0',
  'c_12_0',
  'c_14_0',
  'c_15_0',
  'c_16_0',
  'c_17_0',
  'c_18_0',
  'c_20_0',
  'nkt',
  'c_14_1',
  'c_15_1',
  'c_16_1',
  'c_17_1',
  'c_18_1',
  'c_20_1',
  'c_22_1',
  'jnkt',
  'c_18_2',
  'c_18_3',
  'c_18_4',
  'c_20_3',
  'c_20_4',
  'c_20_5',
  'c_22_5',
  'c_22_6',
  'wnkt',
  'n_6',
  'n_3',
  'cholesterol',
  // Inne
  'waste',
  'water',
  'sucrose',
  'lactose',
  'starch',
  'ash',
  'salt',
  'glucose',
  'fructose',
  // 'protein_energy',
  // 'fat_energy',
  // 'carbohydrates_energy',
  'glycemic_index',
  'protein_fat_exchangers'
] as const

export const getIngredientBaseName = (ingredientBase = '') => {
  if (ingredientBase) {
    return ingredientBase
  } else {
    return 'Kcalmar.pro'
  }
}
