import type { DishInFormType, DishTypes, StoreDietDish } from '@/types/Diet'
import type { Ref } from 'vue'

import { A, D, G } from '@mobily/ts-belt'
import { computed } from 'vue'

type BaseTags = 'is_vegan' | 'is_vegetarian' | 'is_diary_free' | 'is_gluten_free'

const autoTagDict: Record<BaseTags, DishTypes[number]> = {
  is_vegan: 51,
  is_vegetarian: 52,
  is_diary_free: 53,
  is_gluten_free: 54
}

export const useGetAutoTags = (dish: Ref<StoreDietDish | DishInFormType>) => {
  return computed(() => {
    let autoTags: DishTypes = [51, 52, 53, 54]

    dish.value.ingredients.forEach((ingredient) => {
      A.forEach(D.toPairs(autoTagDict), (pair) => {
        const [key, value] = pair as [BaseTags, DishTypes[number]]
        if (G.isNotNullable(ingredient[key]) && !ingredient[key]) {
          autoTags = autoTags.filter((v) => v !== value)
        }
      })
    })
    return autoTags
  })
}
