<template>
  <div
    class="base-radio"
    :class="{ hover: hover, disabled: disabled }"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="clickHandler"
  >
    <div
      class="base-radio__tick"
      :class="{ checked: modelValue }"
    />
    <label
      v-if="label"
      class="base-radio__label"
    >
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const props = defineProps({
  modelValue: {
    required: true,
    type: Boolean
  },
  label: {
    type: String
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue'])
const hover = ref(false)

const clickHandler = () => {
  emit('update:modelValue', !props.modelValue)
}
</script>
