<template>
  <div
    class="ingredient__row"
    :class="`columns-${chosenNutrientsLength}`"
  >
    <div class="column">
      <BaseIcon
        v-if="!ingredient.is_public"
        name="lock"
      />
    </div>
    <div
      class="column ingredient__name"
      :title="ingredient.name"
    >
      {{ ingredient.name }}
    </div>
    <div class="column base">
      {{ getIngredientBaseName(ingredient.base_name) }}
    </div>
    <div class="column ingredient">
      {{ ingredient.measurement }} ({{ ingredient.grams }}g)
    </div>
    <div
      v-for="(value, key, index) in ingredient.nutrients"
      :key="`nutrientValue-${key}-${index}`"
      class="column ingredient sortable"
    >
      {{ value }}
    </div>
    <div class="column favorite">
      <BaseTooltip class="base-tooltip--right base-tooltip--top">
        <template #tooltip>
          {{
            !isIngredientFavorite ? 'Dodaj do ulubionych' : 'Usuń z ulubionych'
          }}
        </template>
        <IconButton
          name="favorite"
          type="close"
          :icon-variant="!isIngredientFavorite ? 'outline' : 'fill'"
          @click="toggleFavorite(ingredient.id)"
        />
      </BaseTooltip>
    </div>
    <div
      class="row__actions"
      :class="{ 'one-item': ingredient.is_public }"
    >
      <div class="ingredient__actions">
        <Space
          direction="columns"
          :space="2"
        >
          <BaseTooltip class="base-tooltip--right base-tooltip--top">
            <template #tooltip>
              Skopiuj produkt
            </template>
            <IconButton
              icon-size="18"
              name="filter_none"
              @click="copyIngredient(ingredient.id)"
            />
          </BaseTooltip>
          <BaseTooltip
            v-if="!ingredient.is_public"
            class="base-tooltip--right base-tooltip--top"
          >
            <template #tooltip>
              Edytuj produkt
            </template>
            <IconButton
              icon-size="18"
              name="edit"
              @click="editProduct(ingredient.id)"
            />
          </BaseTooltip>
          <BaseTooltip
            v-if="!ingredient.is_public"
            class="base-tooltip--right base-tooltip--top"
          >
            <template #tooltip>
              Usuń produkt
            </template>
            <IconButton
              class="delete"
              name="delete"
              variant="outline"
              type="error"
              icon-size="18"
              @click="onDelete(ingredient.id)"
            />
          </BaseTooltip>
        </Space>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { DietListIngredient } from '@/types/Diet'
import type { PropType } from 'vue'

import { storeToRefs } from 'pinia'
import { computed, toRef } from 'vue'
import { useRouter } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import { IconButton } from '@/components/IconButton'
import { Space } from '@/components/Space'
import { ingredientsService } from '@/services/ingredientsService'
import { useGlobalStore } from '@/store/globalStore'
import { reportError } from '@/utils/reportError'

const props = defineProps({
  ingredient: {
    type: Object as PropType<DietListIngredient>,
    required: true
  },
  hasAddIngredientPermission: {
    type: Boolean,
    required: true
  },
  chosenNutrientsLength: {
    type: Number,
    required: true
  },
  onDelete: {
    type: Function as PropType<(ingredientId: number) => void>,
    required: true
  }
})

const canCopy = toRef(props, 'hasAddIngredientPermission')
const router = useRouter()
const globalStore = useGlobalStore()
const { hasPerm, showAppUpgradePlanModal, changeIngredientCounter, toggleFavoriteIngredient } =
  globalStore
const { user } = storeToRefs(globalStore)

const isIngredientFavorite = computed(() => {
  return user?.value?.favourite_ingredients.includes(props.ingredient.id)
})

const editProduct = (ingredientId: number) => {
  void router.push({ name: 'IngredientsListEdit', params: { ingredientId } })
}

const toggleFavorite = async (ingredientId: number) => {
  if (hasPerm('accounts.dish_favourite')) {
    const response = await ingredientsService.toggleFavoriteIngredient(ingredientId)
    if (response.status === 200) {
      toggleFavoriteIngredient(ingredientId)
    }
  } else {
    showAppUpgradePlanModal()
  }
}
const copyIngredient = async (ingredientId: number) => {
  if (canCopy.value) {
    try {
      const response = await ingredientsService.copyIngredient(ingredientId)
      if (response.status === 200) {
        changeIngredientCounter({
          value: 1
        })
        void router.push({
          name: 'IngredientsListEdit',
          params: { ingredientId: response.data.id }
        })
      }
    } catch (error) {
      reportError(error, 'Error while copying ingredient', { id: ingredientId })
    }
  } else {
    showAppUpgradePlanModal()
  }
}

const getIngredientBaseName = (ingredientBase = '') => {
  if (ingredientBase) {
    return ingredientBase
  } else {
    return 'Kcalmar.pro'
  }
}
</script>

<style scoped>
.ingredient__row {
  display: grid;
  align-items: center;
  grid-column-gap: 10px;
  height: 40px;
  max-height: 40px;
  position: relative;
}

.row__actions {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, transparent, var(--color-main-10) 50px);
}

.ingredient__row:hover .row__actions {
  display: block;
}

.ingredient__row:hover,
.ingredient__row--selected {
  background: var(--color-main-10);
}

.ingredient__row:hover .favorite :deep(.base-icon) {
  color: var(--color-main-100);
}

.columns-6 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(6, 100px) 20px 105px;
}

.columns-5 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(5, 100px) 20px 105px;
}

.columns-4 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(4, 100px) 20px 105px;
}

.columns-3 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(3, 100px) 20px 105px;
}

.columns-2 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(2, 100px) 20px 105px;
}

.columns-1 {
  grid-template-columns: 28px minmax(150px, auto) 80px 170px repeat(1, 100px) 20px 105px;
}

@media screen and (width <= 1580px) {
  .columns-6 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(6, 74px) 20px 105px;
  }

  .columns-5 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(5, 74px) 20px 105px;
  }

  .columns-4 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(4, 74px) 20px 105px;
  }

  .columns-3 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(3, 74px) 20px 105px;
  }

  .columns-2 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(2, 74px) 20px 105px;
  }

  .columns-1 {
    grid-template-columns: 28px minmax(150px, auto) 80px 120px repeat(1, 74px) 20px 105px;
  }
}

.column {
  text-align: right;
}

.ingredient__name {
  text-align: left;
  color: var(--color-primary-text);
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 10px;
}

.row__actions.one-item {
  padding-right: 66px;
}

.ingredient__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 10px;
}

.sortable {
  text-align: right;
  padding-right: 25px;
  min-width: 30px;
}

.base {
  font-family: Montserrat-Medium;
}

.delete {
  color: var(--color-main-100);
}
</style>
