import type { SearchIngredient } from '@/services/dishService'
import type { DietIngredient } from '@/types/Diet'

export const mapToSearchIngredient = (ingredient: DietIngredient): SearchIngredient => ({
  ingredient_name: ingredient.name,
  ingredient__is_gluten_free: ingredient.is_gluten_free,
  ingredient__is_diary_free: ingredient.is_diary_free,
  ingredient__is_vegetarian: ingredient.is_vegetarian,
  ingredient__is_vegan: ingredient.is_vegan
})
