<template>
  <IngredientsListV2Search />
  <IngredientsListV2Navigation />
  <IngredientsListV2Table />
  <IngredientsListV2IzzModal />
  <IngredientsListV2DeleteIngredientModal />
  <ChosenNutrientsModal
    v-if="chosenNutrientsModalVisible"
    :chosen-nutrients="chosenNutrients"
    @close="hideChosenNutrientsModal"
    @load-custom-nutrients="loadCustomNutrients($event)"
  />
</template>

<script lang="ts" setup>
import type { DietListIngredient } from '@/types/Diet'
import type { IngredientType } from '@/types/IngredientList'
import type { Nutrients } from '@/utils/nutrients'

import { A, G } from '@mobily/ts-belt'
import { useQuery } from '@tanstack/vue-query'
import { useElementSize } from '@vueuse/core'
import round from 'lodash/round'
import { storeToRefs } from 'pinia'
import { computed, onMounted, provide, ref } from 'vue'
import { useRouter } from 'vue-router'

import ChosenNutrientsModal from '@/components/ingredients/ChosenNutrientsModal.vue'
import { useFuseSearch } from '@/hooks/useFuseSearch'
import { ingredientsService } from '@/services/ingredientsService'
import { useGlobalStore } from '@/store/globalStore'
import { reportError } from '@/utils/reportError'

import IngredientsListV2DeleteIngredientModal from './IngredientsListV2DeleteIngredientModal.vue'
import IngredientsListV2IzzModal from './IngredientsListV2IzzModal.vue'
import IngredientsListV2Navigation from './IngredientsListV2Navigation.vue'
import IngredientsListV2Search from './IngredientsListV2Search.vue'
import IngredientsListV2Table from './IngredientsListV2Table.vue'
import { IngredientsFiltersInject } from './provider'

const chosenNutrients = ref<Nutrients[]>([
  'protein',
  'fat',
  'carbohydrates',
  'carbohydrate_exchanger',
  'fiber'
])
const {
  isLoading,
  data: ingredients,
  refetch
} = useQuery({
  queryKey: ['ingredients', ...chosenNutrients.value],
  queryFn: async () => (await ingredientsService.fetchIngredients(chosenNutrients.value)).data,
  initialData: []
})
const ingredientsType = ref<IngredientType>('all')
const navigationRef = ref<HTMLDivElement>()
const searchRef = ref<HTMLDivElement>()
const router = useRouter()

const { height: searchHeight } = useElementSize(searchRef)
const { height: navigationHeight } = useElementSize(navigationRef)
const searchedPhrase = ref('')
const chosenNutrientsModalVisible = ref(false)

const ingredientTypeForFilters = ref<IngredientType[]>([])
const ingredientCategoryForFilters = ref<number[]>([])
const ingredientBaseForFilters = ref<number[]>([])
const ingredientTagForFilters = ref<(keyof DietListIngredient)[]>([])
const kcalMinValue = ref<number>(0)
const kcalMaxValue = ref<number>(0)
const resetFiltersValue = ref(0)
const izzConditionsConfirmed = ref(true)
const ingredientsIzzModalVisible = ref(false)
const getFavoritesIngredientsIds = ref<number[]>([])
const ingredientToDelete = ref<number>()
const searchedIngredients = useFuseSearch(ingredients, searchedPhrase, ['name'])
const globalStore = useGlobalStore()
const { user } = storeToRefs(globalStore)
const {
  hasPerm,
  createMessage,
  showAppUpgradePlanModal,
  changeIngredientCounter,
  toggleFavoriteIngredient
} = globalStore

const ingredientsFilterBy = computed(() => {
  const returnedIngredientByCategoryList: DietListIngredient[] = []
  const favoriteIngredients = getFavoritesIngredientsIds
  let newIngredients = searchedIngredients.value

  if (!izzConditionsConfirmed.value) {
    newIngredients = newIngredients.filter((ingredient) => ingredient.base_name !== 'IŻŻ')
  }
  if (ingredientsType.value === 'is_public') {
    newIngredients = newIngredients.filter((ingredient) => ingredient.is_public)
  }
  if (ingredientsType.value === 'is_private') {
    newIngredients = newIngredients.filter((ingredient) => !ingredient.is_public)
  }
  if (ingredientsType.value === 'is_favorite') {
    newIngredients = newIngredients.filter((ingredient) =>
      favoriteIngredients.value.includes(ingredient.id)
    )
  }

  if (ingredientTypeForFilters.value.length > 0) {
    if (ingredientTypeForFilters.value.includes('is_public')) {
      newIngredients.forEach((ingredient) => {
        if (ingredient.is_public) {
          if (!returnedIngredientByCategoryList.includes(ingredient))
            returnedIngredientByCategoryList.push(ingredient)
        }
        return false
      })
    }
    if (ingredientTypeForFilters.value.includes('is_private')) {
      newIngredients.forEach((ingredient) => {
        if (!ingredient.is_public) {
          if (!returnedIngredientByCategoryList.includes(ingredient))
            returnedIngredientByCategoryList.push(ingredient)
        }
        return false
      })
    }
    if (ingredientTypeForFilters.value.includes('is_favorite')) {
      newIngredients.forEach((ingredient) => {
        if (favoriteIngredients.value.includes(ingredient.id)) {
          if (!returnedIngredientByCategoryList.includes(ingredient))
            returnedIngredientByCategoryList.push(ingredient)
        }
        return false
      })
    }

    newIngredients = returnedIngredientByCategoryList
  }
  if (ingredientCategoryForFilters.value.length > 0) {
    newIngredients = newIngredients.filter((ingredient) =>
      ingredientCategoryForFilters.value.includes(ingredient.category)
    )
  }
  if (ingredientBaseForFilters.value.length > 0) {
    newIngredients = newIngredients.filter((ingredient) =>
      ingredientBaseForFilters.value.includes(ingredient.base)
    )
  }
  if (ingredientTagForFilters.value.length > 0) {
    A.forEach(ingredientTagForFilters.value, (tag) => {
      newIngredients = newIngredients.filter((ingredient) => {
        return ingredient[tag] === 0 || ingredient[tag] === true
      })
    })
  }
  if (kcalMinValue.value !== 0) {
    newIngredients = newIngredients.filter((ingredient) => {
      return ingredient.nutrients.calories >= kcalMinValue.value
    })
  }
  if (kcalMaxValue.value !== 0) {
    newIngredients = newIngredients.filter((ingredient) => {
      return ingredient.nutrients.calories <= kcalMaxValue.value
    })
  }

  return newIngredients
})

const ingredientsFilterByCounted = computed(() => {
  return ingredientsFilterBy.value.length
})

const kcalMin = computed(() => {
  const data = ingredients
  return round(Math.min(...data.value.map((o) => o.nutrients.calories)), 1)
})
const kcalMax = computed(() => {
  const data = ingredients
  return round(Math.max(...data.value.map((o) => o.nutrients.calories)), 1)
})

const showIngredientDeleteModal = (ingredientId: number) => {
  ingredientToDelete.value = ingredientId
}
const hideIngredientDeleteModal = () => {
  ingredientToDelete.value = undefined
}

const deleteIngredient = async () => {
  if (ingredientToDelete.value) {
    await ingredientsService.deleteIngredient(ingredientToDelete.value)
    changeIngredientCounter({
      value: -1
    })
    await refetch()
    hideIngredientDeleteModal()
    createMessage({ title: 'Usunięto produkt' })
  }
}

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const showIngredientsIzzModal = () => {
  ingredientsIzzModalVisible.value = true
}
const resetFilters = () => {
  resetFiltersValue.value++
  ingredientTypeForFilters.value = []
  ingredientCategoryForFilters.value = []
  ingredientTagForFilters.value = []
  ingredientBaseForFilters.value = []
  searchedPhrase.value = ''
  scrollToTop()
}
const setKcalMin = (value: number) => {
  kcalMinValue.value = value
  scrollToTop()
}
const setKcalMax = (value: number) => {
  kcalMaxValue.value = value
  scrollToTop()
}
const resetKcalMinMax = () => {
  kcalMinValue.value = 0
  kcalMaxValue.value = 0
}

const resetExtendedFilters = () => {
  resetFilters()
}
const setIngredientTypeForFilters = (ingredientTypesList: IngredientType[]) => {
  ingredientTypeForFilters.value = ingredientTypesList
  scrollToTop()
}
const setIngredientCategoryForFilters = (ingredientCategoryList: number[]) => {
  ingredientCategoryForFilters.value = ingredientCategoryList

  scrollToTop()
}
const setIngredientBaseForFilters = (ingredientBaseList: number[]) => {
  ingredientBaseForFilters.value = ingredientBaseList

  scrollToTop()
}
const setIngredientTagForFilters = (ingredientTagList: string[]) => {
  ingredientTagForFilters.value = ingredientTagList as (keyof DietListIngredient)[]

  scrollToTop()
}

const getChosenNutrients = () => {
  const nutrients: Nutrients[] = []
  if (ingredients.value.length > 0) {
    for (const nutrient in ingredients.value[0]?.nutrients) {
      if (nutrient !== 'calories') {
        nutrients.push(nutrient as Nutrients)
      }
    }
  }
  return nutrients
}
const hideChosenNutrientsModal = () => {
  chosenNutrientsModalVisible.value = false
}
const loadCustomNutrients = async (nutrientsList: Nutrients[]) => {
  chosenNutrients.value = nutrientsList
  hideChosenNutrientsModal()
  await refetch()

  localStorage.setItem('chosenNutrients', getChosenNutrients().toString())
}
const showChosenNutrientsModal = () => {
  if (hasPerm('accounts.diet_microelements')) {
    chosenNutrientsModalVisible.value = true
  } else {
    showAppUpgradePlanModal()
  }
}

const areFiltersActive = computed(() => {
  return (
    Boolean(searchedPhrase.value) ||
    ingredientTypeForFilters.value.length > 0 ||
    ingredientTypeForFilters.value.length > 0 ||
    ingredientCategoryForFilters.value.length > 0 ||
    ingredientBaseForFilters.value.length > 0 ||
    ingredientTagForFilters.value.length > 0 ||
    kcalMinValue.value > kcalMin.value ||
    (kcalMaxValue.value < kcalMax.value && kcalMaxValue.value !== 0)
  )
})

const getAllCounter = computed(() => {
  if (!izzConditionsConfirmed.value) {
    return ingredients.value.filter((ingredient) => ingredient.base_name !== 'IŻŻ').length
  }
  return ingredients.value.length
})
const getPublicCounter = computed(() => {
  if (!izzConditionsConfirmed.value) {
    return ingredients.value.filter((ingredient) => ingredient.base_name !== 'IŻŻ').length
  }
  return ingredients.value.filter((ingredient) => ingredient.is_public).length
})

const getPrivateCounter = computed(() => {
  return ingredients.value.filter((ingredient) => !ingredient.is_public).length
})

const getFavoriteCounter = computed(() => {
  let _ingredient = ingredients.value
  if (!izzConditionsConfirmed.value) {
    _ingredient = _ingredient.filter((ingredient) => ingredient.base_name !== 'IŻŻ')
  }
  const favoriteIngredients = getFavoritesIngredientsIds
  return _ingredient.filter((ingredient) => favoriteIngredients.value.includes(ingredient.id))
    .length
})

const setIngrediedientsFilterByType = (type: IngredientType) => {
  ingredientsType.value = type
  resetFilters()
}

onMounted(async () => {
  createMessage({
    title: 'Wartości podane w tabeli dotyczą 100 g części jadalnej produktu',
    timeout: 0
  })

  const savedChosenNutrients = localStorage.getItem('chosenNutrients')

  getFavoritesIngredientsIds.value = user?.value?.favourite_ingredients ?? []
  let savedChosenNutrientsList: Nutrients[] = []
  try {
    if (savedChosenNutrients) {
      if (G.isString(savedChosenNutrients) && savedChosenNutrients.length > 0) {
        await loadCustomNutrients(savedChosenNutrients.split(',') as Nutrients[])
      } else {
        await loadCustomNutrients(savedChosenNutrientsList)
      }
    }
  } catch (err) {
    reportError(err, 'Error during loading custom nutrients', { savedChosenNutrients })
  }
  try {
    await refetch()
  } catch (err) {
    reportError(err, 'Error during refetching ingredients')
  }
  if (!user?.value?.izz_conditions_confirm) {
    izzConditionsConfirmed.value = false
    ingredientsIzzModalVisible.value = true
  }
  getFavoritesIngredientsIds.value = user?.value?.favourite_ingredients ?? []
})

const toggleFavorite = async (ingredientId: number) => {
  if (hasPerm('accounts.dish_favourite')) {
    const response = await ingredientsService.toggleFavoriteIngredient(ingredientId)
    if (response.status === 200) {
      toggleFavoriteIngredient(ingredientId)
    }
  } else {
    showAppUpgradePlanModal()
  }
}

const isIngredientFavorite = (id: number) => {
  return !!user?.value?.favourite_ingredients.includes(id)
}

const editProduct = async (ingredientId: number) => {
  await router.push({ name: 'IngredientsListEdit', params: { ingredientId } })
}

const copyIngredient = async (ingredientId: number) => {
  const response = await ingredientsService.copyIngredient(ingredientId)
  if (response.status === 200) {
    changeIngredientCounter({
      value: 1
    })
    await router.push({
      name: 'IngredientsListEdit',
      params: { ingredientId: response.data.id }
    })
  }
}

provide(IngredientsFiltersInject, {
  kcalMax,
  ingredients,
  kcalMin,
  ingredientsType,
  ingredientsFilterBy,
  resetFiltersValue,
  izzConditionsConfirmed,
  loading: isLoading,
  setIngredientTypeForFilters,
  setIngrediedientsFilterByType,
  setIngredientCategoryForFilters,
  setIngredientBaseForFilters,
  setIngredientTagForFilters,
  setKcalMin,
  setKcalMax,
  resetKcalMinMax,
  resetExtendedFilters,
  areFiltersActive,
  searchedPhrase,
  showChosenNutrientsModal,
  showIngredientsIzzModal,
  ingredientsFilterByCounted,
  getAllCounter,
  getFavoriteCounter,
  getPublicCounter,
  getPrivateCounter,
  searchRef,
  navigationRef,
  searchHeight,
  navigationHeight,
  ingredientsIzzModalVisible,
  getChosenNutrients,
  toggleFavorite,
  isIngredientFavorite,
  copyIngredient,
  editProduct,
  showIngredientDeleteModal,
  deleteIngredient,
  ingredientToDelete
})
</script>
