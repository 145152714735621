<template>
  <div class="base-loader">
    <div class="base-loader__circle1 base-loader__circle" />
    <div class="base-loader__circle2 base-loader__circle" />
    <div class="base-loader__circle3 base-loader__circle" />
    <div class="base-loader__circle4 base-loader__circle" />
    <div class="base-loader__circle5 base-loader__circle" />
    <div class="base-loader__circle6 base-loader__circle" />
    <div class="base-loader__circle7 base-loader__circle" />
    <div class="base-loader__circle8 base-loader__circle" />
    <div class="base-loader__circle9 base-loader__circle" />
    <div class="base-loader__circle10 base-loader__circle" />
    <div class="base-loader__circle11 base-loader__circle" />
    <div class="base-loader__circle12 base-loader__circle" />
  </div>
</template>
