import type { VariantPropsOf } from '@/utils/variants'
import type { PropType } from 'vue'

import { computed, h, useSlots } from 'vue'

import { colors } from '@/assets/scss/variables'
import { variants } from '@/utils/variants'

import s from './style.module.scss'

const styles = variants({
  base: s.root,
  variants: {
    type: {
      thin: s.thin,
      extralight: s.extralight,
      light: s.light,
      regular: s.regular,
      medium: s.medium,
      semibold: s.semibold,
      bold: s.bold,
      extrabold: s.extrabold,
      black: s.black
    },
    size: {
      xs: s['size-xs'],
      sm: s['size-sm'],
      base: s['size-base'],
      lg: s['size-lg'],
      xl: s['size-xl'],
      '2xl': s['size-2xl'],
      '3xl': s['size-3xl'],
      '4xl': s['size-4xl'],
      '5xl': s['size-5xl'],
      '6xl': s['size-6xl'],
      '7xl': s['size-7xl'],
      '8xl': s['size-8xl'],
      '9xl': s['size-9xl']
    },
    align: {
      left: s.left,
      center: s.center,
      right: s.right
    }
  },
  defaultVariants: {
    type: 'regular',
    size: 'base',
    align: 'left'
  }
})

export type Props = VariantPropsOf<typeof styles> & {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div'
  color?: keyof typeof colors
}

export const T = (props: Props) => {
  const slots = useSlots()
  const color = props.color ? colors[props.color] : undefined
  const classes = computed(() => {
    return styles({
      type: props.type,
      size: props.size,
      align: props.align
    })
  })
  const as = props.as ?? 'p'

  return h(as, { class: classes.value, style: { color } }, slots.default?.())
}

T.props = {
  type: String as PropType<Props['type']>,
  size: String as PropType<Props['size']>,
  as: String as PropType<Props['as']>,
  align: String as PropType<Props['align']>,
  color: String as PropType<Props['color']>
}
