// 97 elements
export const normsNutrients = [
  // Podstawowe składniki odżywcze
  'energy_value_kj',
  'energy_value_according_regulation_kcal',
  'energy_value_according_regulation_kj',
  'carbohydrates_total',
  'animal_protein',
  'plant_protein',
  'protein_according_regulation',
  'fiber',
  // Składniki mineralne
  'sodium',
  'potassium',
  'calcium',
  'phosphorus',
  'magnesium',
  'iron',
  'zinc',
  'copper',
  'iodine',
  'manganese',
  // Witaminy
  'thiamin',
  'riboflavin',
  'niacin',
  'folate_total',
  'vitamin_B_12',
  'vitamin_B6',
  'vitamin_A_RAE',
  'vitamin_C',
  'vitamin_D',
  'vitamin_E',
  'carotene_beta',
  'retinol',
  // Aminokwasy
  'isoleucine',
  'leucine',
  'lysine',
  'methionine',
  'cystine',
  'phenylalanine',
  'tyrosine',
  'threonine',
  'tryptophan',
  'valine',
  'arginine',
  'histidine',
  'alanine',
  'aspartic_acid',
  'glutamic_acid',
  'glycine',
  'proline',
  'serine',
  // Kwasy tłuszczowe
  'c_4_0',
  'c_6_0',
  'c_8_0',
  'c_10_0',
  'c_12_0',
  'c_14_0',
  'c_15_0',
  'c_16_0',
  'c_17_0',
  'c_18_0',
  'c_20_0',
  'nkt',
  'c_14_1',
  'c_15_1',
  'c_16_1',
  'c_17_1',
  'c_18_1',
  'c_20_1',
  'c_22_1',
  'jnkt',
  'c_18_2',
  'c_18_3',
  'c_18_4',
  'c_20_3',
  'c_20_4',
  'c_20_5',
  'c_22_5',
  'c_22_6',
  'wnkt',
  'n_6',
  'n_3',
  'cholesterol',
  // Inne
  'waste',
  'water',
  'sucrose',
  'lactose',
  'starch',
  'ash',
  'salt',
  'glucose',
  'fructose',
  'protein_energy',
  'fat_energy',
  'carbohydrates_energy',
  'glycemic_index',
  'glycemic_load',
  'simple_sugars_sum',
  'carbohydrate_exchanger',
  'protein_fat_exchangers'
] as const

export const normsAssumptions = [
  {
    name: 'Podstawowe składniki odżywcze',
    nutrients: {
      energy_value_kj: {
        name: 'Wartość energetyczna kJ',
        tooltip: '',
        maxValue: 100000
      },
      energy_value_according_regulation_kcal: {
        name: 'Wart. energ. wg Rozp. 1169/2011',
        tooltip: '',
        maxValue: 10000
      },
      energy_value_according_regulation_kj: {
        name: 'Wart. energ. wg Rozp. 1169/2011 kJ',
        tooltip: '',
        maxValue: 100000
      },
      carbohydrates_total: {
        name: 'Węglowodany ogółem',
        tooltip: '',
        maxValue: 10000
      },
      animal_protein: {
        name: 'Białko zwierzęce',
        tooltip: '',
        maxValue: 10000
      },
      plant_protein: {
        name: 'Białko roślinne',
        tooltip: '',
        maxValue: 10000
      },
      protein_according_regulation: {
        name: 'Białko ogółem wg Rozp 1169/2011',
        tooltip: '',
        maxValue: 10000
      },
      fiber: {
        name: 'Błonnik',
        tooltip: '',
        maxValue: 10000
      }
    }
  },
  {
    name: 'Składniki mineralne',
    nutrients: {
      sodium: {
        name: 'Sód',
        tooltip:
          'Normy ustalane na podstawie badań lub obserwacji przeciętnego spożycia żywności przez osoby zdrowe i prawidłowo odżywione (AI).',
        maxValue: 10000
      },
      potassium: {
        name: 'Potas',
        tooltip:
          'Normy ustalane na podstawie badań lub obserwacji przeciętnego spożycia żywności przez osoby zdrowe i prawidłowo odżywione (AI).',
        maxValue: 10000
      },
      calcium: {
        name: 'Wapń',
        tooltip: '',
        maxValue: 10000
      },
      phosphorus: {
        name: 'Fosfor',
        tooltip: '',
        maxValue: 10000
      },
      magnesium: {
        name: 'Magnez',
        tooltip: '',
        maxValue: 10000
      },
      iron: {
        name: 'Żelazo',
        tooltip: '',
        maxValue: 10000
      },
      zinc: {
        name: 'Cynk',
        tooltip: '',
        maxValue: 10000
      },
      copper: {
        name: 'Miedź',
        tooltip: '',
        maxValue: 10000
      },
      iodine: {
        name: 'Jod',
        tooltip:
          'Normy ustalane na podstawie badań lub obserwacji przeciętnego spożycia żywności przez osoby zdrowe i prawidłowo odżywione (AI).',
        maxValue: 10000
      },
      manganese: {
        name: 'Mangan',
        tooltip:
          'Normy ustalane na podstawie badań lub obserwacji przeciętnego spożycia żywności przez osoby zdrowe i prawidłowo odżywione (AI).',
        maxValue: 10000
      }
    }
  },
  {
    name: 'Witaminy',
    nutrients: {
      thiamin: {
        name: 'Tiamina',
        tooltip: 'Witamina B1',
        maxValue: 10000
      },
      riboflavin: {
        name: 'Ryboflawina',
        tooltip: 'Witamina B2',
        maxValue: 10000
      },
      niacin: {
        name: 'Niacyna',
        tooltip: 'Witamina PP/B3',
        maxValue: 10000
      },
      folate_total: {
        name: 'Foliany',
        tooltip: 'Witamina B9',
        maxValue: 10000
      },
      vitamin_B_12: {
        name: 'Kobalamina',
        tooltip: 'Witamina B12',
        maxValue: 10000
      },
      vitamin_B6: {
        name: 'Pirydoksyna',
        tooltip: 'Witamina B6',
        maxValue: 10000
      },
      vitamin_A_RAE: {
        name: 'Ekwiwalent Retinolu',
        tooltip: 'Witamina A',
        maxValue: 10000
      },
      vitamin_C: {
        name: 'Witamina C',
        tooltip: '',
        maxValue: 10000
      },
      vitamin_D: {
        name: 'Witamina D',
        tooltip:
          'Normy ustalane na podstawie badań lub obserwacji przeciętnego spożycia żywności przez osoby zdrowe i prawidłowo odżywione (AI).',
        maxValue: 10000
      },
      vitamin_E: {
        name: 'Witamina E',
        tooltip:
          'Normy ustalane na podstawie badań lub obserwacji przeciętnego spożycia żywności przez osoby zdrowe i prawidłowo odżywione (AI).',
        maxValue: 10000
      },
      carotene_beta: {
        name: 'β-karoten',
        tooltip: '',
        maxValue: 10000
      },
      retinol: {
        name: 'Retinol',
        tooltip: '',
        maxValue: 10000
      }
    }
  },
  {
    name: 'Aminokwasy',
    nutrients: {
      isoleucine: {
        name: 'Izoleucyna',
        tooltip: '',
        maxValue: 10000
      },
      leucine: {
        name: 'Leucyna',
        tooltip: '',
        maxValue: 10000
      },
      lysine: {
        name: 'Lizyna',
        tooltip: '',
        maxValue: 10000
      },
      methionine: {
        name: 'Metionina',
        tooltip: '',
        maxValue: 10000
      },
      cystine: {
        name: 'Cysteina',
        tooltip: '',
        maxValue: 10000
      },
      phenylalanine: {
        name: 'Fenyloalanina',
        tooltip: '',
        maxValue: 10000
      },
      tyrosine: {
        name: 'Tyrozyna',
        tooltip: '',
        maxValue: 10000
      },
      threonine: {
        name: 'Treonina',
        tooltip: '',
        maxValue: 10000
      },
      tryptophan: {
        name: 'Tryptofan',
        tooltip: '',
        maxValue: 10000
      },
      valine: {
        name: 'Walina',
        tooltip: '',
        maxValue: 10000
      },
      arginine: {
        name: 'Arginina',
        tooltip: '',
        maxValue: 10000
      },
      histidine: {
        name: 'Histydyna',
        tooltip: '',
        maxValue: 10000
      },
      alanine: {
        name: 'Alanina',
        tooltip: '',
        maxValue: 10000
      },
      aspartic_acid: {
        name: 'Kwas asparaginowy',
        tooltip: '',
        maxValue: 10000
      },
      glutamic_acid: {
        name: 'Kwas glutaminowy',
        tooltip: '',
        maxValue: 10000
      },
      glycine: {
        name: 'Glicyna',
        tooltip: '',
        maxValue: 10000
      },
      proline: {
        name: 'Prolina',
        tooltip: '',
        maxValue: 10000
      },
      serine: {
        name: 'Seryna',
        tooltip: '',
        maxValue: 10000
      }
    }
  },
  {
    name: 'Kwasy tłuszczowe',
    nutrients: {
      c_4_0: {
        name: 'C4:0',
        tooltip: '',
        maxValue: 10000
      },
      c_6_0: {
        name: 'C6:0',
        tooltip: '',
        maxValue: 10000
      },
      c_8_0: {
        name: 'C8:0',
        tooltip: '',
        maxValue: 10000
      },
      c_10_0: {
        name: 'C10:0',
        tooltip: '',
        maxValue: 10000
      },
      c_12_0: {
        name: 'C12:0',
        tooltip: '',
        maxValue: 10000
      },
      c_14_0: {
        name: 'C14:0',
        tooltip: '',
        maxValue: 10000
      },
      c_15_0: {
        name: 'C15:0',
        tooltip: '',
        maxValue: 10000
      },
      c_16_0: {
        name: 'C16:0',
        tooltip: '',
        maxValue: 10000
      },
      c_17_0: {
        name: 'C17:0',
        tooltip: '',
        maxValue: 10000
      },
      c_18_0: {
        name: 'C18:0',
        tooltip: '',
        maxValue: 10000
      },
      c_20_0: {
        name: 'C20:0',
        tooltip: '',
        maxValue: 10000
      },
      nkt: {
        name: 'Nasycone ogółem',
        tooltip: '',
        maxValue: 10000
      },
      c_14_1: {
        name: 'C14:1',
        tooltip: '',
        maxValue: 10000
      },
      c_15_1: {
        name: 'C15:1',
        tooltip: '',
        maxValue: 10000
      },
      c_16_1: {
        name: 'C16:1',
        tooltip: '',
        maxValue: 10000
      },
      c_17_1: {
        name: 'C17:1',
        tooltip: '',
        maxValue: 10000
      },
      c_18_1: {
        name: 'C18:1',
        tooltip: '',
        maxValue: 10000
      },
      c_20_1: {
        name: 'C20:1',
        tooltip: '',
        maxValue: 10000
      },
      c_22_1: {
        name: 'C22:1',
        tooltip: '',
        maxValue: 10000
      },
      jnkt: {
        name: 'Jednonienasycone ogółem',
        tooltip: '',
        maxValue: 10000
      },
      c_18_2: {
        name: 'C18:2',
        tooltip: 'LA',
        maxValue: 10000
      },
      c_18_3: {
        name: 'C18:3',
        tooltip: 'ALA/GLA',
        maxValue: 10000
      },
      c_18_4: {
        name: 'C18:4',
        tooltip: '',
        maxValue: 10000
      },
      c_20_3: {
        name: 'C20:3',
        tooltip: '',
        maxValue: 10000
      },
      c_20_4: {
        name: 'C20:4',
        tooltip: 'ARA',
        maxValue: 10000
      },
      c_20_5: {
        name: 'EPA',
        tooltip: 'C20:5',
        maxValue: 10000
      },
      c_22_5: {
        name: 'DPA',
        tooltip: '',
        maxValue: 10000
      },
      c_22_6: {
        name: 'DHA',
        tooltip: 'C22:6',
        maxValue: 10000
      },
      wnkt: {
        name: 'Wielonienasycone ogółem',
        tooltip: '',
        maxValue: 10000
      },
      n_6: {
        name: 'n-6',
        tooltip: 'Omega 6',
        maxValue: 10000
      },
      n_3: {
        name: 'n-3',
        tooltip: 'Omega 3',
        maxValue: 10000
      },
      cholesterol: {
        name: 'Cholesterol',
        tooltip: '',
        maxValue: 10000
      }
    }
  },
  {
    name: 'Inne',
    nutrients: {
      waste: {
        name: 'Odpadki',
        tooltip: '',
        maxValue: 10000
      },
      water: {
        name: 'Woda',
        tooltip: '',
        maxValue: 10000
      },
      sucrose: {
        name: 'Sacharoza',
        tooltip: '',
        maxValue: 10000
      },
      lactose: {
        name: 'Laktoza',
        tooltip: '',
        maxValue: 10000
      },
      starch: {
        name: 'Skrobia',
        tooltip: '',
        maxValue: 10000
      },
      ash: {
        name: 'Popiół',
        tooltip: '',
        maxValue: 10000
      },
      salt: {
        name: 'Sól',
        tooltip: '',
        maxValue: 10000
      },
      glucose: {
        name: 'Glukoza',
        tooltip: '',
        maxValue: 10000
      },
      fructose: {
        name: 'Fruktoza',
        tooltip: '',
        maxValue: 10000
      },
      // protein_energy: {
      //   name: '% energii z białek',
      //   tooltip: '',
      //   maxValue: 10000
      // },
      fat_energy: {
        name: '% energii z tłuszczów',
        tooltip: '',
        maxValue: 10000
      },
      carbohydrates_energy: {
        name: '% energii z węglowodanów ogółem',
        tooltip: 'Węglowodany przyswajalne + błonnik',
        maxValue: 10000
      },
      glycemic_index: {
        name: 'Indeks glikemiczny',
        tooltip: '',
        maxValue: 100
      },
      glycemic_load: {
        name: 'Ładunek glikemiczny',
        tooltip: '',
        maxValue: 100
      },
      simple_sugars_sum: {
        name: 'Suma cukrów prostych',
        tooltip:
          'Cukry proste są sumą dostępnych danych o składnikach: glukozy, fruktozy, laktozy i sacharozy',
        maxValue: 40000
      },
      carbohydrate_exchanger: {
        name: 'Wymienniki węglowodanowe',
        tooltip: '',
        maxValue: 10000
      },
      protein_fat_exchangers: {
        name: 'Wymienniki białkowo-tłuszczowe',
        tooltip: '',
        maxValue: 10000
      }
    }
  }
]

export const normsRanges = {
  age_or_pregnancy_or_lactation_range: [
    '0-0,5',
    '0,5-1',
    '1-2',
    '2-3',
    '4-6',
    '7-9',
    '10-12',
    '13-15',
    '16-18',
    '19-30',
    '31-50',
    '51-65',
    '66-75',
    '>75',
    '<19 pregnancy',
    '>19 pregnancy',
    '<19 lactation',
    '>19 lactation'
  ] as const,
  male: {
    cholesterol: [
      300,
      300,
      300,
      300,
      300,
      300,
      300,
      300,
      300,
      300,
      300,
      300,
      300,
      300,
      null,
      null,
      null,
      null
    ] as const,
    c_22_6: [
      0.1,
      0.1,
      0.1,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      null,
      null,
      null,
      null
    ] as const,
    c_20_5: [
      null,
      null,
      null,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      null,
      null,
      null,
      null
    ] as const,
    c_20_4: [
      0.14,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ] as const,
    copper: [
      0.2,
      0.3,
      0.3,
      0.3,
      0.4,
      0.7,
      0.7,
      0.9,
      0.9,
      0.9,
      0.9,
      0.9,
      0.9,
      0.9,
      null,
      null,
      null,
      null
    ] as const,
    iodine: [
      110,
      130,
      90,
      90,
      90,
      100,
      120,
      150,
      150,
      150,
      150,
      150,
      150,
      150,
      null,
      null,
      null,
      null
    ] as const,
    folate_total: [
      null,
      null,
      150,
      150,
      200,
      300,
      300,
      400,
      400,
      400,
      400,
      400,
      400,
      400,
      null,
      null,
      null,
      null
    ] as const,
    vitamin_C: [
      null,
      null,
      40,
      40,
      50,
      50,
      50,
      75,
      75,
      90,
      90,
      90,
      90,
      90,
      null,
      null,
      null,
      null
    ] as const,
    carbohydrates: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ] as const,
    vitamin_A_RAE: [
      null,
      null,
      400,
      400,
      450,
      500,
      600,
      900,
      900,
      900,
      900,
      900,
      900,
      900,
      null,
      null,
      null,
      null
    ] as const,
    vitamin_D: [
      10,
      10,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      15,
      null,
      null,
      null,
      null
    ] as const,
    vitamin_E: [4, 5, 6, 6, 6, 7, 10, 10, 10, 10, 10, 10, 10, 10, null, null, null, null] as const,
    zinc: [2, 3, 3, 3, 5, 5, 8, 11, 11, 11, 11, 11, 11, 11, null, null, null, null] as const,
    niacin: [
      null,
      null,
      6,
      6,
      8,
      12,
      12,
      16,
      16,
      16,
      16,
      16,
      16,
      16,
      null,
      null,
      null,
      null
    ] as const,
    fiber: [
      null,
      null,
      10,
      10,
      null,
      null,
      10,
      null,
      null,
      10,
      10,
      10,
      10,
      10,
      null,
      null,
      null,
      null
    ] as const,
    sodium: [
      120,
      370,
      750,
      750,
      1000,
      1200,
      1300,
      1500,
      1500,
      1500,
      1500,
      1500,
      1500,
      1500,
      null,
      null,
      null,
      null
    ] as const,
    thiamin: [
      null,
      null,
      0.5,
      0.5,
      0.6,
      0.9,
      1,
      1.2,
      1.2,
      1.3,
      1.3,
      1.3,
      1.3,
      1.3,
      null,
      null,
      null,
      null
    ] as const,
    fat: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ] as const,
    potassium: [
      400,
      750,
      800,
      800,
      1100,
      1800,
      2400,
      3000,
      3500,
      3500,
      3500,
      3500,
      3500,
      3500,
      null,
      null,
      null,
      null
    ] as const,
    vitamin_B_12: [
      null,
      null,
      0.9,
      0.9,
      1.2,
      1.8,
      1.8,
      2.4,
      2.4,
      2.4,
      2.4,
      2.4,
      2.4,
      2.4,
      null,
      null,
      null,
      null
    ] as const,
    phosphorus: [
      150,
      300,
      460,
      460,
      500,
      600,
      1250,
      1250,
      1250,
      700,
      700,
      700,
      700,
      700,
      null,
      null,
      null,
      null
    ] as const,
    manganese: [
      0.003,
      0.6,
      1.2,
      1.2,
      1.5,
      1.5,
      1.9,
      2.2,
      2.2,
      2.3,
      2.3,
      2.3,
      2.3,
      2.3,
      null,
      null,
      null,
      null
    ] as const,
    calcium: [
      200,
      260,
      700,
      700,
      1000,
      1000,
      1300,
      1300,
      1300,
      1000,
      1000,
      1000,
      1200,
      1200,
      null,
      null,
      null,
      null
    ] as const,
    riboflavin: [
      null,
      null,
      0.5,
      0.5,
      0.6,
      0.9,
      1,
      1.3,
      1.3,
      1.3,
      1.3,
      1.3,
      1.3,
      1.3,
      null,
      null,
      null,
      null
    ] as const,
    magnesium: [
      30,
      70,
      80,
      80,
      130,
      130,
      240,
      410,
      410,
      400,
      420,
      420,
      420,
      420,
      null,
      null,
      null,
      null
    ] as const,
    iron: [0.3, 11, 7, 7, 10, 10, 10, 12, 12, 10, 10, 10, 10, 10, null, null, null, null] as const,
    protein: [
      null,
      null,
      1.17,
      1.17,
      1.1,
      1.1,
      1.1,
      1.1,
      0.95,
      0.9,
      0.9,
      0.9,
      0.9,
      0.9,
      null,
      null,
      null,
      null
    ] as const,
    vitamin_B6: [
      null,
      null,
      0.5,
      0.5,
      0.6,
      1,
      1.2,
      1.3,
      1.3,
      1.3,
      1.3,
      1.7,
      1.7,
      1.7,
      null,
      null,
      null,
      null
    ] as const,
    glycemic_index: [
      55,
      55,
      55,
      55,
      55,
      55,
      55,
      55,
      55,
      55,
      55,
      55,
      55,
      55,
      null,
      null,
      null,
      null
    ] as const,
    glycemic_load: [80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, null, null, null, null]
  },
  female: {
    cholesterol: [
      300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300
    ] as const,
    c_22_6: [
      0.1, 0.1, 0.1, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125, 0.125,
      0.275, 0.275, 0.275, 0.275
    ] as const,
    c_20_5: [
      null,
      null,
      null,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125,
      0.125
    ] as const,
    c_20_4: [
      0.14,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ] as const,
    copper: [
      0.2, 0.3, 0.3, 0.3, 0.4, 0.7, 0.7, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 1, 1, 1.3, 1.3
    ] as const,
    iodine: [
      110, 130, 90, 90, 90, 100, 120, 150, 150, 150, 150, 150, 150, 150, 220, 220, 290, 290
    ] as const,
    folate_total: [
      null,
      null,
      150,
      150,
      200,
      300,
      400,
      400,
      400,
      400,
      400,
      400,
      400,
      400,
      600,
      600,
      500,
      500
    ] as const,
    vitamin_C: [
      null,
      null,
      40,
      40,
      50,
      50,
      50,
      65,
      65,
      75,
      75,
      75,
      75,
      75,
      80,
      85,
      115,
      120
    ] as const,
    carbohydrates: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ] as const,
    vitamin_A_RAE: [
      null,
      null,
      400,
      400,
      450,
      500,
      600,
      700,
      700,
      700,
      700,
      700,
      700,
      700,
      750,
      770,
      1200,
      1300
    ] as const,
    vitamin_D: [10, 10, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15] as const,
    vitamin_E: [4, 5, 6, 6, 6, 7, 8, 8, 8, 8, 8, 8, 8, 8, 10, 10, 11, 11] as const,
    zinc: [2, 3, 3, 3, 5, 5, 8, 9, 9, 8, 8, 8, 8, 8, 12, 11, 13, 12] as const,
    niacin: [null, null, 6, 6, 8, 12, 12, 14, 14, 14, 14, 14, 14, 14, 18, 18, 17, 17] as const,
    fiber: [
      null,
      null,
      10,
      10,
      null,
      null,
      10,
      null,
      null,
      10,
      10,
      10,
      10,
      10,
      null,
      null,
      null,
      null
    ] as const,
    sodium: [
      120, 370, 750, 750, 1000, 1200, 1300, 1500, 1500, 1500, 1500, 1500, 1500, 1500, 1500, 1500,
      1500, 1500
    ] as const,
    thiamin: [
      null,
      null,
      0.5,
      0.5,
      0.6,
      0.9,
      1,
      1.1,
      1.1,
      1.1,
      1.1,
      1.1,
      1.1,
      1.1,
      1.4,
      1.4,
      1.5,
      1.5
    ] as const,
    fat: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ] as const,
    potassium: [
      400, 750, 800, 800, 1100, 1800, 2400, 3000, 3500, 3500, 3500, 3500, 3500, 3500, 3500, 3500,
      4000, 4000
    ] as const,
    vitamin_B_12: [
      null,
      null,
      0.9,
      0.9,
      1.2,
      1.8,
      1.8,
      2.4,
      2.4,
      2.4,
      2.4,
      2.4,
      2.4,
      2.4,
      2.6,
      2.6,
      2.8,
      2.8
    ] as const,
    phosphorus: [
      150, 300, 460, 460, 500, 600, 1250, 1250, 1250, 700, 700, 700, 700, 700, 1250, 700, 1250, 700
    ] as const,
    manganese: [
      0.003, 0.6, 1.2, 1.2, 1.5, 1.5, 1.6, 1.6, 1.6, 1.8, 1.8, 1.8, 1.8, 1.8, 2, 2, 2.6, 2.6
    ] as const,
    calcium: [
      200, 260, 700, 700, 1000, 1000, 1300, 1300, 1300, 1000, 1000, 1200, 1200, 1200, 1300, 1000,
      1300, 1000
    ] as const,
    riboflavin: [
      null,
      null,
      0.5,
      0.5,
      0.6,
      0.9,
      1,
      1.1,
      1.1,
      1.1,
      1.1,
      1.1,
      1.1,
      1.1,
      1.4,
      1.4,
      1.6,
      1.6
    ] as const,
    magnesium: [
      30, 70, 80, 80, 130, 130, 240, 360, 360, 310, 320, 320, 320, 320, 400, 360, 360, 320
    ] as const,
    iron: [0.3, 11, 7, 7, 10, 10, 10, 15, 15, 18, 18, 10, 10, 10, 27, 27, 10, 10] as const,
    protein: [
      null,
      null,
      1.17,
      1.17,
      1.1,
      1.1,
      1.1,
      1.1,
      0.95,
      0.9,
      null,
      null,
      null,
      null,
      null,
      1.2,
      1.45,
      null
    ] as const,
    vitamin_B6: [
      null,
      null,
      0.5,
      0.5,
      0.6,
      1,
      1.2,
      1.2,
      1.2,
      1.3,
      1.3,
      1.5,
      1.5,
      1.5,
      1.9,
      1.9,
      2,
      2
    ] as const,
    simple_sugars_sum: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ] as const,
    carbohydrate_exchanger: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ] as const,
    glycemic_index: [
      55, 55, 55, 55, 55, 55, 55, 55, 55, 55, 55, 55, 55, 55, 55, 55, 55, 55
    ] as const,
    glycemic_load: [
      80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80
    ] as const,
    protein_fat_exchangers: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ] as const
  }
}
