<template>
  <BaseModal
    :modal-class="'ingredients-izz-modal'"
    @close="hideIngredientsIzzModal"
  >
    <template #title>
      Warunki korzystania z Bazy danych produktów IŻŻ
    </template>
    <div class="content">
      <div>
        Wartość odżywcza produktów pochodzi ze źródła: Baza danych
        <span>„Tabele wartości odżywczej produktów spożywczych i potraw – Instytut Żywności i Żywienia,
          Warszawa 2017, Wydanie IV rozszerzone i uaktualnione.”</span>
        i zostały wykorzystane w ramach kalkulatora za zgodą Instytutu Żywności i Żywienia w
        Warszawie. Użytkownik Kalkulatora zobowiązany jest do przestrzegania postanowień licencji
        wskazanych poniżej.
      </div>
      <div>Użytkownik kalkulatora jest uprawniony do:</div>
      <div>
        - wyświetlania wartości odżywczych produktów i posiłków obliczonych przy użyciu kalkulatora,
        na urządzeniu użytkownika kalkulatora w trybie online,
      </div>
      -uzyskania przez użytkownika kalkulatora wersji drukowanej lub elektronicznej w formacie PDF,
      wartości odżywczych produktów i posiłków, obliczonych przy użyciu kalkulatora i przekazania go
      osobie trzeciej,
      <div>
        - wykorzystania prezentowanych danych i udostępniania osobom trzecim, wyłącznie za
        pośrednictwem serwisu Kcalmar.com; Kcalmar.pro oraz aplikacji mobilnych „Kcalmar dieta i
        przepisy”.
      </div>
      <div>
        - Użytkownik kalkulatora nie jest uprawniony do dokonywania opracowań lub przystosowań,
        wprowadzania zmian lub uzupełnień odnośnie do wyświetlanych danych, które pochodzą z bazy
        danych „Tabele wartości odżywczej produktów spożywczych i potraw. Instytut Żywności i
        Żywienia, Warszawa 2017, Wydanie IV rozszerzone i uaktualnione” (dalej: „Baza”).
      </div>
      <div>
        - Użytkownik kalkulatora nie posiada prawa do dokonywania tłumaczenia jakiejkolwiek części
        prezentowanych mu danych.
      </div>
      - Użytkownik aplikacji kalkulatora nie jest nadto uprawniony do wypożyczania, wynajmowania ani
      do innych form przekazywania osobom trzecim udzielonej w ramach korzystania z kalkulatora
      licencji poza przypadkami opisanymi powyżej.
      <div>
        -Na użytkowniku kalkulatora spoczywa obowiązek zabezpieczenia danych pochodzących z Bazy
        przed ich nieuprawnionym rozpowszechnianiem i kopiowaniem.
      </div>
    </div>
    <template #actions>
      <div
        v-if="!izzConditionsConfirmed"
        class="actions"
      >
        <StyledButton
          variant="outline"
          @click.once="hideIngredientsIzzModal"
        >
          Anuluj
        </StyledButton>
        <StyledButton @click.once="izzConditionsConfirm()">
          Akceptuję
        </StyledButton>
      </div>
      <div
        v-else
        class="actions confirmed"
      >
        <StyledButton
          variant="outline"
          @click.once="hideIngredientsIzzModal"
        >
          Zamknij
        </StyledButton>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { G } from '@mobily/ts-belt'
import BaseModal from '@/components/BaseModal.vue'
import { ingredientsService } from '@/services/ingredientsService'
import { reportError } from '@/utils/reportError'

import { StyledButton } from '../StyledButton'

const emit = defineEmits(['hideIngredientsIzzModal', 'izzConditionsConfirmed'])
const props = defineProps({
  izzConditionsConfirmedProp: {
    type: Boolean,
    required: true
  }
})
const izzConditionsConfirmed = ref(props.izzConditionsConfirmedProp)

const hideIngredientsIzzModal = () => {
  emit('hideIngredientsIzzModal')
}
const izzConditionsConfirm = async () => {
  if (G.isNotNullable(dataLayer) && G.isArray(dataLayer)) {
    dataLayer.push({
      event: 'izz_database_accepted',
      button_location: 'modal'
    })
  }
  try {
    await ingredientsService.izzConditionsConfirm()
    izzConditionsConfirmed.value = true

    emit('izzConditionsConfirmed')
  } catch (error) {
    reportError(error, 'Error while confirming IZZ conditions')
  }
}
</script>
<style>
.ingredients-izz-modal {
  width: 748px !important;
  padding: 32px 80px !important;
}

.base-modal-title {
  font-size: 24px !important;
  font-family: Montserrat-Medium !important;
}
</style>
<style scoped>
.content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.content div span {
  font-family: Montserrat-Medium;
  font-size: 12px;
}

.actions {
  width: 270px;
  display: flex;
  justify-content: space-between;
}

.actions.confirmed {
  justify-content: flex-end;
}
</style>
