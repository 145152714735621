import { api } from '@/services/apiService'
import type { UserFlag } from '@/store/types'

export const userService = {
  async updateFlag(userFlag: UserFlag, value = true) {
    const res = await api.post(`/user_flags/`, {
      [userFlag]: value
    })

    return res.status === 200
  }
}
