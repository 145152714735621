<template>
  <div class="base-select">
    <div
      tabindex="0"
      class="base-select__selected"
      :class="{ 'base-select__selected--active': active }"
      @click.stop="show(active)"
    >
      {{ selectedText }}
    </div>
    <div
      v-if="active"
      ref="selectRef"
      class="base-select__options"
    >
      <div
        v-for="(option, index) in options"
        :key="option.value"
        :ref="(el) => (itemRefs[index] = el as HTMLDivElement)"
        class="base-select__option"
        :class="{ 'base-select__option--selected': modelValue === option.value }"
        @click.stop="selectOption(option, index)"
      >
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T extends string | number">
import type { PropType } from 'vue'

import { G } from '@mobily/ts-belt'
import { useEventListener } from '@vueuse/core'
import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue'

import eventBus from '@/eventBus'

type Option<T> = {
  text: string
  value: T
}

const props = defineProps({
  modelValue: {
    required: true,
    type: [String, Number]
  },
  options: {
    type: Array as PropType<Option<T>[] | readonly Option<T>[]>,
    required: true
  }
})
const emit = defineEmits(['update:modelValue'])
const itemRefs = ref<HTMLDivElement[]>([])
const selectRef = ref<HTMLDivElement>()
const active = ref(false)
const activeOptionIndex = ref<number>()

const selectedText = computed(() => {
  return props.options.find((option) => option.value === props.modelValue)?.text
})

const show = async (isActive: boolean) => {
  eventBus.emit('deactivateSelects')
  active.value = !active.value
  if (!isActive && G.isNotNullable(activeOptionIndex.value)) {
    await nextTick()

    const activeElement = itemRefs.value[activeOptionIndex.value]

    if (activeElement && selectRef.value) {
      selectRef.value.scrollTo(0, activeElement.offsetTop - selectRef.value.offsetHeight / 2)
    }
  }
}
const hide = () => {
  active.value = false
}
const selectOption = (option: Option<T>, index: number) => {
  emit('update:modelValue', option.value)
  activeOptionIndex.value = index
  hide()
}

useEventListener(document, 'click', hide)

onMounted(() => {
  eventBus.on('deactivateSelects', hide)
  const activeOption = props.options.find((option) => option.value === props.modelValue)
  if (activeOption) {
    activeOptionIndex.value = props.options.indexOf(activeOption)
  }
})
onBeforeUnmount(() => {
  eventBus.off('deactivateSelects', hide)
})
</script>
