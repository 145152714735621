<template>
  <tr>
    <td class="ingredient-name">
      <div class="ingredients-table__cell">
        <DishOrIngredientAddInput
          v-if="replaceIngredientInputActiveIndex === ingredientIndex"
          test-id="DietDishFormIngredient"
          :filters="dishFlags"
          mode="ingredient"
          @select="
            replaceIngredient({
              dishOrIngredientId: $event.dishOrIngredientId,
            })
          "
          @keyup.esc="emit('hideReplaceIngredientInput')"
        />
        <div v-else>
          <BaseTooltip
            v-if="canDoiBeEditedStatus"
            class="base-tooltip--top base-tooltip--left ingredient-name-tooltip ingredient-tooltip"
          >
            <div class="ingredient-name-button">
              <div
                class="ingredient-name-text"
                @click="!isLoading && emit('setReplaceIngredientInputActiveIndex', ingredientIndex)"
              >
                {{ ingredient.name }}
              </div>
            </div>
            <template #tooltip>
              Kliknij, aby podmienić produkt w potrawie.
            </template>
          </BaseTooltip>
          <div
            v-else
            class="ingredient-name-text"
          >
            {{ ingredient.name }}
          </div>
        </div>
        <div class="allergens-row">
          <div
            v-if="ingredientAllergens.notLike"
            class="allergens not-like"
          />
          <div
            v-if="ingredientAllergens.like"
            class="allergens like"
          />
          <div
            v-if="ingredientAllergens.allergies"
            class="allergens allergies"
          />
          <div
            v-if="ingredientAllergens.intolerances"
            class="allergens intolerances"
          />
        </div>
      </div>
    </td>
    <td class="ingredient-quantity">
      <div class="ingredients-table__cell">
        <BaseInputNumber
          test-id="DietDishFormIngredient__Quantity"
          class="ingredient-quantity__input"
          :class="{ 'is-disabled': !canDoiBeEditedStatus && mode !== 'add' }"
          :get-ref="
            (el) => {
              getIngredientInputRef(el)
            }
          "
          :tabindex="ingredientIndex + 1"
          :min="0"
          :max="9999"
          :precision="1"
          :step="0.1"
          :disabled="isLoading"
          :model-value="round(ingredient.quantity, 1)"
          @update:model-value="
            emit('updateIngredientInDishQuantity', {
              ingredientIndex,
              originalIndex,
              quantity: $event,
              dishIngredientId: ingredient.dish_ingredient_id
            })
          "
        />
        <template v-if="!extended">
          g
        </template>
      </div>
    </td>
    <td class="ingredient-measurement">
      <div class="ingredients-table__cell">
        <BaseInputNumber
          test-id="DietDishFormIngredient__Measurement"
          class="ingredient-measurement__input"
          :class="{ 'is-disabled': !canDoiBeEditedStatus && mode !== 'add' }"
          :tabindex="ingredientIndex + 1"
          :min="0"
          :max="maxQuantity"
          :precision="2"
          :step="0.01"
          :disabled="isLoading"
          :model-value="ingredientMeasurement"
          @update:model-value="
            emit('updateIngredientInDishQuantity', {
              ingredientIndex,
              originalIndex,
              quantity: round(Math.min($event * ingredient.grams, 9999), 1),
              dishIngredientId: ingredient.dish_ingredient_id
            })
          "
        />
        x {{ ingredient.measurement }}
      </div>
    </td>
    <template v-if="extended">
      <td class="ingredient-nutrient">
        <div class="ingredients-table__cell">
          {{ dietsService.getIngredientNutrient(ingredient, 'calories', true) }}
        </div>
      </td>
      <td
        v-for="nutrient in selectedNutrients"
        :key="`ingredient-${nutrient}`"
        class="ingredient-nutrient"
      >
        <div class="ingredients-table__cell">
          {{ dietsService.getIngredientNutrient(ingredient, nutrient, true) }}
        </div>
      </td>
    </template>
    <td
      v-if="canDoiBeEditedStatus"
      class="ingredient-actions"
    >
      <div class="ingredients-table__cell">
        <IconButton
          name="delete"
          type="destructive"
          @click="
            emit('deleteIngredient', {
              originalIndex,
              dishIngredientId: ingredient.dish_ingredient_id,
              ingredientId: ingredient.ingredient_id
            })
          "
        />
      </div>
    </td>
  </tr>
</template>
<script setup lang="ts">
import type { AllergensMap, DietIngredient, DishSearchFilters } from '@/types/Diet'
import type { Nutrients } from '@/utils/nutrients'

import round from 'lodash/round'
import { computed, ref, toRef } from 'vue'

import BaseInputNumber from '@/components/BaseInputNumber.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import { IconButton } from '@/components/IconButton'
import { dietsService } from '@/services/dietsService'
import { useDietsStore } from '@/store/dietsStore'

import DishOrIngredientAddInput from '@/components/DishOrIngredientAddInput.vue'
import { reportError } from '@/utils/reportError'

type Emits = {
  (e: 'hideReplaceIngredientInput'): void
  (
    e: 'deleteIngredient',
    data: { dishIngredientId?: number; ingredientId: number; originalIndex: number }
  ): void
  (e: 'setReplaceIngredientInputActiveIndex', index: number): void
  (
    e: 'replaceIngredient',
    data: {
      dishIngredientId: number | undefined
      dishOrIngredientId: number
      ingredientIndex: number
      originalIndex: number
      quantity: number
      ingredient: DietIngredient
    }
  ): void
  (
    e: 'updateIngredientInDishQuantity',
    data: {
      dishIngredientId?: number
      ingredientIndex: number
      originalIndex: number
      quantity: number
    }
  ): void
}
type Props = {
  allergens?: AllergensMap
  canDoiBeEditedStatus: boolean
  dishFlags?: DishSearchFilters
  extended: boolean
  getIngredientInputRef: (ref: HTMLInputElement) => void
  ingredient: DietIngredient
  ingredientIndex: number
  mode: string
  originalIndex: number
  replaceIngredientInputActiveIndex?: number
  selectedNutrients: Nutrients[]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()
const isLoading = ref(false)
const { getDishOrIngredientAllergens } = useDietsStore()
const maxQuantity = computed(() => Math.min(round(9999 / ingredient.value.grams, 1), 9999))

const ingredient = toRef(props, 'ingredient')

const ingredientMeasurement = computed(() => {
  return round(ingredient.value.quantity / ingredient.value.grams, 2)
})
const ingredientAllergens = computed(() => getDishOrIngredientAllergens(props.ingredient))

const replaceIngredient = async (data: {
  dishOrIngredientId: number
}) => {
  try {
    isLoading.value = true

    const response = await dietsService.fetchIngredient(data.dishOrIngredientId)

    emit('replaceIngredient', {
      dishIngredientId: ingredient.value.dish_ingredient_id,
      dishOrIngredientId: data.dishOrIngredientId,
      ingredientIndex: props.ingredientIndex,
      originalIndex: props.originalIndex,
      quantity: ingredient.value.quantity,
      ingredient: response.data
    })
    isLoading.value = false
  } catch (err) {
    reportError(err, 'Error while fetching ingredient during replacing', {
      ingredientId: data.dishOrIngredientId
    })
  }
}
</script>
<style lang="scss" scoped>
.ingredients-table__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-weight: normal;
  font-size: 12px;
  height: 54px;
  padding: 0;
  font-family: Montserrat-Medium;
  padding-right: 12px;
  white-space: nowrap;
  width: fit-content;
}

.ingredients-table__cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 42px;
  padding-right: 30px;
  white-space: nowrap;
  width: fit-content;
}

.ingredient-name {
  font-size: 14px;
  width: auto;
}

.ingredient-name .ingredients-table__header {
  cursor: pointer;
}

.ingredient-name .ingredients-table__cell {
  justify-content: left;
  white-space: normal;
  max-width: 90%;
  word-break: break-word;
}

.ingredient-quantity {
  width: 100px;
}

.ingredient-quantity .ingredients-table__header {
  justify-content: center;
  cursor: pointer;
}

.ingredient-measurement {
  width: 136px;
}

.ingredient-measurement .ingredients-table__cell {
  justify-content: left;
}

.ingredient-nutrient {
  width: 40px;
}

.ingredient-nutrient .ingredients-table__header {
  cursor: pointer;
}

.ingredient-nutrient.disabled {
  pointer-events: none;
}

.ingredient-actions {
  width: 28px;
}

.ingredient-actions .ingredients-table__header,
.ingredient-actions .ingredients-table__cell {
  padding: 0;
}

.ingredient-actions .base-button .base-icon {
  color: var(--color-tertiary-text);
}

.ingredient-actions .base-button:hover .base-icon {
  color: #f55f6d;
}

.ingredient-quantity__input {
  width: 52px;
  padding: 5px 4px;
  text-align: center;
}

.ingredient-measurement__input {
  width: 52px;
  padding: 5px 4px;
  text-align: center;
}

.ingredient-name-text {
  cursor: pointer;
}

.add-dish-or-ingredient-form.extended {
  min-width: 675px;
}

.allergens-row {
  display: flex;
  margin-left: 10px;
}

.allergens {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  vertical-align: middle;
}

.allergens.not-like {
  background: var(--color-warning-60);
}

.allergens.like {
  background: var(--color-success-60);
}

.allergens.allergies {
  background: #faa0a8;
}

.allergens.intolerances {
  background: #a0a8fa;
}

.name {
  width: calc(100% - 144px);
  overflow-y: auto;
}

.name__textarea {
  font-size: 24px;
  line-height: 28px;
  border: 0 !important;
  padding: 5px 0;
  font-family: Montserrat-Medium;
  width: 100%;
  display: block;
}

.info {
  line-height: 22px;
  height: 22px;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 16px;
}

.info-icon {
  margin-right: 6px;
}

.kcal-quantity-measurement {
  margin-bottom: 6px;
  font-size: 18px;
  font-family: Montserrat-Medium;
  display: flex;
  flex-direction: row;
  height: 32px;
  line-height: 32px;
}

.quantity {
  height: 32px;
  margin-left: 24px;
}

:deep(.base-input.is-disabled) {
  border-bottom: none;
  pointer-events: none;
}

.measurement {
  height: 32px;
  margin-left: 24px;
}

.nutrients {
  display: flex;
  flex-flow: row wrap;
  font-size: 12px;
  margin-top: 12px;
  line-height: 14px;
}

.nutrient {
  margin-right: 15px;
  white-space: nowrap;
}

.ingredients-switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 50px;
}

.ingredients-switch__label {
  font-size: 14px;
  font-family: Montserrat-Medium;
  line-height: 32px;
  height: 32px;
}

.ingredients-switch__radio {
  margin-left: 20px;
}

.ingredients {
  margin-top: 30px;
}

.extended .ingredients {
  padding: 0;
}

.ingredients-table {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
}

.ingredients-table :deep(tr) {
  vertical-align: top;
}

.ingredients-table :deep(th),
.ingredients-table :deep(td) {
  padding: 0;

  /* border: 1px solid orange; */
}

.ingredient-name .ingredient-tooltip {
  display: inline;
}

.ingredient-name :deep(.ingredients-table__cell) {
  justify-content: left;
  white-space: normal;
  max-width: 90%;
  word-break: break-word;
}

.ingredient-measurement :deep(.ingredients-table__cell) {
  justify-content: left;
}

.ingredient-actions .ingredients-table__header,
.ingredient-actions :deep(.ingredients-table__cell) {
  padding: 0;
}

.ingredients-empty {
  padding: 19px 0;
  font-size: 'Montserrat-Medium';
  font-size: 12px;
  text-align: center;
  border: 2px dashed var(--color-main-100);
  margin: 10px 0;
}

/* Add ingredient */
.add-ingredient {
  margin-top: 6px;
  height: 32px;
  width: 334px;
}

.extended .add-ingredient {
  padding: 0;
}

.section-name {
  font-family: Montserrat-Medium;
  font-size: 14px;
  line-height: 32px;
  margin-right: 16px;
  min-width: 85px;
}

.section-label {
  font-family: Montserrat-Medium;
}

/* Portions */
.portions {
  margin-top: 32px;
  font-size: 12px;
}

.portions .portions-inner {
  display: inline-block;
}

.used-portions-input,
.max-portions-input {
  text-align: center;
  width: 48px;
}

.max-portions-input,
.preparation-time-input {
  margin: 0 4px;
}

/* Preparation time */
.preparation-time {
  margin-top: 20px;
  font-size: 12px;
}

.preparation-time-input {
  width: 48px;
  text-align: center;
}

/* Preparation steps */
.preparation-steps {
  margin-top: 20px;
}

.preparation-steps-textarea {
  border: 0 !important;
  padding: 5px 10px 5px 0;
  line-height: 2.16;
}

.preparation-steps-textarea.is-disabled {
  pointer-events: none;
  background-color: rgb(239 239 239 / 30%);
}

.extended .preparation-steps-textarea {
  max-height: none;
}

/* Size */
.size {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  line-height: 32px;
  font-size: 14px;
}

.extended .size {
  flex-direction: column;
  align-items: flex-start;
}

.size-radio {
  margin-right: 20px;
  min-width: 150px;
}

/* Meal types */
.meal-types {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  line-height: 32px;
  font-size: 14px;
}

.extended .meal-types {
  flex-direction: column;
  align-items: flex-start;
}

.meal-types-checkbox {
  margin-right: 20px;
  min-width: 150px;
}

/* Dish types */
.dish-types {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  line-height: 32px;
  font-size: 14px;
}

.dish-types-row {
  display: flex;
  flex-wrap: wrap;
  font-family: Montserrat-Medium;
  gap: 8px;
  margin-bottom: 8px;
}

.chosen-type-box {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  padding: 1px 12px;
  width: max-content;
  height: 28px;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.chosen-type-box:hover {
  background: #f7f7f7;
}

.show-tags {
  display: flex;
  color: #000;
  background: #f0f0f0;
  width: 204px;
  height: 36px;
  border: none;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  padding: 0 12px 0 10px !important;
}

.extended .dish-types {
  flex-direction: column;
  align-items: flex-start;
}

.dish-types-checkbox {
  margin-right: 20px;
  min-width: 150px;
}

.ingredient-name-button {
  cursor: pointer;
}

.remove-tag {
  cursor: pointer;
  margin-left: 10px;
  color: rgb(43 43 43 / 36%);
}
</style>
