<template>
  <router-link
    v-slot="{ isActive, href: routerHref }"
    :to="props.to ?? '-'"
    custom
  >
    <li :class="[$style.root, isActive && $style.active, ghost && $style.ghost]">
      <a :href="props.href ?? routerHref">
        <Space
          :padding-x="props.hiddenText ? 6 : 8"
          :padding-y="props.ghost ? 0 : 6"
          :space="4"
          align-y="center"
          :align-x="props.hiddenText ? 'center' : 'start'"
          direction="columns"
        >
          <slot name="leftContent">
            <KcalmarIcon
              v-if="props.icon"
              :name="props.icon"
            />
          </slot>
          <T
            v-if="!props.hiddenText"
            type="medium"
            as="span"
          >
            <slot />
          </T>
        </Space>
      </a>
    </li>
  </router-link>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'

import { useCssModule } from 'vue'

import { KcalmarIcon } from '@ui/KcalmarIcon'
import { T } from '@ui/T'

import { Space } from '@/components/Space'

type Props = {
  active?: boolean
  ghost?: boolean
  hiddenText: boolean
  href?: string
  icon?: RemixIconName
  to?: RouteLocationRaw
}

const props = defineProps<Props>()
const $style = useCssModule()
</script>

<style lang="scss" module scoped>
.root {
  border-radius: $radius-radi-6;
  overflow: hidden;
  transition: background-color 0.2s;
  flex: 0 0 auto;

  a {
    text-decoration: none;
    color: $text-secondary;
  }

  i {
    color: $fill-600;
  }

  &.active {
    background-color: $colors-turquoise-50;

    a {
      color: $text-primary;
    }

    i {
      color: $colors-alpha-900;
    }
  }

  &.ghost:hover {
    a {
      color: $text-primary;
    }
  }

  &:hover:not(.active, .ghost) {
    background-color: $fill-100;

    a {
      color: $text-primary;
    }

    i {
      color: $colors-alpha-900;
    }
  }
}
</style>
