<template>
  <div
    v-if="announcements?.length"
    class="announces"
  >
    <DashboardAnnounceItem
      v-for="(item, index) in announcements"
      :key="index"
      :announce="item"
      :is-closeable="closeableAnnounces"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'

import DashboardAnnounceItem from '@/pages/dashboard/components/DashboardAnnounceItem.vue'
import { useGlobalStore } from '@/store/globalStore'

const globalStore = useGlobalStore()
const closeableAnnounces = ref(false)
const announcements = computed(() => globalStore.user?.announcements)
const route = useRoute()

onBeforeMount(() => {
  if (route.matched.some((r) => r.name === 'DashboardBase')) {
    closeableAnnounces.value = false
  } else {
    closeableAnnounces.value = true
  }
})
</script>

<style scoped>
.announces {
  width: 100%;
}

.announces :deep(.announce__controls) button {
  width: 28px;
  height: 28px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  background: var(--color-main-100);
  justify-content: center;
  transition: all 0.2s;
  border: none;
}

.announces :deep(.announce__controls) button i {
  color: #fff;
}

.announces :deep(.announce__controls) button:hover i {
  color: #fff;
}

.announces :deep(.announce__controls) button:hover {
  background: #008183;
}
</style>
