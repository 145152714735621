import type { DietListIngredient } from '@/types/Diet'
import type { Nutrients } from '@/utils/nutrients'
import { F } from '@mobily/ts-belt'
import type { ComputedRef, InjectionKey, Ref } from 'vue'

import { inject } from 'vue'

export type IngredientType = 'is_public' | 'is_private' | 'is_favorite' | 'all'

export type IngredientsFiltersProps = {
  areFiltersActive: ComputedRef<boolean>

  copyIngredient: (ingredientId: number) => Promise<void>
  deleteIngredient: (ingredientId: number) => Promise<void>
  editProduct: (ingredientId: number) => Promise<void>
  getAllCounter: ComputedRef<number>
  getChosenNutrients: () => Nutrients[]
  getFavoriteCounter: ComputedRef<number>
  getPrivateCounter: ComputedRef<number>
  getPublicCounter: ComputedRef<number>
  ingredientToDelete: Ref<number | undefined>
  ingredients: Ref<DietListIngredient[]>
  ingredientsFilterBy: Ref<DietListIngredient[]>
  ingredientsFilterByCounted: ComputedRef<number>
  ingredientsIzzModalVisible: Ref<boolean>
  ingredientsType: Ref<IngredientType>
  isIngredientFavorite: (ingredientId: number) => boolean
  izzConditionsConfirmed: Ref<boolean>
  kcalMax: Ref<number>
  kcalMin: Ref<number>
  loading: Ref<boolean>
  navigationHeight: Ref<number>
  navigationRef: Ref<HTMLDivElement | undefined>
  resetExtendedFilters: () => void
  resetFiltersValue: Ref<number>
  resetKcalMinMax: () => void
  searchHeight: Ref<number>
  searchRef: Ref<HTMLDivElement | undefined>
  searchedPhrase: Ref<string>
  setIngrediedientsFilterByType: (type: IngredientType) => void
  setIngredientBaseForFilters: (ingredientBaseList: number[]) => void
  setIngredientCategoryForFilters: (ingredientCategoryList: number[]) => void
  setIngredientTagForFilters: (ingredientTypesList: string[]) => void
  setIngredientTypeForFilters: (ingredientTypesList: IngredientType[]) => void
  setKcalMax: (value: number) => void
  setKcalMin: (value: number) => void
  showChosenNutrientsModal: () => void
  showIngredientDeleteModal: (ingredientId: number) => void
  showIngredientsIzzModal: () => void
  toggleFavorite: (ingredientId: number) => Promise<void>
}

export const IngredientsFiltersInject = Symbol() as InjectionKey<IngredientsFiltersProps>

export const useIngredientsProvider = () =>
  F.coerce<IngredientsFiltersProps>(inject(IngredientsFiltersInject))
