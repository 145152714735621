<template>
  <div
    v-if="visible"
    class="announce"
    :class="{ extended: showExtended }"
  >
    <div class="announce__left">
      <BaseIcon
        name="error"
        type="warning"
        size="22"
      />
    </div>
    <div class="announce__container">
      <h2>
        {{ announce.name }}
      </h2>
      <div
        class="announce__content"
        v-html="content"
      />
    </div>
    <div class="announce__controls">
      <IconButton
        v-if="announce.short_content"
        :name="showExtended ? 'expand_less' : 'expand_more'"
        @click="extend"
      />
      <IconButton
        v-if="isCloseable"
        icon-size="16"
        name="close"
        @click="closeAndSave"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import { IconButton } from '@/components/IconButton'

type Props = {
  announce: {
    content: string
    id: number
    name: string
    short_content: string
  }
  isCloseable: boolean
}

const props = defineProps<Props>()
const visible = ref(true)
const showExtended = ref(false)

const content = computed(() => {
  if (props.announce.short_content && !showExtended.value) {
    return props.announce.short_content
  }
  return props.announce.content
})

const extend = () => {
  showExtended.value = !showExtended.value
}
const closeAndSave = () => {
  visible.value = false
  sessionStorage.setItem(`announce_${props.announce.id}`, 'hidden')
}

onMounted(() => {
  if (sessionStorage.getItem(`announce_${props.announce.id}`) && props.isCloseable) {
    visible.value = false
  }
})
</script>

<style scoped>
.announce {
  background: #fff;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 16px;
}

.announce__container {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.announce__left {
  padding-top: 3px;
}

h2 {
  color: var(--color-primary-text);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  font-family: Montserrat-Medium;
  line-height: 28px;
  text-align: left;
  margin: 0 0 8px;
}

.announce :deep(p),
.announce .announce__content {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  margin: 0 0 8px;
  color: var(--color-secondary-text);
}
</style>
