<template>
  <BaseModal
    @close="modalClose"
  >
    <template #title>
      Wyświetlanie dni
    </template>

    <Space :space="6">
      <T size="sm">
        Zmiana formatu wyświetlania dni wpłynie na wygląd wydruku PDF
      </T>
      <KcalmarRadio
        name="days_of_week"
        label="Dni tygodnia"
        description="Przykład: Poniedziałek, Wtorek, Środa..."
        :model-value="dietDisplayType === 'days_of_week'"
        @update:model-value="dietDisplayType = 'days_of_week'"
      />
      <KcalmarRadio
        name="numeric"
        label="Numeryczny"
        description="Przykład: Dzień 1, Dzień 2, Dzień 3..."
        :model-value="dietDisplayType === 'numeric'"
        @update:model-value="dietDisplayType = 'numeric'"
      />
    </Space>

    <template #actions>
      <StyledButton
        variant="outline"
        @click.once="modalClose"
      >
        Anuluj
      </StyledButton>
      <StyledButton
        @click.once="handleConfirm"
      >
        Zapisz
      </StyledButton>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import BaseModal from '@/components/BaseModal.vue'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import { KcalmarRadio } from '@/components/ui/KcalmarRadio'
import { T } from '@/components/ui/T'
import { useDietsStore } from '@/store/dietsStore'
import type { DietDisplayType } from '@/types/Diet'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { G } from '@mobily/ts-belt'

const emit = defineEmits(['close'])

const dietStore = useDietsStore()
const { diet } = storeToRefs(dietStore)
const dietDisplayType = ref<DietDisplayType>(diet.value?.data.diet_type ?? 'days_of_week')

const handleConfirm = () => {
  if (G.isNotNullable(dataLayer) && G.isArray(dataLayer)) {
    dataLayer.push({
      event: 'weekview_dayformat_modal_confirm',
      dayformat: dietDisplayType.value === 'numeric' ? 'numbers' : 'days'
    })
  }
  dietStore.updateDietDisplayType(dietDisplayType.value)

  emit('close')
}
const modalClose = () => {
  emit('close')
}
</script>
