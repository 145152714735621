<template>
  <div :class="[$style.root, { [$style.active]: props.active }]">
    <Space
      direction="columns"
      :space="5"
      align-y="center"
    >
      <BaseIcon
        v-if="props.icon"
        :name="props.icon"
        type="disabled"
        :class="$style.icon"
      />
      <T
        as="span"
        size="sm"
        type="semibold"
        :class="$style.text"
      >
        {{ props.title }}
      </T>
      <KcalmarBadge
        size="small"
        type="informative"
        :active="active"
      >
        <T
          as="span"
          size="sm"
          type="semibold"
        >
          {{ props.count }}
        </T>
      </KcalmarBadge>
    </Space>
  </div>
</template>

<script setup lang="ts">
import { useCssModule } from 'vue'

import { KcalmarBadge } from '@ui/KcalmarBadge'
import { Space } from '@ui/Space'
import { T } from '@ui/T'

import BaseIcon from '@/components/BaseIcon.vue'

const $style = useCssModule()

const props = defineProps<{
  active?: boolean
  count: string | number
  icon?: IconName
  title: string
}>()
</script>

<style scoped module lang="scss">
.active {
  border-bottom: 2px solid $state-primary-active;

  .text {
    color: $text-primary;
  }
}

.root {
  padding: 10px 0;

  .icon {
    color: $state-primary-active;
  }

  &:not(.active) {
    cursor: pointer;
    border-bottom: 2px solid transparent;

    .text {
      color: $text-secondary;
    }

    .icon {
      color: $fill-400;
    }

    &:hover {
      .text {
        color: $text-primary;
      }

      .icon {
        color: $state-primary-active;
      }
    }
  }
}
</style>
