import type { DietType } from '@/store/types'

import { A, G } from '@mobily/ts-belt'
import { useEventListener, useThrottleFn } from '@vueuse/core'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import eventBus from '@/eventBus'
import { dietsService } from '@/services/dietsService'
import { useGlobalStore } from '@/store/globalStore'
import { reportError } from '@/utils/reportError'
import { stringify } from '@/utils/stringify'
import type { DietDisplayType } from '@/types/Diet'

type Props = {
  dietType: DietType
}

export type GroupOption = {
  id: number
  name: string
}

export type DietGroupDiet = {
  calories: number
  created: string
  description: string
  group?: number
  id: number
  name: string
  selected: boolean
}

export type DietGroup = {
  diets: DietGroupDiet[]
  dietsCount: number
  dietsIds: number[]
  expanded: boolean
  id: number
  name: string
  page: number
  selected: boolean
}

export const useDietList = ({ dietType }: Props) => {
  const route = useRoute()
  const router = useRouter()
  const globalStore = useGlobalStore()

  const groupNameInput = ref<HTMLInputElement[]>()
  const dietNameInput = ref<HTMLInputElement[]>()
  const dietsCount = ref(0)
  const groups = ref<DietGroup[]>([])
  const groupsOptions = ref<GroupOption[]>([])
  const selected = ref({
    all: false,
    groups: new Set<number>(),
    diets: new Set<number>()
  })
  const selectedDietsCount = ref(0)
  const expandedGroup = ref<number>(0)
  const hoveredGroupOrDiet = ref('')
  const highlightedDiet = ref('')
  const searchPhrase = ref('')
  const sortByGroups = ref('name')
  const sortByDiets = ref('name')
  const loadingDiets = ref(false)
  const loading = ref(true)
  const pageSize = ref(100)
  const pageSentinel = ref(20)
  const dragActive = ref(false)
  const dietsMoveLimit = ref(250)
  const dietsShareLimit = ref(100)
  const dietsAssignToLeaderLimit = ref(100)
  const groupNameEdit = ref<{ id?: number; name: string }>({
    id: undefined,
    name: ''
  })
  const dietNameEdit = ref<{ id?: number; name: string }>({
    id: undefined,
    name: ''
  })
  const addDietModal = ref({
    visible: false,
    name: '',
    groupId: 0,
    dietDisplayType: 'days_of_week' as DietDisplayType
  })
  const deleteDietModal = ref<{ diet?: DietGroupDiet; visible: boolean }>({
    visible: false,
    diet: undefined
  })
  const addGroupModal = ref({
    visible: false,
    name: ''
  })
  const deleteGroupModal = ref<{ group?: DietGroup; visible: boolean }>({
    visible: false,
    group: undefined
  })
  const assignToPatientModal = ref({
    visible: false,
    dietId: 0
  })
  const assignToLeaderModal = ref({
    visible: false,
    dietsIds: [] as number[]
  })
  const shareDietModal = ref({
    visible: false,
    dietsIds: [] as number[]
  })
  const copyDietMenu = ref({
    visible: false,
    dietId: 0
  })
  const moveDietMenu = ref({
    visible: false
  })

  const addDietModalGroups = computed(() => {
    return groupsOptions.value.map((group) => {
      return {
        value: group.id,
        text: group.name
      }
    })
  })
  const addDietButtonDisabled = computed(() => {
    return addDietModal.value.name.trim() === ''
  })
  const addGroupButtonDisabled = computed(() => {
    return addGroupModal.value.name.trim() === ''
  })
  const deleteDietsButtonDisabled = computed(() => {
    return selected.value.diets.size === 0
  })
  const isUniversityDiet = computed(() => {
    return dietType === 'university'
  })
  const canAssignToLeader = computed(() => {
    return dietType === 'user' && globalStore.hasPerm('accounts.diet_assign_to_leader')
  })
  const isRodoAccepted = computed(() => {
    return globalStore.user?.is_rodo_accepted ?? false
  })
  const selectedDietsEmpty = computed(() => {
    return selectedDietsCount.value === 0
  })
  const moveDisabled = computed(() => {
    return selectedDietsCount.value > dietsMoveLimit.value
  })
  const assignToLeaderDisabled = computed(() => {
    return selectedDietsCount.value > dietsAssignToLeaderLimit.value
  })
  const shareDisabled = computed(() => {
    return selectedDietsCount.value > dietsShareLimit.value
  })

  const groupPartiallySelected = (group: DietGroup) => {
    return group.dietsIds.filter((dietId) => selected.value.diets.has(dietId)).length > 0
  }
  const getIconBySort = (sortBy: string) => {
    if (sortByDiets.value === sortBy) {
      return 'expand_less'
    } else if (sortByDiets.value === `-${sortBy}`) {
      return 'expand_more'
    } else {
      return 'unfold_more'
    }
  }
  const loadGroups = async (): Promise<DietGroup[]> => {
    try {
      const groupResponse = await dietsService.fetchGroups({
        dietType,
        searchPhrase: searchPhrase.value,
        ordering: sortByGroups.value
      })

      return groupResponse.data.map(({ diets_ids, id, name }) => ({
        diets: [],
        dietsIds: diets_ids,
        dietsCount: diets_ids.length,
        id,
        name,
        selected: selected.value.groups.has(id) || allDietsInGroupSelected(diets_ids),
        expanded: id === expandedGroup.value,
        page: 0
      }))
    } catch (e) {
      reportError(e, 'Error fetching groups', {
        dietType,
        searchPhrase: searchPhrase.value,
        ordering: sortByGroups.value
      })
      return []
    }
  }

  const loadGroupsOptions = async () => {
    try {
      const groupResponse = await dietsService.fetchGroups({
        dietType,
        searchPhrase: searchPhrase.value
      })

      groupsOptions.value = groupResponse.data.map((group) => {
        return {
          id: group.id,
          name: group.name
        }
      })
    } catch (err) {
      reportError(err, 'Error fetching groups', {
        dietType,
        searchPhrase: searchPhrase.value
      })
    }
  }

  const loadDiets = async (group: DietGroup) => {
    try {
      loadingDiets.value = true
      group.page = group.page + 1

      const dietResponse = await dietsService.fetchDiets({
        dietType,
        searchPhrase: searchPhrase.value,
        ordering: sortByDiets.value,
        groupId: group.id,
        page: group.page
      })

      A.forEach(dietResponse.data.results, (diet) => {
        group.diets.push({
          ...diet,
          selected: selected.value.diets.has(diet.id)
        })
      })

      loadingDiets.value = false
      return group
    } catch (err) {
      reportError(err, 'Error fetching diets', {
        dietType,
        searchPhrase: searchPhrase.value,
        ordering: sortByDiets.value,
        groupId: group.id,
        page: group.page
      })
      return []
    }
  }

  const loadGroupsAndDiets = async () => {
    const data = await loadGroups()
    const group = findGroup(data)
    if (group) {
      await loadDiets(group)
    }
    groups.value = [...data]
    countDiets()
  }

  const countDiets = () => {
    const counter = groups.value.reduce((prev, group) => {
      return (prev += group.dietsIds.length)
    }, 0)
    dietsCount.value = counter
  }

  const filterDiets = async (phrase: string) => {
    loading.value = true
    searchPhrase.value = phrase
    selected.value.all = false
    selected.value.groups.clear()
    selected.value.diets.clear()
    await loadGroupsAndDiets()
    loading.value = false
  }

  const setSortByGroups = async () => {
    loading.value = true
    if (sortByGroups.value === 'name') {
      sortByGroups.value = '-name'
    } else {
      sortByGroups.value = 'name'
    }
    await loadGroupsAndDiets()
    loading.value = false
  }

  const setSortByDiets = async (type: string) => {
    loading.value = true
    if (sortByDiets.value === type) {
      sortByDiets.value = `-${type}`
    } else {
      sortByDiets.value = type
    }
    await loadGroupsAndDiets()
    loading.value = false
  }

  const expandGroup = async (group: DietGroup) => {
    loading.value = true
    const clickedGroupId = group.id
    groups.value.map((g) => {
      if (clickedGroupId !== g.id) {
        g.expanded = false
        g.diets = []
        g.page = 0
      }
      return g
    })
    if (group.expanded) {
      group.diets = []
      group.page = 0
      expandedGroup.value = 0
    } else {
      group.page = 0
      await loadDiets(group)
      expandedGroup.value = group.id
    }
    group.expanded = !group.expanded
    loading.value = false
  }

  const handleDietsScroll = async (group: DietGroup, event: Event) => {
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    const el = event.srcElement as HTMLElement
    const actualOffset = Math.floor(el.scrollTop / 38)
    if (group.diets.length < group.dietsCount) {
      if (actualOffset >= group.page * pageSize.value - pageSentinel.value) {
        await loadDiets(group)
      }
    }
  }

  const setHoverGroupOrDiet = (key: string) => {
    hoveredGroupOrDiet.value = key
  }

  const isHoveredGroupOrDiet = (key: string) => {
    return hoveredGroupOrDiet.value === key
  }

  const setHighlightedDiet = (key: string) => {
    highlightedDiet.value = key
  }

  const isHighlightedDiet = (key: string) => {
    return highlightedDiet.value === key
  }

  const groupIsSelectable = (group: DietGroup) => {
    return group.dietsIds.length > 0
  }

  const allGroupsSelected = () => {
    return groups.value.every((group) => selected.value.groups.has(group.id))
  }

  const allDietsInGroupSelected = (dietsIds: number[]) => {
    return dietsIds.every((dietId) => selected.value.diets.has(dietId))
  }

  const updateSelectedDietsCount = () => {
    selectedDietsCount.value = selected.value.diets.size
  }

  const selectDiet = (diet: DietGroupDiet, group: DietGroup) => {
    selected.value.diets.add(diet.id)
    diet.selected = true
    if (allDietsInGroupSelected(group.dietsIds)) {
      selected.value.groups.add(group.id)
      group.selected = true
      if (allGroupsSelected()) {
        selected.value.all = true
      }
    }
    updateSelectedDietsCount()
  }

  const unselectDiet = (diet: DietGroupDiet, group: DietGroup) => {
    selected.value.diets.delete(diet.id)
    diet.selected = false
    selected.value.groups.delete(group.id)
    group.selected = false
    selected.value.all = false
    updateSelectedDietsCount()
  }

  const selectGroup = (group: DietGroup) => {
    group.dietsIds.map((dietId) => selected.value.diets.add(dietId))
    group.diets.map((diet) => {
      selectDiet(diet, group)
    })
    selected.value.groups.add(group.id)
    group.selected = true
    if (allGroupsSelected()) {
      selected.value.all = true
    }
    updateSelectedDietsCount()
  }

  const unselectGroup = (group: DietGroup) => {
    group.dietsIds.map((dietId) => selected.value.diets.delete(dietId))
    group.diets.map((diet) => {
      unselectDiet(diet, group)
    })
    selected.value.groups.delete(group.id)
    group.selected = false
    selected.value.all = false
    updateSelectedDietsCount()
  }

  const selectAll = () => {
    groups.value.map((group) => {
      selectGroup(group)
    })
    selected.value.all = true
    updateSelectedDietsCount()
  }

  const unselectAll = () => {
    groups.value.map((group) => {
      unselectGroup(group)
    })
    selected.value.all = false
    updateSelectedDietsCount()
  }

  const handleDietCheckbox = (diet: DietGroupDiet, group: DietGroup) => {
    if (diet.selected) {
      unselectDiet(diet, group)
    } else {
      selectDiet(diet, group)
    }
  }

  const handleGroupCheckbox = (group: DietGroup) => {
    if (group.selected) {
      unselectGroup(group)
    } else {
      selectGroup(group)
    }
  }

  const handleAllCheckbox = () => {
    if (selected.value.all) {
      unselectAll()
    } else {
      selectAll()
    }
  }

  const ghostText = () => {
    if (selected.value.diets.size === 1) {
      return '1 jadłospis do przeniesienia'
    } else {
      return `${selected.value.diets.size} jadłospisy do przeniesienia`
    }
  }

  const handleDietDragStart = (diet: DietGroupDiet, group: DietGroup, event: DragEvent) => {
    if (event.dataTransfer) {
      dragActive.value = true
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('groupId', stringify(group.id))
      event.dataTransfer.setData('selectedAll', stringify(selected.value.all))
      event.dataTransfer.setData('selectedGroups', stringify(Array.from(selected.value.groups)))
      event.dataTransfer.setData('selectedDiets', stringify(Array.from(selected.value.diets)))
      if (!diet.selected) {
        unselectAll()
        selectDiet(diet, group)
        event.dataTransfer.setData('draggedDiets', stringify([diet.id]))
      } else {
        event.dataTransfer.setData('draggedDiets', stringify(Array.from(selected.value.diets)))
      }

      const ghostImage = document.getElementById('ghost-image')
      if (ghostImage) {
        event.dataTransfer.setDragImage(ghostImage, 0, 0)
      }
    }
  }

  const handleDragEnd = () => {
    dragActive.value = false
  }

  const handleDietDrop = async (group: DietGroup, event: DragEvent) => {
    if (dragActive.value && event.dataTransfer) {
      try {
        const groupId = JSON.parse(event.dataTransfer.getData('groupId')) as number
        const selectedAll = JSON.parse(event.dataTransfer.getData('selectedAll')) as boolean
        const selectedGroups = JSON.parse(event.dataTransfer.getData('selectedGroups')) as number[]
        const selectedDiets = JSON.parse(event.dataTransfer.getData('selectedDiets')) as number[]
        const draggedDiets = JSON.parse(event.dataTransfer.getData('draggedDiets')) as number[]

        if (G.isArray<number[]>(draggedDiets) && draggedDiets.length <= dietsMoveLimit.value) {
          if (group.id !== groupId) {
            loading.value = true
            await dietsService.moveDietsToGroup({
              dietType,
              groupId: group.id,
              dietsIds: draggedDiets
            })
            selected.value.all = selectedAll
            selected.value.groups = new Set(selectedGroups)
            selected.value.diets = new Set(selectedDiets)
            updateSelectedDietsCount()
            await loadGroupsAndDiets()
            loading.value = false
          }
        } else {
          globalStore.createMessage({
            title: 'Nie udało się przenieść jadłospisów',
            content: `Maksymalna liczba przenoszonych jadłospisów to ${dietsMoveLimit.value}.`,
            timeout: 0
          })
        }
      } catch (e) {
        // Log
        console.error(e)
      }
    }
  }

  const showAddDietModal = () => {
    if (G.isNotNullable(dataLayer) && G.isArray(dataLayer)) {
      dataLayer.push({
        event: 'create_diet_button_clicked',
        button_location: 'diets_tab'
      })
    }
    addDietModal.value.visible = true
  }

  const hideAddDietModal = () => {
    addDietModal.value.visible = false
    addDietModal.value.name = ''
    addDietModal.value.groupId = 0
  }

  const showDeleteDietModal = (diet?: DietGroupDiet) => {
    deleteDietModal.value.visible = true
    deleteDietModal.value.diet = diet
  }

  const hideDeleteDietModal = () => {
    deleteDietModal.value.visible = false
    deleteDietModal.value.diet = undefined
  }

  const addDiet = async () => {
    if (addDietModal.value.name.trim() !== '') {
      if (G.isNotNullable(dataLayer) && G.isArray(dataLayer)) {
        dataLayer.push({
          event: 'create_diet_modal_confirm',
          dayformat: addDietModal.value.dietDisplayType === 'numeric' ? 'numbers' : 'days'
        })
      }
      const response = await dietsService.createDiet({
        dietType,
        name: addDietModal.value.name,
        groupId: addDietModal.value.groupId,
        dietDisplayType: addDietModal.value.dietDisplayType
      })
      globalStore.changeDietCounter({
        dietType,
        value: 1
      })
      hideAddDietModal()
      globalStore.createMessage({ title: 'Dodano jadłospis' })
      void router.push({
        name: 'DietDetailWeek',
        params: { dietType, dietId: response.data.id }
      })
    }
  }

  const deleteDiet = async () => {
    let dietsIds = Array.from(selected.value.diets)
    if (deleteDietModal.value.diet) {
      dietsIds = [deleteDietModal.value.diet.id]
    }
    await dietsService.deleteDiet({
      dietType,
      dietsIds
    })

    if (dietsIds.length === 1) {
      globalStore.createMessage({ title: 'Usunięto jadłospis' })
    } else {
      globalStore.createMessage({ title: 'Usunięto jadłospisy' })
    }
    unselectAll()
    hideDeleteDietModal()
    await loadGroupsAndDiets()
    globalStore.changeDietCounter({
      dietType,
      value: -1 * dietsIds.length
    })
  }

  const showAddGroupModal = () => {
    addGroupModal.value.visible = true
  }

  const hideAddGroupModal = () => {
    addGroupModal.value.visible = false
    addGroupModal.value.name = ''
  }

  const addGroup = async () => {
    if (addGroupModal.value.name.trim() !== '') {
      const groupExists = groups.value.map((group) => group.name).includes(addGroupModal.value.name)
      if (groupExists) {
        globalStore.createMessage({
          title: 'Grupa o takiej nazwie już istnieje. Wybierz inną nazwę i spróbuj ponownie.'
        })
        hideAddGroupModal()
      } else {
        await dietsService.createGroup({
          dietType,
          name: addGroupModal.value.name.trim()
        })
        globalStore.createMessage({
          title: `Dodano grupę ${addGroupModal.value.name}`
        })
        hideAddGroupModal()
        await Promise.all([loadGroupsAndDiets(), loadGroupsOptions()])
      }
    }
  }

  const showDeleteGroupModal = (group: DietGroup) => {
    deleteGroupModal.value.visible = true
    deleteGroupModal.value.group = group
  }

  const hideDeleteGroupModal = () => {
    deleteGroupModal.value.visible = false
    deleteGroupModal.value.group = undefined
  }

  const deleteGroup = async () => {
    try {
      if (G.isNotNullable(deleteGroupModal.value.group)) {
        await dietsService.deleteGroup({
          dietType,
          groupId: deleteGroupModal.value.group.id
        })
        globalStore.createMessage({ title: 'Usunięto grupę' })
        unselectAll()
        hideDeleteGroupModal()
        await Promise.all([loadGroupsAndDiets(), loadGroupsOptions()])
      }
    } catch (err) {
      reportError(err, 'Error deleting group')
    }
  }

  const showAssignToPatientModal = (dietId: number) => {
    if (isRodoAccepted.value) {
      assignToPatientModal.value.dietId = dietId
      assignToPatientModal.value.visible = true
    } else {
      location.href = import.meta.env.VUE_APP_HOST + '/dietetyk/account/agreements'
    }
  }

  const hideAssignToPatientModal = () => {
    assignToPatientModal.value.visible = false
    assignToPatientModal.value.dietId = 0
  }

  const showAssignToLeaderModal = (dietId = 0) => {
    let dietsIds = Array.from(selected.value.diets)
    if (dietId !== 0) {
      dietsIds = [dietId]
    }
    assignToLeaderModal.value.dietsIds = dietsIds
    assignToLeaderModal.value.visible = true
  }

  const hideAssignToLeaderModal = () => {
    assignToLeaderModal.value.visible = false
    assignToLeaderModal.value.dietsIds = []
  }

  const showShareDietModal = (dietId = 0) => {
    if (globalStore.hasPerm('accounts.diet_share')) {
      let dietsIds = Array.from(selected.value.diets)
      if (dietId !== 0) {
        dietsIds = [dietId]
      }
      shareDietModal.value.dietsIds = dietsIds
      shareDietModal.value.visible = true
    } else {
      globalStore.showAppUpgradePlanModal()
    }
  }

  const hideShareDietModal = () => {
    shareDietModal.value.visible = false
    shareDietModal.value.dietsIds = []
  }

  const showCopyDietMenu = (dietId: number) => {
    copyDietMenu.value.visible = true
    copyDietMenu.value.dietId = dietId
    setHighlightedDiet(`diet-${dietId}`)
  }

  const hideCopyDietMenu = () => {
    copyDietMenu.value.visible = false
    copyDietMenu.value.dietId = 0
    setHighlightedDiet('')
  }

  const copyDiet = async (groupId: number) => {
    const dietId = copyDietMenu.value.dietId
    try {
      loading.value = true
      await dietsService.copyDietsToGroup({
        dietType,
        groupId,
        dietsIds: [dietId]
      })
      await loadGroupsAndDiets()
      globalStore.changeDietCounter({
        dietType,
        value: 1
      })
      globalStore.createMessage({ title: 'Skopiowano jadłospis' })
      hideCopyDietMenu()
      loading.value = false
    } catch (err) {
      reportError(err, 'Error copying diet', {
        dietType,
        groupId,
        dietsIds: [dietId]
      })
    }
  }

  const showMoveDietMenu = () => {
    moveDietMenu.value.visible = true
  }

  const hideMoveDietMenu = () => {
    moveDietMenu.value.visible = false
  }

  const moveDiet = async (groupId: number) => {
    const dietsIds = Array.from(selected.value.diets)
    loading.value = true
    await dietsService.moveDietsToGroup({
      dietType,
      groupId,
      dietsIds
    })
    await loadGroupsAndDiets()
    if (dietsIds.length === 1) {
      globalStore.createMessage({ title: 'Przeniesiono jadłospis' })
    } else {
      globalStore.createMessage({ title: 'Przeniesiono jadłospisy' })
    }
    hideMoveDietMenu()
    loading.value = false
  }

  const showGroupNameEdit = async (group: DietGroup) => {
    groupNameEdit.value.id = group.id
    groupNameEdit.value.name = group.name
    await nextTick(() => {
      groupNameInput.value?.[0]?.focus()
    })
  }
  const hideGroupNameEdit = () => {
    groupNameEdit.value.id = undefined
    groupNameEdit.value.name = ''
  }

  const saveGroupName = async (event: MouseEvent | KeyboardEvent) => {
    const groupId = groupNameEdit.value.id
    const name = groupNameEdit.value.name.trim()
    const groupName = groups.value.find((group) => group.name === name)
    if ('key' in event && event.type === 'keyup' && event.key === 'Enter' && name === '') {
      globalStore.createMessage({ title: 'Nazwa grupy nie może być pusta' })
    } else if (groupName && groupName.id !== groupId) {
      globalStore.createMessage({
        title: 'Grupa o podanej nazwie już istnieje'
      })
    } else {
      const group = groups.value.find(({ id }) => id === groupId)
      if (group && group.name !== name && G.isNotNullable(groupId)) {
        await dietsService.updateGroupName({
          dietType,
          groupId,
          name
        })
        group.name = name
        const groupOption = groupsOptions.value.find(({ id }) => id === groupId)
        if (groupOption) {
          groupOption.name = name
        }
      }
      hideGroupNameEdit()
      globalStore.createMessage({ title: 'Zapisano nazwę grupy' })
    }
  }

  const showDietNameEdit = async (diet: DietGroupDiet) => {
    dietNameEdit.value.id = diet.id
    dietNameEdit.value.name = diet.name
    await nextTick(() => {
      dietNameInput.value?.[0]?.focus()
    })
  }

  const hideDietNameEdit = () => {
    dietNameEdit.value.id = undefined
    dietNameEdit.value.name = ''
  }

  const saveDietName = async (event: MouseEvent | KeyboardEvent) => {
    const dietId = dietNameEdit.value.id
    const name = dietNameEdit.value.name.trim()
    if (dietId) {
      if ('key' in event && event.key === 'Enter' && name === '') {
        globalStore.createMessage({
          title: 'Nazwa jadłospisu nie może być pusta'
        })
      } else {
        const group = groups.value.find(({ dietsIds }) => dietsIds.includes(dietId))
        const diet = group?.diets.find(({ id }) => id === dietId)

        if (diet && diet.name !== name) {
          await dietsService.updateDietName({
            dietType,
            dietId,
            name
          })
          diet.name = name
        }

        hideDietNameEdit()
        globalStore.createMessage({ title: 'Zapisano nazwę jadłospisu' })
      }
    }
  }

  const findGroup = (_groups: DietGroup[]) => {
    const groupIndex = _groups.findIndex((group) => group.expanded)

    if (groupIndex !== -1) {
      return _groups[groupIndex]
    } else {
      return null
    }
  }

  const deactivateSelects = () => {
    eventBus.emit('deactivateSelects')
  }

  useEventListener(document, 'click', () => {
    hideMoveDietMenu()
    hideCopyDietMenu()
    hideDietNameEdit()
    hideGroupNameEdit()
  })

  onMounted(async () => {
    searchPhrase.value = route.query.name?.toString() ?? ''
    await loadGroupsAndDiets()
    await loadGroupsOptions()

    loading.value = false
  })

  const throttledDietScroll = useThrottleFn(handleDietsScroll, 500, true, false)

  return {
    groupNameInput,
    dietNameInput,
    addDiet,
    addDietButtonDisabled,
    addDietModalGroups,
    addGroup,
    addGroupButtonDisabled,
    addGroupModal,
    addDietModal,
    allGroupsSelected,
    assignToLeaderDisabled,
    assignToLeaderModal,
    assignToPatientModal,
    canAssignToLeader,
    copyDiet,
    copyDietMenu,
    countDiets,
    deactivateSelects,
    deleteDietsButtonDisabled,
    deleteDiet,
    deleteDietModal,
    deleteGroup,
    deleteGroupModal,
    dietNameEdit,
    // dietType,
    dietsAssignToLeaderLimit,
    dietsCount,
    dietsMoveLimit,
    dietsShareLimit,
    dragActive,
    expandedGroup,
    moveDiet,
    moveDietMenu,
    filterDiets,
    ghostText,
    groupIsSelectable,
    groupNameEdit,
    groups,
    groupsOptions,
    handleAllCheckbox,
    handleDietCheckbox,
    handleDietDragStart,
    handleDietDrop,
    showAddDietModal,
    hideAddDietModal,
    showDeleteDietModal,
    hideDeleteDietModal,
    showAddGroupModal,
    hideAddGroupModal,
    showDeleteGroupModal,
    hideDeleteGroupModal,
    showDietNameEdit,
    saveDietName,
    showGroupNameEdit,
    saveGroupName,
    handleDragEnd,
    isUniversityDiet,
    selectedDietsEmpty,
    moveDisabled,
    shareDisabled,
    groupPartiallySelected,
    setSortByDiets,
    setSortByGroups,
    expandGroup,
    setHoverGroupOrDiet,
    isHoveredGroupOrDiet,
    setHighlightedDiet,
    isHighlightedDiet,
    handleGroupCheckbox,
    showAssignToLeaderModal,
    hideAssignToLeaderModal,
    showAssignToPatientModal,
    hideAssignToPatientModal,
    showShareDietModal,
    hideShareDietModal,
    showCopyDietMenu,
    hideCopyDietMenu,
    showMoveDietMenu,
    hideMoveDietMenu,
    selected,
    shareDietModal,
    searchPhrase,
    loading,
    sortByDiets,
    sortByGroups,
    loadingDiets,
    selectedDietsCount,
    getIconBySort,
    hideDietNameEdit,
    hideGroupNameEdit,
    throttledDietScroll
  }
}
