<template>
  <div class="schedule">
    <div class="header">
      <router-link
        v-if="G.isNotNullable(prevDayIndex)"
        class="header-link left"
        :to="{
          name: 'DietDetailDay',
          params: {
            dietType: route.params.dietType,
            dietId: route.params.dietId,
            dayIndex: prevDayIndex
          }
        }"
      >
        <BaseIcon
          size="24"
          name="chevron_left"
        />
      </router-link>
      <span class="header-name">{{ dayName }}</span>
      <router-link
        v-if="nextDayIndex"
        class="header-link right"
        :to="{
          name: 'DietDetailDay',
          params: {
            dietType: route.params.dietType,
            dietId: route.params.dietId,
            dayIndex: nextDayIndex
          }
        }"
      >
        <BaseIcon
          size="24"
          name="chevron_right"
        />
      </router-link>
      <div class="day-menu">
        <IconButton
          variant="secondary"
          name="more_vert"
          @click.stop="setActiveMenu(`day-menu-${dayIndex}`)"
        />
        <div
          v-if="checkActiveMenu(`day-menu-${dayIndex}`)"
          class="day-menu__items"
        >
          <div
            class="day-menu__item"
            @click.once="copyDayHandler()"
          >
            <BaseIcon
              size="16"
              name="filter_none"
            /> <span>Skopiuj dzień</span>
          </div>
          <div
            v-if="canDietBeEdited"
            class="day-menu__item"
            :class="{ disabled: !copiedDayStatus }"
            @click.once="pasteDayHandler()"
          >
            <BaseIcon
              size="16"
              name="queue"
              :type="copiedDayStatus ? 'default' : 'disabled'"
            />
            <span>Wklej dzień</span>
          </div>
          <div
            v-if="canDietBeEdited"
            class="day-menu__item"
            @click.once="setActiveDayDeleteModal(dayIndex)"
          >
            <BaseIcon
              size="16"
              name="delete"
            /> <span>Usuń dzień</span>
          </div>
        </div>
        <BaseModal
          v-if="activeDayDeleteModal === dayIndex"
          @close="setActiveDayDeleteModal()"
        >
          <template #title>
            Czy na pewno chcesz wyczyścić dzień?
          </template>
          Wyczyszczenie dnia usunie aktualnie dodane potrawy w kolumnie
          <span>{{ schedule?.days[dayIndex]?.name }}</span>.
          <template #actions>
            <StyledButton
              variant="outline"
              @click.once="setActiveDayDeleteModal()"
            >
              Anuluj
            </StyledButton>
            <StyledButton @click.once="setActiveDayDeleteModal(), deleteDayHandler()">
              Wyczyść
            </StyledButton>
          </template>
        </BaseModal>
      </div>
    </div>
    <div
      v-for="(meal, mealIndex) in meals"
      :key="`meal-${mealIndex}`"
      class="meal"
      :class="{
        'is-disabled': !canDietBeEdited
      }"
      @drop="dropDishOrIngredientHandler($event, mealIndex)"
      @dragover.prevent
    >
      <div class="meal-header">
        <BaseSelect
          class="meal-hour"
          :model-value="meal.days[dayIndex]?.hour ?? ''"
          :options="hourOptions"
          @update:model-value="updateDayHourHandler($event, mealIndex)"
        />
        <span class="meal-name">{{ meal.name }}</span>
        <div
          v-if="
            schedule?.energyDistribution &&
              (meal.days[dayIndex]?.dishesAndIngredients?.length ?? 0 > 0)
          "
          class="energy-distribution base-tooltip base-tooltip--top base-tooltip--right"
          @mouseenter="setActiveTooltip(`energy-distribution-${mealIndex}`)"
          @mouseleave="setActiveTooltip()"
        >
          <div
            class="energy-distribution__dot"
            :class="meal.days[dayIndex]?.energyDistributionClass"
          />
          <div
            v-if="checkActiveTooltip(`energy-distribution-${mealIndex}`)"
            class="base-tooltip__content"
          >
            <div class="energy-distribution__title">
              Rozkład energii
            </div>
            <div class="energy-distribution__data">
              {{ meal.days[dayIndex]?.energyDistribution }} %
              <span class="energy-distribution__data-target">({{ meal.energyDistribution }} %)</span>
              - {{ meal.days[dayIndex]?.calories }} kcal
              <span class="energy-distribution__data-target">({{ meal.energyDistributionCalories }} kcal)</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="meal-data"
        :class="{ empty: meal.days[dayIndex]?.dishesAndIngredients.length === 0 }"
      >
        <div class="meal-dishes-and-ingredients">
          <div
            v-for="(dishOrIngredient, dishOrIngredientIndex) in meal.days[dayIndex]
              ?.dishesAndIngredients ?? []"
            :key="`dish-or-ingredient-${dishOrIngredientIndex}`"
            class="dish-or-ingredient"
            :class="{
              active: activeDishOrIngredient(mealIndex, dishOrIngredientIndex),
              repeated: dishOrIngredient.repeated
            }"
            :draggable="true ? canDietBeEdited : false"
            @click="clickDishOrIngredient(mealIndex, dishOrIngredientIndex)"
            @mousedown.ctrl="updateDragMode('copy')"
            @dragstart="dragStartDishOrIngredientHandler($event, mealIndex, dishOrIngredientIndex)"
          >
            <div class="dish-or-ingredient__name">
              {{ dishOrIngredient.name }}
              <span v-if="dishOrIngredient.portions">{{ dishOrIngredient.portions }}</span>
              <DishOrIngredientAllergens
                test-id="DietDetailDaySchedule"
                :dish-or-ingredient="dishOrIngredient"
              />
            </div>
            <div
              v-if="canDietBeEdited"
              class="dish-or-ingredient__menu"
            >
              <div
                v-if="repeatedDishesAndIngredientsActive && dishOrIngredient.repeated"
                @mouseenter="
                  setActiveTooltip(`assignment-${mealIndex}-${dayIndex}-${dishOrIngredientIndex}`)
                "
                @mouseleave="setActiveTooltip()"
              >
                <IconButton
                  name="assignment"
                  icon-size="16"
                  @click.stop="
                    showRepeatedDishDietsModal(dishOrIngredient.name, dishOrIngredient.type)
                  "
                />
                <div
                  v-if="
                    checkActiveTooltip(
                      `assignment-${mealIndex}-${dayIndex}-${dishOrIngredientIndex}`
                    )
                  "
                  class="base-tooltip__content"
                >
                  Wyświetl
                  <span v-if="dishOrIngredient.type == 'dish'"> powtarzającą się potrawę</span>
                  <span v-else>powtarzający się produkt</span>
                </div>
              </div>
              <div
                class="base-tooltip base-tooltip--top base-tooltip--center"
                @mouseenter="setActiveTooltip(`copy-${mealIndex}-${dishOrIngredientIndex}`)"
                @mouseleave="setActiveTooltip()"
              >
                <IconButton
                  name="filter_none"
                  icon-size="14"
                  @click.stop="copyDishOrIngredientHandler(mealIndex, dishOrIngredientIndex)"
                  @mousedown="updateDragMode('copy'), setActiveTooltip()"
                />
                <div
                  v-if="checkActiveTooltip(`copy-${mealIndex}-${dishOrIngredientIndex}`)"
                  class="base-tooltip__content"
                >
                  W celu skopiowania kliknij w ikonę lub przeciągnij posiłek
                </div>
              </div>
              <div
                class="base-tooltip base-tooltip--top base-tooltip--center"
                @mouseenter="setActiveTooltip(`delete-${mealIndex}-${dishOrIngredientIndex}`)"
                @mouseleave="setActiveTooltip()"
              >
                <IconButton
                  variant="outline"
                  type="destructive"
                  icon-size="16"
                  name="delete"
                  @click.stop="deleteDishOrIngredientHandler(mealIndex, dishOrIngredientIndex)"
                />
                <div
                  v-if="checkActiveTooltip(`delete-${mealIndex}-${dishOrIngredientIndex}`)"
                  class="base-tooltip__content"
                >
                  W celu usunięcia posiłku kliknij w ikonę
                </div>
              </div>
            </div>
          </div>
          <div class="add-dish-or-ingredient">
            <StyledButton
              v-if="!checkActiveAddDishOrIngredient(`add-dish-or-ingredient-${mealIndex}`)"
              left-icon="add"
              :data-component="`add-dish-or-ingredient-${mealIndex}`"
              class="add-dish-or-ingredient__button base-button--secondary base-button--outline"
              @click.stop="setActiveAddDishOrIngredient(`add-dish-or-ingredient-${mealIndex}`)"
              @keyup.enter="setActiveAddDishOrIngredient(`add-dish-or-ingredient-${mealIndex}`)"
            >
              Dodaj pozycję
            </StyledButton>
            <DishOrIngredientAddInput
              v-else
              test-id="DietDetailDaySchedule"
              mode="all"
              :dish-meal-type="meal.type"
              :filters="dishFlags"
              :related-dishes="relatedDishes"
              @select="
                addDishOrIngredient(
                  $event.dishOrIngredientId,
                  $event.dishOrIngredientType,
                  mealIndex
                )
              "
              @select-unused="
                addUnusedDishPortion(
                  $event.mealIndex,
                  $event.dayIndex,
                  $event.dishOrIngredientIndex,
                  mealIndex
                )
              "
              @keyup.esc="setActiveAddDishOrIngredient()"
            />
          </div>
        </div>
        <DietDetailDayScheduleNutrients :day="meal.days[dayIndex]" />
      </div>
    </div>
    <BaseModal
      v-if="repeatedDishDietsModal"
      @close="hideRepeatedDishDietsModal"
    >
      <template #title>
        <span v-if="repeatedDishDataType == 'dish'">Powtarzająca się potrawa</span>
        <span v-else>Powtarzający się produkt</span>
      </template>
      <div class="doi-name">
        {{ repeatedDishDataName }}
      </div>
      <div class="doi-description">
        <span v-if="repeatedDishDataType == 'dish'"> Ta potrawa </span>
        <span v-else> Ten produkt </span>
        występuje w następujących jadłospisach pacjenta (opcja uwzględnia do 5 jadłospisów wstecz):
      </div>
      <div
        v-for="repeatedDiet in repeatedDishDietsData"
        :key="`diet-${repeatedDiet.id}`"
        class="repeated-dish-diets-row"
      >
        <a
          :href="repeatedDiet.url"
          target="_blank"
        >
          {{ repeatedDiet.name }} - {{ repeatedDiet.start_date }} - {{ repeatedDiet.end_date }} (liczba wystąpień:
          {{ repeatedDiet.number_of_occurences }})
        </a>
      </div>
    </BaseModal>
  </div>
</template>

<script lang="ts" setup>
import type { DragMode } from '@/store/types'

import { G, S } from '@mobily/ts-belt'
import { useEventListener } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseModal from '@/components/BaseModal.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import DietDetailDayScheduleNutrients from '@/components/diets/DietDetailDayScheduleNutrients.vue'
import DishOrIngredientAddInput from '@/components/DishOrIngredientAddInput.vue'
import DishOrIngredientAllergens from '@/components/diets/DishOrIngredientAllergens/DishOrIngredientAllergens.vue'
import { IconButton } from '@/components/IconButton'
import { StyledButton } from '@/components/StyledButton'
import { daysNumericMap, hours } from '@/const'
import eventBus from '@/eventBus'
import { dietsService } from '@/services/dietsService'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import { reportError } from '@/utils/reportError'

const dietStore = useDietsStore()
const {
  diet,
  schedule,
  copiedDayStatus,
  dragMode,
  details,
  relatedDishes,
  repeatedDishesAndIngredientsActive,
  canDietBeEdited
} = storeToRefs(dietStore)
const globalStore = useGlobalStore()
const route = useRoute()

const activeTooltip = ref()
const activeMenu = ref()
const activeAddDishOrIngredient = ref()
const activeDayDeleteModal = ref()
const repeatedDishDietsModal = ref(false)
const repeatedDishDietsData = ref()
const user = computed(() => globalStore.user)
const repeatedDishDataName = ref('')
const repeatedDishDataType = ref('')

const dayIndex = computed(() => parseInt(route.params.dayIndex as unknown as string))

const dayName = computed(() => {
  return diet.value?.data.diet_type === 'days_of_week'
    ? (schedule.value?.days[dayIndex.value]?.name ?? '')
    : daysNumericMap[dayIndex.value]
})
const dishFlags = computed(() => {
  return diet.value?.data.dish_search_filters
})
const prevDayIndex = computed(() => {
  if (dayIndex.value > 0) {
    return dayIndex.value - 1
  }
  return undefined
})
const nextDayIndex = computed(() => {
  if (dayIndex.value < 6) {
    return dayIndex.value + 1
  }
  return undefined
})
const meals = computed(() => {
  return schedule.value?.meals ?? []
})

const hourOptions = computed(() => {
  return Array.from(hours, (hour) => {
    return { value: hour, text: hour }
  })
})

const showRepeatedDishDietsModal = async (doiName: string, doiType: string) => {
  try {
    const response = await dietsService.fetchPatientDietRepeatedDishDiets(
      route.params.dietId as unknown as number,
      doiName
    )
    repeatedDishDietsModal.value = true
    repeatedDishDietsData.value = response.data
    repeatedDishDataName.value = doiName
    repeatedDishDataType.value = doiType
  } catch (err) {
    reportError(err, 'Error while fetching repeated dish diets', {
      dietId: route.params.dietId,
      doiName: doiName
    })
  }
}

const hideRepeatedDishDietsModal = () => {
  repeatedDishDietsModal.value = false
  repeatedDishDietsData.value = []
  repeatedDishDataName.value = ''
  repeatedDishDataType.value = ''
}
const setActiveMenu = (value?: string) => {
  activeAddDishOrIngredient.value = undefined
  if (activeMenu.value === value) {
    activeMenu.value = undefined
  } else {
    activeMenu.value = value
  }
}
const checkActiveMenu = (value: string) => {
  return activeMenu.value === value
}

const updateDragMode = (v: DragMode) => {
  dietStore.updateDragMode(v)
}

const copyDayHandler = () => {
  dietStore.copyDay(dayIndex.value)
}
const pasteDayHandler = () => {
  dietStore.pasteDay(dayIndex.value)
}
const deleteDayHandler = () => {
  dietStore.deleteDay(dayIndex.value)
}
const updateDayHourHandler = (dayHour: string, mealIndex: number) => {
  dietStore.updateDayHour(mealIndex, dayIndex.value, dayHour)
}
const setActiveTooltip = (value?: string) => {
  activeTooltip.value = value
}
const checkActiveTooltip = (value: string) => {
  return activeTooltip.value === value
}
const setActiveAddDishOrIngredient = (value?: string) => {
  activeMenu.value = undefined
  activeAddDishOrIngredient.value = value
}
const checkActiveAddDishOrIngredient = (value: string) => {
  return activeAddDishOrIngredient.value === value
}
const deleteDishOrIngredientHandler = (_mealIndex: number, _dishOrIngredientIndex: number) => {
  dietStore.deleteDishOrIngredient(_mealIndex, dayIndex.value, _dishOrIngredientIndex)
}
const setActiveDayDeleteModal = (value?: number) => {
  activeDayDeleteModal.value = value
}
const addDishOrIngredient = async (id: number, type: 'dish' | 'ingredient', mealIndex: number) => {
  try {
    await dietStore.fetchAndAddDishOrIngredient({
      id,
      type,
      mealIndex,
      dayIndex: dayIndex.value
    })

    setActiveAddDishOrIngredient()
  } catch (err) {
    reportError(err, 'Error while fetching and adding dish or ingredient', {
      id,
      type,
      mealIndex,
      dayIndex: dayIndex.value
    })
  }
}
const addUnusedDishPortion = (
  eventMealIndex: number,
  eventDayIndex: number,
  dishOrIngredientIndex: number,
  targetMealIndex: number
) => {
  dietStore.copyDishOrIngredient(
    targetMealIndex,
    dayIndex.value,
    eventMealIndex,
    eventDayIndex,
    dishOrIngredientIndex,
    true
  )
}
const dragStartDishOrIngredientHandler = (
  event: DragEvent,
  mealIndex: number,
  dishOrIngredientIndex: number
) => {
  if (canDietBeEdited.value && event.dataTransfer) {
    setActiveTooltip()
    event.dataTransfer.effectAllowed = 'copyMove'
    event.dataTransfer.dropEffect = dragMode.value as DataTransfer['dropEffect']
    event.dataTransfer.setData('mealIndex', mealIndex.toString())
    event.dataTransfer.setData('dishOrIngredientIndex', dishOrIngredientIndex.toString())
  }
}
const copyDishOrIngredientHandler = (mealIndex: number, dishOrIngredientIndex: number) => {
  dietStore.copyDishOrIngredient(
    mealIndex,
    dayIndex.value,
    mealIndex,
    dayIndex.value,
    dishOrIngredientIndex
  )
  updateDragMode('move')
}

const handleDragAddDrop = async (dataTransfer: DataTransfer, _mealIndex: number) => {
  const id = parseInt(dataTransfer.getData('dishOrIngredientId'))
  if (Number.isNaN(id)) {
    throw new Error('Cannot get id from dataTransfer')
  }
  const type = dataTransfer.getData('type') as 'dish' | 'ingredient'
  if (!['dish', 'ingredient'].includes(type)) {
    throw new Error('Cannot get  proper type from dataTransfer')
  }
  const dishOrIngredientName = dataTransfer.getData('dishOrIngredientName')
  if (S.isEmpty(dishOrIngredientName)) {
    throw new Error('Cannot get proper dishOrIngredientName from dataTransfer')
  }
  await dietStore.fetchAndAddDishOrIngredient({
    id,
    type,
    mealIndex: _mealIndex,
    dayIndex: dayIndex.value
  })
  if (type === 'ingredient') {
    await dietStore.sendProductToFile({
      email: user.value?.email ?? '',
      productName: dishOrIngredientName,
      productId: id
    })
  }
}

const handleDragCopyDrop = (dataTransfer: DataTransfer, _mealIndex: number) => {
  const mealIndex = parseInt(dataTransfer.getData('mealIndex'))
  const dishOrIngredientIndex = parseInt(dataTransfer.getData('dishOrIngredientIndex'))

  if (Number.isNaN(mealIndex) || Number.isNaN(dishOrIngredientIndex)) {
    throw new Error('Cannot get mealIndex or dishOrIngredientIndex from dataTransfer')
  }
  dietStore.copyDishOrIngredient(
    _mealIndex,
    dayIndex.value,
    mealIndex,
    dayIndex.value,
    dishOrIngredientIndex
  )
}

const handleDragMoveDrop = (dataTransfer: DataTransfer, _mealIndex: number) => {
  const mealIndex = parseInt(dataTransfer.getData('mealIndex'))
  const dishOrIngredientIndex = parseInt(dataTransfer.getData('dishOrIngredientIndex'))

  if (Number.isNaN(mealIndex) || Number.isNaN(dishOrIngredientIndex)) {
    throw new Error('Cannot get mealIndex or dishOrIngredientIndex from dataTransfer')
  }

  dietStore.moveDishOrIngredient(
    _mealIndex,
    dayIndex.value,
    mealIndex,
    dayIndex.value,
    dishOrIngredientIndex
  )
}

const dropDishOrIngredientHandler = async (event: DragEvent, _mealIndex: number) => {
  try {
    if (canDietBeEdited.value && event.dataTransfer) {
      event.preventDefault()
      if (dragMode.value === 'add') {
        await handleDragAddDrop(event.dataTransfer, _mealIndex)
      } else if (dragMode.value === 'copy') {
        handleDragCopyDrop(event.dataTransfer, _mealIndex)
      } else {
        handleDragMoveDrop(event.dataTransfer, _mealIndex)
      }
      updateDragMode('move')
    }
  } catch (err) {
    reportError(err, 'Error while dropping dish or ingredient', {
      mealIndex: _mealIndex,
      dayIndex: dayIndex.value,
      id: event.dataTransfer?.getData('dishOrIngredientId')
    })
  }
}
const activeDishOrIngredient = (mealIndex: number, dishOrIngredientIndex: number) => {
  if (
    details.value &&
    details.value.mealIndex === mealIndex &&
    details.value.dayIndex === dayIndex.value &&
    details.value.dishOrIngredientIndex === dishOrIngredientIndex
  ) {
    return true
  }
  return false
}
const clickDishOrIngredient = (mealIndex: number, dishOrIngredientIndex: number) => {
  if (activeDishOrIngredient(mealIndex, dishOrIngredientIndex)) {
    dietStore.updateDetails()
  } else {
    dietStore.updateDetails({
      mealIndex: mealIndex,
      dayIndex: dayIndex.value,
      dishOrIngredientIndex: dishOrIngredientIndex
    })
  }
  eventBus.emit('resizeBaseTextArea')
}

const hideMenu = () => {
  activeMenu.value = undefined
  activeAddDishOrIngredient.value = undefined
}

useEventListener('click', hideMenu)
</script>

<style scoped>
.header {
  height: 42px;
  line-height: 42px;
  background: var(--color-main-100);
  text-align: center;
  position: relative;
}

.header-link {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 32px;
  height: 32px;
  top: 5px;
  border-radius: 16px;
  transition: all 0.2s ease;
}

.header-link.left {
  left: calc(50% - 90px - 12px);
}

.header-link.right {
  left: calc(50% + 90px - 20px);
}

.header-link:hover {
  background: var(--color-main-30);
}

.header-link :deep(.base-icon) {
  color: #fff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.header-link:hover :deep(.base-icon) {
  color: var(--color-main-100);
}

.header-name {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.41px;
}

/* Day menu */
.day-menu {
  position: absolute;
  text-decoration: none;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  right: 7px;
  border-radius: 16px;
  transition: all 0.2s ease;
  border: 0;
  padding: 0;
  background: transparent;
}

.day-menu__items {
  position: absolute;
  display: flex;
  z-index: 20;
  background: #fff;
  flex-direction: column;
  right: 0;
  top: 35px;
  box-shadow: 0 1px 6px 0 rgb(155 155 155 / 50%);
}

.day-menu__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  white-space: nowrap;
  height: 20px;
  font-size: 12px;
  padding: 3px 6px;
  cursor: pointer;
}

.day-menu__item:hover {
  background: #f8f8f8;
}

.day-menu__item.disabled {
  color: var(--color-disabled-text);
  pointer-events: none;
}

.day-menu__item.disabled .base-icon {
  color: var(--color-disabled-text);
}

.day-menu__item span {
  margin-left: 6px;
}

.meal-header {
  margin: 0 5px;
  background: #f5f5f5;
  height: 32px;
  text-align: center;
  position: relative;
}

.meal-name {
  font-family: Montserrat-SemiBold;
  color: var(--color-primary-text);
  font-size: 14px;
  letter-spacing: 1.41px;
  line-height: 32px;
}

/* Meal Hour */
.meal-hour {
  position: absolute;
  left: 0;
}

.meal.is-disabled .meal-hour :deep(.base-select__selected) {
  pointer-events: none;
}

.meal.is-disabled:hover .meal-hour :deep(.base-select__selected) {
  border-bottom: none;
}

.meal-hour :deep(.base-select__selected) {
  padding: 9px 21px 8px 8px;
  line-height: 14px;
  border-bottom: 1px solid transparent;
}

.meal.is-disabled .base-select {
  cursor: text;
}

.meal-hour :deep(.base-select__selected::after),
.meal.is-disabled:hover .meal-hour :deep(.base-select__selected::after),
.meal.is-disabled .add-dish-or-ingredient button {
  display: none;
}

.meal:hover .meal-hour :deep(.base-select__selected) {
  border-bottom: 1px solid var(--color-tertiary-text);
}

.meal:hover .meal-hour :deep(.base-select__selected:hover) {
  border-bottom: 1px solid var(--color-primary-text);
}

.meal:hover .meal-hour :deep(.base-select__selected::after),
.meal-hour :deep(.base-select__selected--active::after) {
  display: block;
}

.meal-hour :deep(.base-select__selected--active),
.meal:hover .meal-hour :deep(.base-select__selected--active) {
  border-bottom: 1px solid var(--color-main-100);
}

/* Energy distribution */
.energy-distribution {
  position: absolute;
  right: 12px;
  top: 12px;
  height: 8px;
  width: 8px;
}

.energy-distribution__dot {
  height: 8px;
  width: 8px;
  border-radius: 4px;
}

.energy-distribution__dot.green {
  background: var(--color-success-100);
}

.energy-distribution__dot.yellow {
  background: var(--color-warning-100);
}

.energy-distribution__dot.red {
  background: var(--color-destructive-100);
}

.energy-distribution__title {
  margin-bottom: 4px;
}

.energy-distribution__data {
  font-family: Montserrat-Medium;
}

.energy-distribution__data-target {
  color: var(--color-tertiary-text);
}

/* Meal */
.meal-data {
  display: flex;
  flex-direction: row;
  padding: 9px 13px;
  min-height: 80px;
}

.meal-data.empty {
  min-height: 52px;
  align-items: center;
}

.meal-dishes-and-ingredients {
  flex-grow: 1;
  margin-top: 25px;
}

.meal-data.empty .meal-dishes-and-ingredients {
  margin: 0;
}

/* Dish or ingredient */
.dish-or-ingredient {
  display: flex;
  position: relative;
  flex-direction: row;
  background: var(--color-main-10);
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 8px 15px 8px 5px;
  min-height: 16px;
}

.dish-or-ingredient.active {
  border-left: 4px solid #ec5787;
}

.dish-or-ingredient.repeated {
  background: var(--color-warning-30);
}

.dish-or-ingredient.repeated .dish-or-ingredient__copy-button:hover,
.dish-or-ingredient.repeated .dish-or-ingredient__assignment-button:hover {
  background: var(--color-warning-60);
}

.dish-or-ingredient.repeated .dish-or-ingredient__copy-button .base-icon,
.dish-or-ingredient.repeated .dish-or-ingredient__assignment-button .base-icon {
  color: var(--color-warning-100);
}

.dish-or-ingredient.repeated .dish-or-ingredient__delete-button .base-icon {
  color: var(--color-warning-100);
}

.dish-or-ingredient.repeated .dish-or-ingredient__delete-button:hover .base-icon {
  color: #f55f6d;
}

.dish-or-ingredient.repeated.active {
  border-left: 4px solid var(--color-warning-100);
}

.dish-or-ingredient__name {
  flex-grow: 1;
  font-size: 14px;
  line-height: 16px;
  word-break: break-word;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.dish-or-ingredient__name span {
  font-size: 14px;
  color: var(--color-main-100);
  font-family: Montserrat-Bold;
}

.dish-or-ingredient.repeated .dish-or-ingredient__name span {
  color: var(--color-warning-100);
}

.dish-or-ingredient__menu {
  position: absolute;
  right: 2px;
  top: 0;
  bottom: 0;
  display: none;
  flex-direction: row;
  padding-left: 40px;
  background: linear-gradient(to right, rgb(255 255 255 / 0%), var(--color-main-10) 35%);
}

.dish-or-ingredient.repeated .dish-or-ingredient__menu {
  background: linear-gradient(to right, rgb(255 255 255 / 0%), var(--color-warning-30) 35%);
}

.dish-or-ingredient__menu > div {
  position: relative;
  top: calc(50% - 14px);
}

.dish-or-ingredient:hover .dish-or-ingredient__menu {
  display: flex;
}

/* Add dish or ingradient */
.add-dish-or-ingredient {
  height: 30px;
}

.add-dish-or-ingredient__button {
  display: none;
}

.meal:hover .add-dish-or-ingredient__button {
  display: block;
}

.doi-name {
  font-size: 16px;
  color: var(--color-primary-text);
  margin-bottom: 13px;
}

.doi-description {
  font-size: 12px;
  color: var(--color-primary-text);
}

.repeated-dish-diets-row a {
  display: flex;
  color: var(--color-main-100);
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}

.repeated-dish-diets-row a:hover {
  text-decoration: underline;
  color: var(--color-main-110);
}
</style>
