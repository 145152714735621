<template>
  <div class="assumptions">
    <div class="section inevitable-losses">
      <div class="section__header">
        Straty talerzowe
      </div>
      <div class="inevitable-losses__box">
        <BaseTooltip class="base-tooltip--top base-tooltip--left">
          <div
            class="inevitable-losses__box__clickable"
            @click="handleUpdateInevitableLosesActive()"
          >
            <div class="label">
              Uwzględnij straty talerzowe
            </div>
            <BaseSwitch
              class="inevitable-losses__switch"
              :value="inevitableLossesActive"
            />
          </div>
          <template #tooltip>
            Są to resztki talerzowe i kotłowe oraz odpadki kuchenne.
          </template>
        </BaseTooltip>
        <template v-if="inevitableLossesActive">
          <div class="label">
            Wartość:
          </div>
          <BaseInputNumber
            test-id="DietDetailAssumptions__InevitableLosses"
            class="inevitable-losses__input"
            :min="0.1"
            :max="99"
            :precision="1"
            :step="0.1"
            :model-value="inevitableLossesValue"
            @update:model-value="updateInevitableLossesValue($event)"
          />
          <div class="inevitable-losses__unit">
            %
          </div>
        </template>
      </div>
      <div class="info">
        <BaseIcon name="info" /> Włączenie funkcji spowoduje redukcje wartości energetycznej i
        składników odżywczych w całym jadłospisie.
      </div>
    </div>
    <div class="section energy-demand">
      <div class="section__header">
        Zapotrzebowanie na energię
      </div>
      <div class="energy-demand-type">
        <div class="label">
          Sposób przeliczania KBTW:
        </div>
        <BaseRadio
          v-model="energyDemandStandard"
          class="energy-demand-type__radio"
          :label="'Standardowy'"
        />
        <BaseRadio
          v-model="energyDemandSport"
          class="energy-demand-type__radio"
          :label="'Dla sportowców'"
        />
      </div>
      <div
        v-if="energyDemandStandard"
        class="energy-demand__standard"
      >
        <div class="energy-demand__calories">
          <div class="energy-demand__header">
            Wartość energetyczna
          </div>
          <div class="energy-demand__info">
            <div class="energy-demand__info-left">
              (kcal)
            </div>
          </div>
          <div class="energy-demand__calories-box">
            <BaseInputNumber
              test-id="DietDetailAssumptions__Calories"
              class="energy-demand__input"
              :min="1"
              :max="9999"
              :model-value="calories"
              @update:model-value="handleUpdateNorm('calories', $event)"
            />
          </div>
        </div>
        <div class="energy-demand__protein">
          <div class="energy-demand__header">
            Białko ogółem
          </div>
          <div class="energy-demand__info">
            <div class="energy-demand__info-left">
              {{ proteinGrams }} g
            </div>
          </div>
          <div class="energy-demand__box">
            <div class="energy-demand__box-left">
              <BaseSlider
                test-id="DietDetailAssumptions__Protein"
                :min="0"
                :max="100"
                :color="'pink'"
                :model-value="standardProtein"
                @update:model-value="handleUpdateCalculation('protein', $event, 'standard')"
              />
            </div>
            <div class="energy-demand__box-right">
              <BaseInputNumber
                test-id="DietDetailAssumptions__Protein"
                class="energy-demand__input"
                :model-value="standardProtein"
                @update:model-value="handleUpdateCalculation('protein', $event, 'standard')"
              />
              %
            </div>
          </div>
        </div>
        <div class="energy-demand__fat">
          <div class="energy-demand__header">
            Tłuszcze
          </div>
          <div class="energy-demand__info">
            <div class="energy-demand__info-left">
              {{ fatGrams }} g
            </div>
          </div>
          <div class="energy-demand__box">
            <div class="energy-demand__box-left">
              <BaseSlider
                test-id="DietDetailAssumptions__Fat"
                :min="0"
                :max="100"
                :color="'yellow'"
                :model-value="standardFat"
                @update:model-value="handleUpdateCalculation('fat', $event, 'standard')"
              />
            </div>
            <div class="energy-demand__box-right">
              <BaseInputNumber
                test-id="DietDetailAssumptions__Fat"
                class="energy-demand__input"
                :model-value="standardFat"
                @update:model-value="handleUpdateCalculation('fat', $event, 'standard')"
              />
              %
            </div>
          </div>
        </div>
        <div class="energy-demand__carbohydrates">
          <BaseTooltip class="base-tooltip--top base-tooltip--center">
            <div class="energy-demand__header">
              Węglowodany przyswajalne
            </div>
            <template #tooltip>
              + wartość energetyczna pochodząca z błonnika
            </template>
          </BaseTooltip>
          <div class="energy-demand__info">
            <div class="energy-demand__info-left">
              {{ carbohydratesGrams }} g
            </div>
          </div>
          <div class="energy-demand__box">
            <div class="energy-demand__box-left">
              <BaseSlider
                test-id="DietDetailAssumptions__Carbohydrates"
                :min="0"
                :max="100"
                :color="'purple'"
                :model-value="standardCarbohydrates"
                @update:model-value="handleUpdateCalculation('carbohydrates', $event, 'standard')"
              />
            </div>
            <div class="energy-demand__box-right">
              <BaseInputNumber
                test-id="DietDetailAssumptions__Carbohydrates"
                class="energy-demand__input"
                :model-value="standardCarbohydrates"
                @update:model-value="handleUpdateCalculation('carbohydrates', $event, 'standard')"
              />
              %
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="energyDemandSport"
        class="energy-demand__sport"
      >
        <div class="energy-demand__weight">
          <div class="energy-demand__header">
            Masa ciała pacjenta
          </div>
          <div class="energy-demand__info">
            <div class="energy-demand__info-left">
              (kg)
            </div>
          </div>
          <div class="energy-demand__weight-box">
            <BaseInputNumber
              test-id="DietDetailAssumptions__Sport__Weight"
              class="energy-demand__input"
              :precision="1"
              :step="0.1"
              :min="0.1"
              :max="250"
              :model-value="weight"
              @update:model-value="updatePatientWeight($event)"
            />
          </div>
        </div>
        <div class="energy-demand__protein">
          <div class="energy-demand__header">
            Białko ogółem
          </div>
          <div class="energy-demand__info">
            <div class="energy-demand__info-left">
              {{ standardProtein }} %
            </div>
            <div class="energy-demand__info-right">
              {{ proteinGrams }} g
            </div>
          </div>
          <div class="energy-demand__box">
            <div class="energy-demand__box-left">
              <BaseSlider
                v-model="standardProtein"
                test-id="DietDetailAssumptionsSport__Protein"
                :min="0"
                :max="100"
                :color="'pink'"
                :readonly="true"
              />
            </div>
            <div class="energy-demand__box-right">
              <BaseInputNumber
                test-id="DietDetailAssumptions__Sport__Protein"
                class="energy-demand__input"
                :precision="1"
                :step="0.1"
                :model-value="sportProtein"
                @update:model-value="handleUpdateCalculation('protein', $event, 'sport')"
              />
              g/kg mc
            </div>
          </div>
        </div>
        <div class="energy-demand__fat">
          <div class="energy-demand__header">
            Tłuszcze
          </div>
          <div class="energy-demand__info">
            <div class="energy-demand__info-left">
              {{ standardFat }} %
            </div>
            <div class="energy-demand__info-right">
              {{ fatGrams }} g
            </div>
          </div>
          <div class="energy-demand__box">
            <div class="energy-demand__box-left">
              <BaseSlider
                v-model="standardFat"
                test-id="DietDetailAssumptions__Sport__Fat"
                :min="0"
                :max="100"
                :color="'yellow'"
                :readonly="true"
              />
            </div>
            <div class="energy-demand__box-right">
              <BaseInputNumber
                test-id="DietDetailAssumptions__Sport__Fat"
                class="energy-demand__input"
                :precision="1"
                :step="0.1"
                :model-value="sportFat"
                @update:model-value="handleUpdateCalculation('fat', $event, 'sport')"
              />
              g/kg mc
            </div>
          </div>
        </div>
        <div class="energy-demand__carbohydrates">
          <div class="energy-demand__header">
            Weglowodany przyswajalne
          </div>
          <div class="energy-demand__info">
            <div class="energy-demand__info-left">
              {{ standardCarbohydrates }} %
            </div>
            <div class="energy-demand__info-right">
              {{ carbohydratesGrams }} g
            </div>
          </div>
          <div class="energy-demand__box">
            <div class="energy-demand__box-left">
              <BaseSlider
                v-model="standardCarbohydrates"
                test-id="DietDetailAssumptions__Sport__Carbohydrates"
                :min="0"
                :max="100"
                :color="'purple'"
                :readonly="true"
              />
            </div>
            <div class="energy-demand__box-right">
              <BaseInputNumber
                test-id="DietDetailAssumptions__Sport__Carbohydrates"
                class="energy-demand__input"
                :precision="1"
                :step="0.1"
                :model-value="sportCarbohydrates"
                @update:model-value="handleUpdateCalculation('carbohydrates', $event, 'sport')"
              />
              g/kg mc
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="energyDemandStandard && normsActive"
        class="info"
      >
        <BaseTooltip class="energy-demand__info-tooltip base-tooltip--top base-tooltip--center">
          <BaseIcon name="info" /> Przybliżona wartość energetyczna na podstawie poniższych norm to
          <span class="info--bold">{{ caloriesFromNorms }} kcal</span>
          <template #tooltip>
            Przybliżona wartość energetyczna została obliczona według wzoru na całkowitą przemianę
            materii oraz danych wpisanych w sekcji "Normy"
          </template>
        </BaseTooltip>
      </div>
      <div
        v-if="energyDemandSport"
        class="info"
      >
        <BaseIcon name="info" /> Przybliżona wartość energetyczna to {{ calories }} kcal
      </div>
    </div>
    <div class="section norms">
      <div class="section__header">
        Normy
      </div>
      <div
        class="norms__box"
        @click="handleUpdateNormActive()"
      >
        <div class="label">
          Pokazuj normy
        </div>
        <BaseSwitch
          class="norms__switch"
          :value="normsActive"
        />
      </div>
      <div class="info">
        <BaseIcon name="info" /> Włączając tę funkcję zyskasz możliwość śledzenia realizacji
        najnowszych Norm NCEŻ 2020 oraz zmodyfikujesz podaż poszczególnych składników dla danego
        przypadku.
      </div>
      <div class="norms__group">
        <div class="norms__header">
          Parametry pacjenta
        </div>
        <div class="norms__elements norms__patient">
          <div class="norms__element norms__patient-age">
            <div class="norms__element-name">
              Data urodzenia
            </div>
            <div class="norms__element-data">
              <BaseInputDate
                v-model="patientBirthdate"
                test-id="DietDetailAssumptions"
                :max-date="getBirthdayMaxDate()"
                :min-date="getBirthdayMinDate()"
              />
            </div>
          </div>
          <div class="norms__element norms__patient-gender">
            <div class="norms__element-name">
              Płeć
            </div>
            <div class="norms__element-data">
              <BaseRadio
                v-model="patientGenderMale"
                class="norms__patient-gender__radio"
                :label="'Mężczyzna'"
              />
              <BaseRadio
                v-model="patientGenderFemale"
                class="norms__patient-gender__radio"
                :label="'Kobieta'"
              />
            </div>
          </div>
          <div class="norms__element norms__patient-pregnancy-lactation">
            <div class="norms__element-name">
              Stan fizjologiczny
            </div>
            <div class="norms__element-data">
              <div class="norms__patient-pregnancy">
                <BaseCheckbox
                  v-model="patientPregnancy"
                  class="norms__patient-pregnancy-active"
                  :label="`Ciąża`"
                  :disabled="!patientGenderFemale"
                />
                <BaseRadio
                  v-model="patientPregnancyTrimesterOne"
                  class="norms__patient-pregnancy-trimester"
                  :label="'1 trymestr'"
                  :disabled="!patientPregnancy || !patientGenderFemale"
                />
                <BaseRadio
                  v-model="patientPregnancyTrimesterTwo"
                  class="norms__patient-pregnancy-trimester"
                  :label="'2 trymestr'"
                  :disabled="!patientPregnancy || !patientGenderFemale"
                />
                <BaseRadio
                  v-model="patientPregnancyTrimesterThree"
                  class="norms__patient-pregnancy-trimester"
                  :label="'3 trymestr'"
                  :disabled="!patientPregnancy || !patientGenderFemale"
                />
              </div>
              <div class="norms__patient-lactation">
                <BaseCheckbox
                  v-model="patientLactation"
                  class="norms__patient-lactation-active"
                  :label="`Laktacja`"
                  :disabled="!patientGenderFemale"
                />
                <BaseRadio
                  v-model="patientLactationPeriodZeroSix"
                  class="norms__patient-lactation-period"
                  :label="'0-6 miesięcy'"
                  :disabled="!patientLactation || !patientGenderFemale"
                />
                <BaseRadio
                  v-model="patientLactationPeriodAboveSix"
                  class="norms__patient-lactation-period"
                  :label="'powyżej 6 miesięcy'"
                  :disabled="!patientLactation || !patientGenderFemale"
                />
              </div>
            </div>
          </div>
          <div class="norms__element norms__patient-height">
            <div class="norms__element-name">
              Wzrost
            </div>
            <div class="norms__element-data">
              <BaseInputNumber
                v-model="patientHeight"
                test-id="DietDetailAssumptions__Height"
                class="filter-number"
                :min="1"
                :max="250"
                :precision="1"
                :step="0.1"
              />
              <span>cm</span>
            </div>
          </div>
          <div class="norms__element norms__patient-weight">
            <div class="norms__element-name">
              Masa ciała
            </div>
            <div class="norms__element-data">
              <BaseInputNumber
                v-model="patientWeight"
                test-id="DietDetailAssumptions__Weight"
                class="filter-number"
                :min="0.1"
                :max="250"
                :precision="1"
                :step="0.1"
              />

              <span>kg</span>
            </div>
          </div>
          <div class="norms__element norms__patient-activity-level">
            <div class="norms__element-name">
              Aktywność fizyczna
            </div>
            <div class="norms__element-data">
              <BaseSelect
                v-model="patientActivityLevel"
                :options="activityLevelOptions"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="group in normsAssumptions"
        :key="group.name"
        class="norms__group"
      >
        <div class="norms__header">
          {{ group.name }}
        </div>
        <div class="norms__elements">
          <div
            v-for="(nutrientData, nutrientKey) in group.nutrients"
            :key="nutrientKey"
            class="norms__element"
          >
            <div class="norms__element-name">
              <template v-if="nutrientData?.tooltip">
                <BaseTooltip
                  class="base-tooltip--top base-tooltip--center norms__element-name-tooltip"
                >
                  {{ nutrientData.name }}
                  <template #tooltip>
                    {{ nutrientData.tooltip }}
                  </template>
                </BaseTooltip>
              </template>
              <template v-else>
                {{ nutrientData?.name || '' }}
              </template>
            </div>
            <div class="norms__element-data">
              <div class="norms__element-calculated">
                <div class="norms__element-measurement">
                  <template v-if="nutrientsMap[nutrientKey].measurement">
                    ({{ nutrientsMap[nutrientKey].measurement }})
                  </template>
                </div>
                <div class="norms__element-calculated-value">
                  {{ calculatedNorm(nutrientKey) }}
                </div>
              </div>
              <div class="norms__element-inputed">
                <BaseInputNumber
                  v-if="hasPerm('accounts.diet_norms_edit_values')"
                  :test-id="`DietDetailAssumptions__Norms__${group.name}`"
                  class="norms__element-input"
                  :disabled="nutrientKey === 'glycemic_index' || nutrientKey === 'glycemic_load'"
                  :min="0"
                  :max="nutrientData?.maxValue"
                  :precision="getPrecisionForNutrients(group.name)"
                  :step="getStepForNutrients(group.name)"
                  :model-value="inputedNorm(nutrientKey)"
                  @update:model-value="handleUpdateNorm(nutrientKey, $event)"
                />
                <div
                  v-else
                  class="norms__element-dummy"
                  @click="showAppUpgradePlanModal"
                >
                  {{ calculatedNorm(nutrientKey) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { DietCalculationData, DietCalculationType, DietData } from '@/types/Diet'
import type { Nutrients } from '@/utils/nutrients'

import { G } from '@mobily/ts-belt'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseInputDate from '@/components/BaseInputDate.vue'
import BaseInputNumber from '@/components/BaseInputNumber.vue'
import BaseRadio from '@/components/BaseRadio.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import BaseSlider from '@/components/BaseSlider.vue'
import BaseSwitch from '@/components/BaseSwitch.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import { activityLevelOptions } from '@/utils/common'
import { normsAssumptions } from '@/utils/norms'
import { nutrientsMap } from '@/utils/nutrients'

const dietStore = useDietsStore()
const {
  updateNorm,
  updateInevitableLossesActive,
  updateInevitableLossesValue,
  updateCalculation,
  updateNormsActive,
  updatePatientWeight
} = dietStore
const { diet, caloriesFromNorms } = storeToRefs(dietStore)
const { hasPerm, showAppUpgradePlanModal } = useGlobalStore()
const energyDemandStandard = computed({
  get() {
    return diet.value?.data.calculation.type === 'standard'
  },
  set(value) {
    if (value) {
      dietStore.updateCalculationType('standard')
    }
  }
})
const energyDemandSport = computed({
  get() {
    return diet.value?.data.calculation.type === 'sport'
  },
  set(value) {
    if (value) {
      dietStore.updateCalculationType('sport')
    }
  }
})

const patientBirthdate = computed({
  get() {
    return diet.value?.data.patient.birthdate ?? '01-01-1990'
  },
  set(value: Date) {
    dietStore.updatePatientBirthdate(value)
  }
})
const patientGenderMale = computed({
  get() {
    return diet.value?.data.patient.gender === 'male'
  },
  set() {
    dietStore.updatePatientGender('male')
  }
})
const patientGenderFemale = computed({
  get() {
    // This is not explict value female check, because if gender will be something else then
    // male or female then this will be true, so there will not be situation where both radio
    // buttons are not checked
    return diet.value?.data.patient.gender !== 'male'
  },
  set() {
    dietStore.updatePatientGender('female')
  }
})
const patientWeight = computed({
  get() {
    return diet.value?.data.patient.weight ?? 58
  },
  set(weight) {
    dietStore.updatePatientWeight(weight)
  }
})
const patientHeight = computed({
  get() {
    return diet.value?.data.patient.height ?? 164
  },
  set(height) {
    dietStore.updatePatientHeight(height)
  }
})
const patientPregnancy = computed({
  get() {
    return diet.value?.data.patient.pregnancy.active ?? false
  },
  set(active) {
    dietStore.updatePatientPregnancyActive(active)
  }
})
const patientPregnancyTrimesterOne = computed({
  get() {
    return diet.value?.data.patient.pregnancy.trimester === 'one'
  },
  set() {
    dietStore.updatePatientPregnancyTrimester('one')
  }
})
const patientPregnancyTrimesterTwo = computed({
  get() {
    return diet.value?.data.patient.pregnancy.trimester === 'two'
  },
  set() {
    dietStore.updatePatientPregnancyTrimester('two')
  }
})
const patientPregnancyTrimesterThree = computed({
  get() {
    return diet.value?.data.patient.pregnancy.trimester === 'three'
  },
  set() {
    dietStore.updatePatientPregnancyTrimester('three')
  }
})
const patientLactation = computed({
  get() {
    return diet.value?.data.patient.lactation.active ?? false
  },
  set(active) {
    dietStore.updatePatientLactationActive(active)
  }
})
const patientLactationPeriodZeroSix = computed({
  get() {
    return diet.value?.data.patient.lactation.period === 'zero_six_months'
  },
  set() {
    dietStore.updatePatientLactationPeriod('zero_six_months')
  }
})
const patientLactationPeriodAboveSix = computed({
  get() {
    return diet.value?.data.patient.lactation.period === 'above_six_months'
  },
  set() {
    dietStore.updatePatientLactationPeriod('above_six_months')
  }
})
const patientActivityLevel = computed({
  get() {
    return diet.value?.data.patient.activity_level ?? 1.4
  },
  set(activityLevel) {
    dietStore.updatePatientActivityLevel(activityLevel)
  }
})
const calories = computed(() => {
  return diet.value?.data.norms.calories ?? 0
})
const weight = computed(() => {
  return diet.value?.data.patient.weight ?? 0
})
const inevitableLossesActive = computed(() => {
  return Boolean(diet.value?.data.inevitable_losses.active)
})
const inevitableLossesValue = computed(() => {
  return diet.value?.data.inevitable_losses.value ?? 0
})
const normsActive = computed(() => {
  return Boolean(diet.value?.data.norms.active)
})
// Standard Data
const standardProtein = computed(() => {
  return diet.value?.data.calculation.standard.protein ?? 0
})
const standardFat = computed(() => {
  return diet.value?.data.calculation.standard.fat ?? 0
})
const standardCarbohydrates = computed(() => {
  return diet.value?.data.calculation.standard.carbohydrates ?? 0
})
// Sport Data
const sportProtein = computed(() => {
  return diet.value?.data.calculation.sport.protein ?? 0
})
const sportFat = computed(() => {
  return diet.value?.data.calculation.sport.fat ?? 0
})
const sportCarbohydrates = computed(() => {
  return diet.value?.data.calculation.sport.carbohydrates ?? 0
})
// Norms grams
const proteinGrams = computed(() => {
  return diet.value?.data.norms.protein.inputed ?? 0
})
const fatGrams = computed(() => {
  return diet.value?.data.norms.fat.inputed ?? 0
})
const carbohydratesGrams = computed(() => {
  return diet.value?.data.norms.carbohydrates.inputed ?? 0
})

const calculatedNorm = (nutrientKey: Nutrients) => {
  const n = diet.value?.data.norms[nutrientKey]
  if (G.isObject(n) && 'calculated' in n) {
    return n.calculated ?? 'b.d.'
  }
  return 'b.d.'
}
const getBirthdayMaxDate = () => {
  const today = new Date()
  today.setDate(today.getDate() - 1)
  return today
}
const getBirthdayMinDate = () => {
  const today = new Date()
  today.setMonth(0)
  today.setDate(1)
  today.setFullYear(today.getFullYear() - 115) // minDate is -115 years from now (starts 1st jan)
  return today
}
const getStepForNutrients = (groupName: string) => {
  if (groupName === 'Kwasy tłuszczowe') return 0.001
  return 0.1
}
const getPrecisionForNutrients = (groupName: string) => {
  if (groupName === 'Kwasy tłuszczowe') return 3
  return 1
}
const inputedNorm = (nutrientKey: Nutrients) => {
  const n = diet.value?.data.norms[nutrientKey]
  if (G.isObject(n) && 'calculated' in n) {
    return n.inputed || 0
  }
  return 0
}
const handleUpdateNormActive = () => {
  if (hasPerm('accounts.diet_norms')) {
    const active = !diet.value?.data.norms.active
    updateNormsActive(active)
  } else {
    showAppUpgradePlanModal()
  }
}
const handleUpdateNorm = (nutrientKey: Nutrients, value: number) => {
  updateNorm({
    nutrient: nutrientKey,
    value
  })
}

const handleUpdateInevitableLosesActive = () => {
  if (hasPerm('accounts.diet_inevitable_losses')) {
    const active = !diet.value?.data.inevitable_losses.active
    updateInevitableLossesActive(active)
  } else {
    showAppUpgradePlanModal()
  }
}

const handleUpdateCalculation = <
  T extends DietCalculationType,
  X extends keyof DietCalculationData
>(
  nutrient: X,
  value: DietData['calculation'][T][X],
  type: T
) => {
  updateCalculation({
    nutrient,
    value,
    type
  })
}
</script>

<style scoped>
.section {
  background: #fff;
  box-shadow: 2px 4px 4px 0 rgb(232 232 232 / 50%);
  margin-bottom: 24px;
}

.section__header {
  font-family: Montserrat-Medium;
  font-size: 24px;
  padding: 16px;
}

.info {
  line-height: 20px;
  font-size: 11px;
  padding: 16px;
  color: var(--color-secondary-text);
}

.info :deep(.base-icon) {
  color: var(--color-warning-100);
}

.info--bold {
  font-family: Montserrat-Medium;
  color: var(--color-primary-text);
}

.label {
  font-size: 12px;
  font-family: Montserrat-Medium;
}

.inevitable-losses__box {
  display: flex;
  flex-direction: row;
  height: 24px;
  margin: 0 16px;
  align-items: center;
}

.inevitable-losses__box .base-tooltip {
  margin-right: 24px;
}

.inevitable-losses__box__clickable {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  cursor: pointer;
}

.inevitable-losses__switch {
  margin-left: 24px;
}

.inevitable-losses__input {
  width: 48px;
  font-size: 14px;
  text-align: center;
  margin-left: 1px;
}

.inevitable-losses__unit {
  font-size: 14px;
}

.energy-demand .info {
  padding: 0 16px 16px;
}

.energy-demand-type {
  margin: 0 16px;
  display: flex;
  flex-direction: row;
}

.energy-demand-type__radio {
  margin-left: 20px;
}

.energy-demand__sport,
.energy-demand__standard {
  padding: 24px 6px 16px;
  display: grid;
  grid-template-columns: 265px 1fr 1fr 1fr;
  grid-gap: 6px;
}

.energy-demand__sport {
  margin-bottom: 0;
}

.energy-demand__header {
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 12px;
  font-family: Montserrat-Medium;
}

.energy-demand__calories .energy-demand__header,
.energy-demand__weight .energy-demand__header {
  background: #f5f5f5;
}

.energy-demand__protein .energy-demand__header {
  background: #f16595;
  color: #fff;
}

.energy-demand__fat .energy-demand__header {
  background: #f1c365;
  color: #fff;
}

.energy-demand__carbohydrates .energy-demand__header {
  background: #9f98ff;
  color: #fff;
}

.energy-demand__carbohydrates .base-tooltip {
  width: 100%;
}

.energy-demand__info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 8px;
  font-size: 9px;
  font-family: Montserrat-Medium;
  color: var(--color-tertiary-text);
}

.energy-demand__info-tooltip :deep(.base-tooltip__content) {
  width: 200px;
  text-align: center;
}

.energy-demand__calories-box,
.energy-demand__weight-box {
  display: flex;
  justify-content: center;
}

.energy-demand__box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 0 8px;
}

.energy-demand__box-left {
  width: calc(100% - 120px);
}

.energy-demand__input {
  text-align: center;
  width: 60px;
}

.norms__header {
  height: 32px;
  line-height: 32px;
  color: #fff;
  background: var(--color-main-100);
  font-family: Montserrat-Medium;
  font-size: 12px;
  padding: 0 12px;
  text-transform: uppercase;
}

.norms__box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 16px;
  width: max-content;
  cursor: pointer;
}

.norms__switch {
  margin-left: 12px;
}

.norms__elements {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 8px;
  margin: 8px 6px;
}

.norms__element {
  min-width: 250px;
}

.norms__element-name {
  background: #f5f5f5;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 12px;
}

.norms__element-name-tooltip {
  margin: auto;
}

.norms__element-name-tooltip :deep(.base-tooltip__content) {
  max-width: 200px;
}

.norms__element-data {
  display: flex;
  flex-direction: row;
  padding: 16px 8px 8px;
  justify-content: space-between;
  align-items: flex-end;
}

.norms__element-calculated {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.norms__element-input {
  width: 60px;
  text-align: center;
}

.norms__element-input:disabled {
  border-bottom: none;
  color: inherit;
}

.norms__element-dummy {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  padding: 0 8px;
  height: 25px;
  color: rgb(166 166 166);
  border-bottom: 1px solid var(--color-tertiary-text);
}

.norms__element-dummy:hover {
  border-color: var(--color-main-100);
}

.norms__element-measurement {
  color: var(--color-tertiary-text);
  font-size: 9px;
  height: 0;
  font-family: Montserrat-Medium;
  position: relative;
  top: -6px;
}

.norms__element-calculated-value {
  font-size: 12px;
  color: var(--color-primary-text);
  height: 25px;
  line-height: 25px;
}

.norms__patient {
  grid-template-columns: repeat(3, 1fr);
}

/* .norms__patient-age {
    grid-column-end: span 1;
  } */
.norms__patient-age .norms__element-data {
  justify-content: center;
}

/*  .norms__patient-gender {
    grid-column-end: span 1;
  } */
.norms__patient-gender .norms__element-data {
  justify-content: space-evenly;
  height: 26px;
  align-items: center;
}

.norms__patient-pregnancy-lactation {
  grid-column-end: span 1;
  grid-row-end: span 3;
}

.norms__patient-pregnancy-lactation .norms__element-data {
  justify-content: space-evenly;
  align-items: flex-start;
}

.norms__patient-pregnancy,
.norms__patient-lactation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.norms__patient-pregnancy-active,
.norms__patient-lactation-active {
  margin-bottom: 8px;
}

.norms__patient-pregnancy-trimester,
.norms__patient-lactation-period {
  margin-top: 16px;
}

.norms__patient-weight .norms__element-data,
.norms__patient-height .norms__element-data,
.norms__patient-activity-level .norms__element-data {
  justify-content: center;
}

.norms__patient-weight .base-input,
.norms__patient-height .base-input {
  width: 60px;
  text-align: center;
}

.norms__patient-weight span,
.norms__patient-height span {
  display: inline-block;
  font-size: 12px;
  margin-left: 6px;
}

.norms__patient-activity-level {
  grid-column-end: span 2;
}

@media only screen and (width >= 1400px) {
  .norms__patient {
    grid-template-columns: repeat(6, 1fr);
  }

  .norms__patient-age {
    grid-column-end: span 2;
  }

  .norms__patient-gender {
    grid-column-end: span 2;
  }

  .norms__patient-pregnancy-lactation {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }

  .norms__patient-activity-level {
    grid-column-end: span 2;
  }
}
</style>
