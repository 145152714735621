<template>
  <div class="week">
    <DietDetailWeekSchedule />
    <DietDetailWeekNutrients />
  </div>
</template>

<script lang="ts" setup>
import { G } from '@mobily/ts-belt'
import { tryOnScopeDispose } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'

import DietDetailWeekNutrients from '@/components/diets/DietDetailWeekNutrients.vue'
import DietDetailWeekSchedule from '@/components/diets/DietDetailWeekSchedule.vue'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'

const globalStore = useGlobalStore()
const dietStore = useDietsStore()
const { relatedDishes } = storeToRefs(dietStore)

const unusedDishesMessageId = ref<number>()

const unusedDishesInfo = computed(() => {
  if (relatedDishes.value) {
    return Object.values(relatedDishes.value)
      .filter((dish) => dish.unusedPortions !== 0)
      .map((dish) => `${dish.name} x ${dish.unusedPortions}`)
      .sort((a, b) => {
        // sort by name
        const aName = a
          .toLowerCase()
          .normalize('NFD')
          .replace(/\u0142/g, 'l')
        const bName = b
          .toLowerCase()
          .normalize('NFD')
          .replace(/\u0142/g, 'l')
        if (aName < bName) return -1
        if (aName > bName) return 1
        return 0
      })
      .join('<br/>')
  }
  return ''
})

const showUnusedDishesInfo = () => {
  const id = unusedDishesMessageId.value
  if (unusedDishesInfo.value !== '') {
    if (G.isNullable(id)) {
      unusedDishesMessageId.value = globalStore.createMessage({
        title: 'Niewykorzystane porcje posiłków',
        content: unusedDishesInfo.value,
        timeout: 0
      })
    } else {
      globalStore.updateMessage({
        id,
        content: unusedDishesInfo.value
      })
    }
  } else {
    if (G.isNotNullable(id)) {
      globalStore.deleteMessage(id)
    }
  }
}

watch(unusedDishesInfo, (newInfo, oldInfo) => {
  if (newInfo !== oldInfo) {
    showUnusedDishesInfo()
  }
})

onMounted(() => {
  dietStore.clearUndoRedo()
  showUnusedDishesInfo()
})

tryOnScopeDispose(
  globalStore.$onAction(({ name, args }) => {
    if (name === 'deleteMessage') {
      if (unusedDishesMessageId.value === args[0]) {
        unusedDishesMessageId.value = undefined
      }
    }
  })
)
</script>

<style scoped>
.week {
  background: #fff;
  margin-bottom: 24px;
}
</style>
