<template>
  <div
    v-if="!searchDishesAndIngredients"
    class="results-loader"
  >
    <BaseLoader class="base-loader--size-48" />
  </div>
  <div
    v-else
    class="search"
  >
    <div
      class="filters"
      :class="{ 'filters-not-extended': !filtersExtended }"
    >
      <div class="add-dish">
        <StyledButton
          left-icon="add"
          @click="openAddDishModal"
        >
          Dodaj potrawę
        </StyledButton>
        <BaseModal
          v-if="addDishModalOpened"
          :modal-class="'add-dish-modal'"
          :modal-id="'add-dish-modal'"
          @close="closeAddDishModal"
        >
          <DietDishForm
            :chosen-nutrients="nutrients"
            :mode="'add'"
            :dish="newDish"
            :extended="true"
            @update="updateDish"
          />

          <template #title>
            Dodaj nową potrawę
          </template>
          <template #actions>
            <StyledButton
              variant="outline"
              @click="closeAddDishModal"
            >
              Anuluj
            </StyledButton>
            <StyledButton
              :disabled="!newDish.name"
              @click="addDish"
            >
              Dodaj potrawę
            </StyledButton>
          </template>
        </BaseModal>
      </div>
      <div class="filters-name">
        <BaseInputSearch
          ref="searchInput"
          class="filter-name"
          test-id="DietDetailDaySearch"
          :placeholder="inputPlaceholder"
          :model-value="nameValue"
          :force-focus="true"
          @update:model-value="setName"
        />
      </div>
      <div class="filters-basic">
        <div
          v-if="extended"
          class="filter"
        >
          <label>Wartości odżywcze</label>
          <BaseSelect
            class="filter-select"
            :options="nutrientChoices"
            :model-value="nutrientValue"
            @update:model-value="setNutrient"
          />
        </div>
        <div
          v-if="extended"
          class="filter"
        >
          <label>Kcal od (min):</label>
          <BaseInputNumber
            class="filter-number"
            test-id="DietDetailDaySearch__KcalMin"
            :min="kcalMin"
            :max="kcalMax"
            :precision="1"
            :step="0.1"
            :model-value="kcalMinValue"
            @update:model-value="setKcalMin"
          />
        </div>
        <div
          v-if="extended"
          class="filter"
        >
          <label>Kcal do (max):</label>
          <BaseInputNumber
            test-id="DietDetailDaySearch__KcalMax"
            class="filter-number"
            :min="kcalMin"
            :max="kcalMax"
            :precision="1"
            :step="0.1"
            :model-value="kcalMaxValue"
            @update:model-value="setKcalMax"
          />
        </div>
        <div class="filter">
          <label>Szukaj w</label>
          <BaseSelect
            class="filter-select"
            :options="searchInChoices"
            :model-value="searchInValue"
            @update:model-value="setSearchIn"
          />
        </div>
        <div class="filter">
          <label>Sortuj po</label>
          <BaseSelect
            class="filter-select"
            :options="sortByChoices"
            :model-value="sortByValue"
            @update:model-value="setSortBy"
          />
        </div>
      </div>
      <div
        v-show="extended && filtersExtended"
        class="filters-detailed"
        :class="{ ingredients: searchInValue === 'ingredients' }"
      >
        <div class="row-f">
          <div class="filter filter-dish-type">
            <label>Rodzaj {{ searchInValue === 'ingredients' ? 'produktu' : 'potrawy' }}:</label>
            <div class="filter-box">
              <BaseCheckbox
                v-for="category in categoryChoices"
                :key="category.value"
                :label="category.text"
                :model-value="category.active"
                @update:model-value="setDishCategory(category.value)"
              />
            </div>
          </div>
          <div
            v-if="searchInValue !== 'ingredients'"
            class="filter filter-size"
          >
            <label>Rozmiar:</label>
            <div class="filter-box">
              <BaseCheckbox
                v-for="dishSize in dishSizesChoices"
                :key="dishSize.value"
                :label="dishSize.text"
                :model-value="dishSize.active"
                @update:model-value="setDishSize(dishSize.value)"
              />
            </div>
          </div>
          <div
            v-if="searchInValue === 'ingredients'"
            class="filter ingredients-type filter-base"
          >
            <label>Baza produktów:</label>
            <div class="filter-box">
              <BaseCheckbox
                v-for="ingredientBase in ingredientBasesChoices"
                :key="ingredientBase.value"
                :label="ingredientBase.text"
                :model-value="ingredientBase.active"
                @update:model-value="setIngredientBase(ingredientBase.value)"
              />
            </div>
          </div>
        </div>
        <div
          v-if="searchInValue === 'ingredients'"
          class="filter filter-auto-tags"
        >
          <label>Tagi systemowe:</label>
          <div class="filter-box">
            <BaseCheckbox
              v-for="ingredientTag in ingredientTagsChoices"
              :key="ingredientTag.value"
              :label="ingredientTag.text"
              :model-value="ingredientTag.active"
              @update:model-value="setIngredientTag(ingredientTag.value)"
            />
          </div>
        </div>
        <div
          v-if="searchInValue === 'ingredients'"
          class="filter filter-category"
        >
          <label>Kategoria:</label>
          <div class="filter-box">
            <BaseCheckbox
              v-for="ingredientCategory in ingredientCategoriesChoices"
              :key="ingredientCategory.value"
              :label="ingredientCategory.text"
              :model-value="ingredientCategory.active"
              @update:model-value="setIngredientCategory(ingredientCategory.value)"
            />
          </div>
        </div>
        <div
          v-if="searchInValue !== 'ingredients'"
          class="filter filter-meal-type"
        >
          <label>Typ posiłku:</label>
          <div class="filter-box">
            <BaseCheckbox
              v-for="mealType in mealTypesChoices"
              :key="mealType.value"
              :label="mealType.text"
              :model-value="mealType.active"
              @update:model-value="setMealType(mealType.value)"
            />
          </div>
        </div>
      </div>
      <div
        v-if="extended && searchType === 'dishes' && filtersExtended"
        class="dish-tags"
      >
        <span>Wybrane tagi:</span>
        <span>Filtruj listę potraw dzięki zaawansowanej funkcji tagów systemowych</span>
        <div
          v-if="dishTypesValues.length > 0 || ingredientTagsValues.length > 0"
          class="chosen-types"
        >
          <div
            v-for="(type, index) in getDishTypeNames"
            :key="index"
            class="chosen-type-box editable"
          >
            <span>{{ type.name }}</span>
            <BaseIcon
              size="18"
              name="close"
              class="remove-tag"
              @click="removeSingleTag(type.id)"
            />
          </div>
        </div>
        <StyledButton
          v-if="extended && searchType === 'dishes'"
          class="show-tags"
          variant="secondary"
          left-icon="add"
          left-icon-size="24"
          @click="showDishTagList"
        >
          Pokaż dostępne tagi
        </StyledButton>
      </div>
      <div
        class="actions"
        :class="{ 'not-extended': !extended }"
      >
        <div
          v-if="filtersActive && !extended"
          class="actions-info"
        >
          <BaseIcon
            size="16"
            type="info"
            name="info"
          />
          <div class="actions-info__text">
            Masz aktywne filtry.
          </div>
          <StyledButton
            variant="tertiary"
            size="inline"
            class="actions-info__button clear-filters"
            @click="clearFilters()"
          >
            Wyczyść filtry
          </StyledButton>
        </div>
        <StyledButton
          v-if="extended"
          class="button-filter-extended"
          variant="outline"
          :left-icon="filtersExtended ? 'vertical_align_top' : 'vertical_align_bottom'"
          @click="toggleFilters"
        >
          {{ filtersExtended ? 'Ukryj filtry' : 'Pokaż filtry' }}
        </StyledButton>
        <StyledButton
          v-if="filtersActive && extended"
          class="button-clear-filters"
          variant="outline"
          @click="clearFilters"
        >
          Wyczyść filtry
        </StyledButton>
        <StyledButton
          class="button-extended"
          variant="secondary"
          @click="toogleExtened"
        >
          {{
            extended ? 'Ukryj zaawansowane' : 'Zaawansowane'
          }}
        </StyledButton>
      </div>
    </div>
    <div
      v-if="searchDishesAndIngredientsLoading"
      class="results-overlay"
    >
      <BaseLoader class="base-loader--size-48" />
    </div>
    <div
      v-show="!searchDishesAndIngredientsLoading"
      ref="resultsRef"
      class="results"
      :class="searchType"
      @scroll="updateSearchPage"
    >
      <div
        v-if="limitedDishesAndIngredients.length === 0"
        class="results-empty"
      >
        Brak wyników wyszukiwania
      </div>
      <div
        v-for="(dishOrIngredient, dishOrIngredientIndex) in limitedDishesAndIngredients"
        :key="dishOrIngredientIndex"
        class="result"
        :class="{ active: activeResult === `result-${dishOrIngredientIndex}` }"
        @click="activateResult(`result-${dishOrIngredientIndex}`)"
      >
        <div
          class="result__row-container"
          :draggable="true ? canDietBeEdited : false"
          @dragstart="dragStartDishOrIngredientHandler($event, dishOrIngredient)"
        >
          <div class="result__row result__row-name">
            <div class="result__name">
              {{ dishOrIngredient.name }}
              <span
                v-if="'max_portions' in dishOrIngredient && dishOrIngredient.max_portions > 1"
                class="result__portions"
                :class="{
                  'no-margin': !dishOrIngredient.is_public || dishOrIngredient.is_favourite
                }"
              >
                {{ dishOrIngredient.max_portions }}P
              </span>
            </div>
            <div
              v-if="!dishOrIngredient.is_public"
              class="private"
            >
              <BaseIcon
                name="lock"
                size="16"
                type="disabled"
              />
            </div>
            <div
              v-if="dishOrIngredient.is_favourite"
              class="favorite"
              :class="{ 'small-margin': !dishOrIngredient.is_public }"
            >
              <BaseIcon
                name="favorite"
                size="16"
                type="disabled"
              />
            </div>

            <DishOrIngredientAllergens
              test-id="DietDetailDaySearchDishOrIngredient"
              :dish-or-ingredient="dishOrIngredient"
            />
          </div>
          <div
            v-if="searchType === 'ingredients'"
            class="result__row result__nutrients"
          >
            <div class="result__nutrient">
              {{ getNutrient(dishOrIngredient, 'calories', true) }} kcal
            </div>
            <div class="result__nutrient">
              {{ getIngredientQuantity(dishOrIngredient as DietSearchIngredient) }} g
            </div>
            <div
              v-for="nutrient in nutrients"
              :key="nutrient"
              class="result__nutrient"
            >
              {{ nutrientsMap[nutrient].shortcut }}:
              {{ getNutrient(dishOrIngredient, nutrient, true) }}
              {{ nutrientsMap[nutrient].measurement }}
            </div>
          </div>
          <div
            v-if="searchType === 'ingredients'"
            class="result__row result__nutrients"
          >
            <div class="result__nutrient">
              {{ getNutrient(dishOrIngredient, 'calories', false) }} kcal
            </div>
            <div class="result__nutrient">
              100 g
            </div>
            <div
              v-for="nutrient in nutrients"
              :key="nutrient"
              class="result__nutrient"
            >
              {{ nutrientsMap[nutrient].shortcut }}:
              {{ getNutrient(dishOrIngredient, nutrient, false) }}
              {{ nutrientsMap[nutrient].measurement }}
            </div>
          </div>
          <div
            v-if="searchType === 'dishes'"
            class="result__row result__nutrients"
          >
            <div class="result__nutrient">
              {{ getNutrient(dishOrIngredient, 'calories', true) }} kcal
            </div>
            <div
              v-for="nutrient in nutrients"
              :key="nutrient"
              class="result__nutrient"
            >
              {{ nutrientsMap[nutrient].shortcut }}:
              {{ getNutrient(dishOrIngredient, nutrient, true) }}
              {{ nutrientsMap[nutrient].measurement }}
            </div>
            <div class="result__nutrient">
              ({{ getDishQuantityPerPortion(dishOrIngredient as DietSearchDish) }} g)
            </div>
          </div>
        </div>
        <template v-if="activeResult === `result-${dishOrIngredientIndex}`">
          <div
            v-if="'max_portions' in dishOrIngredient && dishOrIngredient.max_portions > 1"
            class="result__row result__multiple-portions"
          >
            <div class="result__title">
              Produkty i wartości dla:
            </div>
            <div>{{ dishOrIngredient.max_portions }} porcji</div>
          </div>
          <div
            v-if="
              searchType === 'dishes' &&
                'max_portions' in dishOrIngredient &&
                dishOrIngredient.max_portions > 1
            "
            class="result__row result__nutrients"
          >
            <div class="result__nutrient">
              {{ getNutrient(dishOrIngredient, 'calories', false) }} kcal
            </div>
            <div
              v-for="nutrient in nutrients"
              :key="nutrient"
              class="result__nutrient"
            >
              {{ nutrientsMap[nutrient].shortcut }}:
              {{ getNutrient(dishOrIngredient, nutrient, false) }}
              {{ nutrientsMap[nutrient].measurement }}
            </div>
            <div class="result__nutrient">
              ({{ dishOrIngredient.quantity }} g)
            </div>
          </div>
          <div
            v-if="'ingredients' in dishOrIngredient"
            class="result__ingredients"
          >
            <div
              v-for="ingredient in dishOrIngredient.ingredients"
              :key="ingredient.name"
              class="result__row result__ingredient"
            >
              <div class="result__ingredient-row-name result__title">
                <div class="result__ingredient-name">
                  {{ ingredient.name }}
                </div>
                <DishOrIngredientAllergens
                  test-id="DietDetailDaySearchDishIngredient"
                  :dish-or-ingredient="ingredient"
                />
              </div>
              <div class="result__ingredient-quantity">
                {{ ingredient.quantity }} g
              </div>
              <div class="result__ingredient-measurement">
                {{ round(ingredient.quantity / ingredient.grams, 2) }} x
                {{ ingredient.measurement }}
              </div>
            </div>
          </div>
          <div class="result__row result__size">
            <div class="result__title">
              Rozmiar:
            </div>
            <div class="result__value">
              {{ getDishSizeDisplay(dishOrIngredient) }}
            </div>
          </div>
          <div class="result__row result__meal-type">
            <div class="result__title">
              Typ posiłku:
            </div>
            <div class="result__value">
              {{ getDishMealTypesDisplay(dishOrIngredient) }}
            </div>
          </div>
          <div
            v-if="getDishDishTypesDisplay(dishOrIngredient).length > 0"
            class="result__row result__dish-type"
          >
            <div class="result__title">
              Tagi:
            </div>
            <div class="result__value">
              {{ getDishDishTypesDisplay(dishOrIngredient) }}
            </div>
          </div>
        </template>
        <div
          v-if="canDietBeEdited"
          class="add-menu"
        >
          <IconButton
            name="add"
            @click.stop="setActiveMenu(`add-menu-${dishOrIngredientIndex}`)"
          />
          <div
            v-if="checkActiveMenu(`add-menu-${dishOrIngredientIndex}`)"
            class="add-menu__options"
          >
            <div
              v-for="addOption in addOptions"
              :key="addOption.mealIndex"
              class="add-menu__option"
              :class="{ disabled: disabledAddOption(dishOrIngredient, addOption.mealType) }"
              @click.prevent.stop="
                addDishOrIngredient($event, dishOrIngredient, addOption.mealIndex)
              "
            >
              {{ addOption.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <DishesTagList
      v-if="tagListShown"
      :mode="'search'"
      :chosen-tags="chosenTags"
      @set-dish-type="setDishTypes($event)"
      @search-all-tags="searchAllTags($event)"
      @hide-tag-list="hideDishTagList"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Choice } from '@/components/ingredients/IngredientsExtendedFilters.vue'
import type { Nutrients } from '@/utils/nutrients'
import type { SortBy } from '@/utils/worker'

import { A, D } from '@mobily/ts-belt'
import { useEventListener } from '@vueuse/core'
import { klona } from 'klona'
import round from 'lodash/round'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseInputNumber from '@/components/BaseInputNumber.vue'
import BaseInputSearch from '@/components/BaseInputSearch.vue'
import BaseLoader from '@/components/BaseLoader.vue'
import BaseModal from '@/components/BaseModal.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import DietDishForm from '@/components/diets/DietDishForm.vue'
import DishOrIngredientAllergens from '@/components/diets/DishOrIngredientAllergens/DishOrIngredientAllergens.vue'
import DishesTagList from '@/components/dishes/DishesTagList.vue'
import { IconButton } from '@/components/IconButton'
import { StyledButton } from '@/components/StyledButton'
import { useFuseSearch } from '@/hooks/useFuseSearch'
import { useDietDetailProvider } from '@/pages/diets/provider'
import { dietsService } from '@/services/dietsService'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import {
  type DietSearchDish,
  type DietSearchDishOrIngredient,
  type DietSearchIngredient,
  type DishTypes,
  type StoreDietDish,
  type StoreDietMeal
} from '@/types/Diet'
import {
  dishSizes,
  dishTypes,
  ingredientBases,
  ingredientCategories,
  ingredientTags,
  mealTypes
} from '@/utils/common'
import { nutrientsMap } from '@/utils/nutrients'
import { reportError } from '@/utils/reportError'
import calculateNutrient from '@/utils/worker'

type Option = {
  mealIndex: number
  mealType: StoreDietMeal['type']
  text: string
}

type SearchIn = 'ingredients_in_dishes' | 'dishes' | 'ingredients'

const dishSystemTypesList = {
  51: 'is_vegan',
  52: 'is_vegetarian',
  54: 'is_gluten_free',
  53: 'is_diary_free'
}
const searchInput = ref<HTMLInputElement>()
const resultsRef = ref<HTMLDivElement>()
const autoTags: DishTypes = [51, 52, 53, 54]
const nameValue = ref('')
const kcalMinValue = ref(0)
const kcalMaxValue = ref(9999)
const searchInValue = ref<SearchIn>('dishes')
const sortByValue = ref<SortBy>('name')
const categoryValues = ref<string[]>([])
const dishSizesValues = ref<number[]>([])
const dishTypesValues = ref<DishTypes>([])
const mealTypesValues = ref<number[]>([])
const ingredientTagsValues = ref<string[]>([])
const ingredientBasesValues = ref<number[]>([])
const ingredientCategoriesValues = ref<number[]>([])
const addDishModalOpened = ref(false)
const searchAllTagsStatus = ref(false)
const tagListShown = ref(false)
const activeMenu = ref()
const activeResult = ref()
const searchPage = ref(1)
const filtersExtended = ref(true)
const newDish = ref({
  name: '',
  size: 2,
  type: 'dish',
  dish_types: [],
  meal_types: [],
  ingredients: [],
  max_portions: 1,
  used_portions: 1,
  preparation_time: 5,
  preparation_steps: ''
} as unknown as StoreDietDish)

const globalStore = useGlobalStore()
const dietsStore = useDietsStore()
const { hasPerm, showAppUpgradePlanModal } = globalStore

const {
  fetchAndAddDishOrIngredient,
  updateDragMode,
  updateExtended,
  debouncedSaveDish,
  sendProductToFile
} = dietsStore
const { user, hasAddDishPermission } = storeToRefs(globalStore)
const { diet, extended, canDietBeEdited } = storeToRefs(dietsStore)
const route = useRoute()

const {
  nutrients,
  searchDishesAndIngredients: _searchDishesAndIngredients,
  nutrientValue,
  searchDishesAndIngredientsLoading
} = useDietDetailProvider()

const searchDishesAndIngredients = computed(() => klona(_searchDishesAndIngredients.value))

const inputPlaceholder = computed(() => {
  if (searchInValue.value === 'ingredients') {
    return 'Wyszukaj produkt po nazwie...'
  } else if (searchInValue.value === 'ingredients_in_dishes') {
    return 'Wyszukaj potrawę po nazwie produktu...'
  }
  return 'Wyszukaj potrawę po nazwie...'
})
const dayIndex = computed(() => {
  const index = parseInt(route.params.dayIndex as string)
  if (isNaN(index)) {
    reportError(new Error('Invalid day index'), '', { dayIndex: route.params.dayIndex })
    return 0
  }
  return index
})

const addOptions = computed<Option[]>(() => {
  if (diet.value) {
    return diet.value.data.meals.map((meal, mealIndex) => {
      const mealName = meal.name ? meal.name : '(Posiłek bez nazwy)'
      return {
        mealIndex,
        mealType: meal.type,
        text: mealName
      }
    })
  }
  return []
})
const searchType = computed<'dishes' | 'ingredients'>(() => {
  if (searchInValue.value === 'ingredients_in_dishes') {
    return 'dishes'
  }
  return searchInValue.value
})
const kcalMin = computed(() => {
  if (searchDishesAndIngredients.value) {
    const data = searchDishesAndIngredients.value
    if (searchInValue.value === 'ingredients') {
      return round(Math.min(...data.ingredients.map((o) => (o.calories * o.quantity) / 100)), 1)
    } else {
      return round(Math.min(...data.dishes.map((o) => o.calories / o.max_portions)), 1)
    }
  }
  return 0
})
const kcalMax = computed(() => {
  if (searchDishesAndIngredients.value) {
    const data = searchDishesAndIngredients.value
    if (searchInValue.value === 'ingredients') {
      return round(Math.max(...data.ingredients.map((o) => (o.calories * o.quantity) / 100)), 1)
    } else {
      return round(Math.max(...data.dishes.map((o) => o.calories / o.max_portions)), 1)
    }
  }
  return 9999
})

const nutrientChoices = [
  {
    value: 'choosen',
    text: 'Wybrane'
  },
  {
    value: 'default',
    text: 'Domyślne'
  }
]
const searchInChoices = [
  {
    value: 'dishes',
    text: 'Potrawach'
  },
  {
    value: 'ingredients',
    text: 'Produktach'
  },
  {
    value: 'ingredients_in_dishes',
    text: 'Produktach wśród potraw'
  }
]
const sortByChoices = computed(() => {
  const choices = [
    {
      value: 'name',
      text: 'Nazwie'
    },
    {
      value: 'calories',
      text: 'Kaloriach'
    },
    {
      value: 'quantity',
      text: 'Masie'
    }
  ]
  nutrients.value.forEach((nutrient) => {
    if (nutrient !== 'ratio_n_3_n_6' && nutrient !== 'calories') {
      choices.push({
        value: nutrient,
        text: nutrientsMap[nutrient].shortcut
      })
    }
  })
  return choices
})
const categoryChoices = computed<Choice[]>(() => {
  const choices = [
    {
      value: 'public',
      text: 'Publiczne',
      active: false
    },
    {
      value: 'private',
      text: 'Prywatne',
      active: false
    },
    {
      value: 'favourite',
      text: 'Ulubione',
      active: false
    }
  ]
  choices.forEach((choice) => {
    choice.active = categoryValues.value.includes(choice.value)
  })
  return choices
})
const dishSizesChoices = computed(() => {
  return dishSizes.map((choice) => {
    const active = dishSizesValues.value.includes(choice.value)
    return {
      value: choice.value,
      text: choice.text,
      active
    }
  })
})
const mealTypesChoices = computed(() => {
  return mealTypes.map((choice) => {
    const active = mealTypesValues.value.includes(choice.value)
    return {
      value: choice.value,
      text: choice.text,
      active
    }
  })
})

const ingredientTagsChoices = computed(() => {
  const choices: Choice[] = []
  A.forEach(D.keys(ingredientTags), (tag) => {
    choices.push({
      value: tag,
      text: ingredientTags[tag],
      active: ingredientTagsValues.value.includes(tag)
    })
  })

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return choices
})
const ingredientBasesChoices = computed(() => {
  return ingredientBases.map((choice) => {
    const active = ingredientBasesValues.value.includes(choice.value)
    return {
      value: choice.value,
      text: choice.text,
      active
    }
  })
})
const ingredientCategoriesChoices = computed(() => {
  return ingredientCategories.map((choice) => {
    const active = ingredientCategoriesValues.value.includes(choice.value)
    return {
      value: choice.value,
      text: choice.text,
      active
    }
  })
})

const filtersActive = computed(() => {
  if (nameValue.value !== '') return true
  if (categoryValues.value.length !== 0) return true
  if (dishSizesValues.value.length !== 0) return true
  if (dishTypesValues.value.length !== 0) return true
  if (mealTypesValues.value.length !== 0) return true
  if (ingredientTagsValues.value.length !== 0) return true
  if (ingredientBasesValues.value.length !== 0) return true
  if (ingredientCategoriesValues.value.length !== 0) return true
  if (kcalMinValue.value !== kcalMin.value) return true
  if (kcalMaxValue.value !== kcalMax.value) return true
  return false
})

const limitedDishesAndIngredients = computed(() => {
  return dishesAndIngredients.value.slice(0, 50 * searchPage.value)
})

const selectedDishesAndIngredients = computed(() => {
  if (searchDishesAndIngredients.value) {
    return searchDishesAndIngredients.value[searchType.value]
  }
  return [] as DietSearchDish[] | DietSearchIngredient[]
})

const keysToSearch = computed(() => {
  if (searchInValue.value === 'ingredients_in_dishes') {
    return ['ingredients.name']
  }
  return ['name']
})
const dishesOrIngredients = useFuseSearch<DietSearchDish | DietSearchIngredient>(
  selectedDishesAndIngredients,
  nameValue,
  keysToSearch
)

const dishesAndIngredients = computed(() => {
  return calculateNutrient({
    categoryValues: categoryValues.value,
    categoryChoices: categoryChoices.value,
    dishSizesValues: dishSizesValues.value,
    dishTypesValues: dishTypesValues.value,
    dishesOrIngredients: dishesOrIngredients.value,
    favouriteDishes: user?.value?.favourite_dishes ?? [],
    favouriteIngredients: user?.value?.favourite_ingredients ?? [],
    ingredientBasesValues: ingredientBasesValues.value,
    ingredientCategoriesValues: ingredientCategoriesValues.value,
    ingredientTagsValues: ingredientTagsValues.value,
    kcalMaxValue: kcalMaxValue.value,
    kcalMinValue: kcalMinValue.value,
    mealTypesValues: mealTypesValues.value,
    searchAllTagsStatus: searchInValue.value !== 'ingredients' ? searchAllTagsStatus.value : true,
    searchType: searchType.value,
    searchInValue: searchInValue.value,
    sortByValue: sortByValue.value
  })
})

const chosenTags = computed(() => {
  const tags = [...dishTypesValues.value]
  ingredientTagsValues.value.forEach((type) => {
    if (type === 'is_diary_free') {
      tags.push(53)
    }
    if (type === 'is_gluten_free') {
      tags.push(54)
    }
    if (type === 'is_vegetarian') {
      tags.push(52)
    }
    if (type === 'is_vegan') {
      tags.push(51)
    }
  })
  return tags
})

// Other
const openAddDishModal = () => {
  if (hasAddDishPermission.value) {
    addDishModalOpened.value = true
  } else {
    showAppUpgradePlanModal()
  }
}
const closeAddDishModal = () => {
  addDishModalOpened.value = false
  newDish.value = {
    id: 0,
    name: '',
    size: 2,
    type: 'dish',
    dish_types: [],
    meal_types: [],
    ingredients: [],
    max_portions: 1,
    used_portions: 1,
    preparation_time: 5,
    preparation_steps: '',
    is_public: false
  }
}
const updateDish = (dish: StoreDietDish) => {
  newDish.value = dish
}
const addDish = () => {
  debouncedSaveDish(newDish.value)
  closeAddDishModal()
}
const setActiveMenu = (menu: string) => {
  if (activeMenu.value === menu) {
    activeMenu.value = null
  } else {
    activeMenu.value = menu
  }
}
const checkActiveMenu = (menu: string) => {
  return activeMenu.value === menu
}
const activateResult = (resultIndex: string) => {
  activeMenu.value = null
  if (searchType.value === 'dishes') {
    if (activeResult.value === resultIndex) {
      activeResult.value = null
    } else {
      activeResult.value = resultIndex
    }
  } else {
    activeResult.value = null
  }
}
const updateSearchPage = (event: Event) => {
  const el = event.target as HTMLDivElement
  if (el.scrollTop >= el.scrollHeight - el.clientHeight - 100) {
    searchPage.value += 1
  }
}
const resetSearchPage = (scrollTop = true) => {
  activeResult.value = null
  activeMenu.value = null
  searchPage.value = 1
  if (scrollTop && resultsRef.value) {
    resultsRef.value.scrollTop = 0
  }
}
const toogleExtened = () => {
  if (hasPerm('accounts.diet_extended_search')) {
    updateExtended(!extended.value)
  } else {
    showAppUpgradePlanModal()
  }
}
const toggleFilters = () => {
  filtersExtended.value = !filtersExtended.value
}
const addOrRemoveFromList = <T>(valuesList: T[], value: T, scrollTop = true) => {
  const index = valuesList.indexOf(value)
  if (index === -1) {
    valuesList.push(value)
  } else {
    valuesList.splice(index, 1)
  }
  resetSearchPage(scrollTop)
}
const setName = (value: string) => {
  nameValue.value = value
  resetSearchPage()
}
const setNutrient = (value: Nutrients) => {
  nutrientValue.value = value
  sortByValue.value = 'name'
  resetSearchPage()
}
const setKcalMin = (value: number) => {
  kcalMinValue.value = value
  resetSearchPage()
}
const setKcalMax = (value: number) => {
  kcalMaxValue.value = value
  resetSearchPage()
}
const setSearchIn = (value: SearchIn) => {
  clearFilters(false, false)
  searchInValue.value = value
  resetSearchPage()
}
const setSortBy = (value: SortBy) => {
  sortByValue.value = value
  resetSearchPage()
}
const setDishCategory = (value: string) => {
  addOrRemoveFromList(categoryValues.value, value)
}
const setDishSize = (value: number) => {
  if (searchInValue.value !== 'ingredients') {
    addOrRemoveFromList(dishSizesValues.value, value)
  }
}
const setMealType = (value: number) => {
  if (searchInValue.value !== 'ingredients') {
    addOrRemoveFromList(mealTypesValues.value, value)
  }
}

const setIngredientTag = (value: string, scrollTop = true) => {
  addOrRemoveFromList(ingredientTagsValues.value, value, scrollTop)
}
const setIngredientBase = (value: number) => {
  addOrRemoveFromList(ingredientBasesValues.value, value)
}
const setIngredientCategory = (value: number) => {
  addOrRemoveFromList(ingredientCategoriesValues.value, value)
}
const clearFilters = (resetName = true, resetTags = true) => {
  if (resetName) {
    nameValue.value = ''
  }
  categoryValues.value = []
  dishSizesValues.value = []
  dishTypesValues.value = []
  mealTypesValues.value = []
  if (resetTags) {
    ingredientTagsValues.value = []
  }
  ingredientBasesValues.value = []
  ingredientCategoriesValues.value = []
  kcalMinValue.value = kcalMin.value
  kcalMaxValue.value = kcalMax.value

  resetSearchPage()
}
const getDishQuantityPerPortion = (dish: DietSearchDish) => {
  return round(dish.quantity / dish.max_portions, 1)
}
const getIngredientQuantity = (ingredient: DietSearchIngredient) => {
  return round(ingredient.quantity, 1)
}
const getNutrient = (
  dishOrIngredient: DietSearchDishOrIngredient,
  nutrient: Nutrients,
  calculateQuantity: boolean
) => {
  if ('dish_types_ids' in dishOrIngredient) {
    return dietsService.getDishNutrient(dishOrIngredient, nutrient, calculateQuantity)
  }

  return dietsService.getIngredientNutrient(dishOrIngredient, nutrient, calculateQuantity)
}
const getDishSizeDisplay = (dish: DietSearchDishOrIngredient) => {
  if ('meal_types_ids' in dish) {
    const size = dishSizes.find((obj) => {
      return obj.value === dish.size
    })
    return size?.text ?? ''
  }
  return ''
}
const getDishMealTypesDisplay = (dish: DietSearchDishOrIngredient) => {
  if ('meal_types_ids' in dish) {
    let display = ''
    let mealTypesList = dish.meal_types_ids

    if (mealTypesList.length <= 0) {
      mealTypesList = mealTypes.map((item) => item.value)
    }
    mealTypesList.forEach((mealTypeValue, mealTypeIndex) => {
      if (mealTypeIndex !== 0) {
        display += ', '
      }
      const text = mealTypes.find((item) => item.value === mealTypeValue)?.text
      if (text) {
        display += text
      }
    })
    return display
  }
  return ''
}
const getDishDishTypesDisplay = (dish: DietSearchDishOrIngredient) => {
  if ('meal_types_ids' in dish) {
    const display: string[] = []
    const dishTypesList = dish.dish_types_ids
    const dishSystemTypesListTranslated = {
      is_vegan: 'Wegański',
      is_vegetarian: 'Wegetariański',
      is_diary_free: 'Bezmleczny',
      is_gluten_free: 'Bezglutenowy'
    }
    A.forEach(D.toPairs(dishSystemTypesListTranslated), ([systemType, systemTypeText]) => {
      // @ts-expect-error
      if (systemType in dish && dish[systemType] === 0) {
        display.push(systemTypeText)
      }
    })
    dishTypesList.forEach((dishTypeValue) => {
      const displayTemp = dishTypes.find((item) => item.value === dishTypeValue)
      if (displayTemp) {
        display.push(displayTemp.text)
      }
    })
    return display.join(', ')
  }
  return ''
}
const getDishTypeNames = computed(() => {
  const dishSystemTypesListMap: Record<keyof typeof ingredientTags, [string, DishTypes[number]]> = {
    is_vegan: ['Wegańska', 51],
    is_vegetarian: ['Wegetariańska', 52],
    is_gluten_free: ['Bezglutenowa', 54],
    is_diary_free: ['Bezmleczna', 53]
  }
  const dishTypeNames: { id: DishTypes[number]; name: string }[] = []
  dishTypesValues.value.forEach((type) => {
    if (!autoTags.includes(type)) {
      const dishType = dishTypes.find((obj) => obj.value === type)
      dishTypeNames.push({ name: dishType?.text ?? '', id: type })
    }
  })
  ingredientTagsValues.value.forEach((t) => {
    const type = t as keyof typeof dishSystemTypesListMap
    dishTypeNames.push({
      name: dishSystemTypesListMap[type][0],
      id: dishSystemTypesListMap[type][1]
    })
  })
  return dishTypeNames
})
const disabledAddOption = (dishOrIngredient: DietSearchDishOrIngredient, mealType: number) => {
  if ('meal_types_ids' in dishOrIngredient) {
    if (dishOrIngredient.meal_types_ids.length > 0) {
      if (!dishOrIngredient.meal_types_ids.includes(mealType)) {
        return true
      }
    }
  }
  return false
}
const addDishOrIngredient = async (
  event: Event,
  dishOrIngredient: DietSearchDishOrIngredient,
  mealIndex: number
) => {
  try {
    if (!(event.target as HTMLDivElement).classList.contains('disabled')) {
      activeMenu.value = null
      await fetchAndAddDishOrIngredient({
        id: dishOrIngredient.id,
        type: searchType.value === 'dishes' ? 'dish' : 'ingredient',
        mealIndex,
        dayIndex: dayIndex.value
      })
      if (searchType.value === 'ingredients' && user?.value) {
        await sendProductToFile({
          email: user.value.email,
          productName: dishOrIngredient.name,
          productId: dishOrIngredient.id
        })
      }
    }
  } catch (err) {
    reportError(err, 'Error during fetching dish or ingredient', {
      id: dishOrIngredient.id,
      type: searchType.value === 'dishes' ? 'dish' : 'ingredient',
      mealIndex,
      dayIndex: dayIndex.value
    })
  }
}
const dragStartDishOrIngredientHandler = (
  event: DragEvent,
  dishOrIngredient: DietSearchDishOrIngredient
) => {
  updateDragMode('add')
  if (event.dataTransfer) {
    event.dataTransfer.effectAllowed = 'copyMove'
    // event.dataTransfer.dropEffect = dragMode
    event.dataTransfer.setData('dishOrIngredientId', dishOrIngredient.id.toString())
    event.dataTransfer.setData('dishOrIngredientName', dishOrIngredient.name)
    event.dataTransfer.setData('type', searchType.value === 'dishes' ? 'dish' : 'ingredient')
  }
}
const hideMenu = () => {
  activeMenu.value = null
}

const searchAllTags = (status: boolean) => {
  searchAllTagsStatus.value = status
}
const removeSingleTag = (id: DishTypes[number]) => {
  if (!autoTags.includes(id)) {
    addOrRemoveFromList(dishTypesValues.value, id)
  } else {
    addOrRemoveFromList(
      ingredientTagsValues.value,
      dishSystemTypesList[id as keyof typeof dishSystemTypesList]
    )
  }
}
const showDishTagList = () => {
  tagListShown.value = true
}
const hideDishTagList = () => {
  tagListShown.value = false
}
const setDishTypes = (types: DishTypes) => {
  const objectTypes = types
  const newAutoTags: string[] = []

  if (searchInValue.value !== 'ingredients') {
    autoTags.forEach((tag) => {
      if (objectTypes.includes(tag)) {
        newAutoTags.push(dishSystemTypesList[tag as keyof typeof dishSystemTypesList])
        const index = objectTypes.indexOf(tag)
        if (index !== -1) {
          objectTypes.splice(index, 1)
        }
      }
    })
    ingredientTagsValues.value = newAutoTags
    dishTypesValues.value = objectTypes
  }
  hideDishTagList()
}
watch(kcalMin, (value) => {
  kcalMinValue.value = value
})

watch(kcalMax, (value) => {
  kcalMaxValue.value = value
})

watch([() => route.params.dayIndex, extended], () => {
  searchInput.value?.focus()
})

onMounted(() => {
  const searchAllTagsStored = sessionStorage.getItem('searchAllTags')

  searchAllTagsStatus.value = searchAllTagsStored !== 'false'

  kcalMinValue.value = kcalMin.value
  kcalMaxValue.value = kcalMax.value

  setTimeout(() => {
    let dishFlagsAsList
    const dietSearchFilters = diet.value?.data.dish_search_filters
    let dishFlagsContainsFilter
    if (dietSearchFilters) {
      dishFlagsContainsFilter = Object.values(dietSearchFilters).some((value) => value)
      if (dishFlagsContainsFilter) {
        dishFlagsAsList = Object.entries(dietSearchFilters)
        for (const [flagName, flagValue] of dishFlagsAsList) {
          if (flagValue) {
            setIngredientTag(`is_${flagName}`, false)
          }
        }
      }
    }
  }, 300)
})

useEventListener(document, 'click', hideMenu)
</script>

<style>
.add-dish-modal {
  width: 80% !important;
  max-height: 80%;
  inset: 0;
  transform: none;
  margin: auto;
}

.add-dish-modal .base-modal-actions {
  min-width: 675px;
}

.add-dish-modal .close-button-container {
  position: relative;
  width: 100%;
  min-width: 675px;
  right: 0;
}

.add-dish-modal .close-button-container .base-modal-close {
  right: 0;
}
</style>

<style scoped>
.search {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.filters {
  position: relative;
  background: #fff;
  box-shadow: 2px 4px 4px 0 rgb(232 232 232 / 50%);
  padding: 16px;
  margin-bottom: 24px;
}

.extended .filters {
  height: auto;
}

.extended .filters.filters-not-extended {
  min-height: 158px;
}

/* Filters box section */
.filters-name {
  padding-right: 210px;
  margin-bottom: 18px;
}

.filters-basic {
  display: flex;
  flex-direction: column;
}

.extended .filter-meal-type {
  margin-top: 24px;
}

.extended .filters-basic,
.filters-detailed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.filters-detailed {
  margin-bottom: 24px;
  flex-direction: column;
}

/* Single filter section */
.filter {
  display: flex;
  width: 100%;
}

.filter.ingredients-type {
  width: auto;
  margin-left: 40px;
}

.filters-basic .filter {
  flex-flow: row wrap;
  align-items: center;
}

.filters-detailed .filter {
  display: inline-flex;
  flex-direction: column;
  margin-top: 24px;
}

.filters-detailed .filter.filter-category {
  margin-bottom: 24px;
}

.filters-detailed .filter.filter-dish-type,
.filters-detailed .filter.filter-size {
  width: max-content;
}

.filters-detailed .filter.filter-size {
  margin-left: 40px;
}

.filters-detailed .row-f {
  flex-direction: row;
}

.filter > label {
  font-size: 14px;
  font-family: Montserrat-Medium;
  line-height: 26px;
  margin-right: 8px;
  white-space: nowrap;
}

.filter-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  row-gap: 8px;
}

.filter-box .base-checkbox {
  margin-right: 20px;
}

.filter-name {
  height: 30px;
}

.filter-select {
  width: 100px;
}

.filter-number {
  width: 60px;
  text-align: center;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.actions.not-extended {
  position: static;
}

.extended .actions {
  justify-content: flex-end;
}

.actions-info {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 12px;
  line-height: 16px;
  height: 30px;
}

.actions-info__text {
  margin: 0 6px;
}

.button-extended {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.extended .button-extended {
  position: static;
  margin-left: 16px;
}

.button-filter-extended {
  position: absolute;
  right: 180px;
}

.button-clear-filters {
  margin-right: 150px;
}

/* Add dish */
.add-dish {
  position: absolute;
  right: 16px;
  top: 16px;
}

/* Results */
.results {
  flex-grow: 1;
  background: #fff;
  overflow-y: scroll;
  box-shadow: 2px 4px 4px 0 rgb(232 232 232 / 50%);
  position: relative;
}

.results-loader {
  background-color: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.results-empty {
  text-align: center;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
  color: #a6a6a6;
}

.results-overlay {
  flex-grow: 1;
  display: flex;
  background: #fff;
  box-shadow: 2px 4px 4px 0 rgb(232 232 232 / 50%);
  justify-content: center;
  align-items: center;
  position: relative;
}

.result {
  font-size: 14px;
  margin: 4px 15px;
  padding: 4px 15px;
  color: #545454;
  position: relative; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: none; /* Standard */
}

.result.active {
  background: #e5f5f5;
}

.dishes .result {
  cursor: pointer;
}

.result:first-child {
  margin-top: 15px;
}

.result:last-child {
  margin-bottom: 15px;
}

.result__title {
  font-family: Montserrat-Medium;
  margin-right: 10px;
  min-width: 85px;
  align-self: baseline;
}

.result__row-container {
  margin-right: 28px;
  cursor: pointer;
}

.result__row {
  display: flex;
  align-items: baseline;
  margin-bottom: 6px;
}

.result__row .result__value {
  line-height: 1.4;
}

.result__row-name {
  margin-bottom: 8px;
  gap: 8px;
}

.result__name {
  display: inline-block;
  font-family: Montserrat-SemiBold;
  color: #2b2b2b;
  word-break: break-word;
}

.result__portions {
  margin: 0 8px;
  font-family: Montserrat-SemiBold;
  color: #009b9d;
}

.result__portions.no-margin {
  margin-right: 0;
}

.result__nutrients {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 18px;
  color: #545454;
}

.result__nutrient {
  margin-right: 10px;
  white-space: nowrap;
}

.result__multiple-portions,
.result__size,
.result__meal-type,
.result__dish-type {
  margin-top: 15px;
}

.result__ingredients {
  margin: 25px 0;
}

.result__ingredient-row-name {
  width: 240px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.result__ingredient-name {
  margin-right: 10px;
}

.result__ingredient-quantity {
  min-width: 80px;
  margin-right: 10px;
}

.result__ingredient-measurement {
  min-width: 80px;
}

.add-menu {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 28px;
  height: 28px;
}

.add-menu__options {
  position: absolute;
  display: flex;
  z-index: 20;
  background: #fff;
  flex-direction: column;
  right: 0;
  top: 28px;
  box-shadow: 0 1px 6px 0 rgb(155 155 155 / 50%);
}

.add-menu__option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  white-space: nowrap;
  height: 20px;
  font-size: 12px;
  padding: 3px 6px;
  cursor: pointer;
}

.add-menu__option:hover {
  background: #f8f8f8;
}

.add-menu__option.disabled {
  color: #c3c3c3;
  cursor: default;
}

.private,
.favorite {
  pointer-events: none;
}

.private .base-icon,
.favorite .base-icon {
  color: #a6a6a6;
  font-size: 16px;
}

.favorite .base-icon {
  padding-top: 1px;
}

.chosen-types {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.dish-tags {
  color: #2b2b2b;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  font-family: Montserrat-Medium;
}

.dish-tags span:nth-of-type(2) {
  font-family: Montserrat-Medium;
  color: rgb(43 43 43 / 70%);
  margin: 8px 0 0;
}

.dish-tags .show-tags {
  margin-top: 8px;
  color: #000;
  background: #f0f0f0;
  width: 204px;
  height: 36px;
  border: none;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  padding: 0 12px 0 10px !important;
}

.dish-tags .show-tags:hover {
  color: #fff;
  border: 1px solid #007c7d;
  background-color: #007c7d;
}

.dish-tags .show-tags i {
  color: #8d8d8d;
  font-size: 24px !important;
}

.dish-tags .show-tags:hover i {
  color: #fff;
}

.chosen-type-box {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  padding: 1px 12px;
  width: max-content;
  height: 28px;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.chosen-type-box:hover {
  background: #f7f7f7;
}

.remove-tag {
  cursor: pointer;
  margin-left: 10px;
  color: rgb(43 43 43 / 36%);
}
</style>
