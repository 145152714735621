/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { RouteRecordSingleViewWithChildren } from 'vue-router'

import AppLayoutVue from '@/layouts/AppLayout.vue'
import IngredientsListV2 from '@/pages/v2/ingredients/IngredientsListV2.vue'

export const ingredientsV2Router: RouteRecordSingleViewWithChildren = {
  path: '/ingredientsv2',
  component: AppLayoutVue,
  meta: {
    auth: true
  },
  children: [
    {
      path: 'list/',
      name: 'IngredientsListV2',
      component: IngredientsListV2,
      props: true,
      meta: {
        title: 'Lista produktów'
      }
    }
  ]
}
