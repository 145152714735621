/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { RouteRecordSingleViewWithChildren } from 'vue-router'

import AppLayout from '@/layouts/AppLayout.vue'
// DietDetail
import DietDetail from '@/pages/diets/DietDetail.vue'
import DietDetailAssumptions from '@/pages/diets/DietDetailAssumptions.vue'
import DietDetailComments from '@/pages/diets/DietDetailComments.vue'
import DietDetailDay from '@/pages/diets/DietDetailDay.vue'
import DietDetailSettings from '@/pages/diets/DietDetailSettings.vue'
import DietDetailShoppingList from '@/pages/diets/DietDetailShoppingList.vue'
import DietDetailWeek from '@/pages/diets/DietDetailWeek.vue'
import DietListSystem from '@/pages/diets/DietListSystem.vue'
import DietListUniversity from '@/pages/diets/DietListUniversity.vue'
// DietList
import DietListUser from '@/pages/diets/DietListUser.vue'

export const dietsRouter: RouteRecordSingleViewWithChildren = {
  path: '/diet',
  name: 'Diet',
  redirect: { name: 'DietListUser' },
  component: AppLayout,
  props: true,
  meta: {
    auth: true
  },
  children: [
    {
      path: 'user',
      name: 'DietListUser',
      component: DietListUser,
      meta: {
        title: 'Twoje jadłospisy'
      }
    },
    {
      path: 'system',
      name: 'DietListSystem',
      component: DietListSystem,
      meta: {
        title: 'Jadłospisy systemowe'
      }
    },
    {
      path: 'university',
      name: 'DietListUniversity',
      component: DietListUniversity,
      props: {
        dietType: 'university'
      },
      meta: {
        title: 'Jadłospisy studentów'
      }
    },
    {
      path: ':dietType(user|system|university|patient)/:dietId(\\d+)',
      redirect: { name: 'DietDetailWeek' },
      name: 'DietDetail',
      component: DietDetail,
      props: true,
      children: [
        {
          path: 'week',
          name: 'DietDetailWeek',
          component: DietDetailWeek,
          props: true,
          meta: {
            title: 'Grafik tygodniowy'
          }
        },
        {
          path: 'day/:dayIndex([0-6])',
          name: 'DietDetailDay',
          component: DietDetailDay,
          props: true,
          meta: {
            title: 'Grafik dzienny'
          }
        },
        {
          path: 'shopping-list',
          name: 'DietDetailShoppingList',
          component: DietDetailShoppingList,
          props: true,
          meta: {
            title: 'Lista zakupów'
          }
        },
        {
          path: 'comments',
          name: 'DietDetailComments',
          component: DietDetailComments,
          props: true,
          meta: {
            title: 'Komentarze'
          }
        },
        {
          path: 'settings',
          name: 'DietDetailSettings',
          component: DietDetailSettings,
          props: true,
          meta: {
            title: 'Opis i właściwości'
          },
          beforeEnter(to, _, next) {
            if (to.params.dietType === 'system') {
              next({ name: 'PageNotFound' })
            }
            next()
          }
        },
        {
          path: 'assumptions',
          name: 'DietDetailAssumptions',
          component: DietDetailAssumptions,
          props: true,
          meta: {
            title: 'Założenia diety'
          },
          beforeEnter(to, _, next) {
            if (to.params.dietType === 'system') {
              next({ name: 'PageNotFound' })
            }
            next()
          }
        }
      ]
    }
  ]
}
