import type { Config } from '@/services/apiService'
import type { DietType, UserGroup } from '@/store/types'
import type {
  AllergensInfo,
  AllergensMap,
  Coordinates,
  DayNutrients,
  DayNutrientsDayData,
  DayNutrientsDayDataNutrients,
  DietDayIndex,
  DietDishOrIngredient,
  DietDisplayType,
  DietIngredient,
  DietNorms,
  DietSearchDish,
  DietSearchDishIngredient,
  DietSearchDishIngredientSimple,
  DietSearchIngredient,
  DishInFormType,
  DishSearchFilters,
  EnergyDistributionClass,
  HashedDish,
  Hashesh,
  Patient,
  PatientDiet,
  RelatedDish,
  Schedule,
  ScheduleDishOrIngredient,
  ScheduleMealDayDish,
  ScheduleMealDayIngredient,
  StoreDiet,
  StoreDietDish,
  StoreDietMeal,
  SystemDiet,
  UniversityDiet,
  UserDiet
} from '@/types/Diet'
import type { BasicNutrients, Nutrients, NutrientsValues } from '@/utils/nutrients'

import { A, D, F, G } from '@mobily/ts-belt'
import { klona } from 'klona'
import round from 'lodash/round'

import { DAYS_INDEX, daysMap } from '@/const'
import { api } from '@/services/apiService'
import { cyrb53 } from '@/utils/crypto'
import { basedOnCalculatedNutrients, basicNutrients, calculatedNutrients } from '@/utils/nutrients'

import {
  getAllergensFromIngredients,
  getIngredientAllergens,
  getNormClass,
  parseNutrient
} from './helpers'

type Diet<T = DietType> = T extends 'patient'
  ? PatientDiet
  : T extends 'system'
    ? SystemDiet
    : T extends 'user'
      ? UserDiet
      : T extends 'university'
        ? UniversityDiet
        : never

type CreateDietParams<T = DietType> = {
  dietType: T
  groupId?: number
  name: string
  dietDisplayType: DietDisplayType
}

type UpdateDietParams<T = DietType> = {
  dietId: number
  dietType: T
  name: string
}
type DeleteDietsParams = {
  dietType: DietType
  dietsIds: number[]
}

type CreateGroupParams = {
  dietType: DietType
  name: string
}

type FetchGroupsParams = {
  dietType: DietType
  ordering?: string
  searchPhrase?: string
}

type UpdateGroupNameParams = {
  dietType: DietType
  groupId: number
  name: string
}
type DeleteGroupParams = {
  dietType: DietType
  groupId: number
}

type MoveDietsToGroupParams = {
  dietType: DietType
  dietsIds: number[]
  groupId: number
}

type CopyDietsToGroupParams = {
  dietType: DietType
  dietsIds: number[]
  groupId: number | null
}

export type RepeatedUsedDiet = {
  end_date: string
  id: number
  name: string
  number_of_occurences: number
  start_date: string
  url: string
}

export type FetchGroupsResponse = {
  diets_ids: string[]
  id: number
  name: string
}

export type FetchDietsResult = {
  calories: number
  created: string
  description: string
  group?: number
  id: number
  name: string
}

export type FetchDietsResponse = {
  count: number
  next?: number
  previous: number
  results: FetchDietsResult[]
}

export type FetchSystemDietsResponse = {
  calories: number
  id: number
  name: string
}

export type SaveCopiedSystemDietInFileParam = {
  name: string
  type: string
  user: string
}

const parseGroupId = (groupId: number | null | undefined) => {
  if (G.isNullable(groupId) || groupId === 0) {
    return null
  }
  return groupId
}

function getAllNutrients(chosenNutrients: Nutrients[]) {
  const bonusNutrients: Nutrients[] = []
  if (chosenNutrients.includes('ratio_n_3_n_6')) {
    bonusNutrients.push('n_3')
    bonusNutrients.push('n_6')
  }
  if (chosenNutrients.includes('glycemic_load')) {
    bonusNutrients.push('glycemic_index')
  }
  return A.uniq(A.concat(chosenNutrients, bonusNutrients))
}

export const dietsService = {
  // Diets
  createDiet<T extends DietType>({
    dietType,
    groupId,
    name,
    dietDisplayType
  }: CreateDietParams<T>) {
    const group = groupId === 0 ? null : groupId
    const url = `/diets/${dietType}/`
    return api.post<Diet<T>>(url, {
      data: {
        name,
        diet_type: dietDisplayType
      },
      name,
      group: group ? group : null
    })
  },
  fetchDiet<T extends DietType>(dietType: T, dietId: number) {
    const url = `/diets/${dietType}/${dietId}/`
    return api.get<Diet<T>>(url)
  },
  fetchDiets({
    dietType,
    searchPhrase = '',
    groupId,
    page = 1,
    ordering = 'name'
  }: {
    dietType: DietType
    groupId?: number
    ordering?: string
    page?: number
    searchPhrase?: string
  }) {
    const params = new URLSearchParams(
      D.filter(
        { search: searchPhrase, group: groupId?.toString(), page: page.toString(), ordering },
        G.isNotNullable
      )
    ).toString()
    const url = `/diets/${dietType}/?${params}`
    return api.get<FetchDietsResponse>(url)
  },
  fetchSystemDiets({
    searchPhrase = '',
    groupId,
    page = 1,
    ordering = 'name'
  }: {
    groupId?: number
    ordering?: string
    page?: number
    searchPhrase?: string
  }) {
    const params = new URLSearchParams(
      D.filter(
        { search: searchPhrase, group: groupId?.toString(), page: page.toString(), ordering },
        G.isNotNullable
      )
    ).toString()
    const url = `/diets/system/?${params}`
    return api.get<FetchSystemDietsResponse[]>(url)
  },
  updateDietName<T extends DietType>({ dietType, dietId, name }: UpdateDietParams<T>) {
    const url = `/diets/${dietType}/${dietId}/`
    return api.patch<Diet<T>>(url, {
      data: {
        name
      },
      name
    })
  },
  saveDiet<T extends DietType>(diet: Diet<T>, dietType: T) {
    const data = klona(diet) as StoreDiet
    if ('name' in data) {
      data.name = data.data.name
      data.description = data.data.description
      data.calories = data.data.norms.calories
    }
    if ('dietitian_patient' in data) {
      // @ts-ignore
      delete data.dietitian_patient
      // @ts-ignore
      delete data.allergens
    }
    const url = `/diets/${dietType}/${diet.id}/`
    return api.patch<Diet<T>>(url, data)
  },
  deleteDiet({ dietType, dietsIds }: DeleteDietsParams) {
    const url = `/diets/${dietType}/`
    return api.delete(url, {
      data: dietsIds.map((dietId) => {
        return { id: dietId }
      })
    })
  },
  shareDiets(dietType: DietType, dietsIds: number[], email: string) {
    const url = `/diets/${dietType}/share_to_user/`
    return api.post(url, {
      ids: dietsIds,
      email
    })
  },
  // Groups
  createGroup({ dietType, name }: CreateGroupParams) {
    const url = `/diets/${dietType}-group/`
    return api.post<UserGroup>(url, {
      name
    })
  },
  fetchGroups({ dietType, searchPhrase = '', ordering = 'name' }: FetchGroupsParams) {
    const url = `/diets/${dietType}-group/?search=${searchPhrase}&ordering=${ordering}`
    return api.get<UserGroup[]>(url)
  },
  updateGroupName({ dietType, groupId, name }: UpdateGroupNameParams) {
    const url = `/diets/${dietType}-group/${groupId}/`
    return api.patch<UserGroup>(url, {
      name
    })
  },
  deleteGroup({ dietType, groupId }: DeleteGroupParams) {
    const url = `/diets/${dietType}-group/${groupId}/`
    return api.delete(url)
  },
  moveDietsToGroup({ dietType, groupId, dietsIds }: MoveDietsToGroupParams) {
    const url = `/diets/${dietType}/move/`

    return api.patch(url, {
      group_id: parseGroupId(groupId),
      ids: dietsIds
    })
  },
  copyDietsToGroup({ dietType, groupId, dietsIds }: CopyDietsToGroupParams) {
    const url = `/diets/${dietType}/copy/`

    return api.post<UserDiet[]>(url, {
      group_id: parseGroupId(groupId),
      ids: dietsIds
    })
  },
  saveCopiedSystemDietInFile(dietsNames: SaveCopiedSystemDietInFileParam[]) {
    const url = '/users/system_diet_or_used_product_to_file/'
    return api.post(url, { diets_names: dietsNames })
  },
  saveUsedUspOrNaturellProductInFile(productName: { name: string; type: string; user: string }) {
    const url = '/users/system_diet_or_used_product_to_file/'
    return api.post(url, { product_name: productName })
  },
  // Ingredient
  fetchIngredient(ingredientId: number) {
    const url = `/ingredients/${ingredientId}/fetch_ingredient/`
    return api.get<DietIngredient>(url)
  },
  // Common
  async fetchSearchDishesAndIngredients(nutrients: Nutrients[], resetCache = false) {
    const url = '/diets/search/dishes_and_ingredients/'
    const { data } = await api.post<{
      dishes: DietSearchDish[]
      ingredients: DietSearchIngredient[]
    }>(
      url,
      {
        nutrients,
        reset_cache: resetCache
      },
      { handledError: true } as Config
    )
    return data
  },
  async fetchSearchDishesAndIngredientsSimple(flags: DishSearchFilters) {
    const url = '/diets/search/simple_dishes_and_ingredients/'
    const { data } = await api.post<DietSearchDishIngredientSimple[]>(url, flags, {
      handledError: true
    } as Config)
    return data
  },
  fetchPatientDietRepeatedDishes(dietId: number) {
    const url = '/diets/patient/patient_used_dishes/'
    return api.post<string[]>(url, {
      patient_diet_id: dietId
    })
  },
  setIsDietVisible(dietId: string | number, isDietVisible: boolean) {
    const url = `/diets/patient/${dietId}/`
    return api.patch<PatientDiet>(url, {
      is_diet_visible: isDietVisible
    })
  },
  fetchPatientDietRepeatedDishDiets(dietId: number, doiName: string) {
    const url = '/diets/patient/patient_used_dish_diets/'
    return api.post<RepeatedUsedDiet[]>(url, {
      patient_diet_id: dietId,
      doi_name: doiName
    })
  },
  // Assign to
  assignDietToPatient(dietType: DietType, dietId: number, email: string, startDate: string) {
    const url = `/diets/${dietType}/assign_to_patient/`
    const data = {
      id: dietId,
      email,
      start_date: startDate
    }
    return api.post<PatientDiet>(url, data)
  },
  assignDietToLeader(dietType: DietType, dietsIds: number[], email: string) {
    const url = `/diets/${dietType}/assign_to_leader/`
    return api.post(url, {
      ids: dietsIds,
      email
    })
  },
  // Norm helpers
  getNormIndex(patient: Patient, patientAge: number) {
    if (patient.gender === 'female') {
      // NormIndex based on pregnancy
      if (patient.pregnancy.active) {
        if (patientAge < 19) {
          return 14
        }
        return 15
      }
      // NormIndex based on lactaction
      if (patient.lactation.active) {
        if (patientAge < 19) {
          return 16
        }
        return 17
      }
    }
    // NormIndex based on age
    if (patientAge >= 0 && patientAge < 0.5) return 0
    if (patientAge >= 0.5 && patientAge < 1) return 1
    if (patientAge >= 1 && patientAge < 2) return 2
    if (patientAge >= 2 && patientAge <= 3) return 3
    if (patientAge >= 4 && patientAge <= 6) return 4
    if (patientAge >= 7 && patientAge <= 9) return 5
    if (patientAge >= 10 && patientAge <= 12) return 6
    if (patientAge >= 13 && patientAge <= 15) return 7
    if (patientAge >= 16 && patientAge <= 18) return 8
    if (patientAge >= 19 && patientAge <= 30) return 9
    if (patientAge >= 31 && patientAge <= 50) return 10
    if (patientAge >= 51 && patientAge <= 65) return 11
    if (patientAge >= 66 && patientAge <= 75) return 12
    if (patientAge > 75) return 13
    return 0
  },

  /**
   * Calculates schedule data for store and front end display
   *
   * @param  {Array} meals                    list of meals from store
   * @param  {Number} normsCalories           calories from diet
   * @param  {Boolean} dietEnergyDistribution energy distribution status true/false
   * @param  {Array} chosenNutrients         list of nutrients to calculate
   * @param  {Number} lossesMultiplier        multiplier for calculations
   * @return schedule = {
   *   days: [{
   *     name: 'Poniedziałek', // Name of day
   *     calories: 1000,       // Calculated day calories
   *     normClass: 'ok'       // Day norm class based on callories, possible values:
   *                           // ok, red-up, red-down, yellow-up, yellow-down
   *   }, ...],
   *   meals: [{
   *     name: 'Śniadanie',    // Name of meal,
   *     energyDistribution: diet.data.meals[mealIndex].energy_distribution, // Target meal energy distribution
   *     days: [{
   *       hour: day.hour, // Meal-Day hour
   *       dishesAndIngredients: [], // DishesAndIngredients
   *       calories: 0, // Calories
   *       energyDistribution: 0, // Energy distribution value
   *       energyDistributionCalories: 0, // Energy distribution calories - helper
   *       energyDistributionClass: 'ok' // Energy distribution dot class
   *     }],
   *     average: {  // Average values for week schedule
   *       calories: 0,
   *       protein: 0,
   *       fat: 0,
   *       carbohydrates: 0,
   *       proteinPercent: 0,
   *       fatPercent: 0,
   *       carbohydratesPercent: 0
   *     }
   *   }, ...],
   *   normsCalories: diet.data.norms.calories,
   *   energyDistribution: diet.data.energy_distribution
   *  }
   */
  getSchedule(
    meals: StoreDietMeal[],
    normsCalories: number,
    _energyDistribution: boolean,
    chosenNutrients: Nutrients[],
    lossesMultiplier: number,
    repeatedDishesAndIngredients: string[]
  ) {
    // Init schedule structure
    const schedule: Schedule = {
      days: [
        { name: '', normClass: 'ok', calories: 0 },
        { name: '', normClass: 'ok', calories: 0 },
        { name: '', normClass: 'ok', calories: 0 },
        { name: '', normClass: 'ok', calories: 0 },
        { name: '', normClass: 'ok', calories: 0 },
        { name: '', normClass: 'ok', calories: 0 },
        { name: '', normClass: 'ok', calories: 0 }
      ],
      meals: [],
      normsCalories,
      energyDistribution: _energyDistribution
    }
    const allNutrients = getAllNutrients(chosenNutrients)

    // Loop over meals
    A.forEachWithIndex(meals, (mealIndex, meal) => {
      const days = meal.days

      // Helpers
      let daysDivider = 0 // Average helper
      let mealCalories = 0
      let mealProtein = 0
      let mealFat = 0
      let mealCarbohydrates = 0
      let mealFiber = 0

      // Init meal structure
      schedule.meals.push({
        name: meal.name,
        type: meal.type,
        energyDistribution: meal.energy_distribution,
        energyDistributionCalories: round(
          (schedule.normsCalories * meal.energy_distribution) / 100
        ),
        actualEnergyDistribution: 0,
        days: [],
        hour: '',
        mealExists: false,
        average: {
          calories: 0,
          protein: 0,
          fat: 0,
          carbohydrates: 0,
          proteinPercent: 0,
          fatPercent: 0,
          carbohydratesPercent: 0
        }
      })

      // Loop over meal in days
      A.forEachWithIndex(days, (dayIndex, day) => {
        const dishesAndIngredients = day.dishes_and_ingredients

        // Helpers
        const mealDayNutrients = D.makeEmpty<NutrientsValues>() // mealDay nutrients helper
        let mealDayQuantity = 0
        let mealDayCalories = 0
        let mealDayProtein = 0
        let mealDayFat = 0
        let mealDayCarbohydrates = 0
        let mealDayFiber = 0
        let mealDayN3 = 0
        let mealDayN6 = 0

        // Init day in meal structure
        schedule.meals[mealIndex]?.days.push({
          hour: day.hour,
          dishesAndIngredients: [],
          calories: 0,
          quantity: 0,
          energyDistribution: 0,
          energyDistributionClass: 'green',
          nutrients: chosenNutrients.reduce((result, item) => {
            if (item === 'ratio_n_3_n_6') {
              result[item] = '0 : 0'
            } else {
              result[item] = 0
            }
            return result
          }, D.makeEmpty<NutrientsValues>())
        })

        // Overwrite day name
        if (schedule.days[dayIndex]) {
          schedule.days[dayIndex].name = daysMap[dayIndex] ?? ''
        }

        // Check if there are some dishes or ingredients in MealDay and update daysDivder for average calculations
        if (dishesAndIngredients.length > 0) {
          daysDivider += 1
        }

        A.forEach(allNutrients, (nutrientKey) => {
          if (!(nutrientKey in mealDayNutrients) && nutrientKey !== 'ratio_n_3_n_6') {
            mealDayNutrients[nutrientKey] = 0
          }
        })

        A.forEach(dishesAndIngredients, (dishOrIngredient) => {
          if (dishOrIngredient.type === 'dish') {
            A.forEach(dishOrIngredient.ingredients, (ingredient) => {
              const nutrients = ingredient.nutrients
              const ingredientMultiplier =
                round(
                  ((ingredient.quantity * lossesMultiplier) / dishOrIngredient.max_portions) *
                    dishOrIngredient.used_portions,
                  1
                ) / 100
              mealDayCarbohydrates += round(nutrients.carbohydrates * ingredientMultiplier, 1)
            })
          } else {
            const nutrients = dishOrIngredient.nutrients
            const ingredientMultiplier =
              round(dishOrIngredient.quantity * lossesMultiplier, 1) / 100
            mealDayCarbohydrates += round(nutrients.carbohydrates * ingredientMultiplier, 1)
          }
        })

        // Loop over dishesAndIngredients
        A.forEachWithIndex(dishesAndIngredients, (dishOrIngredientIndex, dishOrIngredient) => {
          // Helpers
          let ingredientCarbohydrates = 0
          let doiQuantity = 0
          let doiCalories = 0
          let doiProtein = 0
          let doiFat = 0
          let doiCarbohydrates = 0
          let doiCarbohydratesSum = 0
          let doiFiber = 0
          let doiN3 = 0
          let doiN6 = 0
          // Init dishOrIngredient structure
          if (dishOrIngredient.type === 'dish') {
            const ingredients = dishOrIngredient.ingredients
            const data: ScheduleMealDayDish = {
              name: dishOrIngredient.name,
              type: dishOrIngredient.type,
              portions: '',
              calories: 0,
              proteinPercent: 0,
              fatPercent: 0,
              protein: 0,
              carbohydrates: 0,
              fat: 0,
              fiber: 0,
              maxPortions: dishOrIngredient.max_portions,
              carbohydratesPercent: 0,
              carbohydratesPercentBar: 0,
              repeated: repeatedDishesAndIngredients.includes(dishOrIngredient.name),
              ingredients
            }

            // Update dish portions
            if (dishOrIngredient.max_portions > 1) {
              data.portions = `${dishOrIngredient.used_portions}/${dishOrIngredient.max_portions} P`
            }
            A.forEach(ingredients, (ingredient) => {
              const nutrients = ingredient.nutrients
              const ingredientMultiplier =
                round(
                  ((ingredient.quantity * lossesMultiplier) / dishOrIngredient.max_portions) *
                    dishOrIngredient.used_portions,
                  1
                ) / 100
              doiCarbohydratesSum += round(nutrients.carbohydrates * ingredientMultiplier, 1)

              const nutrientMultiplier =
                round(
                  ((ingredient.quantity * lossesMultiplier) / dishOrIngredient.max_portions) *
                    dishOrIngredient.used_portions,
                  1
                ) / 100

              ingredientCarbohydrates = round(nutrients.carbohydrates * nutrientMultiplier, 1)
              doiQuantity += round(
                (ingredient.quantity / dishOrIngredient.max_portions) *
                  dishOrIngredient.used_portions,
                1
              )
              doiCalories += round(nutrients.calories * nutrientMultiplier, 1)
              doiProtein += round(nutrients.protein * nutrientMultiplier, 1)
              doiFat += round(nutrients.fat * nutrientMultiplier, 1)
              doiCarbohydrates += ingredientCarbohydrates
              doiFiber += round(nutrients.fiber * nutrientMultiplier, 1)
              doiN3 += nutrients.n_3 * nutrientMultiplier
              doiN6 += nutrients.n_6 * nutrientMultiplier

              A.forEach(allNutrients, (key) => {
                // Calculate nutrient value
                if (key === 'glycemic_index') {
                  const glycemicIndexHelper = nutrients.glycemic_index || 0
                  if (doiCarbohydratesSum > 0) {
                    mealDayNutrients.glycemic_index +=
                      (glycemicIndexHelper * ingredientCarbohydrates) / mealDayCarbohydrates
                  } else {
                    mealDayNutrients.glycemic_index = 0
                  }
                } else if (key === 'waste') {
                  let caloriesHelper = nutrients.calories || 0
                  caloriesHelper = caloriesHelper * nutrientMultiplier
                  mealDayNutrients[key] += (nutrients[key] * caloriesHelper) / 100
                } else if (key === 'simple_sugars_sum') {
                  mealDayNutrients[key] += round(nutrients.sucrose * nutrientMultiplier, 1)
                  mealDayNutrients[key] += round(nutrients.lactose * nutrientMultiplier, 1)
                  mealDayNutrients[key] += round(nutrients.glucose * nutrientMultiplier, 1)
                  mealDayNutrients[key] += round(nutrients.fructose * nutrientMultiplier, 1)
                } else {
                  if (key !== 'ratio_n_3_n_6') {
                    mealDayNutrients[key] += round(nutrients[key] * nutrientMultiplier, 1)
                  }
                }
              })
            })

            schedule.meals[mealIndex]?.days[dayIndex]?.dishesAndIngredients.push(data)
          } else {
            const data: ScheduleMealDayIngredient = {
              name: dishOrIngredient.name,
              type: dishOrIngredient.type,
              portions: '',
              calories: 0,
              proteinPercent: 0,
              fatPercent: 0,
              protein: 0,
              carbohydrates: 0,
              fat: 0,
              fiber: 0,
              ingredient_id: dishOrIngredient.ingredient_id,
              carbohydratesPercent: 0,
              carbohydratesPercentBar: 0,
              repeated: repeatedDishesAndIngredients.includes(dishOrIngredient.name)
            }

            const nutrients = dishOrIngredient.nutrients
            const nutrientMultiplier = round(dishOrIngredient.quantity * lossesMultiplier, 1) / 100
            // Calculate basic data
            doiQuantity = round(dishOrIngredient.quantity, 1)
            doiCalories = round(nutrients.calories * nutrientMultiplier, 1)
            doiProtein = round(nutrients.protein * nutrientMultiplier, 1)
            doiFat = round(nutrients.fat * nutrientMultiplier, 1)
            doiCarbohydrates = round(nutrients.carbohydrates * nutrientMultiplier, 1)
            doiFiber = round(nutrients.fiber * nutrientMultiplier, 1)
            doiN3 = nutrients.n_3 * nutrientMultiplier
            doiN6 = nutrients.n_6 * nutrientMultiplier

            // Calculate choosen nutrients data
            A.forEach(allNutrients, (key) => {
              // Init nutrient helper value
              if (!(key in mealDayNutrients) && key !== 'ratio_n_3_n_6') {
                mealDayNutrients[key] = 0
              }
              // Calculate nutrient value
              if (key === 'glycemic_index' && mealDayCarbohydrates !== 0) {
                const glycemicIndexHelper = nutrients.glycemic_index || 0
                mealDayNutrients.glycemic_index +=
                  (glycemicIndexHelper * doiCarbohydrates) / mealDayCarbohydrates
              } else if (key === 'waste') {
                let caloriesHelper = nutrients.calories || 0
                caloriesHelper = caloriesHelper * nutrientMultiplier
                mealDayNutrients[key] += (nutrients[key] * caloriesHelper) / 100
              } else if (key === 'simple_sugars_sum') {
                mealDayNutrients[key] += round(nutrients.sucrose * nutrientMultiplier, 1)
                mealDayNutrients[key] += round(nutrients.lactose * nutrientMultiplier, 1)
                mealDayNutrients[key] += round(nutrients.glucose * nutrientMultiplier, 1)
                mealDayNutrients[key] += round(nutrients.fructose * nutrientMultiplier, 1)
              } else if (key === 'ratio_n_3_n_6') {
                mealDayNutrients[key] = nutrients[key]
              } else {
                mealDayNutrients[key] += round(nutrients[key] * nutrientMultiplier, 1)
              }
            })

            schedule.meals[mealIndex]?.days[dayIndex]?.dishesAndIngredients.push(data)
          }

          // Update mealDay data
          mealDayQuantity += doiQuantity
          mealDayCalories += doiCalories
          mealDayProtein += doiProtein
          mealDayFat += doiFat
          // mealDayCarbohydrates += doiCarbohydrates
          mealDayFiber += doiFiber
          mealDayN3 += doiN3
          mealDayN6 += doiN6

          // Update meal data
          mealCalories += doiCalories
          mealProtein += doiProtein
          mealFat += doiFat
          mealCarbohydrates += doiCarbohydrates
          mealFiber += doiFiber

          // Energy distribution
          const energyDistribution = round((mealDayCalories * 100) / schedule.normsCalories, 1)
          const energyDistributionDifference = Math.abs(
            energyDistribution - (schedule.meals[mealIndex]?.energyDistribution ?? 0)
          )

          // Update MealDay calories and quantity
          const scheduleDay = schedule.meals[mealIndex]?.days[dayIndex]
          if (scheduleDay) {
            scheduleDay.quantity = round(mealDayQuantity, 1)
            scheduleDay.calories = round(mealDayCalories, 1)
            scheduleDay.energyDistribution = energyDistribution
            let energyDistributionClass: EnergyDistributionClass = 'green'
            if (energyDistributionDifference > 10) {
              energyDistributionClass = 'red'
            } else if (energyDistributionDifference > 5) {
              energyDistributionClass = 'yellow'
            }
            scheduleDay.energyDistributionClass = energyDistributionClass
          }

          // Calculate dishOrIngredient percents
          if (doiCalories !== 0) {
            // Helpers
            const proteinPercent = round((doiProtein * 4 * 100) / doiCalories, 1)
            const fatPercent = round((doiFat * 9 * 100) / doiCalories, 1)
            const carbohydratesPercent = round(
              ((doiCarbohydrates * 4 + doiFiber * 2) * 100) / doiCalories,
              1
            )
            let proteinPercentBar = proteinPercent
            let fatPercentBar = fatPercent
            // Day calories
            if (schedule.days[dayIndex]) {
              schedule.days[dayIndex].calories = round(
                schedule.days[dayIndex].calories + doiCalories,
                1
              )
            }
            // DishOrIngredient nutrients
            const scheduleDishOrIngredient =
              scheduleDay?.dishesAndIngredients[dishOrIngredientIndex]
            if (scheduleDishOrIngredient) {
              scheduleDishOrIngredient.calories = round(doiCalories, 1)
              scheduleDishOrIngredient.protein = round(doiProtein, 1)
              scheduleDishOrIngredient.fat = round(doiFat, 1)
              scheduleDishOrIngredient.carbohydrates = round(doiCarbohydrates, 1)
              scheduleDishOrIngredient.fiber = round(doiFiber, 1)

              if (proteinPercent > 100) {
                proteinPercentBar = 100 - fatPercent - carbohydratesPercent
              }
              if (fatPercent > 100) {
                fatPercentBar = 100 - carbohydratesPercent - proteinPercent
              }
              scheduleDishOrIngredient.proteinPercent = proteinPercent
              scheduleDishOrIngredient.fatPercent = fatPercent
              scheduleDishOrIngredient.carbohydratesPercent = carbohydratesPercent
              scheduleDishOrIngredient.carbohydratesPercentBar =
                100 - fatPercentBar - proteinPercentBar
            }
          }
        })

        // Update day in meal nutrients
        if (dishesAndIngredients.length > 0) {
          A.forEach(chosenNutrients, (key) => {
            const nutrients = schedule.meals[mealIndex]?.days[dayIndex]?.nutrients
            if (!nutrients) return
            if (key === 'glycemic_index') {
              nutrients[key] = round(mealDayNutrients.glycemic_index, 0)
            } else if (key === 'glycemic_load') {
              nutrients[key] = round(
                (round(mealDayNutrients.glycemic_index, 0) * mealDayCarbohydrates) / 100,
                1
              )
            } else if (key === 'waste') {
              if (mealDayCalories !== 0) {
                nutrients[key] = round((mealDayNutrients.waste / mealDayCalories) * 100, 1)
              } else {
                nutrients[key] = 0
              }
            } else if (key === 'protein_energy') {
              if (mealDayCalories !== 0) {
                nutrients[key] = round(((mealDayProtein * 4) / mealDayCalories) * 100, 1)
              } else {
                nutrients[key] = 0
              }
            } else if (key === 'fat_energy') {
              if (mealDayCalories !== 0) {
                nutrients[key] = round(((mealDayFat * 9) / mealDayCalories) * 100, 1)
              } else {
                nutrients[key] = 0
              }
            } else if (key === 'carbohydrates_energy') {
              if (mealDayCalories !== 0) {
                nutrients[key] = round(
                  ((mealDayCarbohydrates * 4 + mealDayFiber * 2) / mealDayCalories) * 100,
                  1
                )
              } else {
                nutrients[key] = 0
              }
            } else if (key === 'protein_fat_exchangers') {
              nutrients[key] = round((mealDayProtein * 4 + mealDayFat * 9) / 100, 1)
            } else if (key === 'ratio_n_3_n_6') {
              const n3 = mealDayN3
              const n6 = mealDayN6
              nutrients[key] = `0 : ${round(n6, 1)}`
              if (n3 > 0) nutrients[key] = `1 : ${round(n6 / n3, 1)}`
            } else if (key === 'n_3') {
              nutrients.n_3 = round(mealDayN3, 1)
            } else if (key === 'n_6') {
              nutrients.n_6 = round(mealDayN6, 1)
            } else {
              nutrients[key] = round(mealDayNutrients[key], 1)
            }
          })
        }

        // Calculate meal average
        const currentMeal = schedule.meals[mealIndex]
        if (daysDivider && currentMeal) {
          const averageCalories = round(mealCalories / daysDivider, 1)
          const averageProtein = round(mealProtein / daysDivider, 1)
          const averageFat = round(mealFat / daysDivider, 1)
          const averageCarbohydrates = round(mealCarbohydrates / daysDivider, 1)
          const averageFiber = round(mealFiber / daysDivider, 1)
          const proteinPercent = averageCalories
            ? round((averageProtein * 4 * 100) / averageCalories, 1)
            : 0
          const fatPercent = averageCalories
            ? round((averageFat * 9 * 100) / averageCalories, 1)
            : 0
          const carbohydratesPercent = averageCalories
            ? round(((averageCarbohydrates * 4 + averageFiber * 2) * 100) / averageCalories, 1)
            : 0

          currentMeal.mealExists = true
          currentMeal.actualEnergyDistribution = round((averageCalories * 100) / normsCalories, 1)
          currentMeal.average.calories = averageCalories
          currentMeal.average.protein = averageProtein
          currentMeal.average.fat = averageFat
          currentMeal.average.carbohydrates = averageCarbohydrates
          currentMeal.average.proteinPercent = proteinPercent
          currentMeal.average.fatPercent = fatPercent
          currentMeal.average.carbohydratesPercent = carbohydratesPercent
        }

        // Update day normClass
        A.forEach(schedule.days, (scheduleDay) => {
          const calories = scheduleDay.calories
          const caloriesPercent = round(((calories - normsCalories) / normsCalories) * 100, 1)
          scheduleDay.normClass = getNormClass('calories', caloriesPercent)
        })
      })
    })

    return schedule
  },
  // Nutrients
  getNutrients<T extends Nutrients>(
    nutrientsKeys: T[] | readonly T[],
    dayNutrients: DayNutrients,
    norms: DietNorms,
    dietNormsPermission: boolean
  ) {
    const dayData = dayNutrients.dayData
    const incompleteData = dayNutrients.incompleteData

    return nutrientsKeys.map((nutrientKey) => {
      let norm = 0
      if (nutrientKey in norms) {
        if (norms.active || A.includes(basicNutrients as unknown as string[], nutrientKey)) {
          const key = F.coerce<BasicNutrients>(nutrientKey)
          if (key !== 'calories') {
            if (dietNormsPermission) {
              norm = norms[key].inputed
            } else {
              norm = norms[key].calculated ?? 0
            }
          } else {
            norm = norms.calories
          }
        }
      }
      return parseNutrient(nutrientKey, incompleteData, dayData, norm)
    })
  },
  getDayNutrients(diet: StoreDiet, lossesMultiplier: number) {
    const dayData: DayNutrientsDayData = [
      D.makeEmpty<NutrientsValues>(),
      D.makeEmpty<NutrientsValues>(),
      D.makeEmpty<NutrientsValues>(),
      D.makeEmpty<NutrientsValues>(),
      D.makeEmpty<NutrientsValues>(),
      D.makeEmpty<NutrientsValues>(),
      D.makeEmpty<NutrientsValues>()
    ]
    const incompleteData: DayNutrientsDayDataNutrients = [{}, {}, {}, {}, {}, {}, {}]

    let incompleteDataInfo = false
    let allergensInfo = false
    let allergensData: number[] = []

    if ('allergens' in diet) {
      const allergens = diet.allergens
      allergensData = [
        ...allergens.allergies_id,
        ...allergens.intolerances_id,
        ...allergens.not_like_id
      ]
    }
    A.forEach(diet.data.meals, (meal) => {
      A.forEachWithIndex(meal.days, (index, day) => {
        const dayIndex = index as unknown as DietDayIndex
        A.forEach(day.dishes_and_ingredients, (dishOrIngredient) => {
          A.forEach(calculatedNutrients, (key) => {
            incompleteData[dayIndex][key] = new Set()
          })
          A.forEach(basedOnCalculatedNutrients, (key) => {
            incompleteData[dayIndex][key] = new Set()
          })

          if (dishOrIngredient.type === 'dish') {
            A.forEach(dishOrIngredient.ingredients, (ingredient) => {
              const quantity = ingredient.quantity
              const nutrients = ingredient.nutrients
              const ingredientMultiplier =
                round(
                  ((quantity * lossesMultiplier) / dishOrIngredient.max_portions) *
                    dishOrIngredient.used_portions,
                  1
                ) / 100
              if (allergensData.includes(ingredient.ingredient_id)) {
                allergensInfo = true
              }
              A.forEach(D.keys(nutrients), (key) => {
                let value = nutrients[key] as number
                if (!(key in incompleteData[dayIndex])) {
                  incompleteData[dayIndex][key] = new Set()
                }

                if (G.isNullable(value)) {
                  if (!A.includes(calculatedNutrients, key) || key === 'waste') {
                    incompleteData[dayIndex][key]?.add(ingredient.name)
                    incompleteDataInfo = true
                  }
                  value = 0
                }

                if (!(key in dayData[dayIndex])) {
                  // @ts-expect-error
                  dayData[dayIndex][key] = 0
                }

                if (key === 'glycemic_index') {
                  let carbohydrates = nutrients.carbohydrates || 0

                  carbohydrates = round(carbohydrates * ingredientMultiplier, 1)
                  dayData[dayIndex][key] += value * carbohydrates
                } else if (key === 'waste') {
                  let calories = nutrients.calories || 0
                  calories = calories * ingredientMultiplier
                  dayData[dayIndex][key] += (value * calories) / 100
                } else if (key === 'n_3' || key === 'n_6') {
                  const newValue = value * ingredientMultiplier
                  dayData[dayIndex][key] = dayData[dayIndex][key] + newValue
                } else if (key !== 'ratio_n_3_n_6') {
                  const newValue = round(value * ingredientMultiplier, 1)
                  dayData[dayIndex][key] = round(dayData[dayIndex][key] + newValue, 1)
                }
              })
            })
          } else {
            const nutrients = dishOrIngredient.nutrients
            const quantity = dishOrIngredient.quantity
            const ingredientMultiplier = round(quantity * lossesMultiplier, 1) / 100
            if (allergensData.includes(dishOrIngredient.ingredient_id)) {
              allergensInfo = true
            }
            A.forEach(D.keys(nutrients), (key) => {
              if (!(key in incompleteData[dayIndex])) {
                incompleteData[dayIndex][key] = new Set()
              }
              if (G.isNullable(nutrients[key])) {
                if (!A.includes(calculatedNutrients, key) || key === 'waste') {
                  incompleteData[dayIndex][key]?.add(dishOrIngredient.name)
                  incompleteDataInfo = true
                }

                // @ts-expect-error
                nutrients[key] = 0
              }

              if (!(key in dayData[dayIndex])) {
                // @ts-expect-error
                dayData[dayIndex][key] = 0
              }

              if (key === 'glycemic_index') {
                let carbohydrates = nutrients.carbohydrates || 0
                carbohydrates = carbohydrates * ingredientMultiplier
                dayData[dayIndex][key] += nutrients[key] * carbohydrates
              } else if (key === 'waste') {
                let calories = nutrients.calories || 0
                calories = calories * ingredientMultiplier
                dayData[dayIndex][key] += (nutrients[key] * calories) / 100
              } else if (key === 'n_3' || key === 'n_6') {
                const newValue = nutrients[key] * ingredientMultiplier
                dayData[dayIndex][key] = dayData[dayIndex][key] + newValue
              } else if (key !== 'ratio_n_3_n_6') {
                const newValue = round(nutrients[key] * ingredientMultiplier, 1)
                dayData[dayIndex][key] = round(dayData[dayIndex][key] + newValue, 1)
              }
            })
          }
          incompleteData[dayIndex].glycemic_load = incompleteData[dayIndex].glycemic_index
          incompleteData[dayIndex].ratio_n_3_n_6 = new Set([
            ...Array.from(incompleteData[dayIndex].n_3 ?? []),
            ...Array.from(incompleteData[dayIndex].n_6 ?? [])
          ])
          incompleteData[dayIndex].simple_sugars_sum = new Set([
            ...Array.from(incompleteData[dayIndex].sucrose ?? []),
            ...Array.from(incompleteData[dayIndex].lactose ?? []),
            ...Array.from(incompleteData[dayIndex].glucose ?? []),
            ...Array.from(incompleteData[dayIndex].fructose ?? [])
          ])
        })
      })
    })
    // Handle calculated nutrients
    A.forEach(dayData, (nutrient) => {
      nutrient.glycemic_load = 0
      nutrient.protein_energy = 0
      nutrient.fat_energy = 0
      nutrient.carbohydrates_energy = 0
      nutrient.protein_fat_exchangers = 0
      nutrient.simple_sugars_sum = 0
      if (
        'carbohydrates' in nutrient &&
        'glycemic_index' in nutrient &&
        nutrient.carbohydrates > 0
      ) {
        nutrient.glycemic_index = round(nutrient.glycemic_index / nutrient.carbohydrates, 0)
        nutrient.glycemic_load = round((nutrient.glycemic_index * nutrient.carbohydrates) / 100, 1)
      }
      if ('waste' in nutrient && 'calories' in nutrient && nutrient.calories > 0) {
        nutrient.waste = round((nutrient.waste / nutrient.calories) * 100, 1)
      }
      if ('protein' in nutrient && 'calories' in nutrient && nutrient.calories > 0) {
        nutrient.protein_energy = round(((nutrient.protein * 4) / nutrient.calories) * 100, 1)
      }
      if ('fat' in nutrient && 'calories' in nutrient && nutrient.calories > 0) {
        nutrient.fat_energy = round(((nutrient.fat * 9) / nutrient.calories) * 100, 1)
      }
      if (
        'carbohydrates' in nutrient &&
        'fiber' in nutrient &&
        'calories' in nutrient &&
        nutrient.calories > 0
      ) {
        nutrient.carbohydrates_energy = round(
          ((nutrient.carbohydrates * 4) / nutrient.calories) * 100 +
            ((nutrient.fiber * 2) / nutrient.calories) * 100,
          1
        )
      }
      if ('protein' in nutrient && 'fat' in nutrient) {
        nutrient.protein_fat_exchangers = round((nutrient.protein * 4 + nutrient.fat * 9) / 100, 1)
      }
      if (
        'sucrose' in nutrient &&
        'lactose' in nutrient &&
        'glucose' in nutrient &&
        'fructose' in nutrient
      ) {
        nutrient.simple_sugars_sum = round(
          nutrient.sucrose + nutrient.lactose + nutrient.glucose + nutrient.fructose,
          1
        )
      }
      if ('n_3' in nutrient && 'n_6' in nutrient) {
        const n3 = nutrient.n_3
        const n6 = nutrient.n_6
        if (n3 > 0) {
          nutrient.ratio_n_3_n_6 = `1 : ${round(n6 / n3, 1)}`
        } else {
          nutrient.ratio_n_3_n_6 = `0 : ${round(n6, 1)}`
        }
      }
      // if (has(nutrient, 'n_3')) {
      //   dayData[dayIndex].n_3 = round(dayData[dayIndex].n_3, 1)
      // }
      // if (has(nutrient, 'n_6')) {
      //   dayData[dayIndex].n_6 = round(dayData[dayIndex].n_6, 1)
      // }
    })

    return { dayData, incompleteData, incompleteDataInfo, allergensInfo }
  },
  // Multi-portions helpers
  hashDish(dishOrIngredient: StoreDietDish) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (dishOrIngredient.type === 'dish') {
      // Do not generate hashes for ingredients, we don't need them as there are no portions
      let hashString = dishOrIngredient.name.replace(/\s+/g, '')
      hashString += `-preparation-steps-${dishOrIngredient.preparation_steps.replace(/\s+/g, '')}`
      hashString += `-preparation-time-${dishOrIngredient.preparation_time}`
      hashString += `-size-${dishOrIngredient.size}`
      hashString += `-type-${dishOrIngredient.type}`
      hashString += `-max-portions-${dishOrIngredient.max_portions}`
      A.forEach(dishOrIngredient.dish_types, (dishType) => {
        hashString += `-dish-types-${dishType}`
      })
      A.forEach(dishOrIngredient.meal_types, (mealType) => {
        hashString += `-meal-types-${mealType}`
      })
      const ingredientsCopy = klona(dishOrIngredient.ingredients)

      ingredientsCopy.sort((a, b) => {
        const ingredientA = a.name.toLowerCase()
        const ingredientB = b.name.toLowerCase()
        if (ingredientA < ingredientB) {
          return -1
        }
        if (ingredientA > ingredientB) {
          return 1
        }
        return 0
      })
      A.forEach(ingredientsCopy, (ingredient) => {
        hashString += `-ingredient-${ingredient.ingredient_id}-${ingredient.name.replace(
          /\s+/g,
          ''
        )}-${ingredient.quantity}`
      })
      return cyrb53(hashString)
    }

    return undefined
  },
  getHashes(meals: StoreDietMeal[]) {
    const hashes: Hashesh = {}

    A.forEachWithIndex(DAYS_INDEX, (dayIndex) => {
      A.forEachWithIndex(meals, (mealIndex, meal) => {
        const dishesAndIngredients = meal.days[dayIndex]?.dishes_and_ingredients
        if (dishesAndIngredients) {
          A.forEachWithIndex(dishesAndIngredients, (dishOrIngredientIndex, dishOrIngredient) => {
            if (dishOrIngredient.type === 'dish') {
              const hash = this.hashDish(dishOrIngredient)
              if (hash) {
                if (!(hash in hashes)) {
                  hashes[hash] = []
                }
                hashes[hash]?.push({
                  name: dishOrIngredient.name,
                  maxPortions: dishOrIngredient.max_portions,
                  usedPortions: dishOrIngredient.used_portions,
                  isPublic: dishOrIngredient.is_public,
                  coordinates: {
                    mealIndex,
                    dayIndex,
                    dishOrIngredientIndex
                  }
                } as HashedDish)
              }
            }
          })
        }
      })
    })

    return hashes
  },
  getRelatedDishes(hashes: Hashesh) {
    const relatedDishes: Record<string, RelatedDish> = {}

    for (const [hash, hashedDishes] of Object.entries(hashes)) {
      const hashedDishesLength = hashedDishes.length
      let currentPortions = 0
      let coordinates: Coordinates[] = []
      const relatedDishesHash: RelatedDish = {
        unusedPortions: 0,
        name: hashedDishes[0]?.name ?? '',
        maxPortions: hashedDishes[0]?.maxPortions ?? 0,
        isPublic: hashedDishes[0]?.isPublic ?? false,
        coordinates: []
      }
      A.forEachWithIndex(hashedDishes, (hashedDishIndex, hashedDish) => {
        const nextCurrentPortions = currentPortions + hashedDish.usedPortions

        if (nextCurrentPortions < hashedDish.maxPortions) {
          // Check if subgroup have space
          currentPortions = nextCurrentPortions
          coordinates.push(hashedDish.coordinates)
          if (hashedDishIndex === hashedDishesLength - 1) {
            // If this is last item in group, add to subgroup
            relatedDishesHash.unusedPortions += hashedDish.maxPortions - currentPortions
            relatedDishesHash.coordinates.push(coordinates)
          }
        } else if (nextCurrentPortions === hashedDish.maxPortions) {
          // Check if subgroup is full
          coordinates.push(hashedDish.coordinates)
          relatedDishesHash.coordinates.push(coordinates)
          coordinates = []
          currentPortions = 0
        } else {
          // Else subgroup is overloaded
          relatedDishesHash.unusedPortions += hashedDish.maxPortions - currentPortions
          relatedDishesHash.coordinates.push(coordinates)
          coordinates = []
          coordinates.push(hashedDish.coordinates)
          currentPortions = hashedDish.usedPortions
          if (hashedDishIndex === hashedDishesLength - 1) {
            // If this is last item in group, add to subgroup
            relatedDishesHash.unusedPortions += hashedDish.maxPortions - currentPortions
            relatedDishesHash.coordinates.push(coordinates)
          }
        }
      })
      relatedDishes[hash] = relatedDishesHash
    }
    return relatedDishes
  },
  getRelatedCoordinates(
    relatedDishes: Record<string | number, RelatedDish> | undefined,
    meals: StoreDietMeal[] | undefined,
    mealIndex: number,
    dayIndex: number,
    dishOrIngredientIndex: number
  ) {
    const dishOrIngredient =
      meals?.[mealIndex]?.days[dayIndex]?.dishes_and_ingredients[dishOrIngredientIndex]
    if (dishOrIngredient && dishOrIngredient.type === 'dish' && dishOrIngredient.max_portions > 1) {
      const dishHash = this.hashDish(dishOrIngredient)
      if (G.isNotNullable(dishHash) && relatedDishes) {
        const related = relatedDishes[dishHash]
        if (!related) {
          return []
        }
        return related.coordinates.reduce<Coordinates[]>((final, group) => {
          if (
            group.some((dish) => {
              return (
                dish.mealIndex === mealIndex &&
                dish.dayIndex === dayIndex &&
                dish.dishOrIngredientIndex === dishOrIngredientIndex
              )
            })
          ) {
            final = group
          }
          return final
        }, [])
      }
      return []
    } else {
      return [{ mealIndex, dayIndex, dishOrIngredientIndex }]
    }
  },
  getDishAllUsedPortions(
    relatedDishes: Record<string | number, RelatedDish> | undefined,
    meals: StoreDietMeal[] | undefined,
    mealIndex: number,
    dayIndex: number,
    dishOrIngredientIndex: number
  ) {
    const coordinates = this.getRelatedCoordinates(
      relatedDishes,
      meals,
      mealIndex,
      dayIndex,
      dishOrIngredientIndex
    )

    return coordinates.reduce((prev, coordinate) => {
      const next =
        meals?.[coordinate.mealIndex]?.days[coordinate.dayIndex]?.dishes_and_ingredients[
          coordinate.dishOrIngredientIndex
        ]

      if (next && 'used_portions' in next) {
        return (prev += next.used_portions)
      }
      return prev
    }, 0)
  },
  // dishOrIngredient helpers
  getDishNutrient(
    dish: DietSearchDish | StoreDietDish | DishInFormType,
    nutrient: Nutrients,
    calculateQuantity?: boolean,
    lossesMultiplier = 1
  ) {
    // We could pass two different dishOrIngredient structures
    // 1. nutrients are inline with other params e.g. dishOrIngredient.protein
    // 2. nutrients are nested with nutrients dict e.g. dishOrIngredient.nutrients.protein
    let nutrientValue = 0
    let doiMultiplier = 1
    let doiCalories = 0
    let doiProtein = 0
    let doiFat = 0
    let doiCarbohydrates = 0
    let doiFiber = 0
    let doiSucrose = 0
    let doiLactose = 0
    let doiGlucose = 0
    let doiFructose = 0

    let ingredientMultiplier = 1
    if (calculateQuantity) {
      if ('used_portions' in dish) {
        // Used portions
        doiMultiplier = (lossesMultiplier / dish.max_portions) * dish.used_portions
      } else if ('max_portions' in dish) {
        // Single portion
        doiMultiplier = lossesMultiplier / dish.max_portions
      }
    } else {
      // All portions
      doiMultiplier = lossesMultiplier
    }

    // @ts-expect-error
    A.forEach(dish.ingredients, (ingredient) => {
      const nutrients =
        'nutrients' in ingredient
          ? F.coerce<Partial<NutrientsValues>>(ingredient.nutrients)
          : F.coerce<Partial<NutrientsValues>>(ingredient)
      ingredientMultiplier = round(ingredient.quantity * doiMultiplier, 1) / 100

      const sucrose = nutrients.sucrose ?? 0
      const lactose = nutrients.lactose ?? 0
      const glucose = nutrients.glucose ?? 0
      const fructose = nutrients.fructose ?? 0
      const calories = nutrients.calories ?? 0
      const protein = nutrients.protein ?? 0
      const fat = nutrients.fat ?? 0
      const fiber = nutrients.fiber ?? 0
      const carbohydrates = nutrients.carbohydrates ?? 0

      doiSucrose += round(sucrose * ingredientMultiplier, 1)
      doiLactose += round(lactose * ingredientMultiplier, 1)
      doiGlucose += round(glucose * ingredientMultiplier, 1)
      doiFructose += round(fructose * ingredientMultiplier, 1)

      doiCalories += round(calories * ingredientMultiplier, 1)
      doiProtein += round(protein * ingredientMultiplier, 1)
      doiFat += round(fat * ingredientMultiplier, 1)
      doiFiber += round(fiber * ingredientMultiplier, 1)
      doiCarbohydrates += round(carbohydrates * ingredientMultiplier, 1)
    })

    if (nutrient === 'glycemic_index' || nutrient === 'glycemic_load') {
      // @ts-expect-error
      A.forEach(dish.ingredients, (ingredient) => {
        const nutrients =
          'nutrients' in ingredient
            ? F.coerce<Partial<NutrientsValues>>(ingredient.nutrients)
            : F.coerce<Partial<NutrientsValues>>(ingredient)
        ingredientMultiplier = round(ingredient.quantity * doiMultiplier, 1) / 100
        const nutrientsGlycemicIndex = nutrients.glycemic_index ?? 0
        const nutrientCarbohydrates = nutrients.carbohydrates ?? 0
        const ingredientCarbohydrates = round(nutrientCarbohydrates * ingredientMultiplier, 1)
        if (doiCarbohydrates > 0) {
          nutrientValue += (ingredientCarbohydrates / doiCarbohydrates) * nutrientsGlycemicIndex
        }
      })
      nutrientValue = round(nutrientValue)
      if (nutrient === 'glycemic_load') nutrientValue = (nutrientValue * doiCarbohydrates) / 100
    } else if (nutrient === 'protein_fat_exchangers') {
      nutrientValue = (doiProtein * 4 + doiFat * 9) / 100
    } else if (nutrient === 'protein_energy' && doiCalories > 0) {
      nutrientValue = ((doiProtein * 4) / doiCalories) * 100
    } else if (nutrient === 'fat_energy' && doiCalories > 0) {
      nutrientValue = ((doiFat * 9) / doiCalories) * 100
    } else if (nutrient === 'carbohydrates_energy' && doiCalories > 0) {
      nutrientValue = ((doiCarbohydrates * 4 + doiFiber * 2) / doiCalories) * 100
    } else if (nutrient === 'waste') {
      let doiWaste = 0
      // @ts-expect-error
      A.forEach(dish.ingredients, (ingredient) => {
        const nutrients =
          'nutrients' in ingredient
            ? F.coerce<Partial<NutrientsValues>>(ingredient.nutrients)
            : F.coerce<Partial<NutrientsValues>>(ingredient)
        ingredientMultiplier = round(ingredient.quantity * doiMultiplier, 1) / 100
        const nutrientsWaste = nutrients.waste ?? 0
        const nutrientsCalories = nutrients.calories ?? 0
        const ingredientCalories = round(nutrientsCalories * ingredientMultiplier, 1)
        const ingredientWaste = (nutrientsWaste * ingredientCalories) / 100
        doiWaste += round(ingredientWaste, 1)
      })
      if (doiCalories > 0) nutrientValue = (doiWaste / doiCalories) * 100
    } else if (nutrient === 'simple_sugars_sum') {
      nutrientValue = doiSucrose + doiLactose + doiGlucose + doiFructose
    } else if (nutrient === 'ratio_n_3_n_6') {
      const { doiN3, doiN6 } = A.reduce(
        // @ts-expect-error
        dish.ingredients,
        { doiN3: 0, doiN6: 0 },
        (prev, ingredient) => {
          prev.doiN3 += this.getIngredientNutrient(ingredient, 'n_3', true, doiMultiplier, false)
          prev.doiN6 += this.getIngredientNutrient(ingredient, 'n_6', true, doiMultiplier, false)
          return prev
        }
      )

      if (doiN3 > 0) return `1 : ${round(doiN6 / doiN3, 1)}`
      return `0 : ${round(doiN6, 1)}`
    } else {
      // @ts-expect-error
      nutrientValue = A.reduce(dish.ingredients, 0, (prev, ingredient) => {
        return (prev += this.getIngredientNutrient(ingredient, nutrient, true, doiMultiplier))
      })
    }
    return round(nutrientValue, 1)
  },
  getIngredientNutrient<T extends Nutrients>(
    ingredient: DietSearchIngredient | DietSearchDishIngredient | DietIngredient,
    nutrient: T,
    calculateQuantity: boolean,
    lossesMultiplier = 1,
    rounded = true
  ): NutrientsValues[T] {
    // We could pass two different dishOrIngredient structures
    // 1. nutrients are inline with other params e.g. dishOrIngredient.protein
    // 2. nutrients are nested with nutrients dict e.g. dishOrIngredient.nutrients.protein
    let nutrientValue = 0
    let doiMultiplier = 1

    if (calculateQuantity) {
      const quantity = ingredient.quantity || 0
      doiMultiplier = round(quantity * lossesMultiplier, 1) / 100
    } else {
      doiMultiplier = lossesMultiplier
    }
    let nutrients: Partial<NutrientsValues>
    if ('nutrients' in ingredient) {
      nutrients = ingredient.nutrients
    } else {
      nutrients = ingredient as unknown as NutrientsValues
    }

    if (nutrient !== 'ratio_n_3_n_6') {
      nutrientValue = (nutrients[nutrient] ?? 0) as number
    }

    if (nutrient === 'glycemic_index') {
      nutrientValue = nutrients.glycemic_index ?? 0
    } else if (nutrient === 'glycemic_load') {
      const doiGlycemicIndex = nutrients.glycemic_index ?? 0
      const nutrientsCarbohydrates = nutrients.carbohydrates ?? 0
      const doiCarbohydrates = round(nutrientsCarbohydrates * doiMultiplier, 1)
      nutrientValue = (doiCarbohydrates * doiGlycemicIndex) / 100
    } else if (nutrient === 'protein_fat_exchangers') {
      const nutrientsProtein = nutrients.protein ?? 0
      const doiProtein = round(nutrientsProtein * doiMultiplier, 1)
      const nutrientsFat = nutrients.fat ?? 0
      const doiFat = round(nutrientsFat * doiMultiplier, 1)
      nutrientValue = (doiProtein * 4 + doiFat * 9) / 100
    } else if (nutrient === 'protein_energy') {
      const nutrientsCalories = nutrients.calories ?? 0
      const doiCalories = round(nutrientsCalories * doiMultiplier, 1)
      const nutrientsProtein = nutrients.protein ?? 0
      const doiProtein = round(nutrientsProtein * doiMultiplier, 1)
      if (doiCalories > 0) nutrientValue = ((doiProtein * 4) / doiCalories) * 100
    } else if (nutrient === 'fat_energy') {
      const nutrientsCalories = nutrients.calories ?? 0
      const doiCalories = round(nutrientsCalories * doiMultiplier, 1)
      const nutrientsFat = nutrients.fat ?? 0
      const doiFat = round(nutrientsFat * doiMultiplier, 1)
      if (doiCalories > 0) nutrientValue = ((doiFat * 9) / doiCalories) * 100
    } else if (nutrient === 'carbohydrates_energy') {
      const nutrientsCalories = nutrients.calories ?? 0
      const doiCalories = round(nutrientsCalories * doiMultiplier, 1)
      const nutrientsCarbohydrates = nutrients.carbohydrates ?? 0
      const doiCarbohydrates = round(nutrientsCarbohydrates * doiMultiplier, 1)
      const nutrientsFiber = nutrients.fiber ?? 0
      const doiFiber = round(nutrientsFiber * doiMultiplier, 1)
      if (doiCalories > 0)
        nutrientValue = ((doiCarbohydrates * 4 + doiFiber * 2) / doiCalories) * 100
    } else if (nutrient === 'waste') {
      nutrientValue = nutrients.waste ?? 0
    } else if (nutrient === 'simple_sugars_sum') {
      const nutrientsSucrose = nutrients.sucrose ?? 0
      const nutrientsLactose = nutrients.lactose ?? 0
      const nutrientsGlucose = nutrients.glucose ?? 0
      const nutrientsFructose = nutrients.fructose ?? 0
      const doiSucrose = round(nutrientsSucrose * doiMultiplier, 1)
      const doiLactose = round(nutrientsLactose * doiMultiplier, 1)
      const doiGlucose = round(nutrientsGlucose * doiMultiplier, 1)
      const doiFructose = round(nutrientsFructose * doiMultiplier, 1)
      nutrientValue = doiSucrose + doiLactose + doiGlucose + doiFructose
    } else if (nutrient === 'ratio_n_3_n_6') {
      const nutrientsN3 = nutrients.n_3 ?? 0
      const nutrientsN6 = nutrients.n_6 ?? 0
      const doiN3 = nutrientsN3 * doiMultiplier
      const doiN6 = nutrientsN6 * doiMultiplier
      if (doiN3 > 0) {
        return `1 : ${round(doiN6 / doiN3, 1)}` as NutrientsValues[T]
      }
      return `0 : ${round(doiN6, 1)}` as NutrientsValues[T]
    } else {
      nutrientValue = nutrientValue * doiMultiplier
    }

    return (rounded ? round(nutrientValue, 1) : nutrientValue) as NutrientsValues[T]
  },
  getDishOrIngredientAllergens(
    dishOrIngredient:
      | ScheduleDishOrIngredient
      | DietDishOrIngredient
      | DietSearchIngredient
      | DietSearchDishIngredient
      | DietSearchDish,
    allergens: AllergensMap,
    allergensPermission = true
  ): AllergensInfo {
    if (allergensPermission) {
      if ('ingredients' in dishOrIngredient) {
        return getAllergensFromIngredients(
          dishOrIngredient.ingredients.map((v) => v.ingredient_id),
          allergens
        )
      } else {
        if ('ingredient_id' in dishOrIngredient) {
          return getIngredientAllergens(dishOrIngredient.ingredient_id, allergens)
        } else if ('id' in dishOrIngredient) {
          return getIngredientAllergens(dishOrIngredient.id, allergens)
        }
      }
    }

    return {
      allergies: false,
      intolerances: false,
      like: false,
      notLike: false
    }
  },

  // Comments
  mealsEnergyDistribution(meals: StoreDietMeal[], mealIndex: number) {
    // WARNING This method accept state object and make changes directly to state
    if (A.isNotEmpty(meals)) {
      let counter = 0 // Loop counter
      // Set index
      let index = mealIndex
      index = index >= meals.length - 1 ? 0 : index + 1
      // Get sum and calculate difference to 100
      let left =
        meals.reduce((sum, meal) => {
          return sum + meal.energy_distribution
        }, 0) - 100

      while (left !== 0 && counter < 12) {
        // Counter to 12 because there could be max 12 meals PREVENTS BROWSER HANGS
        counter += 1
        let newEnergyDistribution = meals[index]?.energy_distribution ?? 0
        newEnergyDistribution = newEnergyDistribution - left
        // if new energy distribution is less than 0,
        // changing of energy distrbution will be put on next meal
        if (newEnergyDistribution < 0) {
          left = -1 * newEnergyDistribution
          newEnergyDistribution = 0
        } else {
          left = 0
        }
        // Update energy distribution
        const meal = meals[index]
        if (meal && G.isNumber(meal.energy_distribution)) {
          meal.energy_distribution = newEnergyDistribution
        }

        // Set index
        index = index >= meals.length - 1 ? 0 : index + 1
      }
    }
  },
  commentImageUpload(image: File) {
    return api.postForm<{ url: string; error?: string }>('/diets/comment/image/', {
      image
    })
  }
}
