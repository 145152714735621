<template>
  <BaseTooltip class="base-tooltip--top base-tooltip--center">
    <button
      class="button"
      :data-test-id="`BaseEditorButton-${componentName}`"
      :class="{ active: active, disabled: disabled }"
      @click="handleAction"
    >
      <i class="icon">{{ icon }}</i>
      <input
        v-if="isColor"
        ref="colorInput"
        class="color-input"
        type="color"
        :data-test-id="`BaseEditorColorInput-${componentName}`"
        @input="handleColorAction"
      >
      <input
        v-if="isImage"
        ref="imageInput"
        class="image-input"
        type="file"
        accept="image/png, image/jpeg"
        :data-test-id="`BaseEditorImageInput-${componentName}`"
        @change="handleFileAction"
      >
    </button>
    <template #tooltip>
      {{ tooltip }}
    </template>
  </BaseTooltip>
</template>

<script lang="ts" setup>
import { computed, ref, toRef } from 'vue'

import BaseTooltip from '@/components/BaseTooltip.vue'
import { dietsService } from '@/services/dietsService'
import { useGlobalStore } from '@/store/globalStore'

const props = defineProps({
  active: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    required: false
  },
  type: {
    type: String,
    required: false
  },
  tooltip: {
    type: String,
    required: true
  },
  componentName: {
    type: String,
    required: true
  }
})
const { createMessage } = useGlobalStore()
const emit = defineEmits(['action'])
const propType = toRef(props, 'type')
const colorInput = ref<HTMLInputElement>()
const imageInput = ref<HTMLInputElement>()
const isColor = computed(() => propType.value === 'color')
const isImage = computed(() => propType.value === 'image')

const handleAction = (event: Event) => {
  if (isColor.value) {
    colorInput.value?.click()
  } else if (isImage.value) {
    imageInput.value?.click()
  } else {
    emit('action', event)
  }
}
const handleColorAction = (event: Event) => {
  emit('action', event)
}
const handleFileAction = async (event: Event) => {
  const file = (event.target as HTMLInputElement).files?.[0]
  if (file) {
    const response = await dietsService.commentImageUpload(file)
    if (response.data.url) {
      emit('action', response.data.url)
    } else if (response.data.error) {
      createMessage({ title: response.data.error })
    } else {
      throw new Error('Wystąpił nieoczekiwany błąd podczas wgrywania pliku.')
    }
  }
}
</script>

<style scoped>
.button {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  position: relative;
  cursor: pointer;
}

.button:hover,
.button.active {
  background: #f7f7f7;
}

.button.disabled {
  background: transparent;
  pointer-events: none;
}

.button .icon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px; /* Preferred icon size */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  cursor: default;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;

  /* Support for Safari and Chrome. */
  text-rendering: optimizelegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  /* Fix for icon inline with text */
  display: inline-flex;
  vertical-align: top;
  color: #999;
}

.button:hover .icon {
  color: var(--color-primary-text);
}

.button.active .icon {
  color: var(--color-main-100);
}

.button.disabled .icon {
  color: #ccc;
}

.color-input {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.image-input {
  display: none;
}
</style>
