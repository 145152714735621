<template>
  <BaseModal
    :modal-class="'diet-share-modal'"
    @close="close"
    @confirm="onSubmit"
  >
    <template #title>
      Udostępnianie jadłospisu
    </template>
    <div>Podaj adres email dietetyka Kcalmar.pro, któremu chcesz udostępnić wybraną dietę.</div>
    <div>
      <BaseInput
        v-model.trim="emailField"
        class="share-diet-input"
        test-id="DietShareModalEmail"
        :class="{ 'base-input-error': !emailMeta.valid && emailMeta.dirty }"
        :force-focus="true"
        placeholder="Podaj adres email"
      />
      <div
        v-if="emailError"
        class="share-diet-error"
      >
        {{ emailError }}
      </div>
    </div>
    <template #actions>
      <StyledButton
        variant="outline"
        @click.once="close"
      >
        Anuluj
      </StyledButton>
      <StyledButton
        :disabled="!emailMeta.valid || sending"
        @click.once="onSubmit"
      >
        Udostępnij jadłospis
      </StyledButton>
      <BaseLoader
        v-if="sending"
        class="base-loader--size-12"
      />
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import type { DietType } from '@/store/types'
import type { PropType } from 'vue'

import { toTypedSchema } from '@vee-validate/zod'
import { useField, useForm } from 'vee-validate'
import { computed, ref } from 'vue'
import * as zod from 'zod'

import BaseInput from '@/components/BaseInput.vue'
import BaseLoader from '@/components/BaseLoader.vue'
import BaseModal from '@/components/BaseModal.vue'
import { StyledButton } from '@/components/StyledButton'
import { dietsService } from '@/services/dietsService'
import { useGlobalStore } from '@/store/globalStore'

const emit = defineEmits(['close'])
const props = defineProps({
  dietType: {
    type: String as PropType<DietType>,
    required: true
  },
  dietsIds: {
    type: Array as PropType<number[]>,
    required: true
  }
})

const globalStore = useGlobalStore()
const sending = ref(false)
const userEmail = computed(() => globalStore.user?.email ?? '')

const validationSchema = toTypedSchema(
  zod.object({
    email: zod
      .string()
      .min(1, 'To pole nie może być puste.')
      .max(250, 'To pole może mieć maksymalnie 250 znaków.')
      .email({ message: 'Nieprawidłowy format adresu email.' })
      .refine((value) => value !== userEmail.value, {
        message: 'Nie możesz udostępnić diety samemu sobie.'
      })
  })
)
const close = () => {
  emit('close')
}

const { handleSubmit, errors } = useForm({
  validationSchema
})
const { value: emailField, meta: emailMeta } = useField<string>('email')
const emailError = computed(() => errors.value.email)
const onSubmit = handleSubmit(async (values, { resetForm }) => {
  sending.value = true
  await dietsService.shareDiets(props.dietType, props.dietsIds, values.email)
  globalStore.createMessage({
    title:
      'Wysłano jadłospis dla podanego adresu email. W przypadku nieotrzymania diety, upewnij się ' +
      'czy podawany adres email jest prawidłowy.'
  })
  sending.value = false
  resetForm()
  close()
})
</script>

<style scoped>
.share-diet-input {
  width: 100%;
  margin-top: 24px;
}

.share-diet-error {
  padding: 5px;
  font-size: 10px;
  color: #f7606e;
}
</style>
