<template>
  <BaseModal
    type="form"
    @close="modalClose"
  >
    <template #title>
      Dodawanie jadłospisu użytkownika
    </template>
    <Form
      v-slot="{ meta, handleSubmit }"
      :validation-schema="validationSchema"
      :initial-values="initialValues"
      :on-submit="submit"
    >
      <Space
        :space="6"
        align="stretch"
        fill
      >
        <FormInput
          test-id="DashboardAddDietModalDietName"
          type="text"
          name="dietName"
          label="Wprowadź nazwę jadłospisu"
          placeholder="Wprowadź nazwę jadłospisu"
          :force-focus="true"
        />
        <FormSelect
          :options="groups"
          name="group"
          label="Wybierz grupę:"
          placeholder="Wybierz lub utwórz grupę dla swojego jadłospisu"
        />
        <Space :space="2">
          <T
            as="span"
            size="sm"
          >
            Wybierz format wyświetlania dni
          </T>
          <T
            as="span"
            size="xs"
          >
            Zmiana formatu wyświetlania dni wpłynie na wygląd wydruku PDF
          </T>
        </Space>
        <KcalmarRadio
          name="days_of_week"
          label="Dni tygodnia"
          description="Przykład: Poniedziałek, Wtorek, Środa..."
          :model-value="dietDisplayType === 'days_of_week'"
          @update:model-value="dietDisplayType = 'days_of_week'"
        />
        <KcalmarRadio
          name="numeric"
          label="Numeryczny"
          description="Przykład: Dzień 1, Dzień 2, Dzień 3..."
          :model-value="dietDisplayType === 'numeric'"
          @update:model-value="dietDisplayType = 'numeric'"
        />
        <Space
          align-x="end"
          direction="columns"
        >
          <StyledButton
            variant="outline"
            @click="modalClose"
          >
            Anuluj
          </StyledButton>
          <StyledButton
            :disabled="!meta.valid"
            @click="handleSubmit(submit)"
          >
            Dodaj jadłospis
          </StyledButton>
        </Space>
      </Space>
    </Form>
  </BaseModal>
</template>

<script lang="ts" setup>
import type { PartialDeep } from 'type-fest'
import type { SubmissionHandler } from 'vee-validate'

import { toTypedSchema } from '@vee-validate/zod'
import { Form } from 'vee-validate'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { G } from '@mobily/ts-belt'
import * as zod from 'zod'

import BaseModal from '@/components/BaseModal.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import { dashboardService } from '@/services/dashboardService'
import { useDashboardStore } from '@/store/dashboardStore'
import { useGlobalStore } from '@/store/globalStore'
import type { DietDisplayType } from '@/types/Diet'
import { KcalmarRadio } from '@/components/ui/KcalmarRadio'
import { T } from '@/components/ui/T'

const globalStore = useGlobalStore()
const dashboardStore = useDashboardStore()
const router = useRouter()
const formSubmited = ref(false)
const dietDisplayType = ref<DietDisplayType>('days_of_week')

const groups = computed(() =>
  dashboardStore.userGroups.map((item) => ({
    label: item.name,
    value: item.id
  }))
)
const emit = defineEmits(['close'])

const schema = zod.object({
  dietName: zod
    .string()
    .max(85, 'To pole może mieć maksymalnie 85 znaków.')
    .min(1, 'To pole jest wymagane'),
  group: zod.number({
    required_error: 'Wybierz opcję',
    invalid_type_error: 'Wybierz opcję'
  })
})
const validationSchema = toTypedSchema(schema)

const initialValues = {
  dietName: '',
  group: 0
}

const modalClose = () => {
  emit('close')
}

const submit: SubmissionHandler<PartialDeep<zod.input<typeof schema>>> = async (values) => {
  if (!formSubmited.value) {
    if (G.isNotNullable(dataLayer) && G.isArray(dataLayer)) {
      dataLayer.push({
        event: 'create_diet_modal_confirm',
        dayformat: dietDisplayType.value === 'numeric' ? 'numbers' : 'days'
      })
    }
    formSubmited.value = true
    try {
      const response = await dashboardService.addDiet({
        name: values.dietName ?? '',
        group: values.group === 0 ? null : (values.group ?? 0),
        dietDisplayType: dietDisplayType.value
      })

      void router.push({
        name: 'DietDetailWeek',
        params: { dietType: 'user', dietId: response.data.id }
      })
      globalStore.createMessage({ title: 'Dodano jadłospis' })
    } catch (err) {
      console.error(err)
    }
  }
}

onMounted(async () => {
  await dashboardStore.fetchUserGroups()
})
</script>
