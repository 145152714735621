import type { VariantPropsOf } from '@/utils/variants'
import type { PropType } from 'vue'

import { computed, useSlots } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import { Space } from '@/components/Space'
import { variants } from '@/utils/variants'

import './style.css'

const styles = variants({
  base: 'styled-button',
  variants: {
    variant: {
      primary: 'styled-button__variant--primary',
      secondary: 'styled-button__variant--secondary',
      tertiary: 'styled-button__variant--tertiary',
      outline: 'styled-button__variant--outline',
      light: 'styled-button__variant--light'
    },
    type: {
      default: '',
      warning: '',
      error: ''
    },
    width: {
      default: 'styled-button__width--default',
      wide: 'styled-button__width--wide',
      short: 'styled-button__width--short'
    },
    size: {
      small: 'styled-button__size--small',
      default: 'styled-button__size--default',
      big: 'styled-button__size--big',
      inline: 'styled-button__size--inline'
    },
    disabled: {
      true: 'styled-button--disabled'
    }
  },
  compoundVariants: [
    {
      variants: {
        variant: 'primary',
        type: 'default'
      },
      className: 'styled-button__compound--primary-default'
    },
    {
      variants: {
        variant: 'primary',
        type: 'warning'
      },
      className: 'styled-button__compound--primary-warning'
    },
    {
      variants: {
        variant: 'primary',
        type: 'error'
      },
      className: 'styled-button__compound--primary-error'
    },
    {
      variants: {
        variant: 'secondary',
        type: 'default'
      },
      className: 'styled-button__compound--secondary-default'
    },
    {
      variants: {
        variant: 'secondary',
        type: 'warning'
      },
      className: 'styled-button__compound--secondary-warning'
    },
    {
      variants: {
        variant: 'secondary',
        type: 'error'
      },
      className: 'styled-button__compound--secondary-error'
    },
    {
      variants: {
        variant: 'tertiary',
        type: 'default'
      },
      className: 'styled-button__compound--tertiary-default'
    },
    {
      variants: {
        variant: 'tertiary',
        type: 'warning'
      },
      className: 'styled-button__compound--tertiary-warning'
    },
    {
      variants: {
        variant: 'tertiary',
        type: 'error'
      },
      className: 'styled-button__compound--tertiary-error'
    },
    {
      variants: {
        variant: 'outline',
        type: 'default'
      },
      className: 'styled-button__compound--outline-default'
    },
    {
      variants: {
        variant: 'outline',
        type: 'warning'
      },
      className: 'styled-button__compound--outline-warning'
    },
    {
      variants: {
        variant: 'outline',
        type: 'error'
      },
      className: 'styled-button__compound--outline-error'
    },
    {
      variants: {
        variant: 'light',
        type: 'default'
      },
      className: 'styled-button__compound--light-default'
    },
    {
      variants: {
        variant: 'light',
        type: 'warning'
      },
      className: 'styled-button__compound--light-warning'
    },
    {
      variants: {
        variant: 'light',
        type: 'error'
      },
      className: 'styled-button__compound--light-error'
    }
  ],
  defaultVariants: {
    variant: 'primary',
    type: 'default',
    width: 'default',
    size: 'default'
  }
})

type Props = VariantPropsOf<typeof styles> & {
  leftIcon?: IconName
  leftIconSize?: IconSize
  rightIcon?: IconName
  rightIconSize?: IconSize
  componentName?: string
}

export const StyledButton = (props: Props) => {
  const slots = useSlots()
  const classes = computed(() => {
    return styles({
      type: props.type,
      variant: props.variant,
      disabled: props.disabled,
      width: props.width,
      size: props.size
    })
  })

  return (
    <button class={classes.value} disabled={props.disabled} data-testId={props.componentName}>
      <Space direction="columns" alignY="center" space={4}>
        {props.leftIcon && <BaseIcon size={props.leftIconSize ?? '16'} name={props.leftIcon} />}
        {slots.default?.()}
        {props.rightIcon && <BaseIcon size={props.rightIconSize ?? '16'} name={props.rightIcon} />}
      </Space>
    </button>
  )
}

StyledButton.props = {
  type: String as PropType<Props['type']>,
  variant: String as PropType<Props['variant']>,
  width: String as PropType<Props['width']>,
  size: String as PropType<Props['size']>,
  disabled: Boolean,
  leftIcon: String as PropType<Props['leftIcon']>,
  leftIconSize: String as PropType<Props['leftIcon']>,
  rightIcon: String as PropType<Props['rightIcon']>,
  rightIconSize: String as PropType<Props['rightIcon']>,
  componentName: String
}
