<template>
  <div
    v-cloak
    class="diet-detail"
  >
    <template v-if="isMounted && (dietType === 'patient' || dietSaveStatus !== 'initialized')">
      <TeleportComponent to="#app-content-header-portal">
        <div class="diet-detail-change-week-save">
          <div
            v-if="dietType === 'patient'"
            class="diet-detail-change-week"
          >
            <BaseTooltip class="base-tooltip--bottom base-tooltip--center">
              <template #tooltip>
                Poprzedni tydzień
              </template>
              <IconButton
                name="keyboard_arrow_left"
                type="close"
                @click="goToPrevDiet"
              />
            </BaseTooltip>
            <span class="dates">{{ changeWeekStartDate }} / {{ changeWeekEndDate }}</span>
            <BaseTooltip class="base-tooltip--bottom base-tooltip--center">
              <template #tooltip>
                Następny tydzień
              </template>
              <IconButton
                name="keyboard_arrow_right"
                type="close"
                @click="goToNextDiet"
              />
            </BaseTooltip>
          </div>
          <SavingStatus
            :status="dietSaveStatus"
            type="diet"
          />
        </div>
      </TeleportComponent>
    </template>
    <div class="info">
      <BaseInfo
        v-if="showAllergens"
        @close="updateAllergensClosedActive(true)"
      >
        <template #icon>
          <BaseIcon
            size="20"
            type="info"
            name="warning"
          />
        </template>
        <div class="allergy-text">
          Jadłospis zawiera produkt, na który Twój pacjent ma alergię, nietolerancję lub nie lubi
          produktu którego użyłeś
        </div>
        <div class="allergy-info">
          <div class="allergy-info__element">
            <div class="allergy-info__square notlike" />
            nielubiane
          </div>
          <div class="allergy-info__element">
            <div class="allergy-info__square like" />
            lubiane
          </div>
          <div class="allergy-info__element">
            <div class="allergy-info__square allergy" />
            alergia
          </div>
          <div class="allergy-info__element">
            <div class="allergy-info__square intolerance" />
            nietolerancja
          </div>
        </div>
      </BaseInfo>
      <BaseInfo
        v-if="showIncompleteData"
        @close="handleIncompleteDataInfoClosed(true)"
      >
        <template #icon>
          <BaseIcon
            size="20"
            type="info"
            name="info"
          />
        </template>
        Twój jadłospis zawiera produkty z niepełnymi danymi odnośnie wartości odżywczych
      </BaseInfo>
      <BaseInfo
        v-if="showLosses"
        @close="handleLossesClosed(true)"
      >
        <template #icon>
          <BaseIcon
            size="20"
            name="delete_sweep"
          />
        </template>
        W jadłospisie uwzględniono straty talerzowe (sprawdź w zakładce założenia diety)
      </BaseInfo>
    </div>
    <div class="nav-actions">
      <div class="nav">
        <router-link
          class="nav-element"
          :to="{
            name: 'DietDetailWeek',
            params: {
              dietType: dietType,
              dietId: route.params.dietId
            }
          }"
        >
          Grafik
        </router-link>
        <router-link
          class="nav-element"
          :to="{
            name: 'DietDetailShoppingList',
            params: {
              dietType: dietType,
              dietId: route.params.dietId
            }
          }"
        >
          Lista zakupów
        </router-link>
        <router-link
          class="nav-element"
          :to="{
            name: 'DietDetailComments',
            params: {
              dietType: dietType,
              dietId: route.params.dietId
            }
          }"
        >
          Komentarze
        </router-link>
        <router-link
          v-if="dietType !== 'system'"
          class="nav-element"
          :to="{
            name: 'DietDetailSettings',
            params: {
              dietType: dietType,
              dietId: route.params.dietId
            }
          }"
        >
          Opis i właściwości
        </router-link>
        <router-link
          v-if="dietType !== 'system'"
          class="nav-element"
          :to="{
            name: 'DietDetailAssumptions',
            params: {
              dietType: dietType,
              dietId: route.params.dietId
            }
          }"
        >
          Założenia diety
        </router-link>
      </div>
      <div class="actions">
        <!-- Undo -->
        <BaseTooltip
          v-if="undoRedoEnabled"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            :disabled="undoHistory.length <= 0"
            name="undo"
            @click="undoHandler"
          />
          <template #tooltip>
            Cofnij akcję (maks. 5)
          </template>
        </BaseTooltip>
        <!-- Redo -->
        <BaseTooltip
          v-if="undoRedoEnabled"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            :disabled="redoHistory.length <= 0"
            name="redo"
            @click="redoHandler"
          />
          <template #tooltip>
            Ponów akcję (maks. 5)
          </template>
        </BaseTooltip>
        <div
          v-if="undoRedoEnabled"
          class="actions-separator"
        />
        <BaseTooltip
          v-if="canChangeDietType"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            ref="driverStep"
            name="calendar_month"
            @click="showChangeDietTypeModal"
          />
          <template #tooltip>
            Zmień sposób wyświetlania dni
          </template>
        </BaseTooltip>
        <!-- Assign to leader -->
        <BaseTooltip
          v-if="canAssignDietToLeader"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="school"
            @click="showAssignToLeaderModal"
          />
          <template #tooltip>
            Przypisz jadłospis do Prowadzącego
          </template>
        </BaseTooltip>
        <DietAssignToLeaderModal
          v-if="assignToLeaderModal && canAssignDietToLeader"
          :diet-type="dietType"
          :diets-ids="[dietId]"
          @close="hideAssignToLeaderModal"
        />
        <!-- Assign to patient -->
        <BaseTooltip
          v-if="dietType !== 'university'"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="person_add"
            @click="showAssignToPatientModal"
          />
          <template #tooltip>
            Przypisz jadłospis do pacjenta
          </template>
        </BaseTooltip>
        <DietAssignToPatientModal
          v-if="assignToPatientModal"
          :diet-type="dietType"
          :diet-id="dietId"
          @close="hideAssignToPatientModal"
          @send-system-diets-list-to-file="sendSystemDietsListToFile"
        />
        <!-- Repeated dishes -->
        <BaseTooltip
          v-if="canRepeatedDishesAndIngredients"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            :type="repeatedDishesAndIngredientsActive ? 'warning' : 'default'"
            name="assignment_turned_in"
            @click="repeatedDishesHandler"
          />
          <template #tooltip>
            Włącz wyświetlanie potraw powtórzonych w innych jadłospisach tego pacjenta
          </template>
        </BaseTooltip>
        <!-- Copy diet -->
        <BaseLoader
          v-show="copyingDiet"
          class="base-loader--size-20 copy-diet-loader"
        />
        <BaseTooltip
          v-if="canCopyDiet"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="content_copy"
            @click="copyDietHandler"
          />
          <template #tooltip>
            Skopiuj jadłospis do schowka
          </template>
        </BaseTooltip>
        <!-- Paste diet -->
        <BaseTooltip
          v-else-if="canPasteDiet"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="content_paste"
            @click="showPasteDietModal"
          />
          <template #tooltip>
            Wklej jadłospis ze schowka
          </template>
        </BaseTooltip>
        <BaseModal
          v-if="pasteDietModal"
          @close="hidePasteDietModal"
        >
          <template #title>
            Czy na pewno chcesz wkleić skopiowany jadłospis?
          </template>
          <div class="paste-diet-modal__text">
            Wklejenie jadłospisu usunie aktualnie dodane potrawy.
          </div>
          <div
            v-if="currentMealsCount !== copiedMealsCount"
            class="meals-count-info"
          >
            <strong>Liczba posiłków w obecnym jadłospisie: {{ currentMealsCount }}</strong>
            <strong>Liczba posiłków w skopiowanym jadłospisie: {{ copiedMealsCount }}</strong>
          </div>
          <div class="paste-diet-modal__scale-form">
            <div class="paste-diet-modal__text">
              Podaj nową kaloryczność jeżeli chcesz przeskalować jadłospis:
            </div>
            <div class="input-container">
              <BaseInputNumber
                v-if="hasPerm('accounts.diet_copy_with_rescale')"
                v-model="pasteDietModalCalories"
                test-id="DietDetail__PasteDietModalCalories"
                class="paste-diet-modal__input"
                :min="0"
                :max="9999"
                :precision="0"
                @input-status="pasteDietModalUpdateStatus = $event"
              />
              <div
                v-else
                class="input-dummy"
                @click="showAppUpgradePlanModal"
              >
                {{ pasteDietModalCalories }}
              </div>
            </div>
          </div>
          <template #actions>
            <StyledButton
              class="paste-diet-modal-clear-storage-button"
              variant="outline"
              @click.once="pasteDietModalClearStorage"
            >
              Wyczyść schowek
            </StyledButton>
            <StyledButton
              variant="outline"
              @click.once="hidePasteDietModal"
            >
              Anuluj
            </StyledButton>
            <StyledButton
              :disabled="pasteDietModalUpdateStatus === 'inprogress'"
              @click.once="pasteDietModalConfirm"
            >
              Wklej
            </StyledButton>
          </template>
        </BaseModal>
        <BaseTooltip
          v-if="dietType === 'patient'"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            variant="outline"
            icon-variant="outline"
            icon-size="24"
            :name="isDietVisible ? 'visibility' : 'visibility_off'"
            :type="isDietVisible ? 'default' : 'disabled'"
            @click="isDietVisibleHandler"
          />

          <template
            v-if="isDietVisible"
            #tooltip
          >
            Jadłospis widoczny dla pacjenta
          </template>
          <template
            v-else
            #tooltip
          >
            Jadłospis niewidoczny dla pacjenta
          </template>
        </BaseTooltip>

        <!-- Print diet -->
        <BaseTooltip class="base-tooltip--top base-tooltip--right">
          <IconButton
            name="print"
            @click="printDietModal = true"
          />
          <template #tooltip>
            Drukuj jadłospis
          </template>
        </BaseTooltip>
        <PrintDietModal
          v-if="printDietModal"
          :diet-type="dietType"
          :diet-id="dietId"
          @close="printDietModal = false"
        />
        <!-- Share diet -->
        <BaseTooltip
          v-if="canShareDiet"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="share"
            @click="showShareDietModal"
          />
          <template #tooltip>
            Udostępnij jadłospis innemu użytkownikowi
          </template>
        </BaseTooltip>
        <DietShareModal
          v-if="shareDietModal"
          :diet-type="dietType"
          :diets-ids="[dietId]"
          @close="hideShareDietModal"
        />
        <!-- Delete diet -->
        <BaseTooltip
          v-if="canDeleteDiet"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="delete"
            type="destructive"
            variant="outline"
            @click="showDeleteDietModal"
          />
          <template #tooltip>
            Wyczyść jadłospis
          </template>
        </BaseTooltip>
        <BaseModal
          v-if="deleteDietModal"
          @close="hideDeleteDietModal"
        >
          <template #title>
            Czy na pewno chcesz wyczyścić cały jadłospis?
          </template>
          Wyczyszczenie całego grafiku usunie aktualnie dodane potrawy, produkty i komentarze z
          całego jadłospisu.
          <template #actions>
            <StyledButton
              variant="outline"
              @click.once="hideDeleteDietModal()"
            >
              Anuluj
            </StyledButton>
            <StyledButton @click.once="deleteDietModalConfirm">
              Wyczyść
            </StyledButton>
          </template>
        </BaseModal>
        <ChangeDietTypeModal
          v-if="isChangeDietTypeModalOpen"
          @close="isChangeDietTypeModalOpen = false"
        />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import type { DietType } from '@/store/types'
import type { Nutrients } from '@/utils/nutrients'
import type { AxiosError } from 'axios'
import type { DietitianPatient, StoreDiet } from '@/types/Diet'

import { G } from '@mobily/ts-belt'
import { useMounted } from '@vueuse/core'
import { addDays, format, parse, subDays } from 'date-fns'
import { storeToRefs } from 'pinia'

import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  Teleport as TeleportComponent,
  watch
} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseInfo from '@/components/BaseInfo.vue'
import BaseInputNumber from '@/components/BaseInputNumber.vue'
import BaseLoader from '@/components/BaseLoader.vue'
import BaseModal from '@/components/BaseModal.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import DietAssignToLeaderModal from '@/components/diets/DietAssignToLeaderModal.vue'
import DietAssignToPatientModal from '@/components/diets/DietAssignToPatientModal.vue'
import ChangeDietTypeModal from '@/pages/diets/components/ChangeDietTypeModal/ChangeDietTypeModal.vue'
import PrintDietModal from '@/pages/diets/components/PrintDietModal/PrintDietModal.vue'
import DietShareModal from '@/components/diets/DietShareModal.vue'
import { IconButton } from '@/components/IconButton'
import SavingStatus from '@/components/SavingStatus.vue'
import { StyledButton } from '@/components/StyledButton'
import { DEFAULT_NUTRIENTS } from '@/const'
import eventBus from '@/eventBus'
import { useSearchDishesAndIngredients } from '@/hooks/useSearchDishesAndIngredients'
import { dietsService } from '@/services/dietsService'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import { reportError } from '@/utils/reportError'

import { DietDetailInject } from './provider'
import { decompressFromUTF16 } from 'lz-string'
import { useDriver } from '@/plugins/driverJS'

const globalStore = useGlobalStore()
const dietStore = useDietsStore()
const route = useRoute()
const router = useRouter()
const isMounted = useMounted()
const copiedMealsCount = ref(0)
const driverStep = ref<HTMLElement | null>(null)

const { hasPerm, showAppUpgradePlanModal, updateUserFlag } = globalStore
const { user } = storeToRefs(globalStore)
const driver = useDriver()

const {
  undo,
  redo,
  resetDietsStore,
  updateDiet,
  copyDiet,
  pasteDiet,
  deleteDiet,
  deleteCopiedDiet,
  updateRepeatedDishesAndIngredients,
  toggleRepeatedDishesAndIngredientsActive,
  toggleIsDietVisible,
  updateLossesClosedActive,
  updateAllergensClosedActive,
  updateIncompleteDataInfoClosedActive
} = dietStore

const {
  diet,
  dietSaveStatus,
  incompleteDataInfo,
  incompleteDataInfoClosed,
  copiedDietStatus,
  copiedDietCalories,
  repeatedDishesAndIngredientsActive,
  undoHistory,
  redoHistory,
  allergensInfo,
  lossesClosed,
  allergensClosed,
  isDietVisible
} = storeToRefs(dietStore)

const dietId = ref(Number(route.params.dietId))
const dietType = ref(route.params.dietType as DietType)
const assignToLeaderModal = ref(false)
const assignToPatientModal = ref(false)
const pasteDietModal = ref(false)
const printDietModal = ref(false)
const shareDietModal = ref(false)
const deleteDietModal = ref(false)
const copyingDiet = ref(false)
const pasteDietModalCalories = ref(0)
const pasteDietModalUpdateStatus = ref('finished')

const isChangeDietTypeModalOpen = ref(false)

const nutrientValue = ref<'choosen' | Nutrients>('choosen')
const nutrients = computed<Nutrients[]>(() => {
  if (diet.value && hasPerm('accounts.diet_microelements')) {
    if (nutrientValue.value === 'choosen') {
      return diet.value.data.choosen_nutrients
    }
  }
  return DEFAULT_NUTRIENTS
})
const {
  data: searchDishesAndIngredients,
  isPending: searchDishesAndIngredientsLoading,
  status: searchDishesAndIngredientsStatus,
  refetch: refetchSearchDishesAndIngredients
} = useSearchDishesAndIngredients(nutrients)

provide(DietDetailInject, {
  searchDishesAndIngredients,
  searchDishesAndIngredientsLoading,
  searchDishesAndIngredientsStatus,
  refetchSearchDishesAndIngredients,
  nutrientValue,
  nutrients
})

const undoRedoEnabled = computed(() => {
  if (route.name) {
    return (
      ['DietDetailWeek', 'DietDetailDay'].includes(route.name.toString()) &&
      dietType.value !== 'system'
    )
  }
  return false
})

const currentMealsCount = computed(() => {
  return diet.value?.data.meals.length ?? 0
})

const isRodoAccepted = computed(() => {
  return user?.value?.is_rodo_accepted ?? false
})
const canAssignDietToLeader = computed(() => {
  return hasPerm('accounts.diet_assign_to_leader') && ['user', 'patient'].includes(dietType.value)
})

const canRepeatedDishesAndIngredients = computed(() => {
  return dietType.value === 'patient'
})
const canCopyDiet = computed(() => {
  return (
    (['system', 'nfz'].includes(dietType.value) || !copiedDietStatus.value) && !copyingDiet.value
  )
})
const canPasteDiet = computed(() => {
  return ['user', 'patient', 'university'].includes(dietType.value) && copiedDietStatus.value
})
const canShareDiet = computed(() => {
  return ['user', 'patient', 'university'].includes(dietType.value)
})
const canDeleteDiet = computed(() => {
  return ['user', 'patient'].includes(dietType.value) && route.name === 'DietDetailWeek'
})
const canChangeDietType = computed(() => {
  return ['user', 'patient', 'university'].includes(dietType.value)
})

const showLosses = computed(() => {
  const inevitableLossesActive = diet.value?.data.inevitable_losses.active
  return inevitableLossesActive && !lossesClosed.value
})
const showAllergens = computed(() => {
  return allergensInfo.value && !allergensClosed.value && hasPerm('accounts.patient_allergens')
})
const showIncompleteData = computed(() => {
  return incompleteDataInfo.value && !incompleteDataInfoClosed.value
})
const dietName = computed(() => {
  return diet.value?.data.name ?? 'jadłospis'
})
const userEmail = computed(() => {
  return user?.value?.email ?? ''
})

const dietStartDate = computed(() => {
  if (diet.value?.data.start_date.includes('-')) {
    return parse(diet.value.data.start_date, 'dd-MM-yyyy', new Date())
  }
  return null
})
const changeWeekStartDate = computed(() => {
  if (dietStartDate.value) {
    return format(dietStartDate.value, 'dd-MM-yyy')
  }
  return ''
})
const changeWeekEndDate = computed(() => {
  if (dietStartDate.value) {
    return format(addDays(dietStartDate.value, 6), 'dd-MM-yyyy')
  }
  return ''
})

const onNextClick = async () => {
  driver.moveNext()
  await updateUserFlag('is_creator_tour_finished')
}

const handleLossesClosed = (active: boolean) => {
  updateLossesClosedActive(active)
}

const handleIncompleteDataInfoClosed = (active: boolean) => {
  updateIncompleteDataInfoClosedActive(active)
}
// Undo
const undoHandler = () => {
  if (hasPerm('accounts.diet_undo_redo')) {
    undo()
    eventBus.emit('undoOrRedo')
  } else {
    showAppUpgradePlanModal()
  }
}
// Redo
const redoHandler = () => {
  if (hasPerm('accounts.diet_undo_redo')) {
    redo()
    eventBus.emit('undoOrRedo')
  } else {
    showAppUpgradePlanModal()
  }
}
// Assign to leader
const showChangeDietTypeModal = async () => {
  if (G.isNotNullable(dataLayer) && G.isArray(dataLayer)) {
    dataLayer.push({
      event: 'weekview_tools_dayformat'
    })
  }
  if (driver.isActive()) {
    await onNextClick()
  }
  isChangeDietTypeModalOpen.value = true
}
const showAssignToLeaderModal = () => {
  assignToLeaderModal.value = true
}
const hideAssignToLeaderModal = () => {
  assignToLeaderModal.value = false
}
// Assign to patient
const showAssignToPatientModal = () => {
  if (isRodoAccepted.value) {
    assignToPatientModal.value = true
  } else {
    location.href = import.meta.env.VUE_APP_HOST + '/dietetyk/account/agreements'
  }
}
const hideAssignToPatientModal = () => {
  assignToPatientModal.value = false
}
// Repeated dishes
const repeatedDishesHandler = () => {
  if (hasPerm('accounts.diet_show_repeated_dish')) {
    toggleRepeatedDishesAndIngredientsActive()
  } else {
    showAppUpgradePlanModal()
  }
}
const isDietVisibleHandler = async () => {
  await toggleIsDietVisible(!isDietVisible.value)
}
const sendSystemDietsListToFile = async () => {
  const payload = {
    type: 'system-diets',
    user: userEmail.value,
    name: dietName.value
  }
  try {
    await dietsService.saveCopiedSystemDietInFile([payload])
  } catch (err) {
    reportError(err, 'Error while saving copied system diet in file', {
      payload
    })
  }
}
// Copy diet
const copyDietHandler = async () => {
  copyingDiet.value = true
  // TODO FIX
  setTimeout(() => {
    copyDiet()
    copyingDiet.value = false
  }, 10)
  if (dietType.value === 'system') {
    await sendSystemDietsListToFile()
  }
}
// Paste diet
const showPasteDietModal = () => {
  pasteDietModalCalories.value = copiedDietCalories.value
  pasteDietModal.value = true
  try {
    const copiedDietFromStorage = localStorage.getItem('copiedDiet') ?? ''
    const copiedDiet = JSON.parse(decompressFromUTF16(copiedDietFromStorage)) as unknown
    if (copiedDiet) {
      copiedMealsCount.value = (copiedDiet as StoreDiet).data.meals.length
    }
  } catch (error) {
    reportError(error, 'Error while parsing copied diet from storage')
    copiedMealsCount.value = 0
  }
}
const hidePasteDietModal = () => {
  pasteDietModal.value = false
}
const pasteDietModalConfirm = () => {
  pasteDiet(pasteDietModalCalories.value)
  hidePasteDietModal()
}
const pasteDietModalClearStorage = () => {
  deleteCopiedDiet()
  hidePasteDietModal()
}

// Share diet
const showShareDietModal = () => {
  if (hasPerm('accounts.diet_share')) {
    shareDietModal.value = true
  } else {
    showAppUpgradePlanModal()
  }
}
const hideShareDietModal = () => {
  shareDietModal.value = false
}
// Delete diet
const showDeleteDietModal = () => {
  deleteDietModal.value = true
}
const hideDeleteDietModal = () => {
  deleteDietModal.value = false
}
const deleteDietModalConfirm = () => {
  deleteDiet()
  hideDeleteDietModal()
}

const goToDiet = (date: Date, event: MouseEvent) => {
  if (diet.value && 'dietitian_patient' in diet.value) {
    const dietitianPatientId = (diet.value.dietitian_patient as DietitianPatient).id
    const href = `${import.meta.env.VUE_APP_NEXT_PREV_DIET_URL}${dietitianPatientId}/${format(
      date,
      'yyyy/M/d'
    )}/`
    if (event.button === 2) {
      // Middle click
      window.open(href, '_blank')
    } else {
      // Normal click
      location.href = href
    }
  }
}
const goToNextDiet = (event: MouseEvent) => {
  if (dietStartDate.value) {
    const date = addDays(dietStartDate.value, 7)
    goToDiet(date, event)
  }
}
const goToPrevDiet = (event: MouseEvent) => {
  if (dietStartDate.value) {
    const date = subDays(dietStartDate.value, 7)
    goToDiet(date, event)
  }
}
const fetchData = async (resetState = false) => {
  try {
    const res = await dietsService.fetchDiet(
      dietType.value,
      route.params.dietId as unknown as number
    )

    updateDiet({
      diet: res.data,
      dietType: dietType.value,
      resetState
    })
    if (dietType.value === 'patient') {
      const response = await dietsService.fetchPatientDietRepeatedDishes(dietId.value)
      updateRepeatedDishesAndIngredients(response.data)
    }
  } catch (error) {
    reportError(error, 'Error while fetching data', {
      type: dietType.value,
      id: route.params.dietId
    })
    if ((error as AxiosError).response?.status === 404) {
      void router.push({ name: 'PageNotFound' })
    }
  }
}

onBeforeMount(async () => {
  await fetchData()
})

onBeforeUnmount(() => {
  resetDietsStore()
  driver.destroy()
})

onMounted(() => {
  driver.setConfig({
    ...driver.getConfig(),
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onNextClick
  })

  if (driverStep.value) {
    driver.setSteps([
      {
        element: driverStep.value,
        popover: {
          showButtons: ['next'],
          title: '✨ Nowa opcja formatowania dni',
          description: 'Teraz możesz dostosować format wyświetlania dni na grafiku i wydruku PDF'
        }
      }
    ])
  }
  setTimeout(() => {
    if (user?.value?.user_flags.includes('is_creator_tour_finished')) {
      driver.drive()
    }
  }, 500)
})

watch(
  () => route.params,
  (newParams, oldParams) => {
    if (G.isNotNullable(newParams.dietId) && G.isNotNullable(newParams.dietType)) {
      if (newParams.dietId !== oldParams.dietId && newParams.dietType !== oldParams.dietType) {
        dietId.value = Number(newParams.dietId)
        dietType.value = newParams.dietType as DietType
        void fetchData(true)
      }
    }
  }
)
</script>

<style scoped>
.align-end {
  align-self: flex-end;
}

.diet-detail {
  max-width: 100%;
}

.diet-detail-change-week-save {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  min-width: 300px;
}

.diet-detail-change-week {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.diet-detail-change-week .dates {
  font-size: 12px;
  min-width: 130px;
  white-space: nowrap;
}

.diet-detail-change-week__button.base-button .base-icon {
  color: var(--color-primary-text);
}



.nav-actions {
  display: flex;
  flex-direction: row;
}

.nav {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.nav-element {
  padding: 10px 8px;
  font-family: Montserrat-Medium;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  border-bottom: 4px solid transparent;
  white-space: nowrap;
  color: var(--color-tertiary-text);
  margin-right: 14px;
}

.nav-element:hover {
  color: var(--color-primary-text);
}

.nav-element.router-link-active {
  border-bottom: 4px solid var(--color-main-100);
  color: var(--color-primary-text);
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2px;
}

.actions-separator {
  width: 2px;
  height: 18px;
  background: var(--color-tertiary-text);
  margin: 0 10px;
}

.actions__repeated-button.active:hover {
  background: var(--color-warning-30);
}

.actions__repeated-button.active .base-icon {
  color: var(--color-warning-100);
}

.actions__repeated-button.active:hover .base-icon {
  color: #e5aa1d;
}

.allergy-text {
  flex-grow: 1;
  align-self: center;
}

.allergy-info {
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
}

.allergy-info__square {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  line-height: 12px;
}

.allergy-info__square.notlike {
  background: var(--color-warning-60);
}

.allergy-info__square.like {
  background: var(--color-success-60);
}

.allergy-info__square.allergy {
  background: #faa0a8;
}

.allergy-info__square.intolerance {
  background: #a0a8fa;
}

.paste-diet-modal__scale-form {
  display: flex;
  flex-direction: row;
}

.meals-count-info {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.paste-diet-modal__text {
  line-height: 26px;
}

.paste-diet-modal__input {
  width: 60px;
  text-align: center;
}

.input-dummy {
  width: 60px;
  text-align: center;
  height: 22px;
  padding-top: 3px;
  border-bottom: 1px solid var(--color-tertiary-text);
  color: rgb(166 166 166);
}

.input-dummy:hover {
  border-bottom: 1px solid var(--color-main-100);
}

.paste-diet-modal-clear-storage-button {
  margin-right: auto;
}

.copy-diet-loader {
  margin: 4px;
}

.share-diet-input {
  width: 100%;
  margin-top: 24px;
}

.share-diet-errors {
  padding: 5px;
  color: var(--color-destructive-100);
}

.share-diet-error {
  font-size: 10px;
}

.base-button--primary.disabled {
  pointer-events: none;
  background-color: #f5f5f5;
  color: #fff;
  border: none;
}
</style>
