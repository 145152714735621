<template>
  <div class="nav">
    <router-link
      v-if="dragActive"
      class="nav__element nav__drop"
      :class="{
        'nav__element--active': elementIsActive('user'),
        'nav__drop--hover': dropHover
      }"
      :to="{ name: 'DietListUser' }"
      @drop="emitDrop"
      @dragenter.prevent.stop="activateDropHover"
      @dragover.prevent.stop
      @dragleave.prevent.stop
    >
      <div class="nav__name">
        <div>Twoje jadłospisy</div>
        <div class="nav__drop-name">
          Przeciągnij tutaj
        </div>
      </div>
      <BaseBadge>{{ getCounter('user') }}</BaseBadge>
    </router-link>
    <router-link
      v-else
      :to="{ name: 'DietListUser' }"
      class="nav__element"
      :class="{ 'nav__element--active': elementIsActive('user') }"
    >
      <div class="nav__name">
        Twoje jadłospisy
      </div>
      <BaseBadge>{{ getCounter('user') }}</BaseBadge>
    </router-link>
    <router-link
      :to="{ name: 'DietListSystem' }"
      class="nav__element"
      :class="{ 'nav__element--active': elementIsActive('system') }"
    >
      <div class="nav__name">
        Jadłospisy systemowe
      </div>
      <BaseBadge>{{ user?.counters.diets.system }}</BaseBadge>
    </router-link>
    <router-link
      v-if="user?.is_leader"
      :to="{ name: 'DietListUniversity' }"
      class="nav__element"
      :class="{ 'nav__element--active': elementIsActive('university') }"
    >
      <div class="nav__name">
        Jadłospisy studentów
      </div>
      <BaseBadge>{{ getCounter('university') }}</BaseBadge>
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import type { DietType } from '@/store/types'
import type { PropType } from 'vue'

import { useEventListener } from '@vueuse/core'
import { computed, ref, watch } from 'vue'

import BaseBadge from '@/components/BaseBadge.vue'
import { useGlobalStore } from '@/store/globalStore'

const globalStore = useGlobalStore()
const emit = defineEmits(['dropFromNav'])

const props = defineProps({
  dietType: {
    required: true,
    type: String as PropType<DietType>
  },
  dietsCount: {
    type: Number,
    default: null
  },
  dragToNav: {
    type: Boolean,
    default: false
  }
})

const dietType = computed(() => props.dietType)
const dietsCount = computed(() => props.dietsCount)
const dragToNav = computed(() => props.dragToNav)
const dragActive = ref(false)
const dropHover = ref(false)

const user = computed(() => globalStore.user)

const activateDropHover = () => {
  dropHover.value = true
}
const deactivateDropHover = () => {
  dropHover.value = false
}
const getCounter = (type: Exclude<DietType, 'patient'>) => {
  const allCount = user.value?.counters.diets[type]
  if (dietType.value === type && dietsCount.value && dietsCount.value !== allCount) {
    return `${dietsCount.value} / ${allCount}`
  }
  return allCount
}
const elementIsActive = (type: DietType) => {
  return type === dietType.value
}
const emitDrop = (event: DragEvent) => {
  emit('dropFromNav', event)
}
useEventListener('dragleave', deactivateDropHover)

watch(dragToNav, (newValue) => {
  dragActive.value = newValue
})
</script>

<style scoped>
.nav {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

.nav__element {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  padding: 14px 15px 10px;
  text-decoration: none;
  border-bottom: 4px solid transparent;
}

.nav__name {
  font-family: Montserrat-Medium;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-tertiary-text);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav__element:hover .nav__name {
  color: var(--color-primary-text);
}

.nav__element.nav__element--active .nav__name {
  color: var(--color-primary-text);
}

.nav__element:hover :deep(.base-badge) {
  background: var(--color-primary-text);
}

.nav__element.nav__element--active :deep(.base-badge) {
  background: var(--color-main-100);
}

.nav__drop {
  padding: 4px 15px;
  margin: 4px 0 0;
}

.nav__drop--hover {
  background-color: #eef8f7;
  border-radius: 4px;
  padding: 3px 14px;
  margin: 4px 0;
  border: 1px solid #5dbab3;
}

.nav__drop-name {
  font-size: 9px;
  line-height: 12px;
}

.nav__element--active {
  border-bottom: 4px solid var(--color-main-100);
}
</style>
