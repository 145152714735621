import type { KcalmarTable } from '@/components/ui/KcalmarTable'
import type { ComputedRef, InjectionKey, Ref } from 'vue'
import type { ComponentExposed } from 'vue-component-type-helpers'

import { inject } from 'vue'
import { F } from '@mobily/ts-belt'

export type CustomPatient = {
  added_at: Date
  calories: number
  description: string
  email: string
  id: string
  name: string
  status: 'active' | 'inactive' | 'waiting'
  tags: string[]
}

export type PatientsListProvider = {
  data: ComputedRef<CustomPatient[]>
  isLoading: Ref<boolean>
  navigationHeight: Ref<number>
  navigationRef: Ref<HTMLDivElement | undefined>
  searchHeight: Ref<number>
  searchRef: Ref<HTMLDivElement | undefined>
  searchedPhrase: Ref<string>
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  tableRef: Ref<ComponentExposed<typeof KcalmarTable<CustomPatient>> | null>
}

export const PatientsListInject = Symbol() as InjectionKey<PatientsListProvider>

export const usePatientsProvider = () => F.coerce<PatientsListProvider>(inject(PatientsListInject))
