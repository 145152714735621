<template>
  <div
    class="base-slider"
    :class="color"
  >
    <progress
      class="base-slider__progress"
      :max="max"
      :value="modelValue"
    />
    <input
      v-if="!readonly"
      class="base-slider__input"
      type="range"
      min="0"
      :data-test-id="`BaseSlider__${testId}`"
      :max="max"
      :value="modelValue"
      @input="handleInput($event)"
    >
  </div>
</template>

<script lang="ts" setup>
defineProps({
  color: {
    default: 'green',
    type: String
  },
  disabled: {
    default: true,
    type: Boolean
  },
  max: {
    default: 100,
    type: Number
  },
  min: {
    default: 0,
    type: Number
  },
  step: {
    default: 1,
    type: Number
  },
  readonly: {
    default: false,
    type: Boolean
  },
  modelValue: {
    required: true,
    type: Number
  },
  testId: {
    required: true,
    type: String
  }
})
const emit = defineEmits(['update:modelValue'])
const handleInput = (event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).value)
}
</script>

<style scoped>
.green .base-slider__progress[value]::-webkit-progress-value {
  background-color: var(--color-main-100);
}

.green .base-slider__input::-webkit-slider-thumb {
  background-color: var(--color-main-100);
}

.green .base-slider__input::-moz-range-thumb {
  background-color: var(--color-main-100);
}

.pink .base-slider__progress[value]::-webkit-progress-value {
  background-color: #f16595;
}

.pink .base-slider__input::-webkit-slider-thumb {
  background-color: #f16595;
}

.pink .base-slider__input::-moz-range-thumb {
  background-color: #f16595;
}

.yellow .base-slider__progress[value]::-webkit-progress-value {
  background-color: #f1c365;
}

.yellow .base-slider__input::-webkit-slider-thumb {
  background-color: #f1c365;
}

.yellow .base-slider__input::-moz-range-thumb {
  background-color: #f1c365;
}

.purple .base-slider__progress[value]::-webkit-progress-value {
  background-color: #9f98ff;
}

.purple .base-slider__input::-webkit-slider-thumb {
  background-color: #9f98ff;
}

.purple .base-slider__input::-moz-range-thumb {
  background-color: #9f98ff;
}
</style>
