<template>
  <div class="dynamic-section">
    <div>
      <div
        v-for="(item, index) in store.userDashboard.content"
        :key="index"
        class="dynamic-section__item"
      >
        <p
          v-if="item.show_title"
          class="dynamic-section__item__title"
        >
          {{ item.title }}
        </p>
        <!-- content video -->
        <div
          v-if="item.type === 'video'"
          class="dynamic-section__item__video"
        >
          <iframe
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            :src="youtubeUrl(item.youtube_id)"
          />
        </div>
        <!-- content image -->
        <div
          v-if="item.type === 'image'"
          class="dynamic-section__item__image"
        >
          <a
            v-if="item.url"
            :href="item.url"
            :target="item.target"
          >
            <img :src="item.image">
          </a>
          <img
            v-if="!item.url"
            :src="item.image"
          >
        </div>
        <!-- content html -->
        <div
          v-if="item.type === 'html'"
          v-html="item.html"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDashboardStore } from '@/store/dashboardStore'

const store = useDashboardStore()

const youtubeUrl = (youtubeId: string) => {
  return `https://www.youtube.com/embed/${youtubeId}`
}
</script>

<style scoped>
.dynamic-section {
  padding-top: 26px;
}

.dynamic-section__item {
  font-size: 14px;
}

.dynamic-section__item :deep(p) {
  color: var(--color-secondary-text);
  line-height: 20px;
}

p.dynamic-section__item__title {
  font-size: 20px;
  font-style: normal;
  font-family: Montserrat-Medium;
  line-height: 28px;
  letter-spacing: -0.017em;
  text-align: left;
  margin: 9px 0;
  color: var(--color-primary-text);
}

.dynamic-section__item__image {
  width: 100%;
  text-align: center;
}

.dynamic-section__item__image img {
  max-width: 100%;
}

.dynamic-section__item__video {
  text-align: center;
}

.dynamic-section__item__video iframe {
  max-width: 640px;
  width: 100%;
  max-height: 390px;
  height: 100vw;
}
</style>
