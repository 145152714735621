<template>
  <router-link
    v-bind="props"
    :class="$style.root"
  >
    <slot />
  </router-link>
</template>

<script lang="ts" setup>
import type { RouterLinkProps } from 'vue-router'

import { useCssModule } from 'vue'

const props = defineProps<RouterLinkProps>()

const $style = useCssModule()
</script>

<style lang="scss" module scoped>
.root {
  @include font-style('base', 'semibold');

  padding: $space-2 0 $space-4;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
  color: $colors-alpha-400;

  &:global(.router-link-active) {
    border-bottom: 2px solid $state-primary-active;
    color: $colors-alpha-900;
  }
}

.root:hover {
  color: $colors-alpha-900;
}
</style>
