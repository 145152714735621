<template>
  <div
    class="form-field"
    :class="{ 'form-field--error': !!errorMessage, success: meta.valid, focused: isFocused }"
  >
    <label
      class="form-field__label"
      :for="name"
    >{{ props.label }}:</label>
    <input
      :id="name"
      class="form-field__input"
      :data-test-id="`${props.testId}__FormInput`"
      :name="name"
      :type="type"
      :value="inputValue"
      :placeholder="placeholder"
      @input="handleChange"
      @blur="handleBlur"
      @focus="handleFocus"
    >
    <p
      v-show="errorMessage"
      class="form-field__error"
    >
      {{ errorMessage }}
    </p>
    <BaseIcon
      v-if="errorMessage"
      class="form-field__icon"
      type="warning"
      size="18"
      name="error"
    />
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate'
import { onMounted, ref } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'

const props = defineProps({
  type: {
    type: String,
    default: 'text'
  },
  value: {
    type: String,
    default: undefined
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: ''
  },
  forceFocus: {
    type: Boolean,
    default: false
  },
  testId: {
    type: String,
    required: true
  }
})

const baseInput = ref<HTMLInputElement>()
const isFocused = ref(false)

const {
  value: inputValue,
  errorMessage,
  handleBlur: _handleBlur,
  handleChange,
  meta
} = useField(() => props.name, undefined, {
  initialValue: props.value,
  label: props.label
})

const handleFocus = () => {
  isFocused.value = true
}
const handleBlur = (e: Event) => {
  isFocused.value = false
  _handleBlur(e)
}

onMounted(() => {
  if (props.forceFocus) {
    baseInput.value?.focus()
  }
})
</script>
