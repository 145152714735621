import type { VariantPropsOf } from '@/utils/variants'
import type { PropType, SetupContext } from 'vue'

import { G } from '@mobily/ts-belt'
import { computed, defineComponent } from 'vue'

import { variants } from '@/utils/variants'

import s from './style.module.scss'
import { Space } from '../Space'
import { T } from '../T'

const radioStyles = variants({
  base: s.radio,
  variants: {
    size: {
      small: s['radio-size--small'],
      default: s['radio-size--default'],
      large: s['radio-size--large'],
      xl: s['radio-size--small'],
      xxl: s['radio-size--small']
    },
    disabled: {
      true: s.disabled
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

const labelStyles = variants({
  base: s.label,
  variants: {
    size: {
      small: s['label-size--small'],
      default: s['label-size--default'],
      large: s['label-size--large'],
      xl: s['label-size--xl'],
      xxl: s['label-size--xxl']
    },
    disabled: {
      true: s['label--disabled']
    },
    checked: {
      true: s['label--checked']
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

type Props = VariantPropsOf<typeof radioStyles> & {
  disabled?: boolean
  description?: string
  label: string
  modelValue: boolean
  name: string
}
type Events = {
  'update:modelValue': (value: boolean) => void
}

const Component = (props: Props, ctx: SetupContext<Events>) => {
  const radioClasses = computed(() => {
    return radioStyles({
      disabled: props.disabled,
      size: props.size
    })
  })

  const labelClasses = computed(() => {
    return labelStyles({
      disabled: props.disabled,
      checked: props.modelValue,
      size: props.size
    })
  })

  const clickHandler = () => {
    if (!props.disabled) {
      ctx.emit('update:modelValue', !props.modelValue)
    }
  }

  return () => (
    <label class={labelClasses.value} for={props.name}>
      <input
        type="radio"
        name={props.name}
        id={props.name}
        checked={props.modelValue}
        onChange={clickHandler}
      />
      <div class={radioClasses.value} />
      <Space space={0}>
        {G.isNotNullable(props.label) && (
          <T as="span" size="sm" type="semibold">
            {props.label}
          </T>
        )}
        {G.isNotNullable(props.description) && (
          <T as="span" size="xs" color="textSecondary">
            {props.description}
          </T>
        )}
      </Space>
    </label>
  )
}

export const KcalmarRadio = defineComponent(Component, {
  props: {
    size: String as PropType<Props['size']>,
    disabled: Boolean,
    description: String,
    label: String,
    modelValue: Boolean,
    name: String
  },
  emits: ['update:modelValue']
})
