<template>
  <div :class="$style.root">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { useCssModule } from 'vue'

const $style = useCssModule()
</script>

<style lang="scss" module scoped>
.root {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $space-9;
}
</style>
