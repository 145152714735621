import type { VariantPropsOf } from '@/utils/variants'
import type { PropType } from 'vue'

import { computed } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import { variants } from '@/utils/variants'

import './style.css'

const styles = variants({
  base: 'icon-button',
  variants: {
    variant: {
      primary: 'icon-button__variant--primary',
      secondary: 'icon-button__variant--secondary',
      outline: 'icon-button__variant--outline'
    },
    type: {
      default: '',
      warning: 'icon-button__type--warning',
      error: '',
      info: '',
      close: 'icon-button__type--close',
      destructive: 'icon-button__type--destructive',
      disabled: 'icon-button__type--disabled'
    },
    hoverType: {
      default: '',
      warning: 'icon-button__hover-type--warning',
      error: '',
      info: '',
      close: 'icon-button__hover-type--close',
      destructive: 'icon-button__hover-type--destructive',
      disabled: 'icon-button__hover-type--disabled'
    },
    size: {
      small: 'icon-button__size--small',
      default: 'icon-button__size--default',
      big: 'icon-button__size--big'
    },
    disabled: {
      true: 'icon-button--disabled'
    },
    rounded: {
      true: 'icon-button--rounded'
    }
  },
  compoundVariants: [
    {
      variants: {
        variant: 'primary',
        type: 'default'
      },
      className: 'icon-button__compound--primary-default'
    },
    {
      variants: {
        variant: 'primary',
        type: 'warning'
      },
      className: 'icon-button__compound--primary-warning'
    },
    {
      variants: {
        variant: 'primary',
        type: 'error'
      },
      className: 'icon-button__compound--primary-error'
    },
    {
      variants: {
        variant: 'primary',
        type: 'info'
      },
      className: 'icon-button__compound--primary-info'
    },
    {
      variants: {
        variant: 'primary',
        type: 'close'
      },
      className: 'icon-button__compound--primary-close'
    },
    {
      variants: {
        variant: 'primary',
        type: 'destructive'
      },
      className: 'icon-button__compound--primary-destructive'
    },
    {
      variants: {
        variant: 'secondary',
        type: 'default'
      },
      className: 'icon-button__compound--secondary-default'
    },
    {
      variants: {
        variant: 'secondary',
        type: 'warning'
      },
      className: 'icon-button__compound--secondary-warning'
    },
    {
      variants: {
        variant: 'secondary',
        type: 'error'
      },
      className: 'icon-button__compound--secondary-error'
    },
    {
      variants: {
        variant: 'secondary',
        type: 'info'
      },
      className: 'icon-button__compound--secondary-info'
    },
    {
      variants: {
        variant: 'secondary',
        type: 'destructive'
      },
      className: 'icon-button__compound--secondary-destructive'
    },
    {
      variants: {
        variant: 'outline',
        type: 'default'
      },
      className: 'icon-button__compound--outline-default'
    },
    {
      variants: {
        variant: 'outline',
        type: 'warning'
      },
      className: 'icon-button__compound--outline-warning'
    },
    {
      variants: {
        variant: 'outline',
        type: 'error'
      },
      className: 'icon-button__compound--outline-error'
    },
    {
      variants: {
        variant: 'outline',
        type: 'info'
      },
      className: 'icon-button__compound--outline-info'
    },
    {
      variants: {
        variant: 'outline',
        type: 'destructive'
      },
      className: 'icon-button__compound--outline-destructive'
    }
  ],
  defaultVariants: {
    variant: 'primary',
    type: 'default',
    hoverType: 'default',
    size: 'default',
    rounded: true
  }
})

type Props = VariantPropsOf<typeof styles> & {
  iconSize?: IconSize
  iconType?: IconType
  iconVariant?: IconVariant
  name: IconName
}

const iconSizeMap: Record<NonNullable<Props['size']>, IconSize> = {
  small: '16',
  default: '20',
  big: '24'
}

export const IconButton = (props: Props) => {
  const classes = computed(() => {
    return styles({
      type: props.type,
      variant: props.variant,
      disabled: props.disabled,
      size: props.size,
      rounded: props.rounded,
      hoverType: props.hoverType
    })
  })

  return (
    <button class={classes.value} disabled={props.disabled}>
      <BaseIcon
        size={props.iconSize ?? iconSizeMap[props.size ?? 'default']}
        name={props.name}
        type={props.iconType}
        variant={props.iconVariant}
      />
    </button>
  )
}

IconButton.props = {
  type: String as PropType<Props['type']>,
  hoverType: String as PropType<Props['hoverType']>,
  variant: String as PropType<Props['variant']>,
  size: String as PropType<Props['size']>,
  name: {
    type: String as PropType<IconName>,
    required: true
  },
  disabled: Boolean,
  rounded: {
    type: Boolean,
    default: true
  },
  iconSize: String as PropType<IconSize>,
  iconType: String as PropType<IconType>,
  iconVariant: String as PropType<IconVariant>
}
