<template>
  <div class="app-logout">
    <button
      data-test-id="AppLogoutButton"
      class="app-logout__button"
      @click="showModal"
    >
      <div class="app-logout__button-icon">
        <BaseIcon
          name="exit_to_app"
          size="16"
        />
      </div>
      Wyloguj się
    </button>
    <BaseModal
      v-if="modalVisible"
      @close="closeModal"
    >
      <template #title>
        Wylogowanie
      </template>
      Czy na pewno chcesz się wylogować?
      <template #actions>
        <StyledButton
          variant="outline"
          component-name="CancelLogoutButton"
          @click="closeModal"
        >
          Anuluj
        </StyledButton>
        <StyledButton
          component-name="ConfirmLogoutButton"
          @click="logout"
        >
          Wyloguj
        </StyledButton>
      </template>
    </BaseModal>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseModal from '@/components/BaseModal.vue'
import { StyledButton } from '@/components/StyledButton'
import { authService } from '@/services/authService'

const modalVisible = ref(false)

const showModal = () => {
  modalVisible.value = true
}
const closeModal = () => {
  modalVisible.value = false
}
const logout = () => {
  authService.deleteTokens()
  authService.redirectToLogoutPage()
}
</script>

<style>
.app-logout__button {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: var(--color-tertiary-text);
  font-family: Montserrat-Medium;
  font-size: 12px;
  background: transparent;
  line-height: 16px;
  border: 0;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
}

.app-logout__button-icon {
  border-radius: 50%;
  padding: 4px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
}

.app-logout__button:hover .app-logout__button-icon {
  background: var(--color-main-30);
  color: var(--color-main-110);
}

.app-logout__button .base-icon {
  color: var(--color-tertiary-text);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.app-logout__button:hover .base-icon {
  color: var(--color-main-110);
}
</style>
