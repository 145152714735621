<template>
  <div class="comments">
    <div class="comment-week">
      <div class="comment-week__header">
        <div class="comment-week__title">
          <BaseIcon
            size="22"
            name="chat"
          />
          <div class="comment-week__title-text">
            Komentarz do jadłospisu
          </div>
        </div>
        <div
          v-if="canDietBeEdited"
          class="comment-week__action"
        >
          <StyledButton
            :left-icon="weekCommentEmpty ? 'add' : 'edit'"
            variant="outline"
            @click="showWeekCommentModal"
          >
            {{ weekCommentEmpty ? 'Dodaj komentarz' : 'Edytuj komentarz' }}
          </StyledButton>

          <BaseModal
            v-if="weekCommentModal"
            :modal-class="'comment-week__modal'"
            @close="hideWeekCommentModal"
          >
            <template #title>
              {{ weekCommentEmpty ? 'Dodaj komentarz' : 'Edytuj komentarz' }}
            </template>

            <BaseEditor
              :value="tempWeekComment"
              @input="updateTempWeekCommentModal"
            />
            <template #actions>
              <StyledButton
                variant="outline"
                @click.once="hideWeekCommentModal"
              >
                Anuluj
              </StyledButton>
              <StyledButton @click.once="updateWeekCommentModal">
                Zapisz
              </StyledButton>
            </template>
          </BaseModal>
        </div>
      </div>
      <div
        v-if="weekCommentEmpty"
        class="comment-week__empty"
        @click="showWeekCommentModal"
      >
        Brak komentarza.<br>
        Dodaj komentarz do jadłospisu, klikając w przycisk
        <span class="comment-week__empty-button">+ Dodaj komentarz</span>.
      </div>
      <div
        v-else
        class="comment-week__content"
        @click="showWeekCommentModal"
      >
        <div class="comment-week__content-box">
          <div
            ref="commentWeekText"
            class="comment-week__content-text"
            :class="{ 'comment-week__content-text--more': showMore }"
            v-html="weekComment"
          />
        </div>
        <Space
          direction="columns"
          align-x="center"
        >
          <StyledButton
            v-if="showMoreButton"
            variant="outline"
            @click.prevent.stop="showMore = !showMore"
          >
            {{ showMore ? 'Pokaż mniej ...' : 'Pokaż więcej ...' }}
          </StyledButton>
        </Space>
      </div>
    </div>
    <div class="comments-grid">
      <div
        v-for="(day, dayIndex) in daysMap"
        :key="day"
      >
        <div class="day">
          <div class="day-name">
            {{ diet?.data.diet_type === 'numeric' ? daysNumericMap[dayIndex] : day }}
          </div>
          <template
            v-for="(commentOrMeal, commentOrMealIndex) in commentsAndMeals[dayIndex]"
            :key="`${dayIndex}-${commentOrMealIndex}`"
          >
            <template v-if="commentOrMeal.type === 'comment'">
              <div
                v-if="editedComment === `${dayIndex}-${commentOrMealIndex}`"
                class="edit-comment-box"
              >
                <div
                  class="edit-comment"
                  @click.stop
                >
                  <label>Wpisz tytuł komentarza</label>
                  <BaseInput
                    id="comment-input"
                    ref="dayCommentTitle"
                    test-id="DietDetailComments__Comment"
                    :get-ref="(el) => (dayCommentTitleInputRef = el)"
                    class="edit-comment__input"
                    :model-value="
                      getComment(
                        commentOrMeal.mealIndex,
                        commentOrMeal.dayIndex,
                        commentOrMeal.commentIndex
                      )?.title
                    "
                    maxlength="50"
                    @update:model-value="
                      handleUpdateCommentTitle(
                        commentOrMeal.mealIndex,
                        commentOrMeal.dayIndex,
                        commentOrMeal.commentIndex,
                        $event
                      )
                    "
                  />
                  <label>Wpisz treść komentarza</label>
                  <BaseTextarea
                    class="edit-comment__textarea"
                    :model-value="
                      getComment(
                        commentOrMeal.mealIndex,
                        commentOrMeal.dayIndex,
                        commentOrMeal.commentIndex
                      ).content
                    "
                    maxlength="5000"
                    @update:model-value="
                      handleUpdateCommentContent(
                        commentOrMeal.mealIndex,
                        commentOrMeal.dayIndex,
                        commentOrMeal.commentIndex,
                        $event
                      )
                    "
                  />
                </div>
              </div>
              <div
                v-else-if="!commentOrMeal.comment"
                class="add-comment-box"
              >
                <StyledButton
                  v-if="canDietBeEdited"
                  :left-icon="'add'"
                  variant="outline"
                  @click.stop="setEditedComment(`${dayIndex}-${commentOrMealIndex}`)"
                >
                  Dodaj komentarz
                </StyledButton>
              </div>
              <div
                v-else
                class="comment-box"
              >
                <div
                  class="comment"
                  :class="{ 'with-cursor': canDietBeEdited }"
                  @click.stop="
                    canDietBeEdited ? setEditedComment(`${dayIndex}-${commentOrMealIndex}`) : ''
                  "
                >
                  <div class="comment-title-content">
                    <div class="comment-title">
                      {{ commentOrMeal.comment.title }}
                    </div>
                    <div class="comment-content">
                      {{ commentOrMeal.comment.content }}
                    </div>
                  </div>
                  <div class="comment-delete">
                    <IconButton
                      v-if="canDietBeEdited"
                      size="small"
                      class="comment-delete__button"
                      name="delete"
                      variant="outline"
                      type="destructive"
                      @click.stop="
                        handleDeleteComment(
                          commentOrMeal.mealIndex,
                          commentOrMeal.dayIndex,
                          commentOrMeal.commentIndex
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </template>
            <div
              v-else
              class="meal-box"
            >
              <div class="meal">
                <div class="meal-name-hour">
                  <div class="meal-name">
                    {{ commentOrMeal.meal.name }}
                  </div>
                  <div class="meal-hour">
                    {{ commentOrMeal.meal.hour }}
                  </div>
                </div>
                <div class="meal-dishes-and-ingredients">
                  <div
                    v-for="(dishOrIngredientName, dishOrIngredientNameIndex) in commentOrMeal.meal
                      .dishesAndIngredients"
                    :key="`${dayIndex}-${commentOrMealIndex}-${dishOrIngredientNameIndex}`"
                    class="meal-dish-or-ingredient"
                  >
                    <span
                      v-if="
                        commentOrMeal.meal.dishesAndIngredients.length > 3 &&
                          dishOrIngredientNameIndex === 2
                      "
                    >{{ dishOrIngredientName }}...</span>
                    <span v-else>{{ dishOrIngredientName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Comment } from '@/types/Diet'

import { G, O, pipe } from '@mobily/ts-belt'
import { useEventListener } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onMounted, ref, watch } from 'vue'

import BaseEditor from '@/components/BaseEditor.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseInput from '@/components/BaseInput.vue'
import BaseModal from '@/components/BaseModal.vue'
import BaseTextarea from '@/components/BaseTextarea.vue'
import { IconButton } from '@/components/IconButton'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import { daysMap, daysNumericMap } from '@/const'
import { useDietsStore } from '@/store/dietsStore'

type ListComment = {
  comment?: { content: string; title: string }
  commentIndex: number
  dayIndex: number
  mealIndex: number
  type: 'comment'
}
type ListMeal = {
  meal: {
    dishesAndIngredients: string[]
    hour: string
    name: string
  }
  type: 'meal'
}

type ListCommentOrMeal = ListComment | ListMeal

const dietStore = useDietsStore()
const { updateCommentTitle, updateCommentContent, deleteComment } = dietStore
const { diet, canDietBeEdited } = storeToRefs(dietStore)
const editedComment = ref<string | undefined>()
const commentWeekText = ref<HTMLDivElement>()
const dayCommentTitleInputRef = ref<HTMLInputElement>()
const tempWeekComment = ref('')
const weekCommentModal = ref(false)
const showMore = ref(false)
const showMoreButton = ref(false)

const weekComment = computed({
  get() {
    return diet.value?.data.week_comment ?? ''
  },
  set(value: string) {
    dietStore.updateWeekComment(value.trim())
  }
})
const weekCommentEmpty = computed(() => {
  return weekComment.value === '' || weekComment.value === '<p></p>'
})

const commentsAndMeals = computed(() => {
  const list: ListCommentOrMeal[][] = []
  const meals = diet.value?.data.meals ?? []
  for (let dayIndex = 0; dayIndex < daysMap.length; dayIndex++) {
    list.push([])
    for (let mealIndex = 0; mealIndex < meals.length; mealIndex++) {
      const meal = meals[mealIndex]
      const mealDay = meal?.days[dayIndex]
      const dishesAndIngredients = mealDay?.dishes_and_ingredients ?? []

      let commentIndex = 0
      if (mealIndex === 0) {
        list[dayIndex]?.push({
          type: 'comment',
          mealIndex,
          dayIndex,
          commentIndex,
          comment: validateComment(mealDay?.comments[commentIndex])
        })
        commentIndex = 1
      }
      list[dayIndex]?.push({
        type: 'meal',
        meal: {
          name: meal?.name ?? '',
          hour: mealDay?.hour ?? '',
          dishesAndIngredients: dishesAndIngredients.map((dishOrIngredient) => {
            return dishOrIngredient.name || ''
          })
        }
      })
      list[dayIndex]?.push({
        type: 'comment',
        mealIndex,
        dayIndex,
        commentIndex,
        comment: validateComment(mealDay?.comments[commentIndex])
      })
    }
  }
  return list
})

const hideWeekCommentModal = (e?: MouseEvent) => {
  if (!e || (e.target as HTMLButtonElement).className !== 'base-modal-overlay') {
    weekCommentModal.value = false
    tempWeekComment.value = ''
  }
}
const showWeekCommentModal = () => {
  tempWeekComment.value = weekComment.value
  hideComment()
  weekCommentModal.value = true
}
const updateTempWeekCommentModal = (value: string) => {
  tempWeekComment.value = value
}
const updateWeekCommentModal = () => {
  weekComment.value = tempWeekComment.value
  tempWeekComment.value = ''
  hideWeekCommentModal()
}

const setEditedComment = (value: string) => {
  hideComment()
  editedComment.value = value
  if (dayCommentTitleInputRef.value) {
    setTimeout(() => {
      dayCommentTitleInputRef.value?.focus()
    }, 100) // We need this timeout because element is not created so fast
  }
}
const hideComment = (event?: MouseEvent | KeyboardEvent) => {
  if (event && event.type === 'keyup') {
    if ('code' in event && event.code === 'Escape') {
      editedComment.value = undefined
    }
  } else {
    editedComment.value = undefined
  }
}
const getComment = (mealIndex: number, dayIndex: number, commentIndex: number) => {
  let comment =
    diet.value?.data.meals[mealIndex]?.days?.[dayIndex]?.comments?.[commentIndex] ?? null
  if (G.isNullable(comment)) {
    comment = {
      title: '',
      content: ''
    }
  }
  return comment
}
const handleUpdateCommentTitle = (
  mealIndex: number,
  dayIndex: number,
  commentIndex: number,
  title: string
) => {
  updateCommentTitle({
    mealIndex,
    dayIndex,
    commentIndex,
    title: title.trim()
  })
}
const handleUpdateCommentContent = (
  mealIndex: number,
  dayIndex: number,
  commentIndex: number,
  content: string
) => {
  updateCommentContent({
    mealIndex,
    dayIndex,
    commentIndex,
    content: content.trim()
  })
}
const handleDeleteComment = (mealIndex: number, dayIndex: number, commentIndex: number) => {
  hideComment()
  deleteComment({
    mealIndex,
    dayIndex,
    commentIndex
  })
}
const validateComment = (comment?: Comment | null) => {
  return pipe(
    O.fromNullable(comment),
    O.filter((c) => {
      return !(c.title === '' && c.content === '')
    }),
    O.toUndefined
  )
}
const initWeekComment = () => {
  void nextTick(() => {
    if (commentWeekText.value) {
      if (commentWeekText.value.clientHeight >= 100) {
        showMoreButton.value = true
      } else {
        showMoreButton.value = false
      }
    }
  })
}
useEventListener(document, 'click', hideComment)
useEventListener(document, 'keyup', hideComment)

onMounted(() => {
  initWeekComment()
})

watch(weekComment, () => {
  initWeekComment()
})
</script>

<style>
.comment-week__modal {
  width: 900px !important;
}
</style>

<style scoped>
.comments {
  background: #fff;
}

.comment-week {
  padding: 16px;
}

.comment-week__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.comment-week__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.comment-week__title-text {
  font-size: 24px;
  font-family: Montserrat-Medium;
  margin-left: 8px;
}

.comment-week__empty {
  border: 1px dashed #f0f0f0;
  padding: 14px;
  font-size: 14px;
  line-height: 18px;
}

.comment-week__modal-actions {
  display: flex;
  flex-direction: row;
}

.comment-week__content {
  padding: 14px;
  border: 1px solid #f0f0f0;
}

.comment-week__content-box {
  position: relative;
}

.comment-week__content-text {
  white-space: pre-line;
  font-size: 14px;
  line-height: 18px;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  max-height: 100px;
}

.comment-week__content-text::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(0deg, rgb(255 255 255 / 100%) 0%, rgb(255 255 255 / 0%) 25%);
}

.comment-week__content-text--more {
  overflow: initial;
  max-height: none;
}

.comment-week__content-text--more::after {
  background: none;
}

.comments-grid {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  margin: 6px 0 24px;
}

.counter {
  color: var(--color-main-110);
  text-align: right;
  font-size: 11px;
}

.counter.warning {
  color: #f55f6d;
}

.day {
  padding: 0 6px;
  height: 100%;
}

.day:hover {
  box-shadow: 0 4px 5px 3px rgb(222 222 222 / 50%);
}

.day-name {
  font-size: 12px;
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.day:hover .day-name {
  font-family: Montserrat-Medium;
  color: var(--color-main-100);
}

.meal {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 11px 8px;
  display: flex;
  flex-direction: column;
  color: var(--color-tertiary-text);
  height: 68px;
  justify-content: space-between;
  box-sizing: content-box;
}

.meal-name-hour {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.meal-name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meal-hour {
  font-size: 11px;
  font-family: Montserrat-Medium;
  align-self: flex-start;
}

.meal-dishes-and-ingredients {
  font-size: 10px;
  max-height: 39px;
  overflow: hidden;
}

.meal-dish-or-ingredient {
  max-height: 13px;
  line-height: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.add-comment-box {
  display: flex;
  align-items: center;
  height: 48px;
}

.add-comment {
  display: flex;
  height: 48px;
  align-items: center;
}

.comment-box {
  overflow: hidden;
}

.comment-box,
.edit-comment-box {
  display: flex;
  height: 186px;
  align-items: center;
}

.comment {
  padding: 11px 8px;
  height: 68px;
  width: 100%;
  font-size: 10px;
  background: var(--color-main-10);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  box-sizing: content-box;
}

.comment.with-cursor,
.comment-week__show.with-cursor {
  cursor: pointer;
}

.comment-title-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.comment-title {
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.comment-content {
  max-height: 39px;
  line-height: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.comment-delete {
  display: flex;
  align-items: flex-end;
  min-width: 22px;
}

.comment-delete__button {
  display: none;
  position: relative;
  left: 2px;
  top: 5px;
}

.day:hover .comment-delete__button {
  display: block;
}

.edit-comment-box {
  display: flex;
  height: 164px;
  align-items: center;
}

.edit-comment {
  padding: 11px 8px;
  height: 146px;
  width: 100%;
  font-size: 10px;
  background: var(--color-main-10);
  border-radius: 4px;
}

.edit-comment label {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 12px;
  margin-bottom: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.edit-comment__input {
  width: 100%;
  margin-bottom: 12px;
}

.edit-comment__textarea {
  width: 100%;
  height: 67px !important;
  scrollbar-width: thin;
}

.edit-comment__textarea::-webkit-scrollbar {
  width: 6px;
}

.comment-week__edit .base-textarea {
  max-height: 200px;
  scrollbar-width: thin;
}

.comment-week__empty-button {
  font-weight: bold;
}
</style>
