/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createRouter, createWebHistory } from 'vue-router'

import PageNotFound from '@/pages/PageNotFound.vue'
import SentryTest from '@/pages/SentryTest.vue'
import { authService } from '@/services/authService'
import { useAuthStore } from '@/store/authStore'
import { useGlobalStore } from '@/store/globalStore'

import { authRouter } from './authRouter'
import { dashboardRouter } from './dashboardRouter'
import { dietsRouter } from './dietsRouter'
import { dishesRouter } from './dishesRouter'
import { ingredientsRouter } from './ingredientsRouter'
import { ingredientsV2Router } from './ingredientsV2Router'
import { nextRouter } from './nextRouter'
import { patientsRouter } from './patientsRouter'

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    authRouter,
    dashboardRouter,
    dietsRouter,
    ingredientsRouter,
    dishesRouter,
    patientsRouter,
    nextRouter,
    ingredientsV2Router,
    {
      path: '/',
      redirect: { name: 'DashboardBase' }
    },
    {
      path: '/sentry/test/',
      component: SentryTest,
      name: 'SentryTest',
      meta: {
        title: 'Sentry Test'
      }
    },
    {
      path: '/404',
      component: PageNotFound,
      name: 'PageNotFound',
      meta: {
        title: 'Nie znaleziono strony'
      }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'PageNotFound' }
    }
  ],
  scrollBehavior(_, __, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  }
})

router.beforeEach(async (to, _, next) => {
  const store = useGlobalStore()
  store.deleteMessages()
  if (to.matched.some((route) => route.meta.auth)) {
    try {
      const authStore = useAuthStore()
      await authStore.verifyAuth()
      const permissions = store.user?.permissions ?? []

      if (permissions.includes('accounts.kcalmar_plan')) {
        next()
      } else {
        window.location.assign(import.meta.env.VUE_APP_HOST + '/dietetyk/account/payments/')
      }
    } catch {
      authService.redirectToLoginPage(to.fullPath)
    }
  } else {
    next()
  }
})
