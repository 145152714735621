<template>
  <div
    class="base-switch"
    :class="{ 'base-switch--active': value }"
    @click="handleClick"
  >
    <div class="base-switch__line">
      <div class="base-switch__circle-out" />
      <div class="base-switch__circle-in" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  value: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['click'])

const handleClick = () => {
  emit('click', !props.value)
}
</script>
