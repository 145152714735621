<template>
  <div
    class="day"
    :class="{ details: !!details, extended: extended }"
  >
    <div class="schedule-nutrients">
      <DietDetailDaySchedule />
      <DietDetailDayNutrients />
    </div>
    <div
      ref="searchDetailsRef"
      class="search-details"
      :style="`height: calc(100vh - ${height}px);`"
    >
      <DietDetailDaySearch v-show="!details" />
      <DietDetailDayDishOrIngredient v-if="details" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useEventListener } from '@vueuse/core'
import round from 'lodash/round'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUpdated, ref } from 'vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'

import DietDetailDayDishOrIngredient from '@/components/diets/DietDetailDayDishOrIngredient.vue'
import DietDetailDayNutrients from '@/components/diets/DietDetailDayNutrients.vue'
import DietDetailDaySchedule from '@/components/diets/DietDetailDaySchedule.vue'
import DietDetailDaySearch from '@/components/diets/DietDetailDaySearch.vue'
import { useDietsStore } from '@/store/dietsStore'
import { reportError } from '@/utils/reportError'

const height = ref(1)
const dietStore = useDietsStore()
const { extended, details } = storeToRefs(dietStore)
const route = useRoute()
const dayIndex = computed(() => {
  const index = parseInt(route.params.dayIndex as string)
  if (isNaN(index)) {
    reportError(new Error('Invalid day index'), '', { dayIndex: route.params.dayIndex })
    return 0
  }
  return index
})
const searchDetailsRef = ref<HTMLElement>()

const calculateHeight = () => {
  if (searchDetailsRef.value) {
    const heightDifference = round(searchDetailsRef.value.getBoundingClientRect().top + 20)
    if (heightDifference !== height.value) {
      height.value = heightDifference
    }
  }
}

onMounted(() => {
  dietStore.clearUndoRedo()
  const storageMealIndex = sessionStorage.getItem('redirectMealIndex')
  const storageDishOrIngredientIndex = sessionStorage.getItem('redirectDishOrIngredientIndex')
  sessionStorage.removeItem('redirectMealIndex')
  sessionStorage.removeItem('redirectDishOrIngredientIndex')

  if (storageMealIndex && storageDishOrIngredientIndex) {
    const mealIndex = parseInt(storageMealIndex)
    const dishOrIngredientIndex = parseInt(storageDishOrIngredientIndex)
    if (isNaN(mealIndex) || isNaN(dishOrIngredientIndex)) {
      reportError(new Error('Invalid meal or dish index'), '', { mealIndex, dishOrIngredientIndex })
      return
    }
    dietStore.updateDetails({
      mealIndex,
      dayIndex: dayIndex.value,
      dishOrIngredientIndex
    })
  } else {
    dietStore.updateDetails()
  }
  setTimeout(calculateHeight, 0)
})

onUpdated(calculateHeight)

onBeforeRouteUpdate((_, __, next) => {
  dietStore.updateDetails()
  next()
})

useEventListener(document, 'scroll', calculateHeight)
</script>

<style scoped>
.day {
  display: flex;
  flex-direction: row;
}

.schedule-nutrients {
  flex-grow: 1;
  margin-right: 13px;
  min-width: 490px;
  width: 60%;
  background: #fff;
  box-shadow: 2px 4px 4px 0 rgb(232 232 232 / 50%);
  margin-bottom: 20px;
}

.search-details {
  position: sticky;
  top: 20px;
  width: 40%;
  min-width: 505px;
}

.extended .schedule-nutrients {
  display: none;
}

.extended .search-details {
  width: 100%;
}
</style>
