<template>
  <Space
    :space="2"
    direction="columns"
    class="of-hidden"
  >
    <KcalmarBadge
      v-for="tag in props.tags"
      :key="tag"
      size="small"
    >
      {{ tag }}
    </KcalmarBadge>
  </Space>
</template>
<script lang="ts" setup>
import { KcalmarBadge } from '@ui/KcalmarBadge'
import { Space } from '@ui/Space'

const props = defineProps<{ tags: string[] }>()
</script>
