/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { RouteRecordSingleViewWithChildren } from 'vue-router'

import NewLayout from '@/layouts/NewLayout.vue'
import KcalmarComponents from '@/pages/ui/KcalmarComponents.vue'

export const nextRouter: RouteRecordSingleViewWithChildren = {
  path: '/next',
  component: NewLayout,
  children: [
    {
      path: '',
      component: KcalmarComponents,
      name: 'Ui',
      meta: {
        title: 'Components'
      }
    }
  ]
}
