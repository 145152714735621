import { F } from '@mobily/ts-belt'
import { type Config, driver } from 'driver.js'
import { inject } from 'vue'
import type { InjectionKey, Plugin } from 'vue'

type Driver = ReturnType<typeof driver>

export const VueDriverSymbol = Symbol('VueDriver') as InjectionKey<Driver>

export const driverPlugin: Plugin = {
  install(app, driverOptions: Config = {}) {
    const driverObj = driver({
      ...driverOptions,
      onPopoverRender: (popover) => {
        for (const child of popover.footerButtons.children) {
          child.classList.add('base-button')
          child.classList.add('base-button--primary')
        }
      }
    })

    app.provide(VueDriverSymbol, driverObj)
  }
}

export function useDriver() {
  const _driver = F.coerce<Driver>(inject(VueDriverSymbol))

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!_driver) throw new Error('VueDriver not found. Did you forget to install the plugin?')

  return _driver
}
